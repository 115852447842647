export const unitGroupNames: string[] = [
    "Lance",
    "Star",
    "Level I",
    "Level II",
]

export const companyGroupNames: string[] = [
    "Company",
    "Binary",
    "Trinary",
    "Level III",
]