import { IEquipmentItem } from "./data-interfaces";

/*
* The data here is/may be copyrighted and NOT included in the GPLv3 license.
*/
export const mechISEquipmentMissiles: IEquipmentItem[] = [
    {
        isAmmo: true,
        name: "Ammo (LRM 5)",
        tag: "ammo-lrm-5",
        sort: "ammo, lrm-a",
        category: "Ammunition",
        alternateName: "Ammo (LRM-5)",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 30000,
        cbillsOneShot: 1250,
        introduced: 2300,
        extinct: 0,
        reintroduced: 0,
        battleValue: 6,
        heat: 0,
        weight: 1,
        range: {
            min: 0,
            short: 0,
            medium: 0,
            long: 0
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 24,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [],
        techRating: "c",
        book: "TM",
        page: 229,
        alphaStrike: {
            heat: 0,
            rangeShort: 0,
            rangeMedium: 0,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: false,
            notes: []
        },
        heatAero: 0
    },
    {
        isAmmo: true,
        name: "Ammo (LRM-5 (Artemis IV Capable))",
        tag: "ammo-lrm-5-artemis",
        sort: "ammo, lrm-a-artemis",
        category: "Ammunition",
        alternateName: "Ammo (LRM-5) (Artemis IV Capable)",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 60000,
        cbillsOneShot: 1250,
        introduced: 2300,
        extinct: 0,
        reintroduced: 0,
        battleValue: 6,
        heat: 0,
        weight: 1,
        range: {
            min: 0,
            short: 0,
            medium: 0,
            long: 0
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 24,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [],
        techRating: "c",
        book: "TM",
        page: 229,
        alphaStrike: {
            heat: 0,
            rangeShort: 0,
            rangeMedium: 0,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: false,
            notes: []
        },
        heatAero: 0
    },
    {
        isAmmo: true,
        name: "Ammo (LRM 10)",
        tag: "ammo-lrm-10",
        sort: "ammo, lrm-b",
        category: "Ammunition",
        alternateName: "Ammo (LRM-10)",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 30000,
        cbillsOneShot: 1250,
        introduced: 2300,
        extinct: 0,
        reintroduced: 0,
        battleValue: 11,
        heat: 0,
        weight: 1,
        range: {
            min: 0,
            short: 0,
            medium: 0,
            long: 0
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 12,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [],
        techRating: "c",
        book: "TM",
        page: 229,
        alphaStrike: {
            heat: 0,
            rangeShort: 0,
            rangeMedium: 0,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: false,
            notes: []
        },
        heatAero: 0
    },
    {
        isAmmo: true,
        name: "Ammo (LRM-10 (Artemis IV Capable))",
        tag: "ammo-lrm-10-artemis",
        sort: "ammo, lrm-b-artemis",
        category: "Ammunition",
        alternateName: "Ammo (LRM-10) (Artemis IV Capable)",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 60000,
        cbillsOneShot: 1250,
        introduced: 2300,
        extinct: 0,
        reintroduced: 0,
        battleValue: 11,
        heat: 0,
        weight: 1,
        range: {
            min: 0,
            short: 0,
            medium: 0,
            long: 0
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 12,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [],
        techRating: "c",
        book: "TM",
        page: 229,
        alphaStrike: {
            heat: 0,
            rangeShort: 0,
            rangeMedium: 0,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: false,
            notes: []
        },
        heatAero: 0
    },
    {
        isAmmo: true,
        name: "Ammo (LRM-15)",
        tag: "ammo-lrm-15",
        sort: "ammo, lrm-c",
        category: "Ammunition",
        alternateName: "Ammo (LRM-15)",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 30000,
        cbillsOneShot: 1250,
        introduced: 2300,
        extinct: 0,
        reintroduced: 0,
        battleValue: 17,
        heat: 0,
        weight: 1,
        range: {
            min: 0,
            short: 0,
            medium: 0,
            long: 0
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 8,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [],
        techRating: "c",
        book: "TM",
        page: 229,
        alphaStrike: {
            heat: 0,
            rangeShort: 0,
            rangeMedium: 0,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: false,
            notes: []
        },
        heatAero: 0
    },
    {
        isAmmo: true,
        name: "Ammo (LRM-15 (Artemis IV Capable))",
        tag: "ammo-lrm-15-artemis",
        sort: "ammo, lrm-c-artemis",
        category: "Ammunition",
        alternateName: "Ammo (LRM-15) (Artemis IV Capable)",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 60000,
        cbillsOneShot: 1250,
        introduced: 2300,
        extinct: 0,
        reintroduced: 0,
        battleValue: 17,
        heat: 0,
        weight: 1,
        range: {
            min: 0,
            short: 0,
            medium: 0,
            long: 0
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 8,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [],
        techRating: "c",
        book: "TM",
        page: 229,
        alphaStrike: {
            heat: 0,
            rangeShort: 0,
            rangeMedium: 0,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: false,
            notes: []
        },
        heatAero: 0
    },
    {
        isAmmo: true,
        name: "Ammo (LRM 20)",
        tag: "ammo-lrm-20",
        sort: "ammo, lrm-d",
        category: "Ammunition",
        alternateName: "Ammo (LRM-20)",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 30000,
        cbillsOneShot: 1250,
        introduced: 2300,
        extinct: 0,
        reintroduced: 0,
        battleValue: 23,
        heat: 0,
        weight: 1,
        range: {
            min: 0,
            short: 0,
            medium: 0,
            long: 0
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 6,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [],
        techRating: "c",
        book: "TM",
        page: 229,
        alphaStrike: {
            heat: 0,
            rangeShort: 0,
            rangeMedium: 0,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: false,
            notes: []
        },
        heatAero: 0
    },
    {
        isAmmo: true,
        name: "Ammo (LRM-20 (Artemis IV Capable))",
        tag: "ammo-lrm-20-artemis",
        sort: "ammo, lrm-d-artemis",
        category: "Ammunition",
        alternateName: "Ammo (LRM-20) (Artemis IV Capable)",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 60000,
        cbillsOneShot: 1250,
        introduced: 2300,
        extinct: 0,
        reintroduced: 0,
        battleValue: 23,
        heat: 0,
        weight: 1,
        range: {
            min: 0,
            short: 0,
            medium: 0,
            long: 0
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 6,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [],
        techRating: "c",
        book: "TM",
        page: 229,
        alphaStrike: {
            heat: 0,
            rangeShort: 0,
            rangeMedium: 0,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: false,
            notes: []
        },
        heatAero: 0
    },
    {
        isAmmo: true,
        name: "Ammo (SRM 2)",
        tag: "ammo-srm-2",
        sort: "ammo, srm-2",
        category: "Ammunition",
        alternateName: "Ammo (SRM-2)",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 27000,
        cbillsOneShot: 1250,
        introduced: 2370,
        extinct: 0,
        reintroduced: 0,
        battleValue: 3,
        heat: 0,
        weight: 1,
        range: {
            min: 0,
            short: 0,
            medium: 0,
            long: 0
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 50,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [],
        techRating: "c",
        book: "TM",
        page: 229,
        alphaStrike: {
            heat: 0,
            rangeShort: 0,
            rangeMedium: 0,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: false,
            notes: []
        },
        heatAero: 0
    },
    {
        isAmmo: true,
        name: "Ammo (SRM 4)",
        tag: "ammo-srm-4",
        sort: "ammo, srm-4",
        category: "Ammunition",
        alternateName: "Ammo (SRM-4)",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 27000,
        cbillsOneShot: 1250,
        introduced: 2370,
        extinct: 0,
        reintroduced: 0,
        battleValue: 5,
        heat: 0,
        weight: 1,
        range: {
            min: 0,
            short: 0,
            medium: 0,
            long: 0
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 25,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [],
        techRating: "c",
        book: "TM",
        page: 229,
        alphaStrike: {
            heat: 0,
            rangeShort: 0,
            rangeMedium: 0,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: false,
            notes: []
        },
        heatAero: 0
    },
    {
        isAmmo: true,
        name: "Ammo (SRM 4 (Artemis IV Capable))",
        tag: "ammo-srm-4",
        sort: "ammo, srm-4",
        category: "Ammunition",
        alternateName: "Ammo (SRM-4) (Artemis IV Capable)",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 54000,
        cbillsOneShot: 1250,
        introduced: 2370,
        extinct: 0,
        reintroduced: 0,
        battleValue: 5,
        heat: 0,
        weight: 1,
        range: {
            min: 0,
            short: 0,
            medium: 0,
            long: 0
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 25,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [],
        techRating: "c",
        book: "TM",
        page: 229,
        alphaStrike: {
            heat: 0,
            rangeShort: 0,
            rangeMedium: 0,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: false,
            notes: []
        },
        heatAero: 0
    },
    {
        isAmmo: true,
        name: "Ammo (SRM 6)",
        tag: "ammo-srm-6",
        sort: "ammo, srm-6",
        category: "Ammunition",
        alternateName: "Ammo (SRM-6)",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 27000,
        cbillsOneShot: 1250,
        introduced: 2370,
        extinct: 0,
        reintroduced: 0,
        battleValue: 7,
        heat: 0,
        weight: 1,
        range: {
            min: 0,
            short: 0,
            medium: 0,
            long: 0
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 15,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [],
        techRating: "c",
        book: "TM",
        page: 229,
        alphaStrike: {
            heat: 0,
            rangeShort: 0,
            rangeMedium: 0,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: false,
            notes: []
        },
        heatAero: 0
    },
    {
        isAmmo: true,
        name: "Ammo (SRM 6 (Artemis IV Capable))",
        tag: "ammo-srm-6",
        sort: "ammo, srm-6",
        category: "Ammunition",
        alternateName: "Ammo (SRM-6) (Artemis IV Capable)",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 54000,
        cbillsOneShot: 1250,
        introduced: 2370,
        extinct: 0,
        reintroduced: 0,
        battleValue: 7,
        heat: 0,
        weight: 1,
        range: {
            min: 0,
            short: 0,
            medium: 0,
            long: 0
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 15,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [],
        techRating: "c",
        book: "TM",
        page: 229,
        alphaStrike: {
            heat: 0,
            rangeShort: 0,
            rangeMedium: 0,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: false,
            notes: []
        },
        heatAero: 0
    },
    {
        isAmmo: true,
        name: "Ammo (SRM-6 (Artemis IV Capable))",
        tag: "ammo-srm-6-artemis4",
        sort: "ammo, srm-6-artemis4",
        category: "Ammunition",
        alternateName: "Ammo (SRM-6) (Artemis IV Capable)",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 54000,
        cbillsOneShot: 1250,
        introduced: 2370,
        extinct: 0,
        reintroduced: 0,
        battleValue: 7,
        heat: 0,
        weight: 1,
        range: {
            min: 0,
            short: 0,
            medium: 0,
            long: 0
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 15,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [],
        techRating: "c",
        book: "TM",
        page: 229,
        alphaStrike: {
            heat: 0,
            rangeShort: 0,
            rangeMedium: 0,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: false,
            notes: []
        },
        heatAero: 0
    },
    {
        isAmmo: true,
        name: "Ammo (Streak SRM 2)",
        tag: "ammo-streak-srm-2",
        sort: "ammo, streak-srm-2",
        category: "Ammunition",
        alternateName: "Ammo (Streak SRM-2)",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 54000,
        cbillsOneShot: 1250,
        introduced: 2647,
        extinct: 2845,
        reintroduced: 3035,
        battleValue: 4,
        heat: 0,
        weight: 1,
        range: {
            min: 0,
            short: 0,
            medium: 0,
            long: 0
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 50,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [],
        techRating: "c",
        book: "TM",
        page: 229,
        alphaStrike: {
            heat: 0,
            rangeShort: 0,
            rangeMedium: 0,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: false,
            notes: []
        },
        heatAero: 0
    },
    {
        isAmmo: true,
        name: "Ammo (Streak SRM 4)",
        tag: "ammo-streak-srm-4",
        sort: "ammo, streak-srm-4",
        category: "Ammunition",
        alternateName: "Ammo (Streak SRM-4)",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 54000,
        cbillsOneShot: 1250,
        introduced: 3058,
        extinct: 0,
        reintroduced: 0,
        battleValue: 7,
        heat: 0,
        weight: 1,
        range: {
            min: 0,
            short: 0,
            medium: 0,
            long: 0
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 50,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [],
        techRating: "c",
        book: "TM",
        page: 229,
        alphaStrike: {
            heat: 0,
            rangeShort: 0,
            rangeMedium: 0,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: false,
            notes: []
        },
        heatAero: 0
    },
    {
        isAmmo: true,
        name: "Ammo (Streak SRM 6)",
        tag: "ammo-streak-srm-6",
        sort: "ammo, streak-srm-6",
        category: "Ammunition",
        alternateName: "Ammo (Streak SRM-6)",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 54000,
        cbillsOneShot: 1250,
        introduced: 3058,
        extinct: 0,
        reintroduced: 0,
        battleValue: 18,
        heat: 0,
        weight: 1,
        range: {
            min: 0,
            short: 0,
            medium: 0,
            long: 0
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 50,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [],
        techRating: "c",
        book: "TM",
        page: 229,
        alphaStrike: {
            heat: 0,
            rangeShort: 0,
            rangeMedium: 0,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: false,
            notes: []
        },
        heatAero: 0
    },
    {
        name: "LRM 5",
        tag: "lrm-5",
        sort: "lrm-a",
        category: "Missile Weapons",
        alternateName: "LRM-5",
        damage: 0,
        notes: "",
        damageAero: 3,
        accuracyModifier: 0,
        cbills: 30000,
        introduced: 2300,
        extinct: 0,
        reintroduced: 0,
        battleValue: 45,
        battleValueOneShot: 9,
        heat: 2,
        weight: 2,
        range: {
            min: 6,
            short: 7,
            medium: 14,
            long: 21
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 24,
        minAmmoTons: 1,
        explosive: false,
        weaponType: [],
        techRating: "c",
        book: "TM",
        page: 229,
        alphaStrike: {
            heat: 2,
            rangeShort: 0.15,
            rangeMedium: 0.3,
            rangeLong: 0.3,
            rangeExtreme: 0,
            tc: false,
            notes: [
                "LRM",
                "Indirect Fire"
            ]
        },
        damageClusters: 5,
        damagePerCluster: 1,
        heatAero: 2,
        rangeAero: "l"
    },
    {
        name: "LRM 5 + Artemis IV",
        tag: "lrm-5-artemis-iv",
        sort: "lrm-a-artemis-iv",
        category: "Missile Weapons",
        damage: 0,
        notes: "",
        damageAero: 3,
        accuracyModifier: 0,
        cbills: 130000,
        introduced: 2598,
        extinct: 2855,
        reintroduced: 3035,
        battleValue: 53,
        battleValueOneShot: 9,
        heat: 2,
        weight: 3,
        range: {
            min: 6,
            short: 7,
            medium: 14,
            long: 21
        },
        space: {
            battlemech: 2,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 2,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 24,
        minAmmoTons: 1,
        explosive: false,
        weaponType: [],
        techRating: "c",
        book: "TM",
        page: 229,
        alphaStrike: {
            heat: 2,
            rangeShort: 0.2,
            rangeMedium: 0.4,
            rangeLong: 0.4,
            rangeExtreme: 0,
            tc: false,
            notes: [
                "LRM",
                "Indirect Fire"
            ]
        },
        damageClusters: 5,
        damagePerCluster: 1,
        heatAero: 2,
        rangeAero: "l"
    },
    {
        name: "LRM 10",
        tag: "lrm-10",
        sort: "lrm-b",
        category: "Missile Weapons",
        alternateName: "LRM-10",
        damage: 0,
        notes: "",
        damageAero: 3,
        accuracyModifier: 0,
        cbills: 100000,
        introduced: 2300,
        extinct: 0,
        reintroduced: 0,
        battleValue: 90,
        battleValueOneShot: 9,
        heat: 4,
        weight: 5,
        range: {
            min: 6,
            short: 7,
            medium: 14,
            long: 21
        },
        space: {
            battlemech: 2,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 2,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 24,
        minAmmoTons: 1,
        explosive: false,
        weaponType: [],
        techRating: "c",
        book: "TM",
        page: 229,
        alphaStrike: {
            heat: 4,
            rangeShort: 0.3,
            rangeMedium: 0.6,
            rangeLong: 0.6,
            rangeExtreme: 0,
            tc: false,
            notes: [
                "LRM",
                "Indirect Fire"
            ]
        },
        damageClusters: 10,
        damagePerCluster: 1,
        heatAero: 4,
        rangeAero: "l"
    },
    {
        name: "LRM 10 + Artemis IV",
        tag: "lrm-10-artemis-iv",
        sort: "lrm-b-artemis-iv",
        category: "Missile Weapons",
        damage: 0,
        notes: "",
        damageAero: 3,
        accuracyModifier: 0,
        cbills: 200000,
        introduced: 2598,
        extinct: 2855,
        reintroduced: 3035,
        battleValue: 98,
        battleValueOneShot: 9,
        heat: 4,
        weight: 6,
        range: {
            min: 6,
            short: 7,
            medium: 14,
            long: 21
        },
        space: {
            battlemech: 3,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 3,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 24,
        minAmmoTons: 1,
        explosive: false,
        weaponType: [],
        techRating: "c",
        book: "TM",
        page: 229,
        alphaStrike: {
            heat: 4,
            rangeShort: 0.4,
            rangeMedium: 0.8,
            rangeLong: 0.8,
            rangeExtreme: 0,
            tc: false,
            notes: [
                "LRM",
                "Indirect Fire"
            ]
        },
        damageClusters: 10,
        damagePerCluster: 1,
        heatAero: 4,
        rangeAero: "l"
    },
    {
        name: "LRM 15",
        tag: "lrm-15",
        sort: "lrm-c",
        category: "Missile Weapons",
        alternateName: "LRM-15",
        damage: 0,
        notes: "",
        damageAero: 3,
        accuracyModifier: 0,
        cbills: 175000,
        introduced: 2300,
        extinct: 0,
        reintroduced: 0,
        battleValue: 136,
        battleValueOneShot: 9,
        heat: 5,
        weight: 7,
        range: {
            min: 6,
            short: 7,
            medium: 14,
            long: 21
        },
        space: {
            battlemech: 3,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 3,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 24,
        minAmmoTons: 1,
        explosive: false,
        weaponType: [],
        techRating: "c",
        book: "TM",
        page: 229,
        alphaStrike: {
            heat: 5,
            rangeShort: 0.45,
            rangeMedium: 0.9,
            rangeLong: 0.9,
            rangeExtreme: 0,
            tc: false,
            notes: [
                "LRM",
                "Indirect Fire"
            ]
        },
        damageClusters: 15,
        damagePerCluster: 1,
        heatAero: 6,
        rangeAero: "l"
    },
    {
        name: "LRM 15 + Artemis IV",
        tag: "lrm-15-artemis-iv",
        sort: "lrm-c-artemis-iv",
        category: "Missile Weapons",
        damage: 0,
        notes: "",
        damageAero: 3,
        accuracyModifier: 0,
        cbills: 275000,
        introduced: 2598,
        extinct: 2855,
        reintroduced: 3035,
        battleValue: 144,
        battleValueOneShot: 9,
        heat: 6,
        weight: 8,
        range: {
            min: 6,
            short: 7,
            medium: 14,
            long: 21
        },
        space: {
            battlemech: 4,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 4,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 24,
        minAmmoTons: 1,
        explosive: false,
        weaponType: [],
        techRating: "c",
        book: "TM",
        page: 229,
        alphaStrike: {
            heat: 5,
            rangeShort: 0.6,
            rangeMedium: 1.2,
            rangeLong: 1.2,
            rangeExtreme: 0,
            tc: false,
            notes: [
                "LRM",
                "Indirect Fire"
            ]
        },
        damageClusters: 15,
        damagePerCluster: 1,
        heatAero: 6,
        rangeAero: "l"
    },
    {
        name: "LRM 20",
        tag: "lrm-20",
        sort: "lrm-d",
        category: "Missile Weapons",
        alternateName: "LRM-20",
        damage: 0,
        notes: "",
        damageAero: 3,
        accuracyModifier: 0,
        cbills: 250000,
        introduced: 2300,
        extinct: 0,
        reintroduced: 0,
        battleValue: 181,
        battleValueOneShot: 9,
        heat: 6,
        weight: 10,
        range: {
            min: 6,
            short: 7,
            medium: 14,
            long: 21
        },
        space: {
            battlemech: 5,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 5,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 24,
        minAmmoTons: 1,
        explosive: false,
        weaponType: [],
        techRating: "c",
        book: "TM",
        page: 229,
        alphaStrike: {
            heat: 5,
            rangeShort: 0.6,
            rangeMedium: 1.2,
            rangeLong: 1.2,
            rangeExtreme: 0,
            tc: true,
            notes: [
                "LRM",
                "Indirect Fire"
            ]
        },
        damageClusters: 20,
        damagePerCluster: 1,
        heatAero: 6,
        rangeAero: "l"
    },
    {
        name: "LRM 20 + Artemis IV",
        tag: "lrm-20-artemis-iv",
        sort: "lrm-d-artemis-iv",
        category: "Missile Weapons",
        damage: 0,
        notes: "",
        damageAero: 3,
        accuracyModifier: 0,
        cbills: 350000,
        introduced: 2598,
        extinct: 2855,
        reintroduced: 3035,
        battleValue: 189,
        battleValueOneShot: 9,
        heat: 6,
        weight: 11,
        range: {
            min: 6,
            short: 7,
            medium: 14,
            long: 21
        },
        space: {
            battlemech: 6,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 6,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 24,
        minAmmoTons: 1,
        explosive: false,
        weaponType: [],
        techRating: "c",
        book: "TM",
        page: 229,
        alphaStrike: {
            heat: 5,
            rangeShort: 0.8,
            rangeMedium: 1.6,
            rangeLong: 1.6,
            rangeExtreme: 0,
            tc: false,
            notes: [
                "LRM",
                "Indirect Fire"
            ]
        },
        damageClusters: 20,
        damagePerCluster: 1,
        heatAero: 6,
        rangeAero: "l"
    },
    {
        name: "SRM 2",
        tag: "srm-2",
        sort: "srm-2",
        category: "Missile Weapons",
        alternateName: "SRM-2",
        damage: 0,
        notes: "",
        damageAero: 3,
        accuracyModifier: 0,
        cbills: 10000,
        introduced: 2370,
        extinct: 0,
        reintroduced: 0,
        battleValue: 21,
        battleValueOneShot: 9,
        heat: 2,
        weight: 1,
        range: {
            min: 0,
            short: 3,
            medium: 6,
            long: 9
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 24,
        minAmmoTons: 1,
        explosive: false,
        weaponType: [],
        techRating: "c",
        book: "TM",
        page: 229,
        alphaStrike: {
            heat: 2,
            rangeShort: 0.2,
            rangeMedium: 0.2,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: false,
            notes: [
                "SRM"
            ]
        },
        damageClusters: 2,
        damagePerCluster: 2,
        heatAero: 2,
        rangeAero: "s"
    },
    {
        name: "SRM 2 + Artemis IV",
        tag: "srm-2-artemis-iv",
        sort: "srm-2-artemis-iv",
        category: "Missile Weapons",
        damage: 0,
        notes: "",
        damageAero: 3,
        accuracyModifier: 0,
        cbills: 110000,
        introduced: 2598,
        extinct: 2855,
        reintroduced: 3035,
        battleValue: 29,
        battleValueOneShot: 9,
        heat: 2,
        weight: 2,
        range: {
            min: 0,
            short: 3,
            medium: 6,
            long: 9
        },
        space: {
            battlemech: 2,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 2,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 24,
        minAmmoTons: 1,
        explosive: false,
        weaponType: [],
        techRating: "c",
        book: "TM",
        page: 229,
        alphaStrike: {
            heat: 2,
            rangeShort: 0.4,
            rangeMedium: 0.4,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: false,
            notes: [
                "SRM"
            ]
        },
        damageClusters: 2,
        damagePerCluster: 2,
        heatAero: 2,
        rangeAero: "s"
    },
    {
        name: "SRM 4",
        tag: "srm-4",
        sort: "srm-4",
        category: "Missile Weapons",
        alternateName: "SRM-4",
        damage: 0,
        notes: "",
        damageAero: 3,
        accuracyModifier: 0,
        cbills: 60000,
        introduced: 2370,
        extinct: 0,
        reintroduced: 0,
        battleValue: 39,
        battleValueOneShot: 9,
        heat: 3,
        weight: 2,
        range: {
            min: 0,
            short: 3,
            medium: 6,
            long: 9
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 24,
        minAmmoTons: 1,
        explosive: false,
        weaponType: [],
        techRating: "c",
        book: "TM",
        page: 229,
        alphaStrike: {
            heat: 3,
            rangeShort: 0.6,
            rangeMedium: 0.6,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: false,
            notes: [
                "SRM"
            ]
        },
        damageClusters: 4,
        damagePerCluster: 2,
        heatAero: 3,
        rangeAero: "s"
    },
    {
        name: "SRM 4 + Artemis IV",
        tag: "srm-4-artemis-iv",
        sort: "srm-4-artemis-iv",
        category: "Missile Weapons",
        damage: 0,
        notes: "",
        damageAero: 3,
        accuracyModifier: 0,
        cbills: 160000,
        introduced: 2598,
        extinct: 2855,
        reintroduced: 3035,
        battleValue: 47,
        battleValueOneShot: 9,
        heat: 3,
        weight: 3,
        range: {
            min: 0,
            short: 3,
            medium: 6,
            long: 9
        },
        space: {
            battlemech: 2,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 2,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 24,
        minAmmoTons: 1,
        explosive: false,
        weaponType: [],
        techRating: "c",
        book: "TM",
        page: 229,
        alphaStrike: {
            heat: 3,
            rangeShort: 0.6,
            rangeMedium: 0.6,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: false,
            notes: [
                "SRM"
            ]
        },
        damageClusters: 4,
        damagePerCluster: 2,
        heatAero: 3,
        rangeAero: "s"
    },
    {
        name: "SRM 6",
        tag: "srm-6",
        sort: "srm-6",
        category: "Missile Weapons",
        alternateName: "SRM-6",
        damage: 0,
        notes: "",
        damageAero: 3,
        accuracyModifier: 0,
        cbills: 80000,
        introduced: 2370,
        extinct: 0,
        reintroduced: 0,
        battleValue: 59,
        battleValueOneShot: 9,
        heat: 4,
        weight: 3,
        range: {
            min: 0,
            short: 3,
            medium: 6,
            long: 9
        },
        space: {
            battlemech: 2,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 2,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 24,
        minAmmoTons: 1,
        explosive: false,
        weaponType: [],
        techRating: "c",
        book: "TM",
        page: 229,
        alphaStrike: {
            heat: 4,
            rangeShort: 0.8,
            rangeMedium: 0.8,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: false,
            notes: [
                "SRM"
            ]
        },
        damageClusters: 6,
        damagePerCluster: 2,
        heatAero: 4,
        rangeAero: "s"
    },
    {
        name: "SRM 6 + Artemis IV",
        tag: "srm-6-artemis-iv",
        sort: "srm-6-artemis-iv",
        category: "Missile Weapons",
        damage: 0,
        notes: "",
        damageAero: 3,
        accuracyModifier: 0,
        cbills: 180000,
        introduced: 2598,
        extinct: 2855,
        reintroduced: 3035,
        battleValue: 67,
        battleValueOneShot: 9,
        heat: 4,
        weight: 3,
        range: {
            min: 0,
            short: 3,
            medium: 6,
            long: 9
        },
        space: {
            battlemech: 3,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 3,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 24,
        minAmmoTons: 1,
        explosive: false,
        weaponType: [],
        techRating: "c",
        book: "TM",
        page: 229,
        alphaStrike: {
            heat: 4,
            rangeShort: 1,
            rangeMedium: 1,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: false,
            notes: [
                "SRM"
            ]
        },
        damageClusters: 6,
        damagePerCluster: 2,
        heatAero: 4,
        rangeAero: "s"
    },
    {
        name: "Streak SRM 2",
        tag: "streak-srm-2",
        sort: "streak-srm-2",
        category: "Missile Weapons",
        alternateName: "Streak SRM-2",
        damage: 0,
        notes: "",
        damageAero: 3,
        accuracyModifier: 0,
        cbills: 15000,
        introduced: 2647,
        extinct: 2845,
        reintroduced: 3035,
        battleValue: 30,
        battleValueOneShot: 9,
        heat: 2,
        weight: 1.5,
        range: {
            min: 0,
            short: 3,
            medium: 6,
            long: 9
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 24,
        minAmmoTons: 1,
        explosive: false,
        weaponType: [],
        techRating: "c",
        book: "TM",
        page: 229,
        alphaStrike: {
            heat: 2,
            rangeShort: 0.4,
            rangeMedium: 0.4,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: false,
            notes: [
                ""
            ]
        },
        damageClusters: 2,
        damagePerCluster: 2,
        heatAero: 2,
        rangeAero: "s"
    },
    {
        name: "Streak SRM 4",
        tag: "streak-srm-4",
        sort: "streak-srm-4",
        category: "Missile Weapons",
        alternateName: "Streak SRM-4",
        damage: 0,
        notes: "",
        damageAero: 3,
        accuracyModifier: 0,
        cbills: 90000,
        introduced: 2370,
        extinct: 0,
        reintroduced: 0,
        battleValue: 59,
        battleValueOneShot: 9,
        heat: 3,
        weight: 5,
        range: {
            min: 0,
            short: 3,
            medium: 6,
            long: 9
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 24,
        minAmmoTons: 1,
        explosive: false,
        weaponType: [],
        techRating: "c",
        book: "TM",
        page: 229,
        alphaStrike: {
            heat: 3,
            rangeShort: 0.8,
            rangeMedium: 0.8,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: false,
            notes: [
                ""
            ]
        },
        damageClusters: 4,
        damagePerCluster: 2,
        heatAero: 3,
        rangeAero: "s"
    },
    {
        name: "Streak SRM 6",
        tag: "streak-srm-6",
        sort: "streak-srm-6",
        category: "Missile Weapons",
        alternateName: "Streak SRM-6",
        damage: 0,
        notes: "",
        damageAero: 3,
        accuracyModifier: 0,
        cbills: 120000,
        introduced: 2370,
        extinct: 0,
        reintroduced: 0,
        battleValue: 89,
        battleValueOneShot: 9,
        heat: 4,
        weight: 4.5,
        range: {
            min: 0,
            short: 3,
            medium: 6,
            long: 9
        },
        space: {
            battlemech: 2,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 2,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 24,
        minAmmoTons: 1,
        explosive: false,
        weaponType: [],
        techRating: "c",
        book: "TM",
        page: 229,
        alphaStrike: {
            heat: 4,
            rangeShort: 1.2,
            rangeMedium: 1.2,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: false,
            notes: [
                ""
            ]
        },
        damageClusters: 6,
        damagePerCluster: 2,
        heatAero: 4,
        rangeAero: "s"
    }
]