/*
    The data here is/may be copyrighted and NOT included in the GPLv3 license.
    This data is blatantly and lovingly copied from the Solaris Skunk Werks project at https://github.com/Solaris-Skunk-Werks/SSW-Master
*/
export const sswMechs: string[] = [
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Archer" model="ARC-2K" tons="70" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1356</battle_value>
    <cost>6086773.333333335</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Earthwerks Incorporated, Vandenberg Mechanized Industries, Gorton, Kingsley &amp; Thorpe Enterprises (GKT)" location="Calloway VI (Earthwerks), New Vandenberg (VMI), Satalice (GKT)">Inner Sphere</techbase>
    <year restricted="FALSE">2856</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Earthwerks Archer" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="280" manufacturer="VOX" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Maximillian 100" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>29</ct>
        <ctr>8</ctr>
        <lt>20</lt>
        <ltr>5</ltr>
        <rt>20</rt>
        <rtr>5</rtr>
        <la>18</la>
        <ra>18</ra>
        <ll>22</ll>
        <rl>22</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Archer ARC-2K 70t, 4/6/0, Std FE, Std; 11.0T/81% Armor; 12 SHS; 2 LRM15, 2 LL</info>
        <battleforce pv="14" wt="3" mv="4" s="2" m="2" l="1" e="0" ov="2" armor="6" internal="6" abilities="SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="12" techbase="2">
            <type>Single Heat Sink</type>

            <location index="10">CT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="4">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Neil 9000</commsystem>
        <tandtsystem>RCA Instatrac Mark XII</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Archer" model="ARC-2R" tons="70" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1477</battle_value>
    <cost>6300973.333333335</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>0</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Earthwerks Incorporated, Vandenberg Mechanized Industries, Gorton, Kingsley &amp; Thorpe Enterprises (GKT)" location="Calloway VI (Earthwerks), New Vandenberg (VMI), Satalice (GKT)">Inner Sphere</techbase>
    <year restricted="FALSE">2474</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Earthwerks Archer" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="280" manufacturer="VOX" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Maximillian 100" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>33</ct>
        <ctr>10</ctr>
        <lt>24</lt>
        <ltr>6</ltr>
        <rt>24</rt>
        <rtr>6</rtr>
        <la>22</la>
        <ra>22</ra>
        <ll>26</ll>
        <rl>26</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Archer ARC-2R 70t, 4/6/0, Std FE, Std; 13.0T/96% Armor; 10 SHS; 4 ML, 2 LRM20</info>
        <battleforce pv="15" wt="3" mv="4" s="2" m="3" l="2" e="0" ov="0" armor="7" internal="6" abilities="SRCH, ES, SEAL, SOA, LRM 1/2/2, IF 2" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="Diverse Optics Type 18">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Diverse Optics Type 18">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Diverse Optics Type 18">(R) (IS) Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Diverse Optics Type 18">(R) (IS) Medium Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Doombud Long Range Missile 20 Racks">(IS) LRM-20</name>
            <type>missile</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Doombud Long Range Missile 20 Racks">(IS) LRM-20</name>
            <type>missile</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20</name>
            <type>ammunition</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20</name>
            <type>ammunition</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20</name>
            <type>ammunition</type>
            <location index="6">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Neil 9000</commsystem>
        <tandtsystem>RCA Instatrac Mark XII</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Archer" model="ARC-2S" tons="70" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1393</battle_value>
    <cost>6303973.333333335</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Earthwerks Incorporated, Vandenberg Mechanized Industries, Gorton, Kingsley &amp; Thorpe Enterprises (GKT)" location="Calloway VI (Earthwerks), New Vandenberg (VMI), Satalice (GKT)">Inner Sphere</techbase>
    <year restricted="FALSE">2915</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Earthwerks Archer" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="280" manufacturer="VOX" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Maximillian 100" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>33</ct>
        <ctr>10</ctr>
        <lt>24</lt>
        <ltr>6</ltr>
        <rt>24</rt>
        <rtr>6</rtr>
        <la>22</la>
        <ra>22</ra>
        <ll>26</ll>
        <rl>26</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Archer ARC-2S 70t, 4/6/0, Std FE, Std; 13.0T/96% Armor; 10 SHS; 4 ML, 2 LRM15, 2 SRM4</info>
        <battleforce pv="14" wt="3" mv="4" s="2" m="2" l="1" e="0" ov="2" armor="7" internal="6" abilities="SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-4</name>
            <type>missile</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-4</name>
            <type>missile</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4</name>
            <type>ammunition</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4</name>
            <type>ammunition</type>
            <location index="6">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Neil 9000</commsystem>
        <tandtsystem>RCA Instatrac Mark XII</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Archer" model="ARC-2W" tons="70" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1338</battle_value>
    <cost>6344973.333333335</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Earthwerks Incorporated, Vandenberg Mechanized Industries, Gorton, Kingsley &amp; Thorpe Enterprises (GKT)" location="Calloway VI (Earthwerks), New Vandenberg (VMI), Satalice (GKT)">Inner Sphere</techbase>
    <year restricted="FALSE">3010</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Earthwerks Archer" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="280" manufacturer="VOX" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Maximillian 100" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>22</ct>
        <ctr>9</ctr>
        <lt>20</lt>
        <ltr>6</ltr>
        <rt>20</rt>
        <rtr>6</rtr>
        <la>15</la>
        <ra>15</ra>
        <ll>19</ll>
        <rl>19</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Archer ARC-2W 70t, 4/6/0, Std FE, Std; 10.0T/74% Armor; 10 SHS; 2 ML, 2 LRM20, 2 SRM4</info>
        <battleforce pv="13" wt="3" mv="4" s="2" m="3" l="2" e="0" ov="1" armor="5" internal="6" abilities="SRCH, ES, SEAL, SOA, LRM 1/1/1, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-4</name>
            <type>missile</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-4</name>
            <type>missile</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4</name>
            <type>ammunition</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-20</name>
            <type>missile</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-20</name>
            <type>missile</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20</name>
            <type>ammunition</type>
            <location index="7">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20</name>
            <type>ammunition</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20</name>
            <type>ammunition</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20</name>
            <type>ammunition</type>
            <location index="7">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Neil 9000</commsystem>
        <tandtsystem>RCA Instatrac Mark XII</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Assassin" model="ASN-101" tons="40" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>757</battle_value>
    <cost>3514463.333333333</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3024</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="280" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>7</hd>
        <ct>11</ct>
        <ctr>4</ctr>
        <lt>9</lt>
        <ltr>2</ltr>
        <rt>9</rt>
        <rtr>2</rtr>
        <la>5</la>
        <ra>5</ra>
        <ll>5</ll>
        <rl>5</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Assassin ASN-101 40t, 7/11/5, Std FE, Std; 4.0T/47% Armor; 10 SHS; 1 ML, 3 SL, 1 LRM5, 1 SRM2</info>
        <battleforce pv="8" wt="2" mv="7/3j" s="2" m="1" l="1" e="0" ov="0" armor="2" internal="3" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="5">
            <type>Standard Jump Jet</type>
            <location index="0">LT</location>
            <location index="1">RT</location>
            <location index="0">RT</location>
            <location index="1">LT</location>
            <location index="10">CT</location>
        </jumpjets>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="4">RL</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="4">LL</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-5</name>
            <type>missile</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-2</name>
            <type>missile</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-2</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-5</name>
            <type>ammunition</type>
            <location index="3">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Assassin" model="ASN-21" tons="40" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>749</battle_value>
    <cost>3743013.333333333</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2676</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="280" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>8</hd>
        <ct>12</ct>
        <ctr>4</ctr>
        <lt>10</lt>
        <ltr>2</ltr>
        <rt>10</rt>
        <rtr>2</rtr>
        <la>6</la>
        <ra>6</ra>
        <ll>6</ll>
        <rl>6</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Assassin ASN-21 40t, 7/11/7, Std FE, Std; 4.5T/53% Armor; 10 SHS; 1 ML, 1 LRM5, 1 SRM2</info>
        <battleforce pv="7" wt="2" mv="7j" s="1" m="1" l="1" e="0" ov="0" armor="2" internal="3" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="7">
            <type>Standard Jump Jet</type>
            <location index="1">RT</location>
            <location index="0">LT</location>
            <location index="0">RT</location>
            <location index="2">LT</location>
            <location index="10">CT</location>
            <location index="1">LT</location>
            <location index="2">RT</location>
        </jumpjets>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-5</name>
            <type>missile</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-2</name>
            <type>missile</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-2</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-5</name>
            <type>ammunition</type>
            <location index="4">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Atlas" model="AS7-D-DC" tons="100" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1858</battle_value>
    <cost>1.0329E7</cost>
    <rules_level>2</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2776</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="300" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="TRUE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>47</ct>
        <ctr>14</ctr>
        <lt>32</lt>
        <ltr>10</ltr>
        <rt>32</rt>
        <rtr>10</rtr>
        <la>34</la>
        <ra>34</ra>
        <ll>41</ll>
        <rl>41</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Atlas AS7-D-DC 100t, 3/5/0, Std FE, Std; 19.0T/99% Armor; 20 SHS; 2 ML, 1 SRM6, 1 AC20, 1 LRM20</info>
        <battleforce pv="19" wt="4" mv="3" s="5" m="5" l="1" e="0" ov="0" armor="10" internal="8" abilities="SRCH, ES, SEAL, SOA, AC 2/2/0, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="20" techbase="2">
            <type>Single Heat Sink</type>

            <location index="5">RL</location>
            <location index="4">RL</location>
            <location index="4">LL</location>
            <location index="5">LL</location>
            <location index="4">LA</location>
            <location index="0">LT</location>
            <location index="10">CT</location>
            <location index="4">RA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Autocannon/20</name>
            <type>ballistic</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="5">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="5">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-20</name>
            <type>missile</type>
            <location index="1">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20</name>
            <type>ammunition</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/20</name>
            <type>ammunition</type>
            <location index="10">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/20</name>
            <type>ammunition</type>
            <location index="11">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Atlas" model="AS7-D" tons="100" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1897</battle_value>
    <cost>9519000.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2755</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="300" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>47</ct>
        <ctr>14</ctr>
        <lt>32</lt>
        <ltr>10</ltr>
        <rt>32</rt>
        <rtr>10</rtr>
        <la>34</la>
        <ra>34</ra>
        <ll>41</ll>
        <rl>41</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Atlas AS7-D 100t, 3/5/0, Std FE, Std; 19.0T/99% Armor; 20 SHS; 4 ML, 1 SRM6, 1 AC20, 1 LRM20</info>
        <battleforce pv="19" wt="4" mv="3" s="5" m="5" l="2" e="0" ov="0" armor="10" internal="8" abilities="SRCH, ES, SEAL, SOA, AC 2/2/0, LRM 1/1/1, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="20" techbase="2">
            <type>Single Heat Sink</type>

            <location index="5">RL</location>
            <location index="4">RL</location>
            <location index="4">LL</location>
            <location index="5">LL</location>
            <location index="4">LA</location>
            <location index="0">LT</location>
            <location index="3">HD</location>
            <location index="4">RA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Autocannon/20</name>
            <type>ballistic</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="5">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="5">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-20</name>
            <type>missile</type>
            <location index="1">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="10">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20</name>
            <type>ammunition</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20</name>
            <type>ammunition</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/20</name>
            <type>ammunition</type>
            <location index="10">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/20</name>
            <type>ammunition</type>
            <location index="11">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Atlas" model="AS7-RS" tons="100" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1849</battle_value>
    <cost>9201000.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2892</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="300" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>47</ct>
        <ctr>14</ctr>
        <lt>32</lt>
        <ltr>10</ltr>
        <rt>32</rt>
        <rtr>10</rtr>
        <la>34</la>
        <ra>34</ra>
        <ll>41</ll>
        <rl>41</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Atlas AS7-RS 100t, 3/5/0, Std FE, Std; 19.0T/99% Armor; 20 SHS; 1 LRM15, 1 AC10, 1 SRM4, 2 LL</info>
        <battleforce pv="18" wt="4" mv="3" s="3" m="4" l="1" e="0" ov="1" armor="10" internal="8" abilities="SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="20" techbase="2">
            <type>Single Heat Sink</type>

            <location index="5">RL</location>
            <location index="4">RL</location>
            <location index="4">LL</location>
            <location index="5">LL</location>
            <location index="4">LA</location>
            <location index="0">LT</location>
            <location index="3">HD</location>
            <location index="4">RA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Autocannon/10</name>
            <type>ballistic</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="1">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-4</name>
            <type>missile</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="5">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="5">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/10</name>
            <type>ammunition</type>
            <location index="8">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/10</name>
            <type>ammunition</type>
            <location index="7">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4</name>
            <type>ammunition</type>
            <location index="7">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Awesome" model="AWS-8Q" tons="80" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1605</battle_value>
    <cost>6598170.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2665</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="240" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>30</ct>
        <ctr>19</ctr>
        <lt>24</lt>
        <ltr>10</ltr>
        <rt>24</rt>
        <rtr>10</rtr>
        <la>24</la>
        <ra>24</ra>
        <ll>33</ll>
        <rl>33</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Awesome AWS-8Q 80t, 3/5/0, Std FE, Std; 15.0T/97% Armor; 28 SHS; 1 SL, 3 PPC</info>
        <battleforce pv="16" wt="4" mv="3" s="3" m="3" l="3" e="0" ov="0" armor="8" internal="6" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="28" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">RL</location>
            <location index="5">RL</location>
            <location index="4">LL</location>
            <location index="5">LL</location>
            <location index="1">LT</location>
            <location index="4">LT</location>
            <location index="3">LT</location>
            <location index="0">LT</location>
            <location index="2">LT</location>
            <location index="10">CT</location>
            <location index="11">CT</location>
            <location index="0">RT</location>
            <location index="2">RT</location>
            <location index="1">RT</location>
            <location index="4">RA</location>
            <location index="7">RA</location>
            <location index="6">RA</location>
            <location index="5">RA</location>
            <location index="3">RA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="8">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="5">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Awesome" model="AWS-8R" tons="80" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1470</battle_value>
    <cost>6388170.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2683</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="240" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>30</ct>
        <ctr>19</ctr>
        <lt>24</lt>
        <ltr>10</ltr>
        <rt>24</rt>
        <rtr>10</rtr>
        <la>24</la>
        <ra>24</ra>
        <ll>33</ll>
        <rl>33</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Awesome AWS-8R 80t, 3/5/0, Std FE, Std; 15.0T/97% Armor; 28 SHS; 2 LRM15, 1 SL, 1 LL</info>
        <battleforce pv="15" wt="4" mv="3" s="2" m="3" l="2" e="0" ov="0" armor="8" internal="6" abilities="SRCH, ES, SEAL, SOA, LRM 1/1/1, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="28" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">LL</location>
            <location index="5">LL</location>
            <location index="5">RL</location>
            <location index="4">RL</location>
            <location index="4">LA</location>
            <location index="5">LA</location>
            <location index="2">LT</location>
            <location index="0">LT</location>
            <location index="3">LT</location>
            <location index="4">LT</location>
            <location index="5">LT</location>
            <location index="1">LT</location>
            <location index="3">RA</location>
            <location index="2">RT</location>
            <location index="3">RT</location>
            <location index="4">RT</location>
            <location index="0">RT</location>
            <location index="5">RT</location>
            <location index="1">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="11">CT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Awesome" model="AWS-8T" tons="80" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1593</battle_value>
    <cost>6550170.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2815</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="240" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>30</ct>
        <ctr>19</ctr>
        <lt>24</lt>
        <ltr>10</ltr>
        <rt>24</rt>
        <rtr>10</rtr>
        <la>24</la>
        <ra>24</ra>
        <ll>33</ll>
        <rl>33</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Awesome AWS-8T 80t, 3/5/0, Std FE, Std; 15.0T/97% Armor; 23 SHS; 2 LRM15, 1 SL, 2 LL</info>
        <battleforce pv="16" wt="4" mv="3" s="3" m="3" l="2" e="0" ov="0" armor="8" internal="6" abilities="SRCH, ES, SEAL, SOA, LRM 1/1/1, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="23" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">RT</location>
            <location index="1">RT</location>
            <location index="3">LT</location>
            <location index="3">RT</location>
            <location index="0">LT</location>
            <location index="1">LT</location>
            <location index="4">RL</location>
            <location index="2">LT</location>
            <location index="3">RA</location>
            <location index="2">RT</location>
            <location index="5">LL</location>
            <location index="0">RT</location>
            <location index="5">RL</location>
            <location index="4">LL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Awesome" model="AWS-8V" tons="80" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1510</battle_value>
    <cost>6433170.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2980</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="240" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>30</ct>
        <ctr>19</ctr>
        <lt>24</lt>
        <ltr>10</ltr>
        <rt>24</rt>
        <rtr>10</rtr>
        <la>24</la>
        <ra>24</ra>
        <ll>33</ll>
        <rl>33</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Awesome AWS-8V 80t, 3/5/0, Std FE, Std; 15.0T/97% Armor; 28 SHS; 1 LRM15, 1 SL, 1 PPC, 1 LL</info>
        <battleforce pv="15" wt="4" mv="3" s="3" m="3" l="2" e="0" ov="0" armor="8" internal="6" abilities="SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="28" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">RT</location>
            <location index="1">RT</location>
            <location index="3">LT</location>
            <location index="3">RT</location>
            <location index="0">LT</location>
            <location index="1">LT</location>
            <location index="4">RL</location>
            <location index="2">LT</location>
            <location index="5">RT</location>
            <location index="2">RT</location>
            <location index="5">LL</location>
            <location index="0">RT</location>
            <location index="5">RL</location>
            <location index="4">LL</location>
            <location index="4">LT</location>
            <location index="5">LA</location>
            <location index="4">LA</location>
            <location index="5">LT</location>
            <location index="6">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="7">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="11">CT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Banshee" model="BNC-3E" tons="95" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1422</battle_value>
    <cost>9526577.5</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>0</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2475</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="380" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>40</ct>
        <ctr>17</ctr>
        <lt>30</lt>
        <ltr>10</ltr>
        <rt>30</rt>
        <rtr>10</rtr>
        <la>21</la>
        <ra>21</ra>
        <ll>26</ll>
        <rl>26</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Banshee BNC-3E 95t, 4/6/0, Std FE, Std; 15.0T/82% Armor; 16 SHS; 1 SL, 1 PPC, 1 AC5</info>
        <battleforce pv="14" wt="4" mv="4" s="2" m="2" l="2" e="0" ov="0" armor="8" internal="8" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="16" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">RL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Autocannon/5</name>
            <type>ballistic</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/5</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Banshee" model="BNC-3M" tons="95" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1595</battle_value>
    <cost>9824327.5</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2579</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="380" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>40</ct>
        <ctr>17</ctr>
        <lt>30</lt>
        <ltr>10</ltr>
        <rt>30</rt>
        <rtr>10</rtr>
        <la>21</la>
        <ra>21</ra>
        <ll>26</ll>
        <rl>26</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Banshee BNC-3M 95t, 4/6/0, Std FE, Std; 15.0T/82% Armor; 16 SHS; 2 ML, 1 SL, 2 PPC</info>
        <battleforce pv="16" wt="4" mv="4" s="2" m="2" l="2" e="0" ov="1" armor="8" internal="8" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="16" techbase="2">
            <type>Single Heat Sink</type>

            <location index="10">CT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="0">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Banshee" model="BNC-3MC" tons="95" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1487</battle_value>
    <cost>9660827.5</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3037</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="380" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>40</ct>
        <ctr>17</ctr>
        <lt>30</lt>
        <ltr>10</ltr>
        <rt>30</rt>
        <rtr>10</rtr>
        <la>21</la>
        <ra>21</ra>
        <ll>26</ll>
        <rl>26</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Banshee BNC-3MC 95t, 4/6/0, Std FE, Std; 15.0T/82% Armor; 11 SHS; 1 SL, 1 PPC, 1 AC10</info>
        <battleforce pv="15" wt="4" mv="4" s="2" m="2" l="1" e="0" ov="0" armor="8" internal="8" abilities="SRCH, ES, SEAL, SOA, AC 1/1/0" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="11" techbase="2">
            <type>Single Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Autocannon/10</name>
            <type>ballistic</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/10</name>
            <type>ammunition</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/10</name>
            <type>ammunition</type>
            <location index="7">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Banshee" model="BNC-3Q" tons="95" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1394</battle_value>
    <cost>9517727.5</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2915</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="380" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>40</ct>
        <ctr>17</ctr>
        <lt>30</lt>
        <ltr>10</ltr>
        <rt>30</rt>
        <rtr>10</rtr>
        <la>21</la>
        <ra>21</ra>
        <ll>26</ll>
        <rl>26</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Banshee BNC-3Q 95t, 4/6/0, Std FE, Std; 15.0T/82% Armor; 12 SHS; 1 SL, 1 AC20</info>
        <battleforce pv="14" wt="4" mv="4" s="3" m="2" l="0" e="0" ov="0" armor="8" internal="8" abilities="SRCH, ES, SEAL, SOA, AC 2/2/0" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="12" techbase="2">
            <type>Single Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Autocannon/20</name>
            <type>ballistic</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/20</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/20</name>
            <type>ammunition</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/20</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/20</name>
            <type>ammunition</type>
            <location index="1">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/20</name>
            <type>ammunition</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/20</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Banshee" model="BNC-3S (Reinesblatt)" tons="95" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1678</battle_value>
    <cost>8373765.000000001</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3029</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="285" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>40</ct>
        <ctr>17</ctr>
        <lt>30</lt>
        <ltr>10</ltr>
        <rt>30</rt>
        <rtr>10</rtr>
        <la>21</la>
        <ra>21</ra>
        <ll>26</ll>
        <rl>26</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Banshee BNC-3S (Reinesblatt) 95t, 3/5/0, Std FE, Std; 15.0T/82% Armor; 21 SHS; 4 ML, 2 SL, 1 AC10, 1 LRM10, 1 LL, 1 Htcht</info>
        <battleforce pv="17" wt="4" mv="3" s="4" m="4" l="1" e="0" ov="1" armor="8" internal="8" abilities="MEL, SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="21" techbase="2">
            <type>Single Heat Sink</type>

            <location index="5">RL</location>
            <location index="3">RT</location>
            <location index="4">RL</location>
            <location index="1">RT</location>
            <location index="0">LT</location>
            <location index="1">LT</location>
            <location index="0">RT</location>
            <location index="2">RT</location>
            <location index="4">LL</location>
            <location index="5">LL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="8">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="9">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="7">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Autocannon/10</name>
            <type>ballistic</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/10</name>
            <type>ammunition</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/10</name>
            <type>ammunition</type>
            <location index="10">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-10</name>
            <type>missile</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">Hatchet</name>
            <type>physical</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="10">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Banshee" model="BNC-3S" tons="95" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1751</battle_value>
    <cost>8833695.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3026</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="285" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>40</ct>
        <ctr>17</ctr>
        <lt>30</lt>
        <ltr>10</ltr>
        <rt>30</rt>
        <rtr>10</rtr>
        <la>21</la>
        <ra>21</ra>
        <ll>26</ll>
        <rl>26</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Banshee BNC-3S 95t, 3/5/0, Std FE, Std; 15.0T/82% Armor; 21 SHS; 4 ML, 2 SL, 2 PPC, 1 AC10, 1 SRM6</info>
        <battleforce pv="18" wt="4" mv="3" s="4" m="4" l="2" e="0" ov="2" armor="8" internal="8" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="21" techbase="2">
            <type>Single Heat Sink</type>

            <location index="5">RL</location>
            <location index="4">RA</location>
            <location index="4">RL</location>
            <location index="1">RT</location>
            <location index="0">LT</location>
            <location index="1">LT</location>
            <location index="0">RT</location>
            <location index="10">CT</location>
            <location index="4">LL</location>
            <location index="5">LL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="9">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="10">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="7">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="8">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Autocannon/10</name>
            <type>ballistic</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="11">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/10</name>
            <type>ammunition</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/10</name>
            <type>ammunition</type>
            <location index="10">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="BattleMaster" model="BLR-1G-DC" tons="85" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1494</battle_value>
    <cost>9227793.333333334</cost>
    <rules_level>2</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2855</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="340" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="TRUE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>40</ct>
        <ctr>11</ctr>
        <lt>28</lt>
        <ltr>8</ltr>
        <rt>28</rt>
        <rtr>8</rtr>
        <la>24</la>
        <ra>24</ra>
        <ll>26</ll>
        <rl>26</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>BattleMaster BLR-1G-DC 85t, 4/6/0, Std FE, Std; 14.5T/88% Armor; 17 SHS; 4 ML, 1 PPC, 2 MG, 1 SRM6</info>
        <battleforce pv="15" wt="4" mv="4" s="3" m="3" l="1" e="0" ov="1" armor="8" internal="7" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="17" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="10">CT</location>
            <location index="0">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="1">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="5">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Machine Gun</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Battlemaster" model="BLR-1D" tons="85" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1522</battle_value>
    <cost>8145193.333333334</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Earthwerks Incorporated, Red Devil Industries" location="Keystone (Earthwerks), Pandora (Red Devil)">Inner Sphere</techbase>
    <year restricted="FALSE">2867</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Hollis Mark X" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="340" manufacturer="VOX" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="StarGuard IV" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>40</ct>
        <ctr>11</ctr>
        <lt>28</lt>
        <ltr>8</ltr>
        <rt>28</rt>
        <rtr>8</rtr>
        <la>27</la>
        <ra>27</ra>
        <ll>31</ll>
        <rl>31</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Battlemaster BLR-1D 85t, 4/6/0, Std FE, Std; 15.5T/94% Armor; 24 SHS; 4 ML, 1 PPC, 2 MG</info>
        <battleforce pv="15" wt="4" mv="4" s="4" m="3" l="1" e="0" ov="0" armor="8" internal="7" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="24" techbase="2">
            <type>Single Heat Sink</type>

            <location index="5">RL</location>
            <location index="4">RL</location>
            <location index="4">LL</location>
            <location index="5">LL</location>
            <location index="0">LT</location>
            <location index="1">LT</location>
            <location index="2">LT</location>
            <location index="10">CT</location>
            <location index="11">CT</location>
            <location index="0">RT</location>
            <location index="1">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="Donal">(IS) PPC</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Martell">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Martell">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Martell">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Martell">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="SperryBrowning">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="SperryBrowning">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="5">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Machine Gun</name>
            <type>ammunition</type>
            <location index="4">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>HarfordCo COM 4000</commsystem>
        <tandtsystem>HarfordCo XKZ 1</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Battlemaster" model="BLR-1G" tons="85" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1519</battle_value>
    <cost>8454493.333333334</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2633</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="340" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>40</ct>
        <ctr>11</ctr>
        <lt>28</lt>
        <ltr>8</ltr>
        <rt>28</rt>
        <rtr>8</rtr>
        <la>24</la>
        <ra>24</ra>
        <ll>26</ll>
        <rl>26</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Battlemaster BLR-1G 85t, 4/6/0, Std FE, Std; 14.5T/88% Armor; 18 SHS; 6 ML, 1 PPC, 2 MG, 1 SRM6</info>
        <battleforce pv="15" wt="4" mv="4" s="3" m="3" l="1" e="0" ov="1" armor="8" internal="7" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="18" techbase="2">
            <type>Single Heat Sink</type>

            <location index="5">RL</location>
            <location index="4">RL</location>
            <location index="4">LL</location>
            <location index="5">LL</location>
            <location index="0">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="Donal">(IS) PPC</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Martell">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Martell">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Martell">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="1">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Martell">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Martell">(R) (IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Martell">(R) (IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="SperryBrowning">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="5">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="SperryBrowning">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Machine Gun</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="7">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Battlemaster" model="BLR-1S" tons="85" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1507</battle_value>
    <cost>8225643.333333334</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Earthwerks Incorporated, Red Devil Industries" location="Keystone (Earthwerks), Pandora (Red Devil)">Inner Sphere</techbase>
    <year restricted="FALSE">3025</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Hollis Mark X" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="340" manufacturer="VOX" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="StarGuard IV" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>40</ct>
        <ctr>11</ctr>
        <lt>28</lt>
        <ltr>8</ltr>
        <rt>28</rt>
        <rtr>8</rtr>
        <la>24</la>
        <ra>24</ra>
        <ll>26</ll>
        <rl>26</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Battlemaster BLR-1S 85t, 4/6/0, Std FE, Std; 14.5T/88% Armor; 20 SHS; 4 ML, 1 LRM15, 1 LRM5, 2 SRM2</info>
        <battleforce pv="15" wt="4" mv="4" s="3" m="4" l="1" e="0" ov="0" armor="8" internal="7" abilities="SRCH, ES, SEAL, SOA, LRM 1/1/1, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="20" techbase="2">
            <type>Single Heat Sink</type>

            <location index="5">RL</location>
            <location index="4">RL</location>
            <location index="5">LL</location>
            <location index="4">LL</location>
            <location index="0">LT</location>
            <location index="1">RT</location>
            <location index="0">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="Martell">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="1">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Martell">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Martell">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Martell">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-2</name>
            <type>missile</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-2</name>
            <type>missile</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-5</name>
            <type>missile</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="7">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-5</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-2</name>
            <type>ammunition</type>
            <location index="4">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>HarfordCo COM 4000</commsystem>
        <tandtsystem>HarfordCo XKZ 1</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Black Knight" model="BL-7-KNT-L" tons="75" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1409</battle_value>
    <cost>6426437.5</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Kong Interstellar Corporation" location="Connaught">Inner Sphere</techbase>
    <year restricted="FALSE">2809</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Technicron Standard" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="300" manufacturer="Vlar" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Valiant Lamellor" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>25</ct>
        <ctr>8</ctr>
        <lt>20</lt>
        <ltr>7</ltr>
        <rt>20</rt>
        <rtr>7</rtr>
        <la>20</la>
        <ra>20</ra>
        <ll>20</ll>
        <rl>20</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Black Knight BL-7-KNT-L 75t, 4/6/0, Std FE, Std; 11.0T/76% Armor; 22 SHS; 4 ML, 1 SL, 3 LL</info>
        <battleforce pv="14" wt="3" mv="4" s="3" m="3" l="0" e="0" ov="2" armor="6" internal="6" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="22" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">RL</location>
            <location index="5">RL</location>
            <location index="4">LL</location>
            <location index="5">LL</location>
            <location index="0">LT</location>
            <location index="1">LT</location>
            <location index="5">RA</location>
            <location index="4">RA</location>
            <location index="0">RT</location>
            <location index="1">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="Tronel III">(IS) Large Laser</name>
            <type>energy</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Tronel III">(IS) Large Laser</name>
            <type>energy</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Tronel II">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Tronel II">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="8">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Tronel II">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Tronel II">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Tronel I">(IS) Small Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="6">RA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Tek BattleCom</commsystem>
        <tandtsystem>Tek Tru-Trak</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Black Knight" model="BL-7-KNT" tons="75" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1443</battle_value>
    <cost>6594437.5</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Kong Interstellar Corporation" location="Connaught">Inner Sphere</techbase>
    <year restricted="FALSE">2809</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Technicron Standard" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="300" manufacturer="Vlar" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Valiant Lamellor" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>25</ct>
        <ctr>8</ctr>
        <lt>20</lt>
        <ltr>7</ltr>
        <rt>20</rt>
        <rtr>7</rtr>
        <la>20</la>
        <ra>20</ra>
        <ll>20</ll>
        <rl>20</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Black Knight BL-7-KNT 75t, 4/6/0, Std FE, Std; 11.0T/76% Armor; 20 SHS; 4 ML, 1 SL, 1 PPC, 2 LL</info>
        <battleforce pv="14" wt="3" mv="4" s="3" m="3" l="1" e="0" ov="2" armor="6" internal="6" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="20" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">RL</location>
            <location index="5">RL</location>
            <location index="4">LL</location>
            <location index="5">LL</location>
            <location index="0">LT</location>
            <location index="1">LT</location>
            <location index="0">RT</location>
            <location index="1">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="Magna Hellstar II">(IS) PPC</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Tronel III">(IS) Large Laser</name>
            <type>energy</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Tronel III">(IS) Large Laser</name>
            <type>energy</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Tronel II">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Tronel II">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="7">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Tronel II">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Tronel II">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Tronel I">(IS) Small Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Tek BattleCom</commsystem>
        <tandtsystem>Tek Tru-Trak</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Blackjack" model="BJ-1" tons="45" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>949</battle_value>
    <cost>3146775.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2757</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="180" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>18</ct>
        <ctr>9</ctr>
        <lt>15</lt>
        <ltr>6</ltr>
        <rt>15</rt>
        <rtr>6</rtr>
        <la>12</la>
        <ra>12</ra>
        <ll>17</ll>
        <rl>17</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Blackjack BJ-1 45t, 4/6/4, Std FE, Std; 8.5T/89% Armor; 11 SHS; 4 ML, 2 AC2</info>
        <battleforce pv="9" wt="2" mv="4j" s="2" m="2" l="1" e="0" ov="1" armor="5" internal="4" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="5">LL</location>
            <location index="5">RL</location>
            <location index="4">RL</location>
            <location index="4">LL</location>
        </jumpjets>
        <heatsinks number="11" techbase="2">
            <type>Single Heat Sink</type>

            <location index="1">LT</location>
            <location index="0">LT</location>
            <location index="0">RT</location>
            <location index="1">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Autocannon/2</name>
            <type>ballistic</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Autocannon/2</name>
            <type>ballistic</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/2</name>
            <type>ammunition</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">LA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Blackjack" model="BJ-1DB" tons="45" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1015</battle_value>
    <cost>3105175.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3023</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="180" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>16</ct>
        <ctr>7</ctr>
        <lt>14</lt>
        <ltr>5</ltr>
        <rt>14</rt>
        <rtr>5</rtr>
        <la>11</la>
        <ra>11</ra>
        <ll>14</ll>
        <rl>14</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Blackjack BJ-1DB 45t, 4/6/4, Std FE, Std; 7.5T/78% Armor; 17 SHS; 2 ML, 2 LL</info>
        <battleforce pv="10" wt="2" mv="4j" s="3" m="3" l="0" e="0" ov="0" armor="4" internal="4" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="5">LL</location>
            <location index="5">RL</location>
            <location index="4">RL</location>
            <location index="4">LL</location>
        </jumpjets>
        <heatsinks number="17" techbase="2">
            <type>Single Heat Sink</type>

            <location index="0">RT</location>
            <location index="0">LT</location>
            <location index="1">LT</location>
            <location index="1">RT</location>
            <location index="2">LT</location>
            <location index="3">LT</location>
            <location index="2">RT</location>
            <location index="3">RT</location>
            <location index="4">LT</location>
            <location index="4">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Blackjack" model="BJ-1DC" tons="45" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>917</battle_value>
    <cost>2973500.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3024</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="180" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>18</ct>
        <ctr>9</ctr>
        <lt>15</lt>
        <ltr>6</ltr>
        <rt>15</rt>
        <rtr>6</rtr>
        <la>12</la>
        <ra>12</ra>
        <ll>17</ll>
        <rl>17</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Blackjack BJ-1DC 45t, 4/6/0, Std FE, Std; 8.5T/89% Armor; 12 SHS; 4 ML, 2 SL, 2 AC2</info>
        <battleforce pv="9" wt="2" mv="4" s="3" m="3" l="1" e="0" ov="0" armor="5" internal="4" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="12" techbase="2">
            <type>Single Heat Sink</type>

            <location index="5">RL</location>
            <location index="4">LL</location>
            <location index="5">LL</location>
            <location index="4">RL</location>
            <location index="10">CT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="1">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="1">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Autocannon/2</name>
            <type>ballistic</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Autocannon/2</name>
            <type>ballistic</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/2</name>
            <type>ammunition</type>
            <location index="11">CT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Blackjack" model="BJ-1X" tons="45" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>964</battle_value>
    <cost>3414025.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2756</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="225" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>18</ct>
        <ctr>9</ctr>
        <lt>15</lt>
        <ltr>6</ltr>
        <rt>15</rt>
        <rtr>6</rtr>
        <la>12</la>
        <ra>12</ra>
        <ll>17</ll>
        <rl>17</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Blackjack BJ-1X 45t, 5/8/0, Std FE, Std; 8.5T/89% Armor; 18 SHS; 4 Flmr, 4 ML</info>
        <battleforce pv="10" wt="2" mv="5" s="3" m="2" l="0" e="0" ov="0" armor="5" internal="4" abilities="ENE, HT1, SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="18" techbase="2">
            <type>Single Heat Sink</type>

            <location index="10">CT</location>
            <location index="4">RL</location>
            <location index="4">LL</location>
            <location index="1">RT</location>
            <location index="2">RT</location>
            <location index="1">LT</location>
            <location index="0">RT</location>
            <location index="0">LT</location>
            <location index="2">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Bombardier" model="BMB-10D" tons="65" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1340</battle_value>
    <cost>5579310.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2866</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="260" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>24</ct>
        <ctr>15</ctr>
        <lt>20</lt>
        <ltr>10</ltr>
        <rt>20</rt>
        <rtr>10</rtr>
        <la>20</la>
        <ra>20</ra>
        <ll>26</ll>
        <rl>26</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Bombardier BMB-10D 65t, 4/6/0, Std FE, Std; 12.5T/95% Armor; 10 SHS; 1 MG, 1 SRM4, 2 LRM20</info>
        <battleforce pv="13" wt="3" mv="4" s="2" m="2" l="2" e="0" ov="0" armor="7" internal="5" abilities="SRCH, ES, SEAL, SOA, LRM 1/1/1, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) LRM-20</name>
            <type>missile</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-20</name>
            <type>missile</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-4</name>
            <type>missile</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4</name>
            <type>ammunition</type>
            <location index="5">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20</name>
            <type>ammunition</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20</name>
            <type>ammunition</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Machine Gun</name>
            <type>ammunition</type>
            <location index="11">CT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Cataphract" model="CTF-1X" tons="70" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1316</battle_value>
    <cost>5993853.333333335</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3025</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="280" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>26</ct>
        <ctr>9</ctr>
        <lt>16</lt>
        <ltr>6</ltr>
        <rt>16</rt>
        <rtr>6</rtr>
        <la>22</la>
        <ra>22</ra>
        <ll>22</ll>
        <rl>22</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Cataphract CTF-1X 70t, 4/6/0, Std FE, Std; 11.0T/81% Armor; 16 SHS; 4 ML, 1 PPC, 1 AC10</info>
        <battleforce pv="13" wt="3" mv="4" s="3" m="3" l="1" e="0" ov="0" armor="6" internal="6" abilities="SRCH, ES, SEAL, SOA, AC 1/1/0" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="16" techbase="2">
            <type>Single Heat Sink</type>

            <location index="5">RL</location>
            <location index="5">LL</location>
            <location index="4">RL</location>
            <location index="4">LL</location>
            <location index="3">HD</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Autocannon/10</name>
            <type>ballistic</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Laser</name>
            <type>energy</type>
            <location index="7">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="6">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Laser</name>
            <type>energy</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/10</name>
            <type>ammunition</type>
            <location index="8">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Cataphract" model="CTF-2X" tons="70" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1344</battle_value>
    <cost>5850053.333333335</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3027</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="280" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>30</ct>
        <ctr>13</ctr>
        <lt>20</lt>
        <ltr>10</ltr>
        <rt>20</rt>
        <rtr>10</rtr>
        <la>22</la>
        <ra>22</ra>
        <ll>26</ll>
        <rl>26</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Cataphract CTF-2X 70t, 4/6/0, Std FE, Std; 13.0T/96% Armor; 14 SHS; 2 ML, 1 AC10, 1 SRM4, 1 LL</info>
        <battleforce pv="13" wt="3" mv="4" s="3" m="3" l="0" e="0" ov="1" armor="7" internal="6" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="14" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">RL</location>
            <location index="5">RL</location>
            <location index="4">LL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Autocannon/10</name>
            <type>ballistic</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="7">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-4</name>
            <type>missile</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/10</name>
            <type>ammunition</type>
            <location index="1">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/10</name>
            <type>ammunition</type>
            <location index="2">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Catapult" model="CPLT-A1" tons="65" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1285</battle_value>
    <cost>5580125.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2563</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="260" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>24</ct>
        <ctr>11</ctr>
        <lt>21</lt>
        <ltr>9</ltr>
        <rt>21</rt>
        <rtr>9</rtr>
        <la>20</la>
        <ra>20</ra>
        <ll>24</ll>
        <rl>24</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Catapult CPLT-A1 65t, 4/6/4, Std FE, Std; 12.0T/91% Armor; 15 SHS; 2 LRM15</info>
        <battleforce pv="13" wt="3" mv="4j" s="1" m="2" l="2" e="0" ov="0" armor="6" internal="5" abilities="SRCH, ES, SEAL, SOA, LRM 1/2/2, IF 2" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="1">LT</location>
            <location index="1">RT</location>
            <location index="0">RT</location>
            <location index="0">LT</location>
        </jumpjets>
        <heatsinks number="15" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">RL</location>
            <location index="5">RL</location>
            <location index="5">LL</location>
            <location index="4">LL</location>
            <location index="10">CT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="2">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Catapult" model="CPLT-C1 (Jenny) &quot;Butterbee&quot;" tons="65" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1242</battle_value>
    <cost>5749625.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3027</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="260" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>24</ct>
        <ctr>11</ctr>
        <lt>19</lt>
        <ltr>8</ltr>
        <rt>19</rt>
        <rtr>8</rtr>
        <la>13</la>
        <ra>13</ra>
        <ll>18</ll>
        <rl>18</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Catapult CPLT-C1 (Jenny) "Butterbee" 65t, 4/6/4, Std FE, Std; 10.0T/76% Armor; 15 SHS; 4 ML, 4 SRM6</info>
        <battleforce pv="12" wt="3" mv="4j" s="3" m="3" l="0" e="0" ov="1" armor="5" internal="5" abilities="SRCH, ES, SEAL, SOA, SRM 2/2/0" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="1">LT</location>
            <location index="1">RT</location>
            <location index="0">RT</location>
            <location index="0">LT</location>
        </jumpjets>
        <heatsinks number="15" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">RL</location>
            <location index="5">RL</location>
            <location index="5">LL</location>
            <location index="4">LL</location>
            <location index="3">HD</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Catapult" model="CPLT-C1" tons="65" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1399</battle_value>
    <cost>5751125.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2561</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="260" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>24</ct>
        <ctr>11</ctr>
        <lt>19</lt>
        <ltr>8</ltr>
        <rt>19</rt>
        <rtr>8</rtr>
        <la>13</la>
        <ra>13</ra>
        <ll>18</ll>
        <rl>18</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Catapult CPLT-C1 65t, 4/6/4, Std FE, Std; 10.0T/76% Armor; 15 SHS; 4 ML, 2 LRM15</info>
        <battleforce pv="14" wt="3" mv="4j" s="2" m="3" l="1" e="0" ov="0" armor="5" internal="5" abilities="SRCH, ES, SEAL, SOA, LRM 1/1/1, IF 1" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="1">LT</location>
            <location index="1">RT</location>
            <location index="0">RT</location>
            <location index="0">LT</location>
        </jumpjets>
        <heatsinks number="15" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">RL</location>
            <location index="5">RL</location>
            <location index="5">LL</location>
            <location index="4">LL</location>
            <location index="3">HD</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Catapult" model="CPLT-C1b" tons="65" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1570</battle_value>
    <cost>6243425.0</cost>
    <rules_level>1</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2688</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="260" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>24</ct>
        <ctr>12</ctr>
        <lt>21</lt>
        <ltr>9</ltr>
        <rt>21</rt>
        <rtr>9</rtr>
        <la>16</la>
        <ra>16</ra>
        <ll>21</ll>
        <rl>21</rl>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">LA</location>
        <location index="5">RA</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="6">LA</location>
        <location index="6">RA</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Catapult CPLT-C1b 65t, 4/6/4, Std FE, Std; 10.0T/85% FF Armor; 12 DHS; 4 ML, 2 LRM15</info>
        <battleforce pv="16" wt="3" mv="4j" s="3" m="4" l="2" e="0" ov="0" armor="6" internal="5" abilities="CASE, SRCH, ES, SEAL, SOA, LRM 1/2/2, IF 2" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="3">LT</location>
            <location index="3">RT</location>
            <location index="4">RT</location>
            <location index="4">LT</location>
        </jumpjets>
        <heatsinks number="12" techbase="0">
            <type>Double Heat Sink</type>

            <location index="0">LT</location>
            <location index="0">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="7">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="7">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="8">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Catapult" model="CPLT-C4" tons="65" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1358</battle_value>
    <cost>5815250.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2565</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="260" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>22</ct>
        <ctr>7</ctr>
        <lt>17</lt>
        <ltr>6</ltr>
        <rt>17</rt>
        <rtr>6</rtr>
        <la>15</la>
        <ra>15</ra>
        <ll>23</ll>
        <rl>23</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Catapult CPLT-C4 65t, 4/6/4, Std FE, Std; 10.0T/76% Armor; 10 SHS; 2 SL, 2 LRM20</info>
        <battleforce pv="14" wt="3" mv="4j" s="2" m="2" l="2" e="0" ov="0" armor="5" internal="5" abilities="SRCH, ES, SEAL, SOA, LRM 1/2/2, IF 2" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="1">LT</location>
            <location index="1">RT</location>
            <location index="0">RT</location>
            <location index="0">LT</location>
        </jumpjets>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) LRM-20</name>
            <type>missile</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-20</name>
            <type>missile</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20</name>
            <type>ammunition</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20</name>
            <type>ammunition</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20</name>
            <type>ammunition</type>
            <location index="2">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Catapult" model="CPLT-K2" tons="65" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1319</battle_value>
    <cost>5348925.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2603</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="260" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>24</ct>
        <ctr>11</ctr>
        <lt>20</lt>
        <ltr>8</ltr>
        <rt>20</rt>
        <rtr>8</rtr>
        <la>18</la>
        <ra>18</ra>
        <ll>20</ll>
        <rl>20</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Catapult CPLT-K2 65t, 4/6/0, Std FE, Std; 11.0T/83% Armor; 20 SHS; 2 ML, 2 PPC, 2 MG</info>
        <battleforce pv="13" wt="3" mv="4" s="3" m="3" l="2" e="0" ov="0" armor="6" internal="5" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="20" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">RL</location>
            <location index="5">RL</location>
            <location index="4">LL</location>
            <location index="5">LL</location>
            <location index="0">LT</location>
            <location index="1">LT</location>
            <location index="10">CT</location>
            <location index="2">RT</location>
            <location index="1">RT</location>
            <location index="0">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Machine Gun</name>
            <type>ammunition</type>
            <location index="11">CT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Centurion" model="CN9-A" tons="50" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>945</battle_value>
    <cost>3455500.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2801</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="200" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>18</ct>
        <ctr>7</ctr>
        <lt>13</lt>
        <ltr>6</ltr>
        <rt>13</rt>
        <rtr>6</rtr>
        <la>16</la>
        <ra>16</ra>
        <ll>16</ll>
        <rl>16</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Centurion CN9-A 50t, 4/6/0, Std FE, Std; 8.5T/80% Armor; 10 SHS; 2 ML, 1 AC10, 1 LRM10</info>
        <battleforce pv="9" wt="2" mv="4" s="2" m="3" l="1" e="0" ov="0" armor="5" internal="4" abilities="SRCH, ES, SEAL, SOA, AC 1/1/0, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="0">RT</location>
            <location index="0">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Autocannon/10</name>
            <type>ballistic</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-10</name>
            <type>missile</type>
            <location index="1">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/10</name>
            <type>ammunition</type>
            <location index="1">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/10</name>
            <type>ammunition</type>
            <location index="2">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Centurion" model="CN9-AH" tons="50" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>945</battle_value>
    <cost>3489750.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2874</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="200" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>18</ct>
        <ctr>7</ctr>
        <lt>13</lt>
        <ltr>6</ltr>
        <rt>13</rt>
        <rtr>6</rtr>
        <la>16</la>
        <ra>16</ra>
        <ll>16</ll>
        <rl>16</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Centurion CN9-AH 50t, 4/6/0, Std FE, Std; 8.5T/80% Armor; 10 SHS; 1 AC20, 1 LRM10</info>
        <battleforce pv="9" wt="2" mv="4" s="3" m="3" l="1" e="0" ov="0" armor="5" internal="4" abilities="SRCH, ES, SEAL, SOA, AC 2/2/0, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">LL</location>
            <location index="4">RL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) LRM-10</name>
            <type>missile</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Autocannon/20</name>
            <type>ballistic</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/20</name>
            <type>ammunition</type>
            <location index="1">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/20</name>
            <type>ammunition</type>
            <location index="0">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Centurion" model="CN9-AL" tons="50" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1057</battle_value>
    <cost>3365875.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2915</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="200" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>23</ct>
        <ctr>9</ctr>
        <lt>17</lt>
        <ltr>7</ltr>
        <rt>17</rt>
        <rtr>7</rtr>
        <la>16</la>
        <ra>16</ra>
        <ll>24</ll>
        <rl>24</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Centurion CN9-AL 50t, 4/6/0, Std FE, Std; 11.0T/100% Armor; 16 SHS; 2 ML, 1 SL, 1 LRM10, 1 LL</info>
        <battleforce pv="11" wt="2" mv="4" s="2" m="2" l="1" e="0" ov="0" armor="6" internal="4" abilities="SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="16" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">RL</location>
            <location index="5">RL</location>
            <location index="5">LL</location>
            <location index="4">LL</location>
            <location index="1">RT</location>
            <location index="2">RT</location>
            <location index="3">RT</location>
            <location index="0">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) LRM-10</name>
            <type>missile</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="5">RA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Centurion" model="CN9-YLW &quot;Yen Lo Wang&quot;" tons="50" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>957</battle_value>
    <cost>3439750.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3027</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="200" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>18</ct>
        <ctr>7</ctr>
        <lt>14</lt>
        <ltr>6</ltr>
        <rt>14</rt>
        <rtr>6</rtr>
        <la>14</la>
        <ra>16</ra>
        <ll>16</ll>
        <rl>16</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Centurion CN9-YLW "Yen Lo Wang" 50t, 4/6/0, Std FE, Std; 8.5T/80% Armor; 10 SHS; 2 ML, 1 AC20, 1 Htcht</info>
        <battleforce pv="10" wt="2" mv="4" s="3" m="3" l="0" e="0" ov="0" armor="5" internal="4" abilities="MEL, SRCH, ES, SEAL, SOA, AC 2/2/0" />
        <actuators lla="TRUE" lh="TRUE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="0">LT</location>
            <location index="0">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Autocannon/20</name>
            <type>ballistic</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/20</name>
            <type>ammunition</type>
            <location index="1">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/20</name>
            <type>ammunition</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/20</name>
            <type>ammunition</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">Hatchet</name>
            <type>physical</type>
            <location index="4">LA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Champion" model="CHP-1N2" tons="60" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="C:\\CJ\\HeavyMetalbv2\\Pro\\Bmps\\Champion.jpg">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1233</battle_value>
    <cost>5798800.0</cost>
    <rules_level>1</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2667</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="300" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>24</ct>
        <ctr>8</ctr>
        <lt>18</lt>
        <ltr>6</ltr>
        <rt>18</rt>
        <rtr>6</rtr>
        <la>12</la>
        <ra>12</ra>
        <ll>15</ll>
        <rl>15</rl>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="6">LT</location>
        <location index="7">LT</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
    </armor>
    <baseloadout fcsa4="TRUE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Champion CHP-1N2 60t, 5/8/0, Std FE, Std; 8.0T/71% FF Armor; 10 DHS; 2 ML, 2 SL, 1 SRM6, 1 LB10</info>
        <battleforce pv="12" wt="3" mv="5" s="4" m="3" l="1" e="0" ov="0" armor="5" internal="5" abilities="SRCH, ES, SEAL, SOA, FLK 1/1/1" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) LB 10-X AC</name>
            <type>ballistic</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Slug)</name>
            <type>ammunition</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Cluster)</name>
            <type>ammunition</type>
            <location index="7">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional>&lt;p style=&quot;margin-top: 0&quot;&gt;:br::br:&lt;/p&gt;:br:</additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Champion" model="CHP-2N" tons="60" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1116</battle_value>
    <cost>5047800.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2810</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="300" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>22</ct>
        <ctr>9</ctr>
        <lt>16</lt>
        <ltr>5</ltr>
        <rt>16</rt>
        <rtr>5</rtr>
        <la>10</la>
        <ra>10</ra>
        <ll>13</ll>
        <rl>13</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Champion CHP-2N 60t, 5/8/0, Std FE, Std; 8.0T/64% Armor; 10 SHS; 2 ML, 2 SL, 1 AC10, 1 SRM6</info>
        <battleforce pv="11" wt="3" mv="5" s="3" m="3" l="0" e="0" ov="0" armor="4" internal="5" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Autocannon/10</name>
            <type>ballistic</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/10</name>
            <type>ammunition</type>
            <location index="7">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/10</name>
            <type>ammunition</type>
            <location index="8">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Charger" model="CGR-1A1" tons="80" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>981</battle_value>
    <cost>7520370.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2665</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="400" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>25</ct>
        <ctr>6</ctr>
        <lt>20</lt>
        <ltr>5</ltr>
        <rt>20</rt>
        <rtr>5</rtr>
        <la>15</la>
        <ra>15</ra>
        <ll>20</ll>
        <rl>20</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Charger CGR-1A1 80t, 5/8/0, Std FE, Std; 10.0T/65% Armor; 10 SHS; 5 SL</info>
        <battleforce pv="10" wt="4" mv="5" s="2" m="0" l="0" e="0" ov="0" armor="5" internal="6" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Charger" model="CGR-1A5" tons="80" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1468</battle_value>
    <cost>7719170.000000001</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3023</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="320" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>39</ct>
        <ctr>10</ctr>
        <lt>26</lt>
        <ltr>8</ltr>
        <rt>26</rt>
        <rtr>8</rtr>
        <la>24</la>
        <ra>24</ra>
        <ll>33</ll>
        <rl>33</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Charger CGR-1A5 80t, 4/6/0, Std FE, Std; 15.0T/97% Armor; 13 SHS; 1 ML, 1 SL, 2 SRM6, 1 AC20</info>
        <battleforce pv="15" wt="4" mv="4" s="4" m="3" l="0" e="0" ov="0" armor="8" internal="6" abilities="SRCH, ES, SEAL, SOA, AC 2/2/0, SRM 1/1/0" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="13" techbase="2">
            <type>Single Heat Sink</type>

            <location index="0">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Autocannon/20</name>
            <type>ballistic</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="1">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/20</name>
            <type>ammunition</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/20</name>
            <type>ammunition</type>
            <location index="7">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Charger" model="CGR-1A9" tons="80" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1397</battle_value>
    <cost>7973370.000000001</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3034</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="320" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>25</ct>
        <ctr>6</ctr>
        <lt>20</lt>
        <ltr>5</ltr>
        <rt>20</rt>
        <rtr>5</rtr>
        <la>15</la>
        <ra>15</ra>
        <ll>20</ll>
        <rl>20</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Charger CGR-1A9 80t, 4/6/4, Std FE, Std; 10.0T/65% Armor; 22 SHS; 4 ML, 1 SL, 1 LRM20</info>
        <battleforce pv="14" wt="4" mv="4j" s="3" m="4" l="2" e="0" ov="0" armor="5" internal="6" abilities="SRCH, ES, SEAL, SOA, LRM 1/1/1, IF 1" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="4">LL</location>
            <location index="5">RL</location>
            <location index="4">RL</location>
            <location index="5">LL</location>
        </jumpjets>
        <heatsinks number="22" techbase="2">
            <type>Single Heat Sink</type>

            <location index="11">CT</location>
            <location index="10">CT</location>
            <location index="3">LA</location>
            <location index="4">LA</location>
            <location index="0">LT</location>
            <location index="1">LT</location>
            <location index="5">RA</location>
            <location index="4">RA</location>
            <location index="1">RT</location>
            <location index="0">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="6">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="7">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="5">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-20</name>
            <type>missile</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Charger" model="CGR-1L" tons="80" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>980</battle_value>
    <cost>7662120.000000001</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3022</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="400" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>8</hd>
        <ct>10</ct>
        <ctr>6</ctr>
        <lt>9</lt>
        <ltr>5</ltr>
        <rt>9</rt>
        <rtr>5</rtr>
        <la>7</la>
        <ra>7</ra>
        <ll>11</ll>
        <rl>11</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Charger CGR-1L 80t, 5/8/0, Std FE, Std; 5.5T/36% Armor; 10 SHS; 2 ML, 1 LL</info>
        <battleforce pv="10" wt="4" mv="5" s="2" m="2" l="0" e="0" ov="0" armor="3" internal="6" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Charger" model="CGR-SB &quot;Challenger&quot;" tons="80" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1604</battle_value>
    <cost>6298920.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3025</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="240" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>40</ct>
        <ctr>10</ctr>
        <lt>24</lt>
        <ltr>10</ltr>
        <rt>24</rt>
        <rtr>10</rtr>
        <la>26</la>
        <ra>26</ra>
        <ll>34</ll>
        <rl>34</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Charger CGR-SB "Challenger" 80t, 3/5/0, Std FE, Std; 15.5T/100% Armor; 28 SHS; 1 ML, 4 LL</info>
        <battleforce pv="16" wt="4" mv="3" s="4" m="4" l="0" e="0" ov="0" armor="8" internal="6" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="28" techbase="2">
            <type>Single Heat Sink</type>

            <location index="5">RL</location>
            <location index="4">RL</location>
            <location index="4">LL</location>
            <location index="5">LL</location>
            <location index="3">LA</location>
            <location index="0">LT</location>
            <location index="1">LT</location>
            <location index="4">LT</location>
            <location index="2">LT</location>
            <location index="5">LT</location>
            <location index="6">LT</location>
            <location index="3">LT</location>
            <location index="5">RT</location>
            <location index="3">RT</location>
            <location index="1">RT</location>
            <location index="4">RT</location>
            <location index="6">RT</location>
            <location index="2">RT</location>
            <location index="0">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="7">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="7">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Cicada" model="CDA-2A" tons="40" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>659</battle_value>
    <cost>3705216.6666666665</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2740</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="320" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>11</ct>
        <ctr>6</ctr>
        <lt>6</lt>
        <ltr>3</ltr>
        <rt>6</rt>
        <rtr>3</rtr>
        <la>4</la>
        <ra>4</ra>
        <ll>6</ll>
        <rl>6</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Cicada CDA-2A 40t, 8/12/0, Std FE, Std; 4.0T/47% Armor; 10 SHS; 2 ML, 1 SL</info>
        <battleforce pv="7" wt="2" mv="8" s="2" m="1" l="0" e="0" ov="0" armor="2" internal="3" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Cicada" model="CDA-2B" tons="40" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>626</battle_value>
    <cost>3692966.6666666665</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2840</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="320" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>8</hd>
        <ct>10</ct>
        <ctr>6</ctr>
        <lt>6</lt>
        <ltr>2</ltr>
        <rt>6</rt>
        <rtr>2</rtr>
        <la>3</la>
        <ra>3</ra>
        <ll>5</ll>
        <rl>5</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Cicada CDA-2B 40t, 8/12/0, Std FE, Std; 3.5T/41% Armor; 10 SHS; 1 Flmr, 2 ML</info>
        <battleforce pv="6" wt="2" mv="8" s="2" m="1" l="0" e="0" ov="0" armor="2" internal="3" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Cicada" model="CDA-3C" tons="40" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>771</battle_value>
    <cost>3305933.333333333</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2840</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="280" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>11</ct>
        <ctr>6</ctr>
        <lt>6</lt>
        <ltr>3</ltr>
        <rt>6</rt>
        <rtr>3</rtr>
        <la>4</la>
        <ra>4</ra>
        <ll>6</ll>
        <rl>6</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Cicada CDA-3C 40t, 7/11/0, Std FE, Std; 4.0T/47% Armor; 11 SHS; 1 PPC, 2 MG</info>
        <battleforce pv="8" wt="2" mv="7" s="2" m="1" l="1" e="0" ov="0" armor="2" internal="3" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="11" techbase="2">
            <type>Single Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="4">RL</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="4">LL</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Machine Gun</name>
            <type>ammunition</type>
            <location index="10">CT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Clint" model="CLNT-1-2R" tons="40" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>707</battle_value>
    <cost>3217880.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2607</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="240" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>11</ct>
        <ctr>4</ctr>
        <lt>6</lt>
        <ltr>4</ltr>
        <rt>6</rt>
        <rtr>4</rtr>
        <la>6</la>
        <ra>6</ra>
        <ll>8</ll>
        <rl>8</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Clint CLNT-1-2R 40t, 6/9/0, Std FE, Std; 4.5T/53% Armor; 10 SHS; 1 ML, 1 AC10</info>
        <battleforce pv="7" wt="2" mv="6" s="2" m="2" l="0" e="0" ov="0" armor="2" internal="3" abilities="SRCH, ES, SEAL, SOA, AC 1/1/0" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="0">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Autocannon/10</name>
            <type>ballistic</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/10</name>
            <type>ammunition</type>
            <location index="0">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Clint" model="CLNT-2-3T (Denton)" tons="40" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>873</battle_value>
    <cost>3542280.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3033</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="240" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>11</ct>
        <ctr>4</ctr>
        <lt>6</lt>
        <ltr>4</ltr>
        <rt>6</rt>
        <rtr>4</rtr>
        <la>6</la>
        <ra>6</ra>
        <ll>8</ll>
        <rl>8</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Clint CLNT-2-3T (Denton) 40t, 6/9/6, Std FE, Std; 4.5T/53% Armor; 14 SHS; 2 ML, 1 LL</info>
        <battleforce pv="9" wt="2" mv="6j" s="2" m="2" l="0" e="0" ov="0" armor="2" internal="3" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="6">
            <type>Standard Jump Jet</type>
            <location index="4">LL</location>
            <location index="5">RL</location>
            <location index="4">RL</location>
            <location index="5">LL</location>
            <location index="1">RT</location>
            <location index="1">LT</location>
        </jumpjets>
        <heatsinks number="14" techbase="2">
            <type>Single Heat Sink</type>

            <location index="0">RT</location>
            <location index="10">CT</location>
            <location index="0">LT</location>
            <location index="4">RA</location>
            <location index="3">RA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="5">RA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Clint" model="CLNT-2-3T" tons="40" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>770</battle_value>
    <cost>3570580.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2608</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="240" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>11</ct>
        <ctr>4</ctr>
        <lt>6</lt>
        <ltr>4</ltr>
        <rt>6</rt>
        <rtr>4</rtr>
        <la>6</la>
        <ra>6</ra>
        <ll>8</ll>
        <rl>8</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Clint CLNT-2-3T 40t, 6/9/6, Std FE, Std; 4.5T/53% Armor; 10 SHS; 2 ML, 1 AC5</info>
        <battleforce pv="8" wt="2" mv="6j" s="2" m="2" l="1" e="0" ov="0" armor="2" internal="3" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="6">
            <type>Standard Jump Jet</type>
            <location index="4">LL</location>
            <location index="5">RL</location>
            <location index="4">RL</location>
            <location index="5">LL</location>
            <location index="1">RT</location>
            <location index="0">LT</location>
        </jumpjets>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="0">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Autocannon/5</name>
            <type>ballistic</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="1">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/5</name>
            <type>ammunition</type>
            <location index="2">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Clint" model="CLNT-2-4T" tons="40" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>619</battle_value>
    <cost>3142880.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2950</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="240" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>11</ct>
        <ctr>4</ctr>
        <lt>6</lt>
        <ltr>4</ltr>
        <rt>6</rt>
        <rtr>4</rtr>
        <la>6</la>
        <ra>6</ra>
        <ll>8</ll>
        <rl>8</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Clint CLNT-2-4T 40t, 6/9/0, Std FE, Std; 4.5T/53% Armor; 10 SHS; 1 ML, 2 AC2</info>
        <battleforce pv="6" wt="2" mv="6" s="1" m="1" l="1" e="0" ov="0" armor="2" internal="3" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="0">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Autocannon/2</name>
            <type>ballistic</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Autocannon/2</name>
            <type>ballistic</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/2</name>
            <type>ammunition</type>
            <location index="0">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Commando" model="COM-1D" tons="25" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>558</battle_value>
    <cost>1838250.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>0</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2480</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="150" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>6</hd>
        <ct>6</ct>
        <ctr>2</ctr>
        <lt>5</lt>
        <ltr>2</ltr>
        <rt>5</rt>
        <rtr>2</rtr>
        <la>4</la>
        <ra>4</ra>
        <ll>6</ll>
        <rl>6</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Commando COM-1D 25t, 6/9/0, Std FE, Std; 3.0T/54% Armor; 10 SHS; 1 SRM6, 1 LL</info>
        <battleforce pv="6" wt="1" mv="6" s="2" m="2" l="0" e="0" ov="0" armor="2" internal="2" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="0">LT</location>
            <location index="1">LT</location>
            <location index="0">RT</location>
            <location index="1">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="2">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Commando" model="COM-2D" tons="25" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>541</battle_value>
    <cost>1877750.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>0</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2486</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="150" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>6</hd>
        <ct>8</ct>
        <ctr>4</ctr>
        <lt>6</lt>
        <ltr>3</ltr>
        <rt>6</rt>
        <rtr>3</rtr>
        <la>6</la>
        <ra>6</ra>
        <ll>8</ll>
        <rl>8</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Commando COM-2D 25t, 6/9/0, Std FE, Std; 4.0T/72% Armor; 10 SHS; 1 ML, 1 SRM6, 1 SRM4</info>
        <battleforce pv="5" wt="1" mv="6" s="2" m="2" l="0" e="0" ov="0" armor="2" internal="2" abilities="SRCH, ES, SEAL, SOA, SRM 1/1/0" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="1">RT</location>
            <location index="0">RT</location>
            <location index="0">LT</location>
            <location index="1">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-4</name>
            <type>missile</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4</name>
            <type>ammunition</type>
            <location index="2">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Commando" model="COM-3A" tons="25" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>540</battle_value>
    <cost>1872625.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3025</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="150" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>4</hd>
        <ct>6</ct>
        <ctr>4</ctr>
        <lt>4</lt>
        <ltr>3</ltr>
        <rt>4</rt>
        <rtr>3</rtr>
        <la>4</la>
        <ra>4</ra>
        <ll>6</ll>
        <rl>6</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Commando COM-3A 25t, 6/9/0, Std FE, Std; 3.0T/54% Armor; 10 SHS; 1 Flmr, 1 ML, 2 SRM6</info>
        <battleforce pv="5" wt="1" mv="6" s="2" m="2" l="0" e="0" ov="0" armor="2" internal="2" abilities="SRCH, ES, SEAL, SOA, SRM 1/1/0" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">LL</location>
            <location index="5">LL</location>
            <location index="5">RL</location>
            <location index="4">RL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="6">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="0">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Crab" model="CRB-20" tons="50" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1143</battle_value>
    <cost>3921875.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2810</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="250" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>18</ct>
        <ctr>7</ctr>
        <lt>14</lt>
        <ltr>5</ltr>
        <rt>14</rt>
        <rtr>5</rtr>
        <la>14</la>
        <ra>14</ra>
        <ll>22</ll>
        <rl>22</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Crab CRB-20 50t, 5/8/0, Std FE, Std; 9.0T/85% Armor; 16 SHS; 1 ML, 1 SL, 2 LL</info>
        <battleforce pv="11" wt="2" mv="5" s="3" m="2" l="0" e="0" ov="1" armor="5" internal="4" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="16" techbase="2">
            <type>Single Heat Sink</type>

            <location index="5">RL</location>
            <location index="0">LT</location>
            <location index="4">RL</location>
            <location index="4">LL</location>
            <location index="5">LL</location>
            <location index="0">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Crusader" model="CRD-3D" tons="65" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1338</battle_value>
    <cost>5558510.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="TharHes Industries, Brigadier Corporation, Kallon Industries" location="Tharkad (TharHes), Oliver (Brigadier), Asuncion, Bernardo (Kallon)">Inner Sphere</techbase>
    <year restricted="FALSE">2756</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Crucis-b" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="260" manufacturer="Magna" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Riese 500" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>33</ct>
        <ctr>8</ctr>
        <lt>24</lt>
        <ltr>6</ltr>
        <rt>24</rt>
        <rtr>6</rtr>
        <la>20</la>
        <ra>20</ra>
        <ll>21</ll>
        <rl>21</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Crusader CRD-3D 65t, 4/6/0, Std FE, Std; 12.0T/91% Armor; 14 SHS; 2 ML, 2 LRM15, 2 SRM4</info>
        <battleforce pv="13" wt="3" mv="4" s="3" m="3" l="1" e="0" ov="0" armor="6" internal="5" abilities="SRCH, ES, SEAL, SOA, LRM 1/1/1, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="14" techbase="2">
            <type>Single Heat Sink</type>

            <location index="0">RT</location>
            <location index="0">LT</location>
            <location index="1">RT</location>
            <location index="1">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="Intek">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="7">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Intek">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="7">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Magna Longbow-15">(IS) LRM-15</name>
            <type>missile</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Magna Longbow-15">(IS) LRM-15</name>
            <type>missile</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-4</name>
            <type>missile</type>
            <location index="4">RL</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-4</name>
            <type>missile</type>
            <location index="4">LL</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4</name>
            <type>ammunition</type>
            <location index="10">CT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Garret T11-b</commsystem>
        <tandtsystem>Garret A6</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Crusader" model="CRD-3K" tons="65" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1290</battle_value>
    <cost>5383610.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="TharHes Industries, Brigadier Corporation, Kallon Industries" location="Tharkad (TharHes), Oliver (Brigadier), Asuncion, Bernardo (Kallon)">Inner Sphere</techbase>
    <year restricted="FALSE">2765</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Crucis-b" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="260" manufacturer="Magna" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Riese 500" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>33</ct>
        <ctr>8</ctr>
        <lt>24</lt>
        <ltr>6</ltr>
        <rt>24</rt>
        <rtr>6</rtr>
        <la>20</la>
        <ra>20</ra>
        <ll>21</ll>
        <rl>21</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Crusader CRD-3K 65t, 4/6/0, Std FE, Std; 12.0T/91% Armor; 16 SHS; 2 ML, 2 SRM6, 2 LRM10</info>
        <battleforce pv="13" wt="3" mv="4" s="3" m="3" l="1" e="0" ov="0" armor="6" internal="5" abilities="SRCH, ES, SEAL, SOA, SRM 1/1/0, LRM 1/1/1, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="16" techbase="2">
            <type>Single Heat Sink</type>

            <location index="0">RT</location>
            <location index="0">LT</location>
            <location index="1">RT</location>
            <location index="1">LT</location>
            <location index="2">LT</location>
            <location index="2">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) LRM-10</name>
            <type>missile</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-10</name>
            <type>missile</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="4">RL</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="4">LL</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="6">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="6">LA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Garret T11-b</commsystem>
        <tandtsystem>Garret A6</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Crusader" model="CRD-3L" tons="65" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1297</battle_value>
    <cost>5665110.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="TharHes Industries, Brigadier Corporation, Kallon Industries" location="Tharkad (TharHes), Oliver (Brigadier), Asuncion, Bernardo (Kallon)">Inner Sphere</techbase>
    <year restricted="FALSE">2753</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Crucis-b" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="260" manufacturer="Magna" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Riese 500" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>33</ct>
        <ctr>8</ctr>
        <lt>24</lt>
        <ltr>6</ltr>
        <rt>24</rt>
        <rtr>6</rtr>
        <la>20</la>
        <ra>20</ra>
        <ll>21</ll>
        <rl>21</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Crusader CRD-3L 65t, 4/6/4, Std FE, Std; 12.0T/91% Armor; 12 SHS; 2 ML, 2 MG, 2 LRM10, 2 SRM4</info>
        <battleforce pv="13" wt="3" mv="4j" s="2" m="3" l="1" e="0" ov="1" armor="6" internal="5" abilities="SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="1">LT</location>
            <location index="1">RT</location>
            <location index="0">LT</location>
            <location index="0">RT</location>
        </jumpjets>
        <heatsinks number="12" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">RL</location>
            <location index="4">LL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) LRM-10</name>
            <type>missile</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-10</name>
            <type>missile</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="6">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="6">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-4</name>
            <type>missile</type>
            <location index="5">RL</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-4</name>
            <type>missile</type>
            <location index="5">LL</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4</name>
            <type>ammunition</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="7">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="7">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Machine Gun</name>
            <type>ammunition</type>
            <location index="11">CT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Garret T11-b</commsystem>
        <tandtsystem>Garret A6</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Crusader" model="CRD-3R" tons="65" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1317</battle_value>
    <cost>5628810.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="TharHes Industries, Brigadier Corporation, Kallon Industries" location="Tharkad (TharHes), Oliver (Brigadier), Asuncion, Bernardo (Kallon)">Inner Sphere</techbase>
    <year restricted="FALSE">2752</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Crucis-b" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="260" manufacturer="Magna" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Riese 500" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>33</ct>
        <ctr>8</ctr>
        <lt>24</lt>
        <ltr>6</ltr>
        <rt>24</rt>
        <rtr>6</rtr>
        <la>20</la>
        <ra>20</ra>
        <ll>21</ll>
        <rl>21</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Crusader CRD-3R 65t, 4/6/0, Std FE, Std; 12.0T/91% Armor; 10 SHS; 2 ML, 2 LRM15, 2 MG, 2 SRM6</info>
        <battleforce pv="13" wt="3" mv="4" s="2" m="2" l="1" e="0" ov="2" armor="6" internal="5" abilities="SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="Intek">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="7">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Intek">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="7">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Magna Longbow-15">(IS) LRM-15</name>
            <type>missile</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Magna Longbow-15">(IS) LRM-15</name>
            <type>missile</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Harpoon-6">(IS) SRM-6</name>
            <type>missile</type>
            <location index="4">RL</location>
        </equipment>
        <equipment>
            <name manufacturer="Harpoon-6">(IS) SRM-6</name>
            <type>missile</type>
            <location index="4">LL</location>
        </equipment>
        <equipment>
            <name manufacturer="M100 Heavy">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="8">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="M100 Heavy">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="8">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Machine Gun</name>
            <type>ammunition</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="0">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Garret T11-b</commsystem>
        <tandtsystem>Garret A6</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Cyclops" model="CP-10-HQ" tons="90" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1113</battle_value>
    <cost>9477960.0</cost>
    <rules_level>3</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2753</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="360" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>30</ct>
        <ctr>11</ctr>
        <lt>20</lt>
        <ltr>8</ltr>
        <rt>20</rt>
        <rtr>8</rtr>
        <la>10</la>
        <ra>10</ra>
        <ll>17</ll>
        <rl>17</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Cyclops CP-10-HQ 90t, 4/6/0, Std FE, Std; 10.0T/57% Armor; 14 SHS; 2 ML, 1 LRM10, 1 CCM, 1 SRM4</info>
        <battleforce pv="11" wt="4" mv="4" s="2" m="3" l="1" e="0" ov="0" armor="5" internal="7" abilities="SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="14" techbase="2">
            <type>Single Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-10</name>
            <type>missile</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-4</name>
            <type>missile</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4</name>
            <type>ammunition</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">Collapsible Command Module (CCM)</name>
            <type>equipment</type>
            <location index="0">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Cyclops" model="CP-10-Q" tons="90" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1584</battle_value>
    <cost>9016960.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2801</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="360" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>46</ct>
        <ctr>11</ctr>
        <lt>29</lt>
        <ltr>8</ltr>
        <rt>29</rt>
        <rtr>8</rtr>
        <la>29</la>
        <ra>29</ra>
        <ll>37</ll>
        <rl>37</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Cyclops CP-10-Q 90t, 4/6/0, Std FE, Std; 17.0T/97% Armor; 14 SHS; 3 ML, 2 LRM10, 1 SRM4</info>
        <battleforce pv="16" wt="4" mv="4" s="3" m="3" l="1" e="0" ov="0" armor="9" internal="7" abilities="SRCH, ES, SEAL, SOA, LRM 1/1/1, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="14" techbase="2">
            <type>Single Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-10</name>
            <type>missile</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-10</name>
            <type>missile</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-4</name>
            <type>missile</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4</name>
            <type>ammunition</type>
            <location index="11">CT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Cyclops" model="CP-10-Z" tons="90" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1317</battle_value>
    <cost>9130360.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2710</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="360" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>30</ct>
        <ctr>11</ctr>
        <lt>20</lt>
        <ltr>8</ltr>
        <rt>20</rt>
        <rtr>8</rtr>
        <la>10</la>
        <ra>10</ra>
        <ll>17</ll>
        <rl>17</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Cyclops CP-10-Z 90t, 4/6/0, Std FE, Std; 10.0T/57% Armor; 12 SHS; 2 ML, 1 LRM10, 1 AC20, 1 SRM4</info>
        <battleforce pv="13" wt="4" mv="4" s="3" m="3" l="1" e="0" ov="1" armor="5" internal="7" abilities="SRCH, ES, SEAL, SOA, AC 1/1/0" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="12" techbase="2">
            <type>Single Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Autocannon/20</name>
            <type>ballistic</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-10</name>
            <type>missile</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-4</name>
            <type>missile</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4</name>
            <type>ammunition</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/20</name>
            <type>ammunition</type>
            <location index="10">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/20</name>
            <type>ammunition</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/20</name>
            <type>ammunition</type>
            <location index="11">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/20</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Daboku" model="DCMS-MX90-D" tons="90" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1290</battle_value>
    <cost>7956500.0</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3038</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="270" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>23</ct>
        <ctr>8</ctr>
        <lt>15</lt>
        <ltr>5</ltr>
        <rt>15</rt>
        <rtr>5</rtr>
        <la>16</la>
        <ra>16</ra>
        <ll>20</ll>
        <rl>20</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Daboku DCMS-MX90-D 90t, 3/5/0, Std FE, Std; 9.5T/54% Armor; 12 SHS; 2 LRM10, 2 LL, 4 AC2</info>
        <battleforce pv="13" wt="4" mv="3" s="2" m="2" l="1" e="0" ov="2" armor="5" internal="7" abilities="CASE, SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="12" techbase="2">
            <type>Single Heat Sink</type>

            <location index="0">LT</location>
            <location index="0">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-10</name>
            <type>missile</type>
            <location index="1">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-10</name>
            <type>missile</type>
            <location index="1">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Autocannon/2</name>
            <type>ballistic</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Autocannon/2</name>
            <type>ballistic</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Autocannon/2</name>
            <type>ballistic</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Autocannon/2</name>
            <type>ballistic</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/2</name>
            <type>ammunition</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/2</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="7">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="7">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Dervish" model="DV-6M" tons="55" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1146</battle_value>
    <cost>4927266.666666667</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2610</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="275" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>8</hd>
        <ct>20</ct>
        <ctr>4</ctr>
        <lt>15</lt>
        <ltr>4</ltr>
        <rt>15</rt>
        <rtr>4</rtr>
        <la>10</la>
        <ra>10</ra>
        <ll>15</ll>
        <rl>15</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Dervish DV-6M 55t, 5/8/5, Std FE, Std; 7.5T/65% Armor; 10 SHS; 2 ML, 2 LRM10, 2 SRM2</info>
        <battleforce pv="11" wt="2" mv="5j" s="2" m="2" l="1" e="0" ov="1" armor="4" internal="5" abilities="SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="5">
            <type>Standard Jump Jet</type>
            <location index="10">CT</location>
            <location index="4">LL</location>
            <location index="5">RL</location>
            <location index="4">RL</location>
            <location index="5">LL</location>
        </jumpjets>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-2</name>
            <type>missile</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-2</name>
            <type>missile</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-10</name>
            <type>missile</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-10</name>
            <type>missile</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-2</name>
            <type>ammunition</type>
            <location index="5">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-2</name>
            <type>ammunition</type>
            <location index="5">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="2">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Dragon" model="DRG-1C" tons="60" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1215</battle_value>
    <cost>4955400.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2752</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="300" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>28</ct>
        <ctr>12</ctr>
        <lt>18</lt>
        <ltr>10</ltr>
        <rt>18</rt>
        <rtr>10</rtr>
        <la>20</la>
        <ra>20</ra>
        <ll>28</ll>
        <rl>28</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Dragon DRG-1C 60t, 5/8/0, Std FE, Std; 13.0T/100% Armor; 10 SHS; 2 ML, 1 LRM10, 1 AC2</info>
        <battleforce pv="12" wt="3" mv="5" s="1" m="2" l="1" e="0" ov="0" armor="7" internal="5" abilities="SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Laser</name>
            <type>energy</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-10</name>
            <type>missile</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Autocannon/2</name>
            <type>ballistic</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="1">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/2</name>
            <type>ammunition</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="2">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Dragon" model="DRG-1N" tons="60" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1125</battle_value>
    <cost>4995400.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2754</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="300" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>27</ct>
        <ctr>12</ctr>
        <lt>16</lt>
        <ltr>8</ltr>
        <rt>16</rt>
        <rtr>8</rtr>
        <la>14</la>
        <ra>14</ra>
        <ll>18</ll>
        <rl>18</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Dragon DRG-1N 60t, 5/8/0, Std FE, Std; 10.0T/80% Armor; 10 SHS; 2 ML, 1 LRM10, 1 AC5</info>
        <battleforce pv="11" wt="3" mv="5" s="2" m="2" l="2" e="0" ov="0" armor="5" internal="5" abilities="SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Laser</name>
            <type>energy</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-10</name>
            <type>missile</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="1">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Autocannon/5</name>
            <type>ballistic</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/5</name>
            <type>ammunition</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/5</name>
            <type>ammunition</type>
            <location index="1">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Enforcer" model="ENF-4R" tons="50" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1032</battle_value>
    <cost>3533875.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2777</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="200" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>23</ct>
        <ctr>4</ctr>
        <lt>17</lt>
        <ltr>3</ltr>
        <rt>17</rt>
        <rtr>3</rtr>
        <la>14</la>
        <ra>14</ra>
        <ll>20</ll>
        <rl>20</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Enforcer ENF-4R 50t, 4/6/4, Std FE, Std; 9.0T/85% Armor; 12 SHS; 1 SL, 1 AC10, 1 LL</info>
        <battleforce pv="10" wt="2" mv="4j" s="3" m="2" l="0" e="0" ov="0" armor="5" internal="4" abilities="SRCH, ES, SEAL, SOA, AC 1/1/0" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="4">LL</location>
            <location index="5">LL</location>
            <location index="4">RL</location>
            <location index="5">RL</location>
        </jumpjets>
        <heatsinks number="12" techbase="2">
            <type>Single Heat Sink</type>

            <location index="1">LT</location>
            <location index="0">LT</location>
            <location index="0">RT</location>
            <location index="1">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Autocannon/10</name>
            <type>ballistic</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/10</name>
            <type>ammunition</type>
            <location index="2">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Falcon" model="FLC-4N" tons="30" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>610</battle_value>
    <cost>2249090.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2582</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="180" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>14</ct>
        <ctr>3</ctr>
        <lt>12</lt>
        <ltr>2</ltr>
        <rt>12</rt>
        <rtr>2</rtr>
        <la>8</la>
        <ra>8</ra>
        <ll>13</ll>
        <rl>13</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Falcon FLC-4N 30t, 6/9/6, Std FE, Std; 6.0T/91% Armor; 12 SHS; 1 ML, 2 SL, 2 MG</info>
        <battleforce pv="6" wt="1" mv="6j" s="2" m="1" l="0" e="0" ov="0" armor="3" internal="3" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="6">
            <type>Standard Jump Jet</type>
            <location index="0">RT</location>
            <location index="1">RT</location>
            <location index="0">LT</location>
            <location index="2">RT</location>
            <location index="1">LT</location>
            <location index="2">LT</location>
        </jumpjets>
        <heatsinks number="12" techbase="2">
            <type>Single Heat Sink</type>

            <location index="5">LL</location>
            <location index="4">LL</location>
            <location index="5">RL</location>
            <location index="4">RL</location>
            <location index="10">CT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="5">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Machine Gun</name>
            <type>ammunition</type>
            <location index="11">CT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Firestarter" model="FS9-A" tons="35" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>773</battle_value>
    <cost>3075975.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>0</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2550</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="210" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>13</ct>
        <ctr>6</ctr>
        <lt>11</lt>
        <ltr>5</ltr>
        <rt>11</rt>
        <rtr>5</rtr>
        <la>10</la>
        <ra>10</ra>
        <ll>12</ll>
        <rl>12</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Firestarter FS9-A 35t, 6/9/6, Std FE, Std; 6.5T/87% Armor; 10 SHS; 4 Flmr, 2 ML, 2 SL</info>
        <battleforce pv="8" wt="1" mv="6j" s="2" m="1" l="0" e="0" ov="0" armor="3" internal="3" abilities="ENE, HT1, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="6">
            <type>Standard Jump Jet</type>
            <location index="1">LT</location>
            <location index="0">RT</location>
            <location index="0">LT</location>
            <location index="1">RT</location>
            <location index="2">RT</location>
            <location index="2">LT</location>
        </jumpjets>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">RL</location>
            <location index="4">LL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Flamer</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="3">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Firestarter" model="FS9-H" tons="35" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>694</battle_value>
    <cost>3046600.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2703</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="210" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>13</ct>
        <ctr>6</ctr>
        <lt>11</lt>
        <ltr>5</ltr>
        <rt>11</rt>
        <rtr>5</rtr>
        <la>6</la>
        <ra>6</ra>
        <ll>8</ll>
        <rl>8</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Firestarter FS9-H 35t, 6/9/6, Std FE, Std; 5.5T/74% Armor; 10 SHS; 4 Flmr, 2 ML, 2 MG</info>
        <battleforce pv="7" wt="1" mv="6j" s="2" m="1" l="0" e="0" ov="0" armor="3" internal="3" abilities="HT1, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="6">
            <type>Standard Jump Jet</type>
            <location index="1">LT</location>
            <location index="0">RT</location>
            <location index="0">LT</location>
            <location index="1">RT</location>
            <location index="2">RT</location>
            <location index="2">LT</location>
        </jumpjets>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">RL</location>
            <location index="4">LL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Flamer</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Machine Gun</name>
            <type>ammunition</type>
            <location index="4">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Firestarter" model="FS9-K" tons="35" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>763</battle_value>
    <cost>3069225.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>0</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2550</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="210" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>13</ct>
        <ctr>6</ctr>
        <lt>11</lt>
        <ltr>5</ltr>
        <rt>11</rt>
        <rtr>5</rtr>
        <la>6</la>
        <ra>6</ra>
        <ll>8</ll>
        <rl>8</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Firestarter FS9-K 35t, 6/9/6, Std FE, Std; 5.5T/74% Armor; 10 SHS; 2 Flmr, 2 SL, 1 LL</info>
        <battleforce pv="8" wt="1" mv="6j" s="2" m="1" l="0" e="0" ov="0" armor="3" internal="3" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="6">
            <type>Standard Jump Jet</type>
            <location index="1">LT</location>
            <location index="0">RT</location>
            <location index="0">LT</location>
            <location index="1">RT</location>
            <location index="2">RT</location>
            <location index="2">LT</location>
        </jumpjets>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">LL</location>
            <location index="4">RL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Flamer</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Firestarter" model="FS9-M &quot;Mirage&quot;" tons="35" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>798</battle_value>
    <cost>3066175.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2893</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="210" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>16</ct>
        <ctr>6</ctr>
        <lt>12</lt>
        <ltr>4</ltr>
        <rt>12</rt>
        <rtr>4</rtr>
        <la>12</la>
        <ra>12</ra>
        <ll>16</ll>
        <rl>16</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Firestarter FS9-M "Mirage" 35t, 6/9/6, Std FE, Std; 7.5T/100% Armor; 11 SHS; 2 ML, 2 SL, 2 MG</info>
        <battleforce pv="8" wt="1" mv="6j" s="2" m="1" l="0" e="0" ov="0" armor="4" internal="3" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="6">
            <type>Standard Jump Jet</type>
            <location index="4">LL</location>
            <location index="1">RT</location>
            <location index="2">LT</location>
            <location index="4">RL</location>
            <location index="5">RL</location>
            <location index="5">LL</location>
        </jumpjets>
        <heatsinks number="11" techbase="2">
            <type>Single Heat Sink</type>

            <location index="0">LT</location>
            <location index="1">LT</location>
            <location index="0">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Machine Gun</name>
            <type>ammunition</type>
            <location index="3">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Flashman" model="FLS-7K" tons="75" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1480</battle_value>
    <cost>6341125.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2796</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="300" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>25</ct>
        <ctr>16</ctr>
        <lt>22</lt>
        <ltr>10</ltr>
        <rt>22</rt>
        <rtr>10</rtr>
        <la>24</la>
        <ra>24</ra>
        <ll>27</ll>
        <rl>27</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Flashman FLS-7K 75t, 4/6/0, Std FE, Std; 13.5T/94% Armor; 23 SHS; 1 Flmr, 5 ML, 2 LL</info>
        <battleforce pv="15" wt="3" mv="4" s="4" m="3" l="0" e="0" ov="1" armor="7" internal="6" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="23" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">RL</location>
            <location index="5">RL</location>
            <location index="4">LL</location>
            <location index="5">LL</location>
            <location index="1">LT</location>
            <location index="0">LT</location>
            <location index="2">LT</location>
            <location index="10">CT</location>
            <location index="0">RT</location>
            <location index="1">RT</location>
            <location index="2">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="5">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="5">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Flea" model="FLE-15" tons="20" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>430</battle_value>
    <cost>1520200.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>0</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2523</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="120" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>5</hd>
        <ct>8</ct>
        <ctr>3</ctr>
        <lt>5</lt>
        <ltr>3</ltr>
        <rt>5</rt>
        <rtr>3</rtr>
        <la>4</la>
        <ra>4</ra>
        <ll>4</ll>
        <rl>4</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Flea FLE-15 20t, 6/9/0, Std FE, Std; 3.0T/70% Armor; 10 SHS; 2 ML, 1 Flmr, 2 SL, 2 MG</info>
        <battleforce pv="4" wt="1" mv="6" s="2" m="1" l="0" e="0" ov="0" armor="2" internal="2" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="1">RT</location>
            <location index="0">RT</location>
            <location index="0">LT</location>
            <location index="1">LT</location>
            <location index="4">RL</location>
            <location index="4">LL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Small Laser</name>
            <type>energy</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Small Laser</name>
            <type>energy</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Machine Gun</name>
            <type>ammunition</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">LA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Flea" model="FLE-4" tons="20" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>432</battle_value>
    <cost>1519200.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>0</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2501</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="120" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>5</hd>
        <ct>5</ct>
        <ctr>2</ctr>
        <lt>3</lt>
        <ltr>1</ltr>
        <rt>3</rt>
        <rtr>1</rtr>
        <la>3</la>
        <ra>3</ra>
        <ll>3</ll>
        <rl>3</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Flea FLE-4 20t, 6/9/0, Std FE, Std; 2.0T/46% Armor; 10 SHS; 1 Flmr, 2 SL, 1 LL</info>
        <battleforce pv="4" wt="1" mv="6" s="2" m="1" l="0" e="0" ov="0" armor="1" internal="2" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="0">LT</location>
            <location index="1">LT</location>
            <location index="0">RT</location>
            <location index="1">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Flamer</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Goliath" model="GOL-1H" tons="80" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1449</battle_value>
    <cost>7602400.000000001</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Brigadier Corporation, Corean Enterprises" location="Oliver (Brigadier, destroyed 2837), Stewart (Corean)">Inner Sphere</techbase>
    <year restricted="FALSE">2652</year>
    <motive_type>Quad</motive_type>
    <structure manufacturer="Corean VIII" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="320" manufacturer="Hermes" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="StarSlab/3" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>30</ct>
        <ctr>19</ctr>
        <lt>20</lt>
        <ltr>13</ltr>
        <rt>20</rt>
        <rtr>13</rtr>
        <la>24</la>
        <ra>24</ra>
        <ll>30</ll>
        <rl>30</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Goliath GOL-1H 80t, 4/6/0, Std FE, Std Quad; 14.5T/88% Armor; 17 SHS; 1 PPC, 2 MG, 2 LRM10</info>
        <battleforce pv="14" wt="4" mv="4" s="2" m="3" l="3" e="0" ov="0" armor="8" internal="6" abilities="SRCH, ES, SEAL, SOA, LRM 1/1/1, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="17" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">RRL</location>
            <location index="4">FLL</location>
            <location index="4">RLL</location>
            <location index="4">FRL</location>
            <location index="3">HD</location>
        </heatsinks>
        <equipment>
            <name manufacturer="Rand">(IS) PPC</name>
            <type>energy</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Voelkers 200">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Voelkers 200">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Holly Long Range Missile 10 Racks">(IS) LRM-10</name>
            <type>missile</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Holly Long Range Missile 10 Racks">(IS) LRM-10</name>
            <type>missile</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Machine Gun</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Corean TransBand-J9</commsystem>
        <tandtsystem>Corean B-Tech</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Grand Dragon" model="DRG-1G" tons="60" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1300</battle_value>
    <cost>5176800.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3024</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="300" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>27</ct>
        <ctr>12</ctr>
        <lt>16</lt>
        <ltr>8</ltr>
        <rt>16</rt>
        <rtr>8</rtr>
        <la>14</la>
        <ra>14</ra>
        <ll>18</ll>
        <rl>18</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Grand Dragon DRG-1G 60t, 5/8/0, Std FE, Std; 10.0T/80% Armor; 12 SHS; 3 ML, 1 PPC, 1 LRM10</info>
        <battleforce pv="13" wt="3" mv="5" s="2" m="2" l="2" e="0" ov="1" armor="5" internal="5" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="12" techbase="2">
            <type>Single Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Laser</name>
            <type>energy</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-10</name>
            <type>missile</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="1">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="0">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Grasshopper" model="GHR-5H" tons="70" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1427</battle_value>
    <cost>6003573.333333335</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2780</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="280" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>30</ct>
        <ctr>13</ctr>
        <lt>20</lt>
        <ltr>10</ltr>
        <rt>20</rt>
        <rtr>10</rtr>
        <la>22</la>
        <ra>22</ra>
        <ll>26</ll>
        <rl>26</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Grasshopper GHR-5H 70t, 4/6/4, Std FE, Std; 13.0T/96% Armor; 22 SHS; 4 ML, 1 LRM5, 1 LL</info>
        <battleforce pv="14" wt="3" mv="4j" s="3" m="4" l="1" e="0" ov="0" armor="7" internal="6" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="5">RL</location>
            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="5">LL</location>
        </jumpjets>
        <heatsinks number="22" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">LT</location>
            <location index="4">LA</location>
            <location index="3">LT</location>
            <location index="2">LT</location>
            <location index="1">LT</location>
            <location index="0">LT</location>
            <location index="4">RA</location>
            <location index="2">RT</location>
            <location index="1">RT</location>
            <location index="3">RT</location>
            <location index="0">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="5">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="5">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-5</name>
            <type>missile</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-5</name>
            <type>ammunition</type>
            <location index="5">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Griffin" model="GRF-1E &quot;Sparky&quot;" tons="55" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1449</battle_value>
    <cost>5037706.666666667</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3035</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="275" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>24</ct>
        <ctr>7</ctr>
        <lt>20</lt>
        <ltr>6</ltr>
        <rt>20</rt>
        <rtr>6</rtr>
        <la>18</la>
        <ra>18</ra>
        <ll>20</ll>
        <rl>20</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Griffin GRF-1E "Sparky" 55t, 5/8/5, Std FE, Std; 10.5T/91% Armor; 13 SHS; 5 ML, 1 PPC</info>
        <battleforce pv="14" wt="2" mv="5j" s="2" m="2" l="1" e="0" ov="2" armor="6" internal="5" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="5">
            <type>Standard Jump Jet</type>
            <location index="1">LT</location>
            <location index="0">LT</location>
            <location index="11">CT</location>
            <location index="0">RT</location>
            <location index="1">RT</location>
        </jumpjets>
        <heatsinks number="13" techbase="2">
            <type>Single Heat Sink</type>

            <location index="3">HD</location>
            <location index="10">CT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="5">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="7">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Griffin" model="GRF-1N" tons="55" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1272</battle_value>
    <cost>4924106.666666667</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>0</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Earthwerks Incorporated, Defiance Industries, Kallon Industries" location="Keystone (Earthwerks), Hesperus II (Defiance), Talon (Kallon)">Inner Sphere</techbase>
    <year restricted="FALSE">2492</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Earthwerks GRF" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="275" manufacturer="CoreTek" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Starshield A" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>20</ct>
        <ctr>7</ctr>
        <lt>20</lt>
        <ltr>6</ltr>
        <rt>20</rt>
        <rtr>6</rtr>
        <la>14</la>
        <ra>14</ra>
        <ll>18</ll>
        <rl>18</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Griffin GRF-1N 55t, 5/8/5, Std FE, Std; 9.5T/82% Armor; 12 SHS; 1 PPC, 1 LRM10</info>
        <battleforce pv="13" wt="2" mv="5j" s="1" m="2" l="2" e="0" ov="0" armor="5" internal="5" abilities="SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="5">
            <type>Standard Jump Jet</type>
            <location index="11">CT</location>
            <location index="0">LT</location>
            <location index="0">RT</location>
            <location index="1">RT</location>
            <location index="1">LT</location>
        </jumpjets>
        <heatsinks number="12" techbase="2">
            <type>Single Heat Sink</type>

            <location index="10">CT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="Fusigon">(IS) PPC</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-10</name>
            <type>missile</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="5">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Rawlings 55</jumpjet_model>
        <commsystem>Neil 6000</commsystem>
        <tandtsystem>Octagon Tartrac, System C</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Griffin" model="GRF-1S" tons="55" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1253</battle_value>
    <cost>4767006.666666667</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Earthwerks Incorporated, Defiance Industries, Kallon Industries" location="Keystone (Earthwerks), Hesperus II (Defiance), Talon (Kallon)">Inner Sphere</techbase>
    <year restricted="FALSE">2857</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Earthwerks GRF" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="275" manufacturer="CoreTek" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Starshield A" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>20</ct>
        <ctr>7</ctr>
        <lt>20</lt>
        <ltr>6</ltr>
        <rt>20</rt>
        <rtr>6</rtr>
        <la>14</la>
        <ra>14</ra>
        <ll>18</ll>
        <rl>18</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Griffin GRF-1S 55t, 5/8/5, Std FE, Std; 9.5T/82% Armor; 16 SHS; 2 ML, 1 LRM5, 1 LL</info>
        <battleforce pv="13" wt="2" mv="5j" s="2" m="2" l="1" e="0" ov="1" armor="5" internal="5" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="5">
            <type>Standard Jump Jet</type>
            <location index="10">CT</location>
            <location index="0">LT</location>
            <location index="0">RT</location>
            <location index="1">RT</location>
            <location index="1">LT</location>
        </jumpjets>
        <heatsinks number="16" techbase="2">
            <type>Single Heat Sink</type>

            <location index="3">HD</location>
            <location index="4">RL</location>
            <location index="4">LL</location>
            <location index="5">RL</location>
            <location index="5">LL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="6">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="7">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-5</name>
            <type>missile</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-5</name>
            <type>ammunition</type>
            <location index="2">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Rawlings 55</jumpjet_model>
        <commsystem>Neil 6000</commsystem>
        <tandtsystem>Octagon Tartrac, System C</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Guillotine" model="GLT-4L" tons="70" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1400</battle_value>
    <cost>6043583.333333335</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2825</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="280" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>27</ct>
        <ctr>12</ctr>
        <lt>22</lt>
        <ltr>8</ltr>
        <rt>22</rt>
        <rtr>8</rtr>
        <la>20</la>
        <ra>20</ra>
        <ll>22</ll>
        <rl>22</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Guillotine GLT-4L 70t, 4/6/4, Std FE, Std; 12.0T/88% Armor; 22 SHS; 4 ML, 1 SRM6, 1 LL</info>
        <battleforce pv="14" wt="3" mv="4j" s="4" m="4" l="0" e="0" ov="0" armor="6" internal="6" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="5">RL</location>
            <location index="5">LL</location>
            <location index="4">RT</location>
            <location index="5">LT</location>
        </jumpjets>
        <heatsinks number="22" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="4">LT</location>
            <location index="3">LT</location>
            <location index="1">LT</location>
            <location index="0">LT</location>
            <location index="2">LT</location>
            <location index="0">RT</location>
            <location index="2">RT</location>
            <location index="3">RT</location>
            <location index="1">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="5">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Guillotine" model="GLT-4P" tons="70" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1376</battle_value>
    <cost>6179583.333333335</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2833</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="280" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>25</ct>
        <ctr>8</ctr>
        <lt>17</lt>
        <ltr>5</ltr>
        <rt>17</rt>
        <rtr>5</rtr>
        <la>16</la>
        <ra>16</ra>
        <ll>21</ll>
        <rl>21</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Guillotine GLT-4P 70t, 4/6/4, Std FE, Std; 10.0T/74% Armor; 22 SHS; 4 ML, 1 PPC, 1 SRM6</info>
        <battleforce pv="14" wt="3" mv="4j" s="4" m="4" l="1" e="0" ov="0" armor="5" internal="6" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="5">RL</location>
            <location index="5">LL</location>
            <location index="4">RT</location>
            <location index="5">LT</location>
        </jumpjets>
        <heatsinks number="22" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="4">LT</location>
            <location index="3">LT</location>
            <location index="1">LT</location>
            <location index="0">LT</location>
            <location index="2">LT</location>
            <location index="0">RT</location>
            <location index="2">RT</location>
            <location index="3">RT</location>
            <location index="1">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="2">LA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Hatamoto-Chi" model="HTM-26T" tons="80" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1536</battle_value>
    <cost>7792320.000000001</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3039</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="320" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>33</ct>
        <ctr>10</ctr>
        <lt>24</lt>
        <ltr>8</ltr>
        <rt>24</rt>
        <rtr>8</rtr>
        <la>25</la>
        <ra>25</ra>
        <ll>33</ll>
        <rl>33</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Hatamoto-Chi HTM-26T 80t, 4/6/0, Std FE, Std; 14.5T/94% Armor; 17 SHS; 2 PPC, 2 SRM6</info>
        <battleforce pv="15" wt="4" mv="4" s="2" m="3" l="2" e="0" ov="1" armor="8" internal="6" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="17" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="0">LT</location>
            <location index="3">HD</location>
            <location index="0">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="1">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="1">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="3">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Hatchetman" model="HCT-3F" tons="45" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>854</battle_value>
    <cost>3123990.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3023</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="180" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>6</hd>
        <ct>14</ct>
        <ctr>4</ctr>
        <lt>14</lt>
        <ltr>4</ltr>
        <rt>14</rt>
        <rtr>4</rtr>
        <la>11</la>
        <ra>11</ra>
        <ll>11</ll>
        <rl>11</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Hatchetman HCT-3F 45t, 4/6/4, Std FE, Std; 6.5T/68% Armor; 11 SHS; 2 ML, 1 AC10, 1 Htcht</info>
        <battleforce pv="9" wt="2" mv="4j" s="2" m="2" l="0" e="0" ov="0" armor="3" internal="4" abilities="MEL, SRCH, ES, SEAL, SOA, AC 1/1/0" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="5">RL</location>
            <location index="4">RL</location>
            <location index="5">LL</location>
            <location index="4">LL</location>
        </jumpjets>
        <heatsinks number="11" techbase="2">
            <type>Single Heat Sink</type>

            <location index="1">LT</location>
            <location index="0">LT</location>
            <location index="0">RT</location>
            <location index="1">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Autocannon/10</name>
            <type>ballistic</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/10</name>
            <type>ammunition</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/10</name>
            <type>ammunition</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="7">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">Hatchet</name>
            <type>physical</type>
            <location index="4">RA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Hermes" model="HER-1A" tons="30" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>601</battle_value>
    <cost>2569970.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2856</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="270" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>7</hd>
        <ct>7</ct>
        <ctr>4</ctr>
        <lt>6</lt>
        <ltr>2</ltr>
        <rt>6</rt>
        <rtr>2</rtr>
        <la>5</la>
        <ra>5</ra>
        <ll>6</ll>
        <rl>6</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Hermes HER-1A 30t, 9/14/0, Std FE, Std; 3.5T/53% Armor; 10 SHS; 1 Flmr, 2 ML</info>
        <battleforce pv="6" wt="1" mv="9" s="2" m="1" l="0" e="0" ov="0" armor="2" internal="3" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Hermes" model="HER-1B" tons="30" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>687</battle_value>
    <cost>2612220.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2856</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="270" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>7</hd>
        <ct>7</ct>
        <ctr>4</ctr>
        <lt>6</lt>
        <ltr>2</ltr>
        <rt>6</rt>
        <rtr>2</rtr>
        <la>5</la>
        <ra>5</ra>
        <ll>6</ll>
        <rl>6</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Hermes HER-1B 30t, 9/14/0, Std FE, Std; 3.5T/53% Armor; 10 SHS; 3 ML</info>
        <battleforce pv="7" wt="1" mv="9" s="2" m="2" l="0" e="0" ov="0" armor="2" internal="3" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Hermes II" model="HER-2M &quot;Mercury&quot;" tons="40" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>910</battle_value>
    <cost>3263013.333333333</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2802</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="280" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>17</ct>
        <ctr>6</ctr>
        <lt>14</lt>
        <ltr>5</ltr>
        <rt>14</rt>
        <rtr>5</rtr>
        <la>11</la>
        <ra>11</ra>
        <ll>14</ll>
        <rl>14</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Hermes II HER-2M "Mercury" 40t, 7/11/0, Std FE, Std; 7.5T/88% Armor; 11 SHS; 1 Flmr, 3 ML, 2 MG</info>
        <battleforce pv="9" wt="2" mv="7" s="3" m="2" l="0" e="0" ov="0" armor="4" internal="3" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="11" techbase="2">
            <type>Single Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="1">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Machine Gun (1/2)</name>
            <type>ammunition</type>
            <location index="0">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Hermes II" model="HER-2S" tons="40" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>784</battle_value>
    <cost>3163880.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2798</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="240" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>17</ct>
        <ctr>6</ctr>
        <lt>14</lt>
        <ltr>5</ltr>
        <rt>14</rt>
        <rtr>5</rtr>
        <la>11</la>
        <ra>11</ra>
        <ll>14</ll>
        <rl>14</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Hermes II HER-2S 40t, 6/9/0, Std FE, Std; 7.5T/88% Armor; 10 SHS; 1 Flmr, 1 ML, 1 AC5</info>
        <battleforce pv="8" wt="2" mv="6" s="2" m="1" l="1" e="0" ov="0" armor="4" internal="3" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="0">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Autocannon/5</name>
            <type>ballistic</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/5</name>
            <type>ammunition</type>
            <location index="1">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Hermes II" model="HER-4K &quot;Hermes III&quot;" tons="40" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>976</battle_value>
    <cost>3200680.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3025</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="240" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>17</ct>
        <ctr>6</ctr>
        <lt>14</lt>
        <ltr>5</ltr>
        <rt>14</rt>
        <rtr>5</rtr>
        <la>11</la>
        <ra>11</ra>
        <ll>14</ll>
        <rl>14</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Hermes II HER-4K "Hermes III" 40t, 6/9/0, Std FE, Std; 7.5T/88% Armor; 11 SHS; 2 LL</info>
        <battleforce pv="10" wt="2" mv="6" s="2" m="2" l="0" e="0" ov="0" armor="4" internal="3" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="11" techbase="2">
            <type>Single Heat Sink</type>

            <location index="11">CT</location>
            <location index="10">CT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="0">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Highlander" model="HGN-733" tons="90" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1801</battle_value>
    <cost>8166780.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2866</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="270" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>41</ct>
        <ctr>17</ctr>
        <lt>28</lt>
        <ltr>10</ltr>
        <rt>28</rt>
        <rtr>10</rtr>
        <la>30</la>
        <ra>30</ra>
        <ll>38</ll>
        <rl>38</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Highlander HGN-733 90t, 3/5/3, Std FE, Std; 17.5T/100% Armor; 13 SHS; 2 ML, 1 AC10, 1 SRM6, 1 LRM20</info>
        <battleforce pv="18" wt="4" mv="3j" s="3" m="3" l="1" e="0" ov="1" armor="9" internal="7" abilities="SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="3">
            <type>Standard Jump Jet</type>
            <location index="0">LT</location>
            <location index="10">CT</location>
            <location index="0">RT</location>
        </jumpjets>
        <heatsinks number="13" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="4">LA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="1">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="5">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-20</name>
            <type>missile</type>
            <location index="1">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Autocannon/10</name>
            <type>ballistic</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="7">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20</name>
            <type>ammunition</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20</name>
            <type>ammunition</type>
            <location index="10">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20</name>
            <type>ammunition</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/10</name>
            <type>ammunition</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/10</name>
            <type>ammunition</type>
            <location index="3">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Highlander" model="HGN-733C" tons="90" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1857</battle_value>
    <cost>8299230.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2866</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="270" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>41</ct>
        <ctr>17</ctr>
        <lt>28</lt>
        <ltr>10</ltr>
        <rt>28</rt>
        <rtr>10</rtr>
        <la>30</la>
        <ra>30</ra>
        <ll>38</ll>
        <rl>38</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Highlander HGN-733C 90t, 3/5/3, Std FE, Std; 17.5T/100% Armor; 13 SHS; 2 ML, 1 AC20, 1 SRM6, 1 LRM20</info>
        <battleforce pv="19" wt="4" mv="3j" s="3" m="3" l="1" e="0" ov="1" armor="9" internal="7" abilities="SRCH, ES, SEAL, SOA, AC 1/1/0, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="3">
            <type>Standard Jump Jet</type>
            <location index="0">LT</location>
            <location index="10">CT</location>
            <location index="0">RT</location>
        </jumpjets>
        <heatsinks number="13" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="4">LA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="1">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="5">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-20</name>
            <type>missile</type>
            <location index="1">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20</name>
            <type>ammunition</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20</name>
            <type>ammunition</type>
            <location index="7">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Autocannon/20</name>
            <type>ballistic</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/20</name>
            <type>ammunition</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/20</name>
            <type>ammunition</type>
            <location index="3">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Highlander" model="HGN-733P" tons="90" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1865</battle_value>
    <cost>8181380.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2866</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="270" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>41</ct>
        <ctr>17</ctr>
        <lt>28</lt>
        <ltr>10</ltr>
        <rt>28</rt>
        <rtr>10</rtr>
        <la>30</la>
        <ra>30</ra>
        <ll>38</ll>
        <rl>38</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Highlander HGN-733P 90t, 3/5/3, Std FE, Std; 17.5T/100% Armor; 20 SHS; 2 ML, 1 PPC, 1 SRM6, 1 LRM20</info>
        <battleforce pv="19" wt="4" mv="3j" s="3" m="4" l="2" e="0" ov="0" armor="9" internal="7" abilities="SRCH, ES, SEAL, SOA, LRM 1/1/1, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="3">
            <type>Standard Jump Jet</type>
            <location index="2">LT</location>
            <location index="11">CT</location>
            <location index="2">RT</location>
        </jumpjets>
        <heatsinks number="20" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="5">LL</location>
            <location index="1">LT</location>
            <location index="0">LT</location>
            <location index="5">RL</location>
            <location index="10">CT</location>
            <location index="3">HD</location>
            <location index="0">RT</location>
            <location index="1">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-20</name>
            <type>missile</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20</name>
            <type>ammunition</type>
            <location index="11">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20</name>
            <type>ammunition</type>
            <location index="10">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20</name>
            <type>ammunition</type>
            <location index="5">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Hornet" model="HNT-151" tons="20" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>488</battle_value>
    <cost>1242700.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2760</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="100" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>8</hd>
        <ct>8</ct>
        <ctr>4</ctr>
        <lt>8</lt>
        <ltr>2</ltr>
        <rt>8</rt>
        <rtr>2</rtr>
        <la>6</la>
        <ra>6</ra>
        <ll>6</ll>
        <rl>6</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Hornet HNT-151 20t, 5/8/5, Std FE, Std; 4.0T/93% Armor; 10 SHS; 1 ML, 1 SL, 1 LRM5</info>
        <battleforce pv="5" wt="1" mv="5j" s="1" m="1" l="1" e="0" ov="0" armor="2" internal="2" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="5">
            <type>Standard Jump Jet</type>
            <location index="5">LL</location>
            <location index="4">RL</location>
            <location index="10">CT</location>
            <location index="5">RL</location>
            <location index="4">LL</location>
        </jumpjets>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="1">LT</location>
            <location index="0">LT</location>
            <location index="2">LT</location>
            <location index="0">RT</location>
            <location index="1">RT</location>
            <location index="2">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-5</name>
            <type>missile</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-5</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Hornet" model="HNT-152" tons="20" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>475</battle_value>
    <cost>1275700.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2997</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="100" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>8</hd>
        <ct>8</ct>
        <ctr>4</ctr>
        <lt>8</lt>
        <ltr>2</ltr>
        <rt>8</rt>
        <rtr>2</rtr>
        <la>6</la>
        <ra>6</ra>
        <ll>6</ll>
        <rl>6</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Hornet HNT-152 20t, 5/8/5, Std FE, Std; 4.0T/93% Armor; 10 SHS; 1 ML, 1 SL, 1 SRM4</info>
        <battleforce pv="5" wt="1" mv="5j" s="2" m="2" l="0" e="0" ov="0" armor="2" internal="2" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="5">
            <type>Standard Jump Jet</type>
            <location index="5">LL</location>
            <location index="4">RL</location>
            <location index="10">CT</location>
            <location index="5">RL</location>
            <location index="4">LL</location>
        </jumpjets>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="1">LT</location>
            <location index="0">LT</location>
            <location index="2">LT</location>
            <location index="0">RT</location>
            <location index="2">RT</location>
            <location index="1">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-4</name>
            <type>missile</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Hunchback" model="HBK-4G" tons="50" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1041</battle_value>
    <cost>3457875.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2572</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="200" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>26</ct>
        <ctr>5</ctr>
        <lt>20</lt>
        <ltr>4</ltr>
        <rt>20</rt>
        <rtr>4</rtr>
        <la>16</la>
        <ra>16</ra>
        <ll>20</ll>
        <rl>20</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Hunchback HBK-4G 50t, 4/6/0, Std FE, Std; 10.0T/95% Armor; 13 SHS; 2 ML, 1 SL, 1 AC20</info>
        <battleforce pv="10" wt="2" mv="4" s="4" m="3" l="0" e="0" ov="0" armor="5" internal="4" abilities="SRCH, ES, SEAL, SOA, AC 2/2/0" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="13" techbase="2">
            <type>Single Heat Sink</type>

            <location index="5">RL</location>
            <location index="4">RL</location>
            <location index="5">LL</location>
            <location index="4">LL</location>
            <location index="10">CT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Autocannon/20</name>
            <type>ballistic</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/20</name>
            <type>ammunition</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/20</name>
            <type>ammunition</type>
            <location index="1">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Hunchback" model="HBK-4H" tons="50" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1067</battle_value>
    <cost>3419875.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2819</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="200" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>26</ct>
        <ctr>5</ctr>
        <lt>20</lt>
        <ltr>4</ltr>
        <rt>20</rt>
        <rtr>4</rtr>
        <la>16</la>
        <ra>16</ra>
        <ll>20</ll>
        <rl>20</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Hunchback HBK-4H 50t, 4/6/0, Std FE, Std; 10.0T/95% Armor; 13 SHS; 4 ML, 1 SL, 1 AC10</info>
        <battleforce pv="11" wt="2" mv="4" s="4" m="3" l="0" e="0" ov="0" armor="5" internal="4" abilities="SRCH, ES, SEAL, SOA, AC 1/1/0" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="13" techbase="2">
            <type>Single Heat Sink</type>

            <location index="2">LT</location>
            <location index="4">LT</location>
            <location index="3">LT</location>
            <location index="0">LT</location>
            <location index="1">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="7">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="8">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Autocannon/10</name>
            <type>ballistic</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/10</name>
            <type>ammunition</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/10</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Hunchback" model="HBK-4J" tons="50" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1143</battle_value>
    <cost>3530875.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2856</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="200" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>26</ct>
        <ctr>5</ctr>
        <lt>20</lt>
        <ltr>4</ltr>
        <rt>20</rt>
        <rtr>4</rtr>
        <la>16</la>
        <ra>16</ra>
        <ll>20</ll>
        <rl>20</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Hunchback HBK-4J 50t, 4/6/0, Std FE, Std; 10.0T/95% Armor; 14 SHS; 5 ML, 1 SL, 2 LRM10</info>
        <battleforce pv="11" wt="2" mv="4" s="3" m="3" l="1" e="0" ov="1" armor="5" internal="4" abilities="SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="14" techbase="2">
            <type>Single Heat Sink</type>

            <location index="5">RL</location>
            <location index="4">RL</location>
            <location index="5">LL</location>
            <location index="4">LL</location>
            <location index="0">LT</location>
            <location index="1">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-10</name>
            <type>missile</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-10</name>
            <type>missile</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Hunchback" model="HBK-4N" tons="50" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1087</battle_value>
    <cost>3419875.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2920</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="200" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>26</ct>
        <ctr>5</ctr>
        <lt>20</lt>
        <ltr>4</ltr>
        <rt>20</rt>
        <rtr>4</rtr>
        <la>16</la>
        <ra>16</ra>
        <ll>20</ll>
        <rl>20</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Hunchback HBK-4N 50t, 4/6/0, Std FE, Std; 10.0T/95% Armor; 13 SHS; 4 ML, 1 SL, 2 LRM5, 1 AC5</info>
        <battleforce pv="11" wt="2" mv="4" s="3" m="3" l="1" e="0" ov="1" armor="5" internal="4" abilities="SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="13" techbase="2">
            <type>Single Heat Sink</type>

            <location index="3">LT</location>
            <location index="2">LT</location>
            <location index="1">LT</location>
            <location index="4">LT</location>
            <location index="0">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Autocannon/5</name>
            <type>ballistic</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-5</name>
            <type>missile</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-5</name>
            <type>missile</type>
            <location index="7">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-5</name>
            <type>ammunition</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/5</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Hunchback" model="HBK-4P" tons="50" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1138</battle_value>
    <cost>3377875.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2937</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="200" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>26</ct>
        <ctr>5</ctr>
        <lt>20</lt>
        <ltr>4</ltr>
        <rt>20</rt>
        <rtr>4</rtr>
        <la>16</la>
        <ra>16</ra>
        <ll>20</ll>
        <rl>20</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Hunchback HBK-4P 50t, 4/6/0, Std FE, Std; 10.0T/95% Armor; 23 SHS; 8 ML, 1 SL</info>
        <battleforce pv="11" wt="2" mv="4" s="5" m="4" l="0" e="0" ov="0" armor="5" internal="4" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="23" techbase="2">
            <type>Single Heat Sink</type>

            <location index="6">LT</location>
            <location index="1">LT</location>
            <location index="4">LT</location>
            <location index="2">LT</location>
            <location index="5">LT</location>
            <location index="0">LT</location>
            <location index="3">LT</location>
            <location index="4">LL</location>
            <location index="5">LL</location>
            <location index="5">RL</location>
            <location index="4">RL</location>
            <location index="1">RT</location>
            <location index="0">RT</location>
            <location index="11">CT</location>
            <location index="10">CT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="7">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Hunchback" model="HBK-4SP" tons="50" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1043</battle_value>
    <cost>3419875.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3025</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="200" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>26</ct>
        <ctr>5</ctr>
        <lt>20</lt>
        <ltr>4</ltr>
        <rt>20</rt>
        <rtr>4</rtr>
        <la>16</la>
        <ra>16</ra>
        <ll>20</ll>
        <rl>20</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Hunchback HBK-4SP 50t, 4/6/0, Std FE, Std; 10.0T/95% Armor; 19 SHS; 4 ML, 1 SL, 2 SRM6</info>
        <battleforce pv="10" wt="2" mv="4" s="4" m="4" l="0" e="0" ov="0" armor="5" internal="4" abilities="SRCH, ES, SEAL, SOA, SRM 2/2/0" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="19" techbase="2">
            <type>Single Heat Sink</type>

            <location index="0">LT</location>
            <location index="2">LT</location>
            <location index="3">LT</location>
            <location index="1">LT</location>
            <location index="5">LL</location>
            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="5">RL</location>
            <location index="1">RT</location>
            <location index="0">RT</location>
            <location index="2">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="5">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="5">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="11">CT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Hussar" model="HSR-300-D" tons="30" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>543</battle_value>
    <cost>2563340.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3035</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="270" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>3</hd>
        <ct>4</ct>
        <ctr>1</ctr>
        <lt>3</lt>
        <ltr>1</ltr>
        <rt>3</rt>
        <rtr>1</rtr>
        <la>2</la>
        <ra>2</ra>
        <ll>2</ll>
        <rl>2</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Hussar HSR-300-D 30t, 9/14/0, Std FE, Std; 1.5T/23% Armor; 10 SHS; 1 LL</info>
        <battleforce pv="5" wt="1" mv="9" s="1" m="1" l="0" e="0" ov="0" armor="1" internal="3" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Hussar" model="HSR-350-D" tons="30" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>731</battle_value>
    <cost>2602340.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3038</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="240" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>5</hd>
        <ct>6</ct>
        <ctr>3</ctr>
        <lt>5</lt>
        <ltr>2</ltr>
        <rt>5</rt>
        <rtr>2</rtr>
        <la>3</la>
        <ra>3</ra>
        <ll>3</ll>
        <rl>3</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Hussar HSR-350-D 30t, 8/12/0, Std FE, Std; 2.5T/38% Armor; 10 SHS; 2 ML, 1 LL</info>
        <battleforce pv="7" wt="1" mv="8" s="2" m="2" l="0" e="0" ov="0" armor="1" internal="3" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="3">HD</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="JagerMech" model="JM6-A" tons="65" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1122</battle_value>
    <cost>5474925.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2778</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="260" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>20</ct>
        <ctr>5</ctr>
        <lt>17</lt>
        <ltr>3</ltr>
        <rt>17</rt>
        <rtr>3</rtr>
        <la>11</la>
        <ra>11</ra>
        <ll>16</ll>
        <rl>16</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>JagerMech JM6-A 65t, 4/6/0, Std FE, Std; 8.0T/61% Armor; 10 SHS; 2 ML, 2 LRM15, 2 AC2</info>
        <battleforce pv="11" wt="3" mv="4" s="2" m="2" l="2" e="0" ov="1" armor="4" internal="5" abilities="SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Autocannon/2</name>
            <type>ballistic</type>
            <location index="5">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Autocannon/2</name>
            <type>ballistic</type>
            <location index="5">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="1">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/2</name>
            <type>ammunition</type>
            <location index="1">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="JagerMech" model="JM6-S" tons="65" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>901</battle_value>
    <cost>5225925.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2774</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="260" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>16</ct>
        <ctr>5</ctr>
        <lt>13</lt>
        <ltr>3</ltr>
        <rt>13</rt>
        <rtr>3</rtr>
        <la>6</la>
        <ra>6</ra>
        <ll>11</ll>
        <rl>11</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>JagerMech JM6-S 65t, 4/6/0, Std FE, Std; 6.0T/45% Armor; 10 SHS; 2 ML, 2 AC5, 2 AC2</info>
        <battleforce pv="9" wt="3" mv="4" s="3" m="3" l="2" e="0" ov="0" armor="3" internal="5" abilities="SRCH, ES, SEAL, SOA, AC 1/1/1" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Autocannon/2</name>
            <type>ballistic</type>
            <location index="6">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Autocannon/2</name>
            <type>ballistic</type>
            <location index="6">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/2</name>
            <type>ammunition</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Autocannon/5</name>
            <type>ballistic</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Autocannon/5</name>
            <type>ballistic</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/5</name>
            <type>ammunition</type>
            <location index="1">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/5</name>
            <type>ammunition</type>
            <location index="1">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Javelin" model="JVN-10F &quot;Fire Javelin&quot;" tons="30" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>835</battle_value>
    <cost>2361840.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2819</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="180" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>7</hd>
        <ct>15</ct>
        <ctr>4</ctr>
        <lt>9</lt>
        <ltr>4</ltr>
        <rt>9</rt>
        <rtr>4</rtr>
        <la>9</la>
        <ra>9</ra>
        <ll>13</ll>
        <rl>13</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Javelin JVN-10F "Fire Javelin" 30t, 6/9/6, Std FE, Std; 6.0T/91% Armor; 12 SHS; 4 ML</info>
        <battleforce pv="8" wt="1" mv="6j" s="2" m="2" l="0" e="0" ov="0" armor="3" internal="3" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="6">
            <type>Standard Jump Jet</type>
            <location index="10">CT</location>
            <location index="5">LL</location>
            <location index="4">RL</location>
            <location index="4">LL</location>
            <location index="5">RL</location>
            <location index="11">CT</location>
        </jumpjets>
        <heatsinks number="12" techbase="2">
            <type>Single Heat Sink</type>

            <location index="1">LT</location>
            <location index="0">LT</location>
            <location index="3">HD</location>
            <location index="0">RT</location>
            <location index="1">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Javelin" model="JVN-10N" tons="30" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>594</battle_value>
    <cost>2384640.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2751</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="180" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>6</hd>
        <ct>8</ct>
        <ctr>2</ctr>
        <lt>8</lt>
        <ltr>2</ltr>
        <rt>8</rt>
        <rtr>2</rtr>
        <la>6</la>
        <ra>6</ra>
        <ll>8</ll>
        <rl>8</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Javelin JVN-10N 30t, 6/9/6, Std FE, Std; 4.0T/61% Armor; 10 SHS; 2 SRM6</info>
        <battleforce pv="6" wt="1" mv="6j" s="2" m="2" l="0" e="0" ov="0" armor="2" internal="3" abilities="SRCH, ES, SEAL, SOA, SRM 2/2/0" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="6">
            <type>Standard Jump Jet</type>
            <location index="10">CT</location>
            <location index="4">LL</location>
            <location index="11">CT</location>
            <location index="5">RL</location>
            <location index="4">RL</location>
            <location index="5">LL</location>
        </jumpjets>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="0">RT</location>
            <location index="0">LT</location>
            <location index="1">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="1">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Jenner" model="JR7-A" tons="35" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>712</battle_value>
    <cost>3005325.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2779</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="245" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>7</hd>
        <ct>10</ct>
        <ctr>3</ctr>
        <lt>8</lt>
        <ltr>4</ltr>
        <rt>8</rt>
        <rtr>4</rtr>
        <la>4</la>
        <ra>4</ra>
        <ll>6</ll>
        <rl>6</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Jenner JR7-A 35t, 7/11/5, Std FE, Std; 4.0T/54% Armor; 12 SHS; 1 LL</info>
        <battleforce pv="7" wt="1" mv="7/3j" s="1" m="1" l="0" e="0" ov="0" armor="2" internal="3" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="5">
            <type>Standard Jump Jet</type>
            <location index="1">RT</location>
            <location index="0">RT</location>
            <location index="10">CT</location>
            <location index="0">LT</location>
            <location index="1">LT</location>
        </jumpjets>
        <heatsinks number="12" techbase="2">
            <type>Single Heat Sink</type>

            <location index="3">HD</location>
            <location index="4">LL</location>
            <location index="4">RL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="2">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Jenner" model="JR7-D" tons="35" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>875</battle_value>
    <cost>3188925.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2785</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="245" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>7</hd>
        <ct>10</ct>
        <ctr>3</ctr>
        <lt>8</lt>
        <ltr>4</ltr>
        <rt>8</rt>
        <rtr>4</rtr>
        <la>4</la>
        <ra>4</ra>
        <ll>6</ll>
        <rl>6</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Jenner JR7-D 35t, 7/11/5, Std FE, Std; 4.0T/54% Armor; 10 SHS; 4 ML, 1 SRM4</info>
        <battleforce pv="9" wt="1" mv="7/3j" s="2" m="2" l="0" e="0" ov="1" armor="2" internal="3" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="5">
            <type>Standard Jump Jet</type>
            <location index="1">RT</location>
            <location index="0">RT</location>
            <location index="10">CT</location>
            <location index="0">LT</location>
            <location index="1">LT</location>
        </jumpjets>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="3">HD</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-4</name>
            <type>missile</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4</name>
            <type>ammunition</type>
            <location index="2">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Jenner" model="JR7-F" tons="35" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1011</battle_value>
    <cost>3121425.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2784</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="245" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>7</hd>
        <ct>15</ct>
        <ctr>6</ctr>
        <lt>11</lt>
        <ltr>5</ltr>
        <rt>11</rt>
        <rtr>5</rtr>
        <la>11</la>
        <ra>11</ra>
        <ll>15</ll>
        <rl>15</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Jenner JR7-F 35t, 7/11/5, Std FE, Std; 7.0T/94% Armor; 10 SHS; 4 ML</info>
        <battleforce pv="10" wt="1" mv="7/3j" s="2" m="2" l="0" e="0" ov="0" armor="4" internal="3" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="5">
            <type>Standard Jump Jet</type>
            <location index="1">RT</location>
            <location index="0">RT</location>
            <location index="11">CT</location>
            <location index="0">LT</location>
            <location index="1">LT</location>
        </jumpjets>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="10">CT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">LA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="King Crab" model="KGC-0000" tons="100" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1810</battle_value>
    <cost>9572000.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2815</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="300" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>39</ct>
        <ctr>12</ctr>
        <lt>29</lt>
        <ltr>10</ltr>
        <rt>29</rt>
        <rtr>10</rtr>
        <la>33</la>
        <ra>33</ra>
        <ll>34</ll>
        <rl>34</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>King Crab KGC-0000 100t, 3/5/0, Std FE, Std; 17.0T/89% Armor; 15 SHS; 1 LRM15, 2 AC20, 1 LL</info>
        <battleforce pv="18" wt="4" mv="3" s="3" m="3" l="1" e="0" ov="0" armor="9" internal="8" abilities="SRCH, ES, SEAL, SOA, AC 2/2/0, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="15" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="10">CT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Autocannon/20</name>
            <type>ballistic</type>
            <splitlocation index="0" number="2">RT</splitlocation>
            <splitlocation index="4" number="8">RA</splitlocation>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Autocannon/20</name>
            <type>ballistic</type>
            <splitlocation index="0" number="2">LT</splitlocation>
            <splitlocation index="4" number="8">LA</splitlocation>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/20</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/20</name>
            <type>ammunition</type>
            <location index="4">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="King Crab" model="KGC-010" tons="100" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>2181</battle_value>
    <cost>1.119E7</cost>
    <rules_level>1</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2743</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
        <location index="11">LA</location>
        <location index="11">RA</location>
    </structure>
    <engine rating="300" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>46</ct>
        <ctr>15</ctr>
        <lt>32</lt>
        <ltr>10</ltr>
        <rt>32</rt>
        <rtr>10</rtr>
        <la>34</la>
        <ra>34</ra>
        <ll>41</ll>
        <rl>41</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>King Crab KGC-010 100t, 3/5/0, Std FE, ES; 19.0T/99% Armor; 10 DHS; 2 PPC, 2 SRM6, 2 LB10</info>
        <battleforce pv="22" wt="4" mv="3" s="3" m="4" l="3" e="0" ov="0" armor="10" internal="8" abilities="CASE, SRCH, ES, SEAL, SOA, SRM 1/1/0, FLK 1/1/1" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) LB 10-X AC</name>
            <type>ballistic</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LB 10-X AC</name>
            <type>ballistic</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Cluster)</name>
            <type>ammunition</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Cluster)</name>
            <type>ammunition</type>
            <location index="7">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Cluster)</name>
            <type>ammunition</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Slug)</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Slug)</name>
            <type>ammunition</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Slug)</name>
            <type>ammunition</type>
            <location index="7">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="8">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="9">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Kintaro" model="KTO-18" tons="55" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1187</battle_value>
    <cost>4643406.666666667</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2792</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="275" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>26</ct>
        <ctr>9</ctr>
        <lt>18</lt>
        <ltr>7</ltr>
        <rt>18</rt>
        <rtr>7</rtr>
        <la>18</la>
        <ra>18</ra>
        <ll>23</ll>
        <rl>23</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Kintaro KTO-18 55t, 5/8/0, Std FE, Std; 11.0T/95% Armor; 10 SHS; 2 ML, 3 SRM6, 1 LRM5</info>
        <battleforce pv="12" wt="2" mv="5" s="2" m="3" l="1" e="0" ov="0" armor="6" internal="5" abilities="SRCH, ES, SEAL, SOA, SRM 1/1/0" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) LRM-5</name>
            <type>missile</type>
            <location index="7">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-5</name>
            <type>ammunition</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="1">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="6">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Lancelot" model="LNC25-01X" tons="60" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1659</battle_value>
    <cost>1.3888E7</cost>
    <rules_level>1</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2765</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="3">LT</location>
        <location index="4">LT</location>
        <location index="4">LA</location>
        <location index="4">RA</location>
        <location index="5">LT</location>
        <location index="5">LA</location>
        <location index="5">RA</location>
        <location index="6">LT</location>
        <location index="6">RT</location>
        <location index="7">LT</location>
        <location index="7">RT</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="11">CT</location>
    </structure>
    <engine rating="360" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>7</hd>
        <ct>21</ct>
        <ctr>16</ctr>
        <lt>16</lt>
        <ltr>10</ltr>
        <rt>16</rt>
        <rtr>10</rtr>
        <la>14</la>
        <ra>14</ra>
        <ll>14</ll>
        <rl>14</rl>
        <location index="6">LA</location>
        <location index="6">RA</location>
        <location index="7">LA</location>
        <location index="7">RA</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Lancelot LNC25-01X 60t, 6/9/4, XLFE, ES; 8.5T/76% FF Armor; 13 DHS; 1 ML, 2 ERLL, 1 PPC</info>
        <battleforce pv="17" wt="3" mv="6/3j" s="3" m="3" l="2" e="0" ov="1" armor="5" internal="3" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="4">RL</location>
            <location index="5">RL</location>
            <location index="5">LL</location>
            <location index="4">LL</location>
        </jumpjets>
        <heatsinks number="13" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Large Laser</name>
            <type>energy</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Large Laser</name>
            <type>energy</type>
            <location index="2">RA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Lancelot" model="LNC25-02" tons="60" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1236</battle_value>
    <cost>4769600.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2864</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="240" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>7</hd>
        <ct>21</ct>
        <ctr>16</ctr>
        <lt>16</lt>
        <ltr>10</ltr>
        <rt>16</rt>
        <rtr>10</rtr>
        <la>14</la>
        <ra>14</ra>
        <ll>14</ll>
        <rl>14</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Lancelot LNC25-02 60t, 4/6/0, Std FE, Std; 9.5T/76% Armor; 19 SHS; 1 ML, 1 PPC, 2 LL</info>
        <battleforce pv="12" wt="3" mv="4" s="3" m="3" l="1" e="0" ov="1" armor="5" internal="5" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="19" techbase="2">
            <type>Single Heat Sink</type>

            <location index="5">RL</location>
            <location index="4">LL</location>
            <location index="0">RT</location>
            <location index="1">LT</location>
            <location index="0">LT</location>
            <location index="2">LT</location>
            <location index="4">RL</location>
            <location index="10">CT</location>
            <location index="3">HD</location>
            <location index="5">LL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="1">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="2">LA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Locust" model="LCT-1E" tons="20" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>553</battle_value>
    <cost>1574199.9999999998</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Bergan Industries, Alliance Defenders Limited, Majesty Metals and Manufacturing" location="Ares (Bergan), Alpheratz (ADL), Canopus IV (Majesty)">Inner Sphere</techbase>
    <year restricted="FALSE">2811</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Bergan VII" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="160" manufacturer="LTV" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="StarSlab/1" techbase="2">
        <type>Standard Armor</type>
        <hd>8</hd>
        <ct>10</ct>
        <ctr>2</ctr>
        <lt>8</lt>
        <ltr>2</ltr>
        <rt>8</rt>
        <rtr>2</rtr>
        <la>4</la>
        <ra>4</ra>
        <ll>8</ll>
        <rl>8</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Locust LCT-1E 20t, 8/12/0, Std FE, Std; 4.0T/93% Armor; 10 SHS; 2 ML, 2 SL</info>
        <battleforce pv="6" wt="1" mv="8" s="2" m="1" l="0" e="0" ov="0" armor="2" internal="2" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="5">RL</location>
            <location index="4">RL</location>
            <location index="5">LL</location>
            <location index="4">LL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Garrett T10-B</commsystem>
        <tandtsystem>O/P 911</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Locust" model="LCT-1L" tons="20" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>474</battle_value>
    <cost>1848199.9999999998</cost>
    <rules_level>1</rules_level>
    <era>4</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Bergan Industries, Alliance Defenders Limited, Majesty Metals and Manufacturing" location="Ares (Bergan), Alpheratz (ADL), Canopus IV (Majesty)">Inner Sphere</techbase>
    <year restricted="FALSE">3030</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Bergan VII" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="160" manufacturer="LTV" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <enhancement techbase="0">
        <type>TSM</type>
        <location index="0">LT</location>
        <location index="0">RT</location>
        <location index="1">LT</location>
        <location index="1">RT</location>
        <location index="2">LT</location>
        <location index="2">RT</location>
    </enhancement>
    <armor manufacturer="StarSlab/1" techbase="2">
        <type>Standard Armor</type>
        <hd>8</hd>
        <ct>10</ct>
        <ctr>2</ctr>
        <lt>8</lt>
        <ltr>2</ltr>
        <rt>8</rt>
        <rtr>2</rtr>
        <la>4</la>
        <ra>4</ra>
        <ll>8</ll>
        <rl>8</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Locust LCT-1L 20t, 8[9]/12[14]/0 TSM, Std FE, Std; 4.0T/93% Armor; 10 SHS; 1 ML, 2 MG</info>
        <battleforce pv="5" wt="1" mv="8" s="1" m="1" l="0" e="0" ov="0" armor="2" internal="2" abilities="TSM, SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="5">RL</location>
            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="5">LL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Machine Gun</name>
            <type>ammunition</type>
            <location index="11">CT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Garrett T10-B</commsystem>
        <tandtsystem>O/P 911</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Locust" model="LCT-1M" tons="20" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>424</battle_value>
    <cost>1565199.9999999998</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Bergan Industries, Alliance Defenders Limited, Majesty Metals and Manufacturing" location="Ares (Bergan), Alpheratz (ADL), Canopus IV (Majesty)">Inner Sphere</techbase>
    <year restricted="FALSE">2571</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Bergan VII" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="160" manufacturer="LTV" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="StarSlab/1" techbase="2">
        <type>Standard Armor</type>
        <hd>3</hd>
        <ct>2</ct>
        <ctr>1</ctr>
        <lt>2</lt>
        <ltr>1</ltr>
        <rt>2</rt>
        <rtr>1</rtr>
        <la>1</la>
        <ra>1</ra>
        <ll>1</ll>
        <rl>1</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Locust LCT-1M 20t, 8/12/0, Std FE, Std; 1.0T/23% Armor; 10 SHS; 1 ML, 2 LRM5</info>
        <battleforce pv="4" wt="1" mv="8" s="1" m="2" l="1" e="0" ov="0" armor="1" internal="2" abilities="SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">RL</location>
            <location index="5">RL</location>
            <location index="4">LL</location>
            <location index="5">LL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-5</name>
            <type>missile</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-5</name>
            <type>missile</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-5</name>
            <type>ammunition</type>
            <location index="11">CT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Garrett T10-B</commsystem>
        <tandtsystem>O/P 911</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Locust" model="LCT-1S" tons="20" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>440</battle_value>
    <cost>1538199.9999999998</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>0</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Bergan Industries, Alliance Defenders Limited, Majesty Metals and Manufacturing" location="Ares (Bergan), Alpheratz (ADL), Canopus IV (Majesty)">Inner Sphere</techbase>
    <year restricted="FALSE">2567</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Bergan VII" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="160" manufacturer="LTV" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="StarSlab/1" techbase="2">
        <type>Standard Armor</type>
        <hd>6</hd>
        <ct>6</ct>
        <ctr>2</ctr>
        <lt>6</lt>
        <ltr>2</ltr>
        <rt>6</rt>
        <rtr>2</rtr>
        <la>3</la>
        <ra>3</ra>
        <ll>6</ll>
        <rl>6</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Locust LCT-1S 20t, 8/12/0, Std FE, Std; 3.0T/70% Armor; 10 SHS; 1 ML, 2 SRM2</info>
        <battleforce pv="4" wt="1" mv="8" s="1" m="1" l="0" e="0" ov="0" armor="2" internal="2" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">RL</location>
            <location index="5">RL</location>
            <location index="5">LL</location>
            <location index="4">LL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-2</name>
            <type>missile</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-2</name>
            <type>missile</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-2</name>
            <type>ammunition</type>
            <location index="11">CT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Garrett T10-B</commsystem>
        <tandtsystem>O/P 911</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Locust" model="LCT-1V" tons="20" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>432</battle_value>
    <cost>1512199.9999999998</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>0</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Bergan Industries, Alliance Defenders Limited, Majesty Metals and Manufacturing" location="Ares (Bergan), Alpheratz (ADL), Canopus IV (Majesty)">Inner Sphere</techbase>
    <year restricted="FALSE">2499</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Bergan VII" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="160" manufacturer="LTV" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="StarSlab/1" techbase="2">
        <type>Standard Armor</type>
        <hd>8</hd>
        <ct>10</ct>
        <ctr>2</ctr>
        <lt>8</lt>
        <ltr>2</ltr>
        <rt>8</rt>
        <rtr>2</rtr>
        <la>4</la>
        <ra>4</ra>
        <ll>8</ll>
        <rl>8</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Locust LCT-1V 20t, 8/12/0, Std FE, Std; 4.0T/93% Armor; 10 SHS; 1 ML, 2 MG</info>
        <battleforce pv="4" wt="1" mv="8" s="1" m="1" l="0" e="0" ov="0" armor="2" internal="2" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="5">LL</location>
            <location index="5">RL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="SperryBrowning">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="SperryBrowning">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Machine Gun</name>
            <type>ammunition</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Martell">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Garrett T10-B</commsystem>
        <tandtsystem>O/P 911</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Locust" model="LCT-3V" tons="20" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>490</battle_value>
    <cost>1553699.9999999998</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Bergan Industries, Alliance Defenders Limited, Majesty Metals and Manufacturing" location="Ares (Bergan), Alpheratz (ADL), Canopus IV (Majesty)">Inner Sphere</techbase>
    <year restricted="FALSE">3004</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Bergan VII" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="160" manufacturer="LTV" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="StarSlab/1" techbase="2">
        <type>Standard Armor</type>
        <hd>8</hd>
        <ct>10</ct>
        <ctr>2</ctr>
        <lt>6</lt>
        <ltr>2</ltr>
        <rt>6</rt>
        <rtr>2</rtr>
        <la>4</la>
        <ra>4</ra>
        <ll>6</ll>
        <rl>6</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Locust LCT-3V 20t, 8/12/0, Std FE, Std; 3.5T/81% Armor; 10 SHS; 2 ML, 2 MG</info>
        <battleforce pv="5" wt="1" mv="8" s="2" m="1" l="0" e="0" ov="0" armor="2" internal="2" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="5">LL</location>
            <location index="5">RL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="SperryBrowning">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="SperryBrowning">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Martell">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Machine Gun (1/2)</name>
            <type>ammunition</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Garrett T10-B</commsystem>
        <tandtsystem>O/P 911</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Longbow" model="LGB-0W" tons="85" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1337</battle_value>
    <cost>8494670.833333334</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>0</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2529</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="340" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>21</ct>
        <ctr>10</ctr>
        <lt>18</lt>
        <ltr>7</ltr>
        <rt>18</rt>
        <rtr>7</rtr>
        <la>9</la>
        <ra>9</ra>
        <ll>18</ll>
        <rl>18</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Longbow LGB-0W 85t, 4/6/0, Std FE, Std; 9.0T/55% Armor; 13 SHS; 1 SL, 2 LRM5, 2 LRM20</info>
        <battleforce pv="13" wt="4" mv="4" s="2" m="3" l="3" e="0" ov="0" armor="5" internal="7" abilities="SRCH, ES, SEAL, SOA, LRM 1/3/3, IF 3" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="13" techbase="2">
            <type>Single Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-20</name>
            <type>missile</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-20</name>
            <type>missile</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-5</name>
            <type>missile</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-5</name>
            <type>missile</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-5</name>
            <type>ammunition</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-5</name>
            <type>ammunition</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20</name>
            <type>ammunition</type>
            <location index="1">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20</name>
            <type>ammunition</type>
            <location index="1">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20</name>
            <type>ammunition</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20</name>
            <type>ammunition</type>
            <location index="2">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Marauder II" model="MAD-4A" tons="100" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>2073</battle_value>
    <cost>9356000.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="GM/Blackwell" location="New Valencia">Inner Sphere</techbase>
    <year restricted="FALSE">3012</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="GM Marauder" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="300" manufacturer="Vlar" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Valiant Lamellor" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>45</ct>
        <ctr>16</ctr>
        <lt>31</lt>
        <ltr>11</ltr>
        <rt>31</rt>
        <rtr>11</rtr>
        <la>34</la>
        <ra>34</ra>
        <ll>41</ll>
        <rl>41</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Marauder II MAD-4A 100t, 3/5/3, Std FE, Std; 19.0T/99% Armor; 29 SHS; 2 ML, 2 PPC, 1 LL</info>
        <battleforce pv="21" wt="4" mv="3j" s="3" m="4" l="2" e="0" ov="0" armor="10" internal="8" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="3">
            <type>Standard Jump Jet</type>
            <location index="10">CT</location>
            <location index="5">LL</location>
            <location index="5">RL</location>
        </jumpjets>
        <heatsinks number="29" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="0">LT</location>
            <location index="8">LT</location>
            <location index="3">LT</location>
            <location index="6">LT</location>
            <location index="5">LT</location>
            <location index="7">LT</location>
            <location index="1">LT</location>
            <location index="2">LT</location>
            <location index="4">LT</location>
            <location index="2">RT</location>
            <location index="3">RT</location>
            <location index="4">RT</location>
            <location index="0">RT</location>
            <location index="5">RT</location>
            <location index="1">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="Magna HellStar">(IS) PPC</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Magna HellStar">(IS) PPC</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Magna Mk III">(IS) Large Laser</name>
            <type>energy</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Magna Mk II">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="6">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Magna Mk II">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="6">LA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Chilton 600</jumpjet_model>
        <commsystem>Dalban Micronics</commsystem>
        <tandtsystem>Dalban Hi-Rez II</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Marauder" model="MAD-3D" tons="75" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1470</battle_value>
    <cost>6597500.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="General Motors, Independence Weaponry" location="Kathil (GM), Quentin (Independence)">Inner Sphere</techbase>
    <year restricted="FALSE">2834</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="GM Marauder" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="300" manufacturer="Vlar" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Valiant Lamellor" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>35</ct>
        <ctr>10</ctr>
        <lt>17</lt>
        <ltr>8</ltr>
        <rt>17</rt>
        <rtr>8</rtr>
        <la>22</la>
        <ra>22</ra>
        <ll>18</ll>
        <rl>18</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Marauder MAD-3D 75t, 4/6/0, Std FE, Std; 11.5T/80% Armor; 20 SHS; 2 ML, 2 PPC, 1 LL</info>
        <battleforce pv="15" wt="3" mv="4" s="3" m="3" l="2" e="0" ov="1" armor="6" internal="6" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="20" techbase="2">
            <type>Single Heat Sink</type>

            <location index="5">RL</location>
            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="5">LL</location>
            <location index="0">LT</location>
            <location index="1">LT</location>
            <location index="10">CT</location>
            <location index="11">CT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="Magna Hellstar">(IS) PPC</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Magna Mk II">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="6">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Magna Mk II">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="6">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Magna Hellstar">(IS) PPC</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Dalban Micronics</commsystem>
        <tandtsystem>Dalban Hi-Rez</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Marauder" model="MAD-3L" tons="75" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1369</battle_value>
    <cost>6463750.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="General Motors, Independence Weaponry" location="Kathil (GM), Quentin (Independence)">Inner Sphere</techbase>
    <year restricted="FALSE">2905</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="GM Marauder" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="300" manufacturer="Vlar" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Valiant Lamellor" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>35</ct>
        <ctr>10</ctr>
        <lt>17</lt>
        <ltr>8</ltr>
        <rt>17</rt>
        <rtr>8</rtr>
        <la>22</la>
        <ra>22</ra>
        <ll>18</ll>
        <rl>18</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Marauder MAD-3L 75t, 4/6/0, Std FE, Std; 11.5T/80% Armor; 18 SHS; 2 ML, 1 PPC, 1 AC5, 1 LL</info>
        <battleforce pv="14" wt="3" mv="4" s="3" m="3" l="2" e="0" ov="1" armor="6" internal="6" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="18" techbase="2">
            <type>Single Heat Sink</type>

            <location index="5">RL</location>
            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="5">LL</location>
            <location index="0">LT</location>
            <location index="1">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="Magna Hellstar">(IS) PPC</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Magna Mk II">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="6">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Magna Mk II">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="5">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="GM Whirlwind">(IS) Autocannon/5</name>
            <type>ballistic</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/5</name>
            <type>ammunition</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Dalban Micronics</commsystem>
        <tandtsystem>Dalban Hi-Rez</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Marauder" model="MAD-3M" tons="75" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1335</battle_value>
    <cost>6295750.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="General Motors, Independence Weaponry" location="Kathil (GM), Quentin (Independence)">Inner Sphere</techbase>
    <year restricted="FALSE">2873</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="GM Marauder" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="300" manufacturer="Vlar" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Valiant Lamellor" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>35</ct>
        <ctr>10</ctr>
        <lt>17</lt>
        <ltr>8</ltr>
        <rt>17</rt>
        <rtr>8</rtr>
        <la>22</la>
        <ra>22</ra>
        <ll>18</ll>
        <rl>18</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Marauder MAD-3M 75t, 4/6/0, Std FE, Std; 11.5T/80% Armor; 20 SHS; 2 ML, 1 AC5, 2 LL</info>
        <battleforce pv="13" wt="3" mv="4" s="3" m="3" l="1" e="0" ov="1" armor="6" internal="6" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="20" techbase="2">
            <type>Single Heat Sink</type>

            <location index="5">RL</location>
            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="5">LL</location>
            <location index="0">LT</location>
            <location index="1">LT</location>
            <location index="10">CT</location>
            <location index="2">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="Magna Mk II">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="5">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Magna Mk II">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="5">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="GM Whirlwind">(IS) Autocannon/5</name>
            <type>ballistic</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/5</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Dalban Micronics</commsystem>
        <tandtsystem>Dalban Hi-Rez</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Marauder" model="MAD-3R" tons="75" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1363</battle_value>
    <cost>6631750.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="General Motors, Independence Weaponry" location="Kathil (GM), Quentin (Independence)">Inner Sphere</techbase>
    <year restricted="FALSE">2819</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="GM Marauder" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="300" manufacturer="Vlar" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Valiant Lamellor" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>35</ct>
        <ctr>10</ctr>
        <lt>17</lt>
        <ltr>8</ltr>
        <rt>17</rt>
        <rtr>8</rtr>
        <la>22</la>
        <ra>22</ra>
        <ll>18</ll>
        <rl>18</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Marauder MAD-3R 75t, 4/6/0, Std FE, Std; 11.5T/80% Armor; 16 SHS; 2 ML, 2 PPC, 1 AC5</info>
        <battleforce pv="14" wt="3" mv="4" s="2" m="3" l="2" e="0" ov="1" armor="6" internal="6" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="16" techbase="2">
            <type>Single Heat Sink</type>

            <location index="5">RL</location>
            <location index="4">RL</location>
            <location index="5">LL</location>
            <location index="4">LL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="Magna Hellstar">(IS) PPC</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Magna Hellstar">(IS) PPC</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Magna Mk II">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="6">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Magna Mk II">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="6">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="GM Whirlwind">(IS) Autocannon/5</name>
            <type>ballistic</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/5</name>
            <type>ammunition</type>
            <location index="0">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Dalban Micronics</commsystem>
        <tandtsystem>Dalban Hi-Rez</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Mongoose" model="MON-67" tons="25" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>741</battle_value>
    <cost>1885729.1666666665</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2830</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="200" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>12</ct>
        <ctr>3</ctr>
        <lt>10</lt>
        <ltr>2</ltr>
        <rt>10</rt>
        <rtr>2</rtr>
        <la>8</la>
        <ra>8</ra>
        <ll>12</ll>
        <rl>12</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Mongoose MON-67 25t, 8/12/0, Std FE, Std; 5.5T/99% Armor; 10 SHS; 3 ML, 1 SL</info>
        <battleforce pv="7" wt="1" mv="8" s="2" m="2" l="0" e="0" ov="0" armor="3" internal="2" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">LL</location>
            <location index="4">RL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Mongoose" model="MON-68" tons="25" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>676</battle_value>
    <cost>1794583.3333333335</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3039</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="175" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>12</ct>
        <ctr>3</ctr>
        <lt>10</lt>
        <ltr>2</ltr>
        <rt>10</rt>
        <rtr>2</rtr>
        <la>8</la>
        <ra>8</ra>
        <ll>12</ll>
        <rl>12</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Mongoose MON-68 25t, 7/11/0, Std FE, Std; 5.5T/99% Armor; 10 SHS; 1 LL</info>
        <battleforce pv="7" wt="1" mv="7" s="1" m="1" l="0" e="0" ov="0" armor="3" internal="2" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">RL</location>
            <location index="10">CT</location>
            <location index="4">LL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Orion" model="ON1-K" tons="75" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1429</battle_value>
    <cost>6600250.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2525</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="300" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>36</ct>
        <ctr>10</ctr>
        <lt>22</lt>
        <ltr>10</ltr>
        <rt>22</rt>
        <rtr>10</rtr>
        <la>24</la>
        <ra>24</ra>
        <ll>32</ll>
        <rl>32</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Orion ON1-K 75t, 4/6/0, Std FE, Std; 14.5T/100% Armor; 10 SHS; 2 ML, 1 LRM15, 1 AC10, 1 SRM4</info>
        <battleforce pv="14" wt="3" mv="4" s="3" m="3" l="1" e="0" ov="1" armor="8" internal="6" abilities="SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Autocannon/10</name>
            <type>ballistic</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-4</name>
            <type>missile</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/10</name>
            <type>ammunition</type>
            <location index="7">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/10</name>
            <type>ammunition</type>
            <location index="8">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4</name>
            <type>ammunition</type>
            <location index="6">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Orion" model="ON1-V-DC" tons="75" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1310</battle_value>
    <cost>7491750.0</cost>
    <rules_level>2</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2913</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="300" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="TRUE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>32</ct>
        <ctr>9</ctr>
        <lt>20</lt>
        <ltr>8</ltr>
        <rt>20</rt>
        <rtr>8</rtr>
        <la>19</la>
        <ra>19</ra>
        <ll>20</ll>
        <rl>20</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Orion ON1-V-DC 75t, 4/6/0, Std FE, Std; 11.5T/80% Armor; 10 SHS; 2 ML, 1 LRM15, 1 AC10, 2 SRM4</info>
        <battleforce pv="13" wt="3" mv="4" s="2" m="3" l="1" e="0" ov="1" armor="6" internal="6" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Autocannon/10</name>
            <type>ballistic</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-4</name>
            <type>missile</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/10</name>
            <type>ammunition</type>
            <location index="7">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-4</name>
            <type>missile</type>
            <location index="3">RA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Orion" model="ON1-V" tons="75" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1298</battle_value>
    <cost>6679750.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2799</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="300" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>32</ct>
        <ctr>9</ctr>
        <lt>20</lt>
        <ltr>8</ltr>
        <rt>20</rt>
        <rtr>8</rtr>
        <la>19</la>
        <ra>19</ra>
        <ll>20</ll>
        <rl>20</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Orion ON1-V 75t, 4/6/0, Std FE, Std; 11.5T/80% Armor; 10 SHS; 2 ML, 1 LRM15, 1 AC10, 2 SRM4</info>
        <battleforce pv="13" wt="3" mv="4" s="3" m="3" l="1" e="0" ov="2" armor="6" internal="6" abilities="SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Autocannon/10</name>
            <type>ballistic</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-4</name>
            <type>missile</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/10</name>
            <type>ammunition</type>
            <location index="7">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/10</name>
            <type>ammunition</type>
            <location index="8">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4</name>
            <type>ammunition</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-4</name>
            <type>missile</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Orion" model="ON1-VA" tons="75" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1328</battle_value>
    <cost>6387000.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2901</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="300" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>36</ct>
        <ctr>10</ctr>
        <lt>22</lt>
        <ltr>10</ltr>
        <rt>22</rt>
        <rtr>10</rtr>
        <la>24</la>
        <ra>24</ra>
        <ll>32</ll>
        <rl>32</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Orion ON1-VA 75t, 4/6/0, Std FE, Std; 14.5T/100% Armor; 16 SHS; 2 ML, 1 AC10, 2 SRM4</info>
        <battleforce pv="13" wt="3" mv="4" s="4" m="4" l="0" e="0" ov="0" armor="8" internal="6" abilities="SRCH, ES, SEAL, SOA, AC 1/1/0, SRM 1/1/0" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="16" techbase="2">
            <type>Single Heat Sink</type>

            <location index="5">RL</location>
            <location index="4">RL</location>
            <location index="4">LL</location>
            <location index="5">LL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Autocannon/10</name>
            <type>ballistic</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-4</name>
            <type>missile</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/10</name>
            <type>ammunition</type>
            <location index="7">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/10</name>
            <type>ammunition</type>
            <location index="8">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4</name>
            <type>ammunition</type>
            <location index="1">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-4</name>
            <type>missile</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4</name>
            <type>ammunition</type>
            <location index="0">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Ostroc" model="OSR-2C" tons="60" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1228</battle_value>
    <cost>5009400.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>0</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Ostmann Industries" location="Terra (destroyed 2777)">Inner Sphere</techbase>
    <year restricted="FALSE">2511</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Ost-II" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="300" manufacturer="Vlar" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Riese 475" techbase="2">
        <type>Standard Armor</type>
        <hd>8</hd>
        <ct>22</ct>
        <ctr>6</ctr>
        <lt>22</lt>
        <ltr>4</ltr>
        <rt>22</rt>
        <rtr>4</rtr>
        <la>8</la>
        <ra>8</ra>
        <ll>20</ll>
        <rl>20</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Ostroc OSR-2C 60t, 5/8/0, Std FE, Std; 9.0T/72% Armor; 15 SHS; 2 ML, 1 SRM4, 2 LL</info>
        <battleforce pv="12" wt="3" mv="5" s="3" m="3" l="0" e="0" ov="1" armor="5" internal="5" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="15" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="3">HD</location>
        </heatsinks>
        <equipment>
            <name manufacturer="Totschlagen SRM Quad Launcher">(IS) SRM-4</name>
            <type>missile</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4</name>
            <type>ammunition</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Fuersturm-c">(IS) Large Laser</name>
            <type>energy</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Fuersturm-c">(IS) Large Laser</name>
            <type>energy</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Fuersturm-b">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Fuersturm-b">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Ostmann-L</commsystem>
        <tandtsystem>Ferdinand-a</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Ostroc" model="OSR-2L" tons="60" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1233</battle_value>
    <cost>4964400.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Ostmann Industries" location="Terra (destroyed 2777)">Inner Sphere</techbase>
    <year restricted="FALSE">2884</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Ost-II" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="300" manufacturer="Vlar" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Riese 475" techbase="2">
        <type>Standard Armor</type>
        <hd>8</hd>
        <ct>22</ct>
        <ctr>6</ctr>
        <lt>22</lt>
        <ltr>4</ltr>
        <rt>22</rt>
        <rtr>4</rtr>
        <la>8</la>
        <ra>8</ra>
        <ll>20</ll>
        <rl>20</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Ostroc OSR-2L 60t, 5/8/0, Std FE, Std; 9.0T/72% Armor; 15 SHS; 2 ML, 1 LRM5, 2 LL</info>
        <battleforce pv="12" wt="3" mv="5" s="2" m="2" l="1" e="0" ov="1" armor="5" internal="5" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="15" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">RL</location>
            <location index="4">LL</location>
            <location index="10">CT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="Fuersturm-c">(IS) Large Laser</name>
            <type>energy</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Fuersturm-c">(IS) Large Laser</name>
            <type>energy</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Fuersturm-b">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Fuersturm-b">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-5</name>
            <type>missile</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-5</name>
            <type>ammunition</type>
            <location index="11">CT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Ostmann-L</commsystem>
        <tandtsystem>Ferdinand-a</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Ostroc" model="OSR-2M" tons="60" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1239</battle_value>
    <cost>5238400.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Ostmann Industries" location="Terra (destroyed 2777)">Inner Sphere</techbase>
    <year restricted="FALSE">2793</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Ost-II" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="300" manufacturer="Vlar" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Riese 475" techbase="2">
        <type>Standard Armor</type>
        <hd>8</hd>
        <ct>22</ct>
        <ctr>6</ctr>
        <lt>22</lt>
        <ltr>4</ltr>
        <rt>22</rt>
        <rtr>4</rtr>
        <la>8</la>
        <ra>8</ra>
        <ll>20</ll>
        <rl>20</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Ostroc OSR-2M 60t, 5/8/5, Std FE, Std; 9.0T/72% Armor; 15 SHS; 2 LL</info>
        <battleforce pv="12" wt="3" mv="5j" s="2" m="2" l="0" e="0" ov="0" armor="5" internal="5" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="5">
            <type>Standard Jump Jet</type>
            <location index="0">LT</location>
            <location index="0">RT</location>
            <location index="1">RT</location>
            <location index="11">CT</location>
            <location index="1">LT</location>
        </jumpjets>
        <heatsinks number="15" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">RL</location>
            <location index="4">LL</location>
            <location index="10">CT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="Fuersturm-c">(IS) Large Laser</name>
            <type>energy</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Fuersturm-c">(IS) Large Laser</name>
            <type>energy</type>
            <location index="2">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Ostmann-L</commsystem>
        <tandtsystem>Ferdinand-a</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Ostroc" model="OSR-3C" tons="60" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1288</battle_value>
    <cost>4918400.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Ostmann Industries" location="Terra (destroyed 2777)">Inner Sphere</techbase>
    <year restricted="FALSE">2876</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Ost-II" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="300" manufacturer="Vlar" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Riese 475" techbase="2">
        <type>Standard Armor</type>
        <hd>8</hd>
        <ct>22</ct>
        <ctr>6</ctr>
        <lt>22</lt>
        <ltr>4</ltr>
        <rt>22</rt>
        <rtr>4</rtr>
        <la>8</la>
        <ra>8</ra>
        <ll>20</ll>
        <rl>20</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Ostroc OSR-3C 60t, 5/8/0, Std FE, Std; 9.0T/72% Armor; 15 SHS; 3 LL</info>
        <battleforce pv="13" wt="3" mv="5" s="2" m="2" l="0" e="0" ov="1" armor="5" internal="5" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="15" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">RL</location>
            <location index="4">LL</location>
            <location index="0">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="Fuersturm-c">(IS) Large Laser</name>
            <type>energy</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Fuersturm-c">(IS) Large Laser</name>
            <type>energy</type>
            <location index="1">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="0">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Ostmann-L</commsystem>
        <tandtsystem>Ferdinand-a</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Ostscout" model="OTT-7J" tons="35" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>596</battle_value>
    <cost>3409200.0000000005</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Kong Interstellar Corporation" location="Connaught (destryoed 2802)">Inner Sphere</techbase>
    <year restricted="FALSE">2600</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Kell/S" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="280" manufacturer="VOX" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Durallex Light" techbase="2">
        <type>Standard Armor</type>
        <hd>6</hd>
        <ct>12</ct>
        <ctr>4</ctr>
        <lt>9</lt>
        <ltr>2</ltr>
        <rt>9</rt>
        <rtr>2</rtr>
        <la>6</la>
        <ra>6</ra>
        <ll>8</ll>
        <rl>8</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Ostscout OTT-7J 35t, 8/12/8, Std FE, Std; 4.5T/61% Armor; 10 SHS; 1 ML</info>
        <battleforce pv="6" wt="1" mv="8j" s="1" m="1" l="0" e="0" ov="0" armor="2" internal="3" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="8">
            <type>Standard Jump Jet</type>
            <location index="0">LT</location>
            <location index="2">LT</location>
            <location index="1">LT</location>
            <location index="2">RT</location>
            <location index="1">RT</location>
            <location index="3">LT</location>
            <location index="0">RT</location>
            <location index="3">RT</location>
        </jumpjets>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Ostmann Sct-A</jumpjet_model>
        <commsystem>Barrett 4000</commsystem>
        <tandtsystem>TRSS.2L3</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Ostsol" model="OTL-4D" tons="60" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1277</battle_value>
    <cost>5017600.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Kong Interstellar Corporation" location="Connaught (destroyed 2802)">Inner Sphere</techbase>
    <year restricted="FALSE">2694</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Kell/H" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="300" manufacturer="Vlar" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Valiant Lamellor" techbase="2">
        <type>Standard Armor</type>
        <hd>8</hd>
        <ct>22</ct>
        <ctr>6</ctr>
        <lt>22</lt>
        <ltr>4</ltr>
        <rt>22</rt>
        <rtr>4</rtr>
        <la>8</la>
        <ra>8</ra>
        <ll>20</ll>
        <rl>20</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Ostsol OTL-4D 60t, 5/8/0, Std FE, Std; 9.0T/72% Armor; 16 SHS; 4 ML, 2 LL</info>
        <battleforce pv="13" wt="3" mv="5" s="3" m="3" l="0" e="0" ov="0" armor="5" internal="5" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="16" techbase="2">
            <type>Single Heat Sink</type>

            <location index="5">RL</location>
            <location index="4">RL</location>
            <location index="4">LL</location>
            <location index="5">LL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="Tronel III">(IS) Large Laser</name>
            <type>energy</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Tronel III">(IS) Large Laser</name>
            <type>energy</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Tronel II">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Tronel II">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Tronel II">(R) (IS) Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Tronel II">(R) (IS) Medium Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Barret 509p</commsystem>
        <tandtsystem>TRSS.2L3</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Ostsol" model="OTL-4F" tons="60" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1264</battle_value>
    <cost>5081600.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Kong Interstellar Corporation" location="Connaught (destroyed 2802)">Inner Sphere</techbase>
    <year restricted="FALSE">2871</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Kell/H" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="300" manufacturer="Vlar" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Valiant Lamellor" techbase="2">
        <type>Standard Armor</type>
        <hd>8</hd>
        <ct>22</ct>
        <ctr>6</ctr>
        <lt>22</lt>
        <ltr>4</ltr>
        <rt>22</rt>
        <rtr>4</rtr>
        <la>8</la>
        <ra>8</ra>
        <ll>20</ll>
        <rl>20</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Ostsol OTL-4F 60t, 5/8/0, Std FE, Std; 9.0T/72% Armor; 16 SHS; 2 PPC</info>
        <battleforce pv="13" wt="3" mv="5" s="2" m="2" l="2" e="0" ov="0" armor="5" internal="5" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="16" techbase="2">
            <type>Single Heat Sink</type>

            <location index="5">LL</location>
            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="5">RL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="Dominion-V">(IS) PPC</name>
            <type>energy</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Dominion-V">(IS) PPC</name>
            <type>energy</type>
            <location index="0">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Barret 509p</commsystem>
        <tandtsystem>TRSS.2L3</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Panther" model="PNT-8Z" tons="35" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>741</battle_value>
    <cost>2357460.0000000005</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2739</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="140" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>16</ct>
        <ctr>6</ctr>
        <lt>12</lt>
        <ltr>4</ltr>
        <rt>12</rt>
        <rtr>4</rtr>
        <la>12</la>
        <ra>12</ra>
        <ll>16</ll>
        <rl>16</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Panther PNT-8Z 35t, 4/6/4, Std FE, Std; 7.5T/100% Armor; 14 SHS; 1 SRM4, 1 LL</info>
        <battleforce pv="7" wt="1" mv="4j" s="2" m="2" l="0" e="0" ov="0" armor="4" internal="3" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="5">LL</location>
            <location index="5">RL</location>
        </jumpjets>
        <heatsinks number="14" techbase="2">
            <type>Single Heat Sink</type>

            <location index="0">LT</location>
            <location index="1">LT</location>
            <location index="3">LT</location>
            <location index="2">LT</location>
            <location index="10">CT</location>
            <location index="1">RT</location>
            <location index="2">RT</location>
            <location index="3">RT</location>
            <location index="0">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-4</name>
            <type>missile</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Panther" model="PNT-9R" tons="35" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>769</battle_value>
    <cost>2476260.0000000005</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2759</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="140" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>14</ct>
        <ctr>7</ctr>
        <lt>10</lt>
        <ltr>5</ltr>
        <rt>10</rt>
        <rtr>5</rtr>
        <la>10</la>
        <ra>10</ra>
        <ll>12</ll>
        <rl>12</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Panther PNT-9R 35t, 4/6/4, Std FE, Std; 6.5T/87% Armor; 13 SHS; 1 PPC, 1 SRM4</info>
        <battleforce pv="8" wt="1" mv="4j" s="2" m="2" l="1" e="0" ov="0" armor="3" internal="3" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="5">LL</location>
            <location index="5">RL</location>
        </jumpjets>
        <heatsinks number="13" techbase="2">
            <type>Single Heat Sink</type>

            <location index="2">LT</location>
            <location index="0">LT</location>
            <location index="1">LT</location>
            <location index="3">LT</location>
            <location index="0">RT</location>
            <location index="2">RT</location>
            <location index="1">RT</location>
            <location index="3">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) SRM-4</name>
            <type>missile</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Phoenix Hawk" model="PXH-1" tons="45" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1041</battle_value>
    <cost>4067090.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>0</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Earthwerks Incorporated, Coventry Metal Works, Achernar BattleMechs, Gorton, Kingsley, and Thorpe Enterpises" location="Keystone (Earthwerks), Coventry (CMW), New Avalon (Achernar), New Oslo (GKT)">Inner Sphere</techbase>
    <year restricted="FALSE">2568</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Earthwekrks PXH" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="270" manufacturer="GM" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Durallex Light" techbase="2">
        <type>Standard Armor</type>
        <hd>6</hd>
        <ct>23</ct>
        <ctr>5</ctr>
        <lt>18</lt>
        <ltr>4</ltr>
        <rt>18</rt>
        <rtr>4</rtr>
        <la>10</la>
        <ra>10</ra>
        <ll>15</ll>
        <rl>15</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Phoenix Hawk PXH-1 45t, 6/9/6, Std FE, Std; 8.0T/84% Armor; 10 SHS; 2 ML, 2 MG, 1 LL</info>
        <battleforce pv="10" wt="2" mv="6j" s="2" m="2" l="0" e="0" ov="0" armor="4" internal="4" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="6">
            <type>Standard Jump Jet</type>
            <location index="0">RT</location>
            <location index="0">LT</location>
            <location index="1">LT</location>
            <location index="1">RT</location>
            <location index="2">RT</location>
            <location index="2">LT</location>
        </jumpjets>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="M100">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="7">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="M100">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="5">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Machine Gun</name>
            <type>ammunition</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Harmon">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="6">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Harmon">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Harmon">(IS) Large Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Rawlings 45</jumpjet_model>
        <commsystem>Neil 6000</commsystem>
        <tandtsystem>Octagon Tartrac System C</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Phoenix Hawk" model="PXH-1D" tons="45" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1083</battle_value>
    <cost>4057390.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Earthwerks Incorporated, Coventry Metal Works, Achernar BattleMechs, Gorton, Kingsley, and Thorpe Enterpises" location="Keystone (Earthwerks), Coventry (CMW), New Avalon (Achernar), New Oslo (GKT)">Inner Sphere</techbase>
    <year restricted="FALSE">2586</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Earthwekrks PXH" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="270" manufacturer="GM" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Durallex Light" techbase="2">
        <type>Standard Armor</type>
        <hd>6</hd>
        <ct>23</ct>
        <ctr>5</ctr>
        <lt>18</lt>
        <ltr>4</ltr>
        <rt>18</rt>
        <rtr>4</rtr>
        <la>10</la>
        <ra>10</ra>
        <ll>15</ll>
        <rl>15</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Phoenix Hawk PXH-1D 45t, 6/9/6, Std FE, Std; 8.0T/84% Armor; 12 SHS; 2 ML, 1 LL</info>
        <battleforce pv="11" wt="2" mv="6j" s="2" m="2" l="0" e="0" ov="0" armor="4" internal="4" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="6">
            <type>Standard Jump Jet</type>
            <location index="0">RT</location>
            <location index="0">LT</location>
            <location index="1">LT</location>
            <location index="1">RT</location>
            <location index="2">RT</location>
            <location index="2">LT</location>
        </jumpjets>
        <heatsinks number="12" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">LL</location>
            <location index="4">RL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="Harmon">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="6">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Harmon">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Harmon">(IS) Large Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Rawlings 45</jumpjet_model>
        <commsystem>Neil 6000</commsystem>
        <tandtsystem>Octagon Tartrac System C</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Phoenix Hawk" model="PXH-1K" tons="45" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1073</battle_value>
    <cost>3628552.5</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Earthwerks Incorporated, Coventry Metal Works, Achernar BattleMechs, Gorton, Kingsley, and Thorpe Enterpises" location="Keystone (Earthwerks), Coventry (CMW), New Avalon (Achernar), New Oslo (GKT)">Inner Sphere</techbase>
    <year restricted="FALSE">2603</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Earthwekrks PXH" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="270" manufacturer="GM" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Durallex Light" techbase="2">
        <type>Standard Armor</type>
        <hd>8</hd>
        <ct>23</ct>
        <ctr>5</ctr>
        <lt>18</lt>
        <ltr>4</ltr>
        <rt>18</rt>
        <rtr>4</rtr>
        <la>14</la>
        <ra>14</ra>
        <ll>22</ll>
        <rl>22</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Phoenix Hawk PXH-1K 45t, 6/9/0, Std FE, Std; 9.5T/99% Armor; 13 SHS; 2 ML, 1 SL, 1 LL</info>
        <battleforce pv="11" wt="2" mv="6" s="3" m="2" l="0" e="0" ov="0" armor="5" internal="4" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="13" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">RL</location>
            <location index="4">LL</location>
            <location index="10">CT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="Harmon">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="6">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Harmon">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Harmon">(IS) Large Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Neil 6000</commsystem>
        <tandtsystem>Octagon Tartrac System C</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Quickdraw" model="QKD-4G" tons="60" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1192</battle_value>
    <cost>5480360.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2779</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="300" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>17</ct>
        <ctr>8</ctr>
        <lt>14</lt>
        <ltr>7</ltr>
        <rt>14</rt>
        <rtr>7</rtr>
        <la>11</la>
        <ra>11</ra>
        <ll>15</ll>
        <rl>15</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Quickdraw QKD-4G 60t, 5/8/5, Std FE, Std; 8.0T/64% Armor; 13 SHS; 4 ML, 1 LRM10, 1 SRM4</info>
        <battleforce pv="12" wt="3" mv="5j" s="2" m="3" l="1" e="0" ov="0" armor="4" internal="5" abilities="SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="5">
            <type>Standard Jump Jet</type>
            <location index="0">LT</location>
            <location index="0">RT</location>
            <location index="1">RT</location>
            <location index="10">CT</location>
            <location index="1">LT</location>
        </jumpjets>
        <heatsinks number="13" techbase="2">
            <type>Single Heat Sink</type>

            <location index="3">HD</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-10</name>
            <type>missile</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-4</name>
            <type>missile</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4</name>
            <type>ammunition</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Quickdraw" model="QKD-4H" tons="60" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1242</battle_value>
    <cost>5480360.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2847</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="300" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>17</ct>
        <ctr>8</ctr>
        <lt>14</lt>
        <ltr>7</ltr>
        <rt>14</rt>
        <rtr>7</rtr>
        <la>11</la>
        <ra>11</ra>
        <ll>15</ll>
        <rl>15</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Quickdraw QKD-4H 60t, 5/8/5, Std FE, Std; 8.0T/64% Armor; 13 SHS; 4 ML, 1 LRM10, 1 SRM4</info>
        <battleforce pv="12" wt="3" mv="5j" s="2" m="2" l="1" e="0" ov="1" armor="4" internal="5" abilities="SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="5">
            <type>Standard Jump Jet</type>
            <location index="2">LT</location>
            <location index="0">RT</location>
            <location index="1">RT</location>
            <location index="10">CT</location>
            <location index="1">LT</location>
        </jumpjets>
        <heatsinks number="13" techbase="2">
            <type>Single Heat Sink</type>

            <location index="0">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-10</name>
            <type>missile</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) SRM-4</name>
            <type>missile</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4</name>
            <type>ammunition</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Quickdraw" model="QKD-5A" tons="60" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1196</battle_value>
    <cost>5431160.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3002</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="300" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>17</ct>
        <ctr>8</ctr>
        <lt>14</lt>
        <ltr>7</ltr>
        <rt>14</rt>
        <rtr>7</rtr>
        <la>11</la>
        <ra>11</ra>
        <ll>15</ll>
        <rl>15</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Quickdraw QKD-5A 60t, 5/8/5, Std FE, Std; 8.0T/64% Armor; 17 SHS; 6 ML, 1 SRM4</info>
        <battleforce pv="12" wt="3" mv="5j" s="3" m="3" l="0" e="0" ov="0" armor="4" internal="5" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="5">
            <type>Standard Jump Jet</type>
            <location index="2">LT</location>
            <location index="0">RT</location>
            <location index="1">RT</location>
            <location index="10">CT</location>
            <location index="1">LT</location>
        </jumpjets>
        <heatsinks number="17" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">RL</location>
            <location index="5">RL</location>
            <location index="4">LL</location>
            <location index="5">LL</location>
            <location index="0">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-4</name>
            <type>missile</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4</name>
            <type>ammunition</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Raven" model="RVN-1X" tons="35" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>639</battle_value>
    <cost>2926575.0</cost>
    <rules_level>3</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3024</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="175" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>6</hd>
        <ct>8</ct>
        <ctr>4</ctr>
        <lt>6</lt>
        <ltr>3</ltr>
        <rt>6</rt>
        <rtr>3</rtr>
        <la>6</la>
        <ra>6</ra>
        <ll>8</ll>
        <rl>8</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Raven RVN-1X 35t, 5/8/0, Std FE, Std; 4.0T/54% Armor; 12 SHS; 2 ML, 1 EWEqp, 1 SRM6</info>
        <battleforce pv="6" wt="1" mv="5" s="2" m="2" l="0" e="0" ov="0" armor="2" internal="3" abilities="ECM, SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="12" techbase="2">
            <type>Single Heat Sink</type>

            <location index="5">RL</location>
            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="5">LL</location>
            <location index="3">HD</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">Electronic Warfare Equipment</name>
            <type>equipment</type>
            <location index="1">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Raven" model="RVN-2X" tons="35" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>887</battle_value>
    <cost>2420325.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3030</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="175" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>14</ct>
        <ctr>5</ctr>
        <lt>10</lt>
        <ltr>4</ltr>
        <rt>10</rt>
        <rtr>4</rtr>
        <la>10</la>
        <ra>10</ra>
        <ll>14</ll>
        <rl>14</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Raven RVN-2X 35t, 5/8/0, Std FE, Std; 6.5T/87% Armor; 12 SHS; 2 ML, 1 SRM6, 1 LL</info>
        <battleforce pv="9" wt="1" mv="5" s="2" m="2" l="0" e="0" ov="1" armor="3" internal="3" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="12" techbase="2">
            <type>Single Heat Sink</type>

            <location index="5">LL</location>
            <location index="5">RL</location>
            <location index="4">RL</location>
            <location index="4">LL</location>
            <location index="3">HD</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="0">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Raven" model="RVN-3X" tons="35" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>691</battle_value>
    <cost>3588075.0</cost>
    <rules_level>3</rules_level>
    <era>4</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3028</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="175" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <enhancement techbase="0">
        <type>TSM</type>
        <location index="2">RT</location>
        <location index="3">RT</location>
        <location index="5">LT</location>
        <location index="6">LT</location>
        <location index="10">CT</location>
        <location index="11">CT</location>
    </enhancement>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>6</hd>
        <ct>8</ct>
        <ctr>4</ctr>
        <lt>6</lt>
        <ltr>3</ltr>
        <rt>6</rt>
        <rtr>3</rtr>
        <la>6</la>
        <ra>6</ra>
        <ll>8</ll>
        <rl>8</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Raven RVN-3X 35t, 5[6]/8[9]/0 TSM, Std FE, Std; 4.0T/54% Armor; 12 SHS; 2 ML, 1 EWEqp, 1 SRM6</info>
        <battleforce pv="7" wt="1" mv="5" s="2" m="2" l="0" e="0" ov="0" armor="2" internal="3" abilities="TSM, ECM, SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="12" techbase="2">
            <type>Single Heat Sink</type>

            <location index="5">LL</location>
            <location index="4">LL</location>
            <location index="5">RL</location>
            <location index="4">RL</location>
            <location index="3">HD</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">Electronic Warfare Equipment</name>
            <type>equipment</type>
            <location index="1">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Raven" model="RVN-4X" tons="35" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>820</battle_value>
    <cost>2542825.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3031</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="175" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>16</ct>
        <ctr>5</ctr>
        <lt>11</lt>
        <ltr>4</ltr>
        <rt>11</rt>
        <rtr>4</rtr>
        <la>11</la>
        <ra>11</ra>
        <ll>15</ll>
        <rl>15</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Raven RVN-4X 35t, 5/8/5, Std FE, Std; 7.0T/94% Armor; 12 SHS; 2 ML, 2 MG, 1 SRM6</info>
        <battleforce pv="8" wt="1" mv="5j" s="3" m="2" l="0" e="0" ov="0" armor="4" internal="3" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="5">
            <type>Standard Jump Jet</type>
            <location index="0">LT</location>
            <location index="1">RT</location>
            <location index="1">LT</location>
            <location index="0">RT</location>
            <location index="10">CT</location>
        </jumpjets>
        <heatsinks number="12" techbase="2">
            <type>Single Heat Sink</type>

            <location index="5">RL</location>
            <location index="4">RL</location>
            <location index="5">LL</location>
            <location index="4">LL</location>
            <location index="3">HD</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Machine Gun</name>
            <type>ammunition</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="2">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Rifleman" model="RFL-3C" tons="60" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1066</battle_value>
    <cost>4800800.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3026</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="240" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>22</ct>
        <ctr>7</ctr>
        <lt>15</lt>
        <ltr>4</ltr>
        <rt>15</rt>
        <rtr>4</rtr>
        <la>15</la>
        <ra>15</ra>
        <ll>15</ll>
        <rl>15</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Rifleman RFL-3C 60t, 4/6/0, Std FE, Std; 8.5T/68% Armor; 10 SHS; 2 ML, 2 AC10</info>
        <battleforce pv="11" wt="3" mv="4" s="3" m="3" l="0" e="0" ov="0" armor="5" internal="5" abilities="SRCH, ES, SEAL, SOA, AC 2/2/0" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="0">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="1">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Autocannon/10</name>
            <type>ballistic</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Autocannon/10</name>
            <type>ballistic</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/10</name>
            <type>ammunition</type>
            <location index="1">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/10</name>
            <type>ammunition</type>
            <location index="2">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Rifleman" model="RFL-3N" tons="60" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1039</battle_value>
    <cost>4857300.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2770</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="240" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>6</hd>
        <ct>22</ct>
        <ctr>4</ctr>
        <lt>15</lt>
        <ltr>2</ltr>
        <rt>15</rt>
        <rtr>2</rtr>
        <la>15</la>
        <ra>15</ra>
        <ll>12</ll>
        <rl>12</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Rifleman RFL-3N 60t, 4/6/0, Std FE, Std; 7.5T/60% Armor; 10 SHS; 2 ML, 2 AC5, 2 LL</info>
        <battleforce pv="10" wt="3" mv="4" s="2" m="2" l="1" e="0" ov="2" armor="4" internal="5" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">LL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="MagnaMk. III">(IS) Large Laser</name>
            <type>energy</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="MagnaMk. III">(IS) Large Laser</name>
            <type>energy</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Magna Mk. II">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Magna Mk. II">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Imperator-A">(IS) Autocannon/5</name>
            <type>ballistic</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Imperator-A">(IS) Autocannon/5</name>
            <type>ballistic</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/5</name>
            <type>ammunition</type>
            <location index="10">CT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Rifleman" model="RFL-4D" tons="60" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1173</battle_value>
    <cost>4980800.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3021</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="240" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>6</hd>
        <ct>22</ct>
        <ctr>4</ctr>
        <lt>15</lt>
        <ltr>2</ltr>
        <rt>15</rt>
        <rtr>2</rtr>
        <la>15</la>
        <ra>15</ra>
        <ll>12</ll>
        <rl>12</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Rifleman RFL-4D 60t, 4/6/0, Std FE, Std; 7.5T/60% Armor; 15 SHS; 2 PPC, 2 LL</info>
        <battleforce pv="12" wt="3" mv="4" s="2" m="2" l="1" e="0" ov="2" armor="4" internal="5" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="15" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">LL</location>
            <location index="11">CT</location>
            <location index="10">CT</location>
            <location index="5">RL</location>
            <location index="5">LL</location>
            <location index="4">RL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="5">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="5">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="2">LA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Scorpion" model="SCP-1N (Wednall)" tons="55" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1043</battle_value>
    <cost>5062950.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3030</year>
    <motive_type>Quad</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="330" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>8</hd>
        <ct>24</ct>
        <ctr>8</ctr>
        <lt>17</lt>
        <ltr>5</ltr>
        <rt>17</rt>
        <rtr>5</rtr>
        <la>15</la>
        <ra>15</ra>
        <ll>15</ll>
        <rl>15</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Scorpion SCP-1N (Wednall) 55t, 6/9/0, Std FE, Std Quad; 9.0T/72% Armor; 10 SHS; 1 SRM6, 1 LL</info>
        <battleforce pv="10" wt="2" mv="6" s="2" m="2" l="0" e="0" ov="0" armor="5" internal="5" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="0">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Scorpion" model="SCP-1N" tons="55" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1019</battle_value>
    <cost>5186950.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>0</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Brigadier Corporation" location="Oliver (destroyed 2837)">Inner Sphere</techbase>
    <year restricted="FALSE">2570</year>
    <motive_type>Quad</motive_type>
    <structure manufacturer="Brigadier 800F" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="330" manufacturer="VOX" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="StarSlab/4" techbase="2">
        <type>Standard Armor</type>
        <hd>8</hd>
        <ct>24</ct>
        <ctr>8</ctr>
        <lt>11</lt>
        <ltr>5</ltr>
        <rt>11</rt>
        <rtr>5</rtr>
        <la>10</la>
        <ra>10</ra>
        <ll>10</ll>
        <rl>10</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Scorpion SCP-1N 55t, 6/9/0, Std FE, Std Quad; 7.0T/56% Armor; 10 SHS; 1 PPC, 1 SRM6</info>
        <battleforce pv="10" wt="2" mv="6" s="2" m="2" l="1" e="0" ov="0" armor="4" internal="5" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Marvel Six-Load">(IS) SRM-6</name>
            <type>missile</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="0">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Garret 500A</commsystem>
        <tandtsystem>Garret GRNDTRK 9</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Sentinel" model="STN-1S" tons="40" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>632</battle_value>
    <cost>3132430.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Defiance Industries" location="Hesperus II">Inner Sphere</techbase>
    <year restricted="FALSE">2651</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Defiant V" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="240" manufacturer="Pitban" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Valiant Lamellor" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>12</ct>
        <ctr>7</ctr>
        <lt>10</lt>
        <ltr>5</ltr>
        <rt>10</rt>
        <rtr>5</rtr>
        <la>8</la>
        <ra>8</ra>
        <ll>11</ll>
        <rl>11</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Sentinel STN-1S 40t, 6/9/0, Std FE, Std; 6.0T/70% Armor; 12 SHS; 1 SL, 1 SRM4, 1 AC2</info>
        <battleforce pv="6" wt="2" mv="6" s="2" m="1" l="1" e="0" ov="0" armor="3" internal="3" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="12" techbase="2">
            <type>Single Heat Sink</type>

            <location index="0">LT</location>
            <location index="4">RL</location>
            <location index="4">LL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="Defiance B-1A">(IS) Small Laser</name>
            <type>energy</type>
            <location index="1">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-4</name>
            <type>missile</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Autocannon/2</name>
            <type>ballistic</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/2</name>
            <type>ammunition</type>
            <location index="1">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4</name>
            <type>ammunition</type>
            <location index="2">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>StarLink/Benicia Model AS829G</commsystem>
        <tandtsystem>Targa-7, Vid-Com-17</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Sentinel" model="STN-3K" tons="40" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>652</battle_value>
    <cost>3134830.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Defiance Industries" location="Hesperus II">Inner Sphere</techbase>
    <year restricted="FALSE">2830</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Defiant V" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="240" manufacturer="Pitban" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Valiant Lamellor" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>12</ct>
        <ctr>7</ctr>
        <lt>10</lt>
        <ltr>5</ltr>
        <rt>10</rt>
        <rtr>5</rtr>
        <la>8</la>
        <ra>8</ra>
        <ll>11</ll>
        <rl>11</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Sentinel STN-3K 40t, 6/9/0, Std FE, Std; 6.0T/70% Armor; 10 SHS; 1 SL, 1 AC5, 1 SRM2</info>
        <battleforce pv="7" wt="2" mv="6" s="1" m="1" l="1" e="0" ov="0" armor="3" internal="3" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="0">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="Defiance Type J">(IS) Autocannon/5</name>
            <type>ballistic</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Holly">(IS) SRM-2</name>
            <type>missile</type>
            <location index="1">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-2</name>
            <type>ammunition</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/5</name>
            <type>ammunition</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Defiance B-1A">(IS) Small Laser</name>
            <type>energy</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/5</name>
            <type>ammunition</type>
            <location index="1">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>StarLink/Benicia Model AS829G</commsystem>
        <tandtsystem>Targa-7, Vid-Com-17</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Sentinel" model="STN-3KA" tons="40" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>847</battle_value>
    <cost>3127230.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Defiance Industries" location="Hesperus II">Inner Sphere</techbase>
    <year restricted="FALSE">2830</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Defiant V" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="240" manufacturer="Pitban" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Valiant Lamellor" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>18</ct>
        <ctr>5</ctr>
        <lt>15</lt>
        <ltr>4</ltr>
        <rt>15</rt>
        <rtr>4</rtr>
        <la>11</la>
        <ra>11</ra>
        <ll>18</ll>
        <rl>18</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Sentinel STN-3KA 40t, 6/9/0, Std FE, Std; 8.0T/93% Armor; 13 SHS; 1 SL, 1 LL, 1 SRM2</info>
        <battleforce pv="8" wt="2" mv="6" s="2" m="1" l="0" e="0" ov="0" armor="4" internal="3" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="13" techbase="2">
            <type>Single Heat Sink</type>

            <location index="0">LT</location>
            <location index="2">LT</location>
            <location index="1">LT</location>
            <location index="10">CT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="Holly">(IS) SRM-2</name>
            <type>missile</type>
            <location index="1">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-2</name>
            <type>ammunition</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Defiance B-1A">(IS) Small Laser</name>
            <type>energy</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="2">LA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>StarLink/Benicia Model AS829G</commsystem>
        <tandtsystem>Targa-7, Vid-Com-17</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Sentinel" model="STN-3KB" tons="40" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>823</battle_value>
    <cost>3239230.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Defiance Industries" location="Hesperus II">Inner Sphere</techbase>
    <year restricted="FALSE">2830</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Defiant V" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="240" manufacturer="Pitban" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Valiant Lamellor" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>12</ct>
        <ctr>7</ctr>
        <lt>10</lt>
        <ltr>5</ltr>
        <rt>10</rt>
        <rtr>5</rtr>
        <la>8</la>
        <ra>8</ra>
        <ll>11</ll>
        <rl>11</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Sentinel STN-3KB 40t, 6/9/0, Std FE, Std; 6.0T/70% Armor; 13 SHS; 1 SL, 1 PPC, 1 SRM2</info>
        <battleforce pv="8" wt="2" mv="6" s="2" m="2" l="1" e="0" ov="0" armor="3" internal="3" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="13" techbase="2">
            <type>Single Heat Sink</type>

            <location index="0">LT</location>
            <location index="2">LT</location>
            <location index="1">LT</location>
            <location index="3">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="Holly">(IS) SRM-2</name>
            <type>missile</type>
            <location index="1">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-2</name>
            <type>ammunition</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Defiance B-1A">(IS) Small Laser</name>
            <type>energy</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="2">LA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>StarLink/Benicia Model AS829G</commsystem>
        <tandtsystem>Targa-7, Vid-Com-17</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Shadow Hawk" model="SHD-2D" tons="55" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>899</battle_value>
    <cost>4538756.666666667</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Earthwerks Incorporated, Majesty Metals and Manufacturing" location="Calloway VI (Earthwerks), Dunianshire (Majesty)">Inner Sphere</techbase>
    <year restricted="FALSE">2796</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Earthwerks SHD" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="275" manufacturer="CoreTek" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Maximillian 43" techbase="2">
        <type>Standard Armor</type>
        <hd>6</hd>
        <ct>12</ct>
        <ctr>6</ctr>
        <lt>9</lt>
        <ltr>5</ltr>
        <rt>9</rt>
        <rtr>5</rtr>
        <la>5</la>
        <ra>5</ra>
        <ll>5</ll>
        <rl>5</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Shadow Hawk SHD-2D 55t, 5/8/3, Std FE, Std; 4.5T/39% Armor; 14 SHS; 2 ML, 1 LRM5, 1 AC5, 2 SRM2</info>
        <battleforce pv="9" wt="2" mv="5/2j" s="2" m="3" l="1" e="0" ov="0" armor="2" internal="5" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="3">
            <type>Standard Jump Jet</type>
            <location index="10">CT</location>
            <location index="3">RT</location>
            <location index="0">LT</location>
        </jumpjets>
        <heatsinks number="14" techbase="2">
            <type>Single Heat Sink</type>

            <location index="1">RT</location>
            <location index="0">RT</location>
            <location index="2">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="Armstrong J11">(IS) Autocannon/5</name>
            <type>ballistic</type>
            <location index="1">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Holly Long Range Missile 5 Rack">(IS) LRM-5</name>
            <type>missile</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Holly Short Range Missile 2 Pack">(IS) SRM-2</name>
            <type>missile</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="Martel Model 5">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-2</name>
            <type>ammunition</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-5</name>
            <type>ammunition</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/5</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Martel Model 5">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Holly Short Range Missile 2 Pack">(IS) SRM-2</name>
            <type>missile</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-2</name>
            <type>ammunition</type>
            <location index="6">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Pitban LFT-50</jumpjet_model>
        <commsystem>O/P 300 COMSET</commsystem>
        <tandtsystem>O/P 2000A</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Shadow Hawk" model="SHD-2H" tons="55" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1064</battle_value>
    <cost>4505556.666666667</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>0</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Earthwerks Incorporated, Majesty Metals and Manufacturing" location="Calloway VI (Earthwerks), Dunianshire (Majesty)">Inner Sphere</techbase>
    <year restricted="FALSE">2550</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Earthwerks SHD" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="275" manufacturer="CoreTek" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Maximillian 43" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>23</ct>
        <ctr>8</ctr>
        <lt>18</lt>
        <ltr>6</ltr>
        <rt>18</rt>
        <rtr>6</rtr>
        <la>16</la>
        <ra>16</ra>
        <ll>16</ll>
        <rl>16</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Shadow Hawk SHD-2H 55t, 5/8/3, Std FE, Std; 9.5T/82% Armor; 12 SHS; 1 ML, 1 LRM5, 1 AC5, 1 SRM2</info>
        <battleforce pv="11" wt="2" mv="5/2j" s="2" m="2" l="1" e="0" ov="0" armor="5" internal="5" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="3">
            <type>Standard Jump Jet</type>
            <location index="10">CT</location>
            <location index="1">RT</location>
            <location index="0">LT</location>
        </jumpjets>
        <heatsinks number="12" techbase="2">
            <type>Single Heat Sink</type>

            <location index="0">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="Armstrong J11">(IS) Autocannon/5</name>
            <type>ballistic</type>
            <location index="1">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Holly Long Range Missile 5 Rack">(IS) LRM-5</name>
            <type>missile</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Holly Short Range Missile 2 Pack">(IS) SRM-2</name>
            <type>missile</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="Martel Model 5">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-2</name>
            <type>ammunition</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-5</name>
            <type>ammunition</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/5</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Pitban LFT-50</jumpjet_model>
        <commsystem>O/P 300 COMSET</commsystem>
        <tandtsystem>O/P 2000A</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Shadow Hawk" model="SHD-2K" tons="55" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1147</battle_value>
    <cost>4528306.666666667</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Earthwerks Incorporated, Majesty Metals and Manufacturing" location="Calloway VI (Earthwerks), Dunianshire (Majesty)">Inner Sphere</techbase>
    <year restricted="FALSE">2803</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Earthwerks SHD" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="275" manufacturer="CoreTek" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Maximillian 43" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>23</ct>
        <ctr>8</ctr>
        <lt>18</lt>
        <ltr>6</ltr>
        <rt>18</rt>
        <rtr>6</rtr>
        <la>16</la>
        <ra>16</ra>
        <ll>16</ll>
        <rl>16</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Shadow Hawk SHD-2K 55t, 5/8/3, Std FE, Std; 9.5T/82% Armor; 17 SHS; 1 PPC, 1 LRM5</info>
        <battleforce pv="11" wt="2" mv="5/2j" s="1" m="2" l="2" e="0" ov="0" armor="5" internal="5" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="3">
            <type>Standard Jump Jet</type>
            <location index="10">CT</location>
            <location index="1">RT</location>
            <location index="1">LT</location>
        </jumpjets>
        <heatsinks number="17" techbase="2">
            <type>Single Heat Sink</type>

            <location index="0">RT</location>
            <location index="5">LL</location>
            <location index="4">LL</location>
            <location index="5">RL</location>
            <location index="4">RL</location>
            <location index="0">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-5</name>
            <type>missile</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-5</name>
            <type>ammunition</type>
            <location index="3">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Pitban LFT-50</jumpjet_model>
        <commsystem>O/P 300 COMSET</commsystem>
        <tandtsystem>O/P 2000A</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Spider" model="SDR-5D" tons="30" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>524</battle_value>
    <cost>2942290.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2853</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="240" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>6</hd>
        <ct>8</ct>
        <ctr>4</ctr>
        <lt>6</lt>
        <ltr>2</ltr>
        <rt>6</rt>
        <rtr>2</rtr>
        <la>5</la>
        <ra>5</ra>
        <ll>6</ll>
        <rl>6</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Spider SDR-5D 30t, 8/12/8, Std FE, Std; 3.5T/53% Armor; 10 SHS; 1 Flmr, 1 ML</info>
        <battleforce pv="5" wt="1" mv="8j" s="1" m="1" l="0" e="0" ov="0" armor="2" internal="3" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="8">
            <type>Standard Jump Jet</type>
            <location index="0">RT</location>
            <location index="0">LT</location>
            <location index="2">RT</location>
            <location index="1">RT</location>
            <location index="1">LT</location>
            <location index="2">LT</location>
            <location index="3">RT</location>
            <location index="3">LT</location>
        </jumpjets>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="3">HD</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Spider" model="SDR-5K" tons="30" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>503</battle_value>
    <cost>2728140.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2850</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="240" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>6</hd>
        <ct>8</ct>
        <ctr>4</ctr>
        <lt>6</lt>
        <ltr>2</ltr>
        <rt>6</rt>
        <rtr>2</rtr>
        <la>5</la>
        <ra>5</ra>
        <ll>6</ll>
        <rl>6</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Spider SDR-5K 30t, 8/12/6, Std FE, Std; 3.5T/53% Armor; 10 SHS; 1 ML, 2 MG</info>
        <battleforce pv="5" wt="1" mv="8/4j" s="1" m="1" l="0" e="0" ov="0" armor="2" internal="3" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="6">
            <type>Standard Jump Jet</type>
            <location index="0">RT</location>
            <location index="0">LT</location>
            <location index="2">RT</location>
            <location index="1">RT</location>
            <location index="1">LT</location>
            <location index="2">LT</location>
        </jumpjets>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="3">HD</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Machine Gun</name>
            <type>ammunition</type>
            <location index="11">CT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Spider" model="SDR-5V" tons="30" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>622</battle_value>
    <cost>2984540.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2650</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="240" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>6</hd>
        <ct>8</ct>
        <ctr>4</ctr>
        <lt>6</lt>
        <ltr>2</ltr>
        <rt>6</rt>
        <rtr>2</rtr>
        <la>5</la>
        <ra>5</ra>
        <ll>6</ll>
        <rl>6</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Spider SDR-5V 30t, 8/12/8, Std FE, Std; 3.5T/53% Armor; 10 SHS; 2 ML</info>
        <battleforce pv="6" wt="1" mv="8j" s="1" m="1" l="0" e="0" ov="0" armor="2" internal="3" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="8">
            <type>Standard Jump Jet</type>
            <location index="0">RT</location>
            <location index="0">LT</location>
            <location index="2">RT</location>
            <location index="1">RT</location>
            <location index="1">LT</location>
            <location index="2">LT</location>
            <location index="3">LT</location>
            <location index="3">RT</location>
        </jumpjets>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="3">HD</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Stalker" model="STK-3F" tons="85" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1559</battle_value>
    <cost>7366925.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2594</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="255" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>36</ct>
        <ctr>11</ctr>
        <lt>25</lt>
        <ltr>7</ltr>
        <rt>25</rt>
        <rtr>7</rtr>
        <la>23</la>
        <ra>23</ra>
        <ll>25</ll>
        <rl>25</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Stalker STK-3F 85t, 3/5/0, Std FE, Std; 13.5T/82% Armor; 20 SHS; 4 ML, 2 SRM6, 2 LRM10, 2 LL</info>
        <battleforce pv="16" wt="4" mv="3" s="3" m="4" l="1" e="0" ov="3" armor="7" internal="7" abilities="SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="20" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">RL</location>
            <location index="5">RL</location>
            <location index="4">LL</location>
            <location index="5">LL</location>
            <location index="2">LA</location>
            <location index="0">LT</location>
            <location index="10">CT</location>
            <location index="11">CT</location>
            <location index="2">RA</location>
            <location index="0">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="5">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="6">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="5">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="6">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="1">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="1">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-10</name>
            <type>missile</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-10</name>
            <type>missile</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="7">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="7">LA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Stalker" model="STK-3H" tons="85" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1624</battle_value>
    <cost>7551925.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2638</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="255" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>36</ct>
        <ctr>11</ctr>
        <lt>25</lt>
        <ltr>7</ltr>
        <rt>25</rt>
        <rtr>7</rtr>
        <la>23</la>
        <ra>23</ra>
        <ll>25</ll>
        <rl>25</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Stalker STK-3H 85t, 3/5/0, Std FE, Std; 13.5T/82% Armor; 20 SHS; 4 ML, 2 SRM6, 2 LRM20</info>
        <battleforce pv="16" wt="4" mv="3" s="3" m="4" l="2" e="0" ov="0" armor="7" internal="7" abilities="SRCH, ES, SEAL, SOA, SRM 1/1/0, LRM 1/1/1, IF 1" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="20" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">RL</location>
            <location index="5">RL</location>
            <location index="5">LL</location>
            <location index="4">LL</location>
            <location index="2">LA</location>
            <location index="0">LT</location>
            <location index="11">CT</location>
            <location index="10">CT</location>
            <location index="2">RA</location>
            <location index="0">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="9">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="8">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="9">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="8">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="1">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="1">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-20</name>
            <type>missile</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-20</name>
            <type>missile</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20</name>
            <type>ammunition</type>
            <location index="10">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20</name>
            <type>ammunition</type>
            <location index="10">LA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Stalker" model="STK-4N" tons="85" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1558</battle_value>
    <cost>7174125.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2876</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="255" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>36</ct>
        <ctr>9</ctr>
        <lt>25</lt>
        <ltr>5</ltr>
        <rt>25</rt>
        <rtr>5</rtr>
        <la>22</la>
        <ra>22</ra>
        <ll>29</ll>
        <rl>29</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Stalker STK-4N 85t, 3/5/0, Std FE, Std; 13.5T/82% Armor; 26 SHS; 4 ML, 2 SRM6, 1 LRM10, 2 LL</info>
        <battleforce pv="16" wt="4" mv="3" s="4" m="4" l="1" e="0" ov="1" armor="7" internal="7" abilities="SRCH, ES, SEAL, SOA, SRM 1/1/0, IF 1" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="26" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">RL</location>
            <location index="5">RL</location>
            <location index="5">LL</location>
            <location index="4">LL</location>
            <location index="4">LT</location>
            <location index="3">LT</location>
            <location index="2">LT</location>
            <location index="0">LT</location>
            <location index="1">LT</location>
            <location index="4">RT</location>
            <location index="11">CT</location>
            <location index="10">CT</location>
            <location index="3">RT</location>
            <location index="2">RT</location>
            <location index="0">RT</location>
            <location index="1">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="5">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-10</name>
            <type>missile</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="7">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="7">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="9">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="6">RA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Stalker" model="STK-4P" tons="75" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1461</battle_value>
    <cost>6117125.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2998</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="225" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>35</ct>
        <ctr>10</ctr>
        <lt>25</lt>
        <ltr>7</ltr>
        <rt>25</rt>
        <rtr>7</rtr>
        <la>23</la>
        <ra>23</ra>
        <ll>26</ll>
        <rl>26</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Stalker STK-4P 75t, 3/5/0, Std FE, Std; 13.5T/94% Armor; 20 SHS; 4 ML, 2 SRM6, 1 LRM10, 2 LL</info>
        <battleforce pv="15" wt="3" mv="3" s="3" m="4" l="1" e="0" ov="2" armor="7" internal="6" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="20" techbase="2">
            <type>Single Heat Sink</type>

            <location index="3">LA</location>
            <location index="2">LA</location>
            <location index="0">LT</location>
            <location index="10">CT</location>
            <location index="11">CT</location>
            <location index="4">LL</location>
            <location index="5">LL</location>
            <location index="4">RL</location>
            <location index="5">RL</location>
            <location index="0">RT</location>
            <location index="2">RA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="7">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="6">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="5">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="1">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="1">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-10</name>
            <type>missile</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="5">RA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Stinger" model="STG-3G" tons="20" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>497</battle_value>
    <cost>1662240.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Earthwerks Incorporated, Coventry Metal Works" location="Calloway VI, Grand Base, Keystone (Earthwerks), Coventry (CMW)">Inner Sphere</techbase>
    <year restricted="FALSE">2581</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Earthwerks STG" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="120" manufacturer="GM" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Riese 100" techbase="2">
        <type>Standard Armor</type>
        <hd>6</hd>
        <ct>8</ct>
        <ctr>4</ctr>
        <lt>7</lt>
        <ltr>3</ltr>
        <rt>7</rt>
        <rtr>3</rtr>
        <la>6</la>
        <ra>6</ra>
        <ll>7</ll>
        <rl>7</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Stinger STG-3G 20t, 6/9/6, Std FE, Std; 4.0T/93% Armor; 10 SHS; 2 ML</info>
        <battleforce pv="5" wt="1" mv="6j" s="1" m="1" l="0" e="0" ov="0" armor="2" internal="2" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="6">
            <type>Standard Jump Jet</type>
            <location index="3">LT</location>
            <location index="3">RT</location>
            <location index="2">RT</location>
            <location index="2">LT</location>
            <location index="1">RT</location>
            <location index="1">LT</location>
        </jumpjets>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="5">RL</location>
            <location index="4">RL</location>
            <location index="4">LL</location>
            <location index="5">LL</location>
            <location index="0">LT</location>
            <location index="0">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Chilton 360</jumpjet_model>
        <commsystem>Datacom 26</commsystem>
        <tandtsystem>Dynatec 990</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Stinger" model="STG-3R" tons="20" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>359</battle_value>
    <cost>1615240.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>0</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Earthwerks Incorporated, Coventry Metal Works" location="Calloway VI, Grand Base, Keystone (Earthwerks), Coventry (CMW)">Inner Sphere</techbase>
    <year restricted="FALSE">2479</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Earthwerks STG" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="120" manufacturer="GM" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Riese 100" techbase="2">
        <type>Standard Armor</type>
        <hd>4</hd>
        <ct>6</ct>
        <ctr>4</ctr>
        <lt>6</lt>
        <ltr>2</ltr>
        <rt>6</rt>
        <rtr>2</rtr>
        <la>4</la>
        <ra>4</ra>
        <ll>5</ll>
        <rl>5</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Stinger STG-3R 20t, 6/9/6, Std FE, Std; 3.0T/70% Armor; 10 SHS; 1 ML, 2 MG</info>
        <battleforce pv="4" wt="1" mv="6j" s="1" m="1" l="0" e="0" ov="0" armor="2" internal="2" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="6">
            <type>Standard Jump Jet</type>
            <location index="5">LT</location>
            <location index="3">RT</location>
            <location index="5">RT</location>
            <location index="4">LT</location>
            <location index="4">RT</location>
            <location index="3">LT</location>
        </jumpjets>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="2">LT</location>
            <location index="0">LT</location>
            <location index="1">LT</location>
            <location index="2">RT</location>
            <location index="0">RT</location>
            <location index="1">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="LFN Linblad">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="LFN Linblad">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="5">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Machine Gun</name>
            <type>ammunition</type>
            <location index="10">CT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Chilton 360</jumpjet_model>
        <commsystem>Datacom 26</commsystem>
        <tandtsystem>Dynatec 990</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Thorn" model="THE-F" tons="20" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>510</battle_value>
    <cost>1552320.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>0</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Ford Military Limited" location="Terra">Inner Sphere</techbase>
    <year restricted="FALSE">2490</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Chariot Type II" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="120" manufacturer="GM" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Durallex" techbase="2">
        <type>Standard Armor</type>
        <hd>8</hd>
        <ct>8</ct>
        <ctr>4</ctr>
        <lt>6</lt>
        <ltr>3</ltr>
        <rt>6</rt>
        <rtr>3</rtr>
        <la>6</la>
        <ra>6</ra>
        <ll>7</ll>
        <rl>7</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Thorn THE-F 20t, 6/9/0, Std FE, Std; 4.0T/93% Armor; 10 SHS; 2 ML, 1 LRM5</info>
        <battleforce pv="5" wt="1" mv="6" s="2" m="2" l="1" e="0" ov="0" armor="2" internal="2" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="1">LT</location>
            <location index="0">LT</location>
            <location index="10">CT</location>
            <location index="11">CT</location>
            <location index="0">RT</location>
            <location index="1">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="Hellion Spitfire">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="Hellion Spitfire">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Zeus-5">(IS) LRM-5</name>
            <type>missile</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-5</name>
            <type>ammunition</type>
            <location index="2">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Olmstead 30</commsystem>
        <tandtsystem>Omicron VII</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Thorn" model="THE-S" tons="20" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>510</battle_value>
    <cost>1552320.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Ford Military Limited" location="Terra">Inner Sphere</techbase>
    <year restricted="FALSE">3035</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Chariot Type II" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="120" manufacturer="GM" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Durallex" techbase="2">
        <type>Standard Armor</type>
        <hd>8</hd>
        <ct>8</ct>
        <ctr>4</ctr>
        <lt>6</lt>
        <ltr>3</ltr>
        <rt>6</rt>
        <rtr>3</rtr>
        <la>6</la>
        <ra>6</ra>
        <ll>7</ll>
        <rl>7</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Thorn THE-S 20t, 6/9/0, Std FE, Std; 4.0T/93% Armor; 10 SHS; 2 ML, 1 LRM5</info>
        <battleforce pv="5" wt="1" mv="6" s="2" m="2" l="1" e="0" ov="0" armor="2" internal="2" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="1">LT</location>
            <location index="0">LT</location>
            <location index="10">CT</location>
            <location index="11">CT</location>
            <location index="0">RT</location>
            <location index="1">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="Hellion Spitfire">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="Hellion Spitfire">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Zeus-5">(IS) LRM-5</name>
            <type>missile</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-5</name>
            <type>ammunition</type>
            <location index="2">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Olmstead 30</commsystem>
        <tandtsystem>Omicron VII</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Thorn" model="THE-T" tons="20" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>500</battle_value>
    <cost>1585320.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3038</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="120" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>8</hd>
        <ct>8</ct>
        <ctr>4</ctr>
        <lt>6</lt>
        <ltr>3</ltr>
        <rt>6</rt>
        <rtr>3</rtr>
        <la>6</la>
        <ra>6</ra>
        <ll>7</ll>
        <rl>7</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Thorn THE-T 20t, 6/9/0, Std FE, Std; 4.0T/93% Armor; 10 SHS; 2 ML, 1 SRM4</info>
        <battleforce pv="5" wt="1" mv="6" s="2" m="2" l="0" e="0" ov="0" armor="2" internal="2" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="1">LT</location>
            <location index="0">LT</location>
            <location index="10">CT</location>
            <location index="11">CT</location>
            <location index="0">RT</location>
            <location index="1">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-4</name>
            <type>missile</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4</name>
            <type>ammunition</type>
            <location index="3">RA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Thug" model="THG-10E" tons="80" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1501</battle_value>
    <cost>7739040.000000001</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Earthwerks Incorporated" location="Keystone">Inner Sphere</techbase>
    <year restricted="FALSE">2835</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Earthwerks VOL" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="320" manufacturer="Pitban" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Durallex Heavy Special" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>33</ct>
        <ctr>10</ctr>
        <lt>24</lt>
        <ltr>8</ltr>
        <rt>24</rt>
        <rtr>8</rtr>
        <la>25</la>
        <ra>25</ra>
        <ll>33</ll>
        <rl>33</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Thug THG-10E 80t, 4/6/0, Std FE, Std; 14.5T/94% Armor; 19 SHS; 2 PPC, 2 SRM4</info>
        <battleforce pv="15" wt="4" mv="4" s="3" m="3" l="2" e="0" ov="0" armor="8" internal="6" abilities="SRCH, ES, SEAL, SOA, SRM 1/1/0" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="19" techbase="2">
            <type>Single Heat Sink</type>

            <location index="0">LT</location>
            <location index="4">LA</location>
            <location index="2">LT</location>
            <location index="1">LT</location>
            <location index="4">RA</location>
            <location index="0">RT</location>
            <location index="1">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="Donal">(IS) PPC</name>
            <type>energy</type>
            <location index="5">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Donal">(IS) PPC</name>
            <type>energy</type>
            <location index="5">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Holly">(IS) SRM-4</name>
            <type>missile</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Holly">(IS) SRM-4</name>
            <type>missile</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4</name>
            <type>ammunition</type>
            <location index="3">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Colmax-025</commsystem>
        <tandtsystem>Instatrac Mark XV</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Thunderbolt" model="TDR-5S" tons="65" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1335</battle_value>
    <cost>5356560.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>0</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Earthwerks Incorporated" location="Keystone">Inner Sphere</techbase>
    <year restricted="FALSE">2505</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Earthwerks TDR" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="260" manufacturer="Magna" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Ryerson 150" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>30</ct>
        <ctr>11</ctr>
        <lt>24</lt>
        <ltr>6</ltr>
        <rt>24</rt>
        <rtr>6</rtr>
        <la>20</la>
        <ra>20</ra>
        <ll>29</ll>
        <rl>29</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Thunderbolt TDR-5S 65t, 4/6/0, Std FE, Std; 13.0T/99% Armor; 15 SHS; 3 ML, 1 LRM15, 2 MG, 1 LL, 1 SRM2</info>
        <battleforce pv="13" wt="3" mv="4" s="3" m="3" l="1" e="0" ov="1" armor="7" internal="5" abilities="SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="15" techbase="2">
            <type>Single Heat Sink</type>

            <location index="5">LL</location>
            <location index="4">RL</location>
            <location index="5">RL</location>
            <location index="4">LL</location>
            <location index="3">HD</location>
        </heatsinks>
        <equipment>
            <name manufacturer="Sunglow Type 2">(IS) Large Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Diverse Optics Type 18">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Diverse Optics Type 18">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Diverse Optics Type 18">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="1">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Delta Dart Long Range Missile 15 Rack">(IS) LRM-15</name>
            <type>missile</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Bical Short Range Missile Twin-Rack">(IS) SRM-2</name>
            <type>missile</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Voelkers 200">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Voelkers 200">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="5">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-2</name>
            <type>ammunition</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Machine Gun</name>
            <type>ammunition</type>
            <location index="6">LA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Neil 8000</commsystem>
        <tandtsystem>RCA Instatrac Mark X</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Thunderbolt" model="TDR-5SE" tons="65" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1414</battle_value>
    <cost>5521610.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Earthwerks Incorporated" location="Keystone">Inner Sphere</techbase>
    <year restricted="FALSE">2910</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Earthwerks TDR" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="260" manufacturer="Magna" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Ryerson 150" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>30</ct>
        <ctr>11</ctr>
        <lt>24</lt>
        <ltr>6</ltr>
        <rt>24</rt>
        <rtr>6</rtr>
        <la>20</la>
        <ra>20</ra>
        <ll>29</ll>
        <rl>29</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Thunderbolt TDR-5SE 65t, 4/6/4, Std FE, Std; 13.0T/99% Armor; 17 SHS; 3 ML, 1 LRM10, 1 LL</info>
        <battleforce pv="14" wt="3" mv="4j" s="3" m="3" l="1" e="0" ov="0" armor="7" internal="5" abilities="SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="3">RT</location>
            <location index="4">RT</location>
            <location index="0">LT</location>
            <location index="1">LT</location>
        </jumpjets>
        <heatsinks number="17" techbase="2">
            <type>Single Heat Sink</type>

            <location index="5">LL</location>
            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="1">RT</location>
            <location index="5">RL</location>
            <location index="0">RT</location>
            <location index="2">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-10</name>
            <type>missile</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="10">CT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Neil 8000</commsystem>
        <tandtsystem>RCA Instatrac Mark X</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Thunderbolt" model="TDR-5SS" tons="65" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1337</battle_value>
    <cost>5302985.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Earthwerks Incorporated" location="Keystone">Inner Sphere</techbase>
    <year restricted="FALSE">2930</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Earthwerks TDR" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="260" manufacturer="Magna" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Ryerson 150" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>30</ct>
        <ctr>11</ctr>
        <lt>24</lt>
        <ltr>6</ltr>
        <rt>24</rt>
        <rtr>6</rtr>
        <la>20</la>
        <ra>20</ra>
        <ll>29</ll>
        <rl>29</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Thunderbolt TDR-5SS 65t, 4/6/0, Std FE, Std; 13.0T/99% Armor; 21 SHS; 1 Flmr, 3 ML, 1 PPC, 1 SRM6</info>
        <battleforce pv="13" wt="3" mv="4" s="3" m="3" l="1" e="0" ov="1" armor="7" internal="5" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="21" techbase="2">
            <type>Single Heat Sink</type>

            <location index="5">LL</location>
            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="3">RT</location>
            <location index="5">RL</location>
            <location index="0">LT</location>
            <location index="2">RT</location>
            <location index="4">RT</location>
            <location index="0">RT</location>
            <location index="1">RT</location>
            <location index="10">CT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="1">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="11">CT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Neil 8000</commsystem>
        <tandtsystem>RCA Instatrac Mark X</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Trebuchet" model="TBT-5J" tons="50" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1191</battle_value>
    <cost>4368500.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2850</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="250" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>22</ct>
        <ctr>7</ctr>
        <lt>15</lt>
        <ltr>5</ltr>
        <rt>15</rt>
        <rtr>5</rtr>
        <la>10</la>
        <ra>10</ra>
        <ll>15</ll>
        <rl>15</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Trebuchet TBT-5J 50t, 5/8/5, Std FE, Std; 8.0T/76% Armor; 15 SHS; 3 ML, 1 LRM15</info>
        <battleforce pv="12" wt="2" mv="5j" s="2" m="3" l="1" e="0" ov="0" armor="4" internal="4" abilities="SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="5">
            <type>Standard Jump Jet</type>
            <location index="4">LL</location>
            <location index="10">CT</location>
            <location index="4">RL</location>
            <location index="5">RL</location>
            <location index="5">LL</location>
        </jumpjets>
        <heatsinks number="15" techbase="2">
            <type>Single Heat Sink</type>

            <location index="0">LT</location>
            <location index="3">LT</location>
            <location index="4">LT</location>
            <location index="1">LT</location>
            <location index="2">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="3">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Trebuchet" model="TBT-5N" tons="50" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1191</battle_value>
    <cost>4263500.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2799</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="250" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>22</ct>
        <ctr>7</ctr>
        <lt>11</lt>
        <ltr>5</ltr>
        <rt>11</rt>
        <rtr>5</rtr>
        <la>10</la>
        <ra>10</ra>
        <ll>15</ll>
        <rl>15</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Trebuchet TBT-5N 50t, 5/8/0, Std FE, Std; 7.5T/71% Armor; 10 SHS; 3 ML, 2 LRM15</info>
        <battleforce pv="12" wt="2" mv="5" s="2" m="2" l="1" e="0" ov="1" armor="4" internal="4" abilities="SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="7">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="0">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Trebuchet" model="TBT-5S" tons="50" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>984</battle_value>
    <cost>3996500.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2864</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="250" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>22</ct>
        <ctr>7</ctr>
        <lt>11</lt>
        <ltr>5</ltr>
        <rt>11</rt>
        <rtr>5</rtr>
        <la>10</la>
        <ra>10</ra>
        <ll>15</ll>
        <rl>15</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Trebuchet TBT-5S 50t, 5/8/0, Std FE, Std; 7.5T/71% Armor; 18 SHS; 3 ML, 2 SRM6</info>
        <battleforce pv="10" wt="2" mv="5" s="4" m="4" l="0" e="0" ov="0" armor="4" internal="4" abilities="SRCH, ES, SEAL, SOA, SRM 2/2/0" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="18" techbase="2">
            <type>Single Heat Sink</type>

            <location index="0">LT</location>
            <location index="1">LT</location>
            <location index="5">RL</location>
            <location index="4">RL</location>
            <location index="5">LL</location>
            <location index="4">LL</location>
            <location index="0">RT</location>
            <location index="1">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="6">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="4">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Trebuchet" model="TBT-7K" tons="50" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>996</battle_value>
    <cost>4035500.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3025</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="250" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>22</ct>
        <ctr>7</ctr>
        <lt>11</lt>
        <ltr>5</ltr>
        <rt>11</rt>
        <rtr>5</rtr>
        <la>10</la>
        <ra>10</ra>
        <ll>15</ll>
        <rl>15</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Trebuchet TBT-7K 50t, 5/8/0, Std FE, Std; 7.5T/71% Armor; 11 SHS; 1 PPC, 1 AC5, 1 SRM2</info>
        <battleforce pv="10" wt="2" mv="5" s="2" m="2" l="2" e="0" ov="0" armor="4" internal="4" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="11" techbase="2">
            <type>Single Heat Sink</type>

            <location index="10">CT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Autocannon/5</name>
            <type>ballistic</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-2</name>
            <type>missile</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-2</name>
            <type>ammunition</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/5</name>
            <type>ammunition</type>
            <location index="3">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Urbanmech" model="UM-R60" tons="30" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>504</battle_value>
    <cost>1470125.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>0</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2675</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="60" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>11</ct>
        <ctr>8</ctr>
        <lt>8</lt>
        <ltr>4</ltr>
        <rt>8</rt>
        <rtr>4</rtr>
        <la>10</la>
        <ra>10</ra>
        <ll>12</ll>
        <rl>12</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Urbanmech UM-R60 30t, 2/3/2, Std FE, Std; 6.0T/91% Armor; 11 SHS; 1 SL, 1 AC10</info>
        <battleforce pv="5" wt="1" mv="2j" s="2" m="1" l="0" e="0" ov="0" armor="3" internal="3" abilities="SRCH, ES, SEAL, SOA, AC 1/1/0" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="2">
            <type>Standard Jump Jet</type>
            <location index="10">CT</location>
            <location index="11">CT</location>
        </jumpjets>
        <heatsinks number="11" techbase="2">
            <type>Single Heat Sink</type>

            <location index="1">LT</location>
            <location index="0">LT</location>
            <location index="4">RL</location>
            <location index="5">RL</location>
            <location index="4">LL</location>
            <location index="5">LL</location>
            <location index="0">RT</location>
            <location index="1">RT</location>
            <location index="3">HD</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Autocannon/10</name>
            <type>ballistic</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/10</name>
            <type>ammunition</type>
            <location index="2">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Urbanmech" model="UM-R60L" tons="30" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>470</battle_value>
    <cost>1578125.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2925</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="60" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>6</hd>
        <ct>8</ct>
        <ctr>4</ctr>
        <lt>6</lt>
        <ltr>3</ltr>
        <rt>6</rt>
        <rtr>3</rtr>
        <la>6</la>
        <ra>6</ra>
        <ll>8</ll>
        <rl>8</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Urbanmech UM-R60L 30t, 2/3/2, Std FE, Std; 4.0T/61% Armor; 11 SHS; 1 SL, 1 AC20</info>
        <battleforce pv="5" wt="1" mv="2j" s="2" m="2" l="0" e="0" ov="0" armor="2" internal="3" abilities="SRCH, ES, SEAL, SOA, AC 2/2/0" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="2">
            <type>Standard Jump Jet</type>
            <location index="10">CT</location>
            <location index="11">CT</location>
        </jumpjets>
        <heatsinks number="11" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">RL</location>
            <location index="4">LL</location>
            <location index="0">LT</location>
            <location index="1">RT</location>
            <location index="0">RT</location>
            <location index="1">LT</location>
            <location index="5">LL</location>
            <location index="5">RL</location>
            <location index="3">HD</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Autocannon/20</name>
            <type>ballistic</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/20</name>
            <type>ammunition</type>
            <location index="2">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Valkyrie" model="VLK-QA" tons="30" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>723</battle_value>
    <cost>2196320.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Corean Enterprises" location="New Avalon">Inner Sphere</techbase>
    <year restricted="FALSE">2787</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Corean Model 1AA" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="150" manufacturer="Omni" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Reise 470" techbase="2">
        <type>Standard Armor</type>
        <hd>8</hd>
        <ct>14</ct>
        <ctr>4</ctr>
        <lt>12</lt>
        <ltr>2</ltr>
        <rt>12</rt>
        <rtr>2</rtr>
        <la>9</la>
        <ra>9</ra>
        <ll>12</ll>
        <rl>12</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Valkyrie VLK-QA 30t, 5/8/5, Std FE, Std; 6.0T/91% Armor; 11 SHS; 1 ML, 1 LRM10</info>
        <battleforce pv="7" wt="1" mv="5j" s="1" m="2" l="1" e="0" ov="0" armor="3" internal="3" abilities="SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="5">
            <type>Standard Jump Jet</type>
            <location index="11">CT</location>
            <location index="4">LL</location>
            <location index="5">LL</location>
            <location index="5">RL</location>
            <location index="4">RL</location>
        </jumpjets>
        <heatsinks number="11" techbase="2">
            <type>Single Heat Sink</type>

            <location index="0">LT</location>
            <location index="0">RT</location>
            <location index="1">RT</location>
            <location index="1">LT</location>
            <location index="10">CT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Devastator Series-7">(IS) LRM-10</name>
            <type>missile</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="2">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Norse Industries 3S</jumpjet_model>
        <commsystem>Lynx-shur</commsystem>
        <tandtsystem>Sync-Tracker (39-42071)</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Valkyrie" model="VLK-QF" tons="30" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>653</battle_value>
    <cost>2154070.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Corean Enterprises" location="New Avalon">Inner Sphere</techbase>
    <year restricted="FALSE">2967</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Corean Model 1AA" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="150" manufacturer="Omni" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Reise 470" techbase="2">
        <type>Standard Armor</type>
        <hd>8</hd>
        <ct>14</ct>
        <ctr>4</ctr>
        <lt>12</lt>
        <ltr>2</ltr>
        <rt>12</rt>
        <rtr>2</rtr>
        <la>9</la>
        <ra>9</ra>
        <ll>12</ll>
        <rl>12</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Valkyrie VLK-QF 30t, 5/8/5, Std FE, Std; 6.0T/91% Armor; 11 SHS; 1 Flmr, 1 LRM10</info>
        <battleforce pv="7" wt="1" mv="5j" s="1" m="1" l="1" e="0" ov="0" armor="3" internal="3" abilities="SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="5">
            <type>Standard Jump Jet</type>
            <location index="11">CT</location>
            <location index="4">LL</location>
            <location index="5">LL</location>
            <location index="5">RL</location>
            <location index="4">RL</location>
        </jumpjets>
        <heatsinks number="11" techbase="2">
            <type>Single Heat Sink</type>

            <location index="0">LT</location>
            <location index="0">RT</location>
            <location index="1">RT</location>
            <location index="1">LT</location>
            <location index="10">CT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="Devastator Series-7">(IS) LRM-10</name>
            <type>missile</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Norse Industries 3S</jumpjet_model>
        <commsystem>Lynx-shur</commsystem>
        <tandtsystem>Sync-Tracker (39-42071)</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Victor" model="VTR-9A" tons="80" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1236</battle_value>
    <cost>7981220.000000001</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2508</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="320" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>18</ct>
        <ctr>9</ctr>
        <lt>12</lt>
        <ltr>6</ltr>
        <rt>12</rt>
        <rtr>6</rtr>
        <la>12</la>
        <ra>12</ra>
        <ll>20</ll>
        <rl>20</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Victor VTR-9A 80t, 4/6/4, Std FE, Std; 8.5T/55% Armor; 15 SHS; 2 Flmr, 2 ML, 1 MG, 1 AC20, 1 SRM4</info>
        <battleforce pv="12" wt="4" mv="4j" s="3" m="3" l="0" e="0" ov="0" armor="5" internal="6" abilities="SRCH, ES, SEAL, SOA, AC 1/1/0" />
        <actuators lla="TRUE" lh="TRUE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="5">RL</location>
            <location index="11">CT</location>
            <location index="5">LL</location>
            <location index="10">CT</location>
        </jumpjets>
        <heatsinks number="15" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="0">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Autocannon/20</name>
            <type>ballistic</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="5">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-4</name>
            <type>missile</type>
            <location index="1">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/20</name>
            <type>ammunition</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/20</name>
            <type>ammunition</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/20</name>
            <type>ammunition</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Machine Gun (1/2)</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="1">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Victor" model="VTR-9A1" tons="80" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1302</battle_value>
    <cost>7990220.000000001</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2508</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="320" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>25</ct>
        <ctr>12</ctr>
        <lt>15</lt>
        <ltr>7</ltr>
        <rt>15</rt>
        <rtr>7</rtr>
        <la>15</la>
        <ra>15</ra>
        <ll>20</ll>
        <rl>20</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Victor VTR-9A1 80t, 4/6/4, Std FE, Std; 10.0T/65% Armor; 15 SHS; 2 ML, 2 MG, 1 AC20, 1 SRM4</info>
        <battleforce pv="13" wt="4" mv="4j" s="4" m="4" l="0" e="0" ov="0" armor="5" internal="6" abilities="SRCH, ES, SEAL, SOA, AC 2/2/0" />
        <actuators lla="TRUE" lh="TRUE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="4">RL</location>
            <location index="11">CT</location>
            <location index="4">LL</location>
            <location index="10">CT</location>
        </jumpjets>
        <heatsinks number="15" techbase="2">
            <type>Single Heat Sink</type>

            <location index="1">LT</location>
            <location index="0">LT</location>
            <location index="0">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Autocannon/20</name>
            <type>ballistic</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="5">LL</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="5">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-4</name>
            <type>missile</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/20</name>
            <type>ammunition</type>
            <location index="1">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/20</name>
            <type>ammunition</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/20</name>
            <type>ammunition</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Machine Gun (1/2)</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="5">RL</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Victor" model="VTR-9B" tons="80" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1378</battle_value>
    <cost>7998720.000000001</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2510</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="320" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>30</ct>
        <ctr>15</ctr>
        <lt>20</lt>
        <ltr>10</ltr>
        <rt>20</rt>
        <rtr>10</rtr>
        <la>15</la>
        <ra>15</ra>
        <ll>20</ll>
        <rl>20</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Victor VTR-9B 80t, 4/6/4, Std FE, Std; 11.5T/74% Armor; 15 SHS; 2 ML, 1 AC20, 1 SRM4</info>
        <battleforce pv="14" wt="4" mv="4j" s="4" m="4" l="0" e="0" ov="0" armor="6" internal="6" abilities="SRCH, ES, SEAL, SOA, AC 2/2/0" />
        <actuators lla="TRUE" lh="TRUE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="5">RL</location>
            <location index="11">CT</location>
            <location index="5">LL</location>
            <location index="10">CT</location>
        </jumpjets>
        <heatsinks number="15" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="0">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Autocannon/20</name>
            <type>ballistic</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="5">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-4</name>
            <type>missile</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4</name>
            <type>ammunition</type>
            <location index="1">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/20</name>
            <type>ammunition</type>
            <location index="1">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/20</name>
            <type>ammunition</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/20</name>
            <type>ammunition</type>
            <location index="3">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Victor" model="VTR-9S" tons="80" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1360</battle_value>
    <cost>8016720.000000001</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2998</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="320" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>28</ct>
        <ctr>11</ctr>
        <lt>17</lt>
        <ltr>8</ltr>
        <rt>17</rt>
        <rtr>8</rtr>
        <la>15</la>
        <ra>15</ra>
        <ll>20</ll>
        <rl>20</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Victor VTR-9S 80t, 4/6/4, Std FE, Std; 10.5T/68% Armor; 15 SHS; 2 ML, 1 SRM6, 1 AC20</info>
        <battleforce pv="14" wt="4" mv="4j" s="4" m="4" l="0" e="0" ov="0" armor="6" internal="6" abilities="SRCH, ES, SEAL, SOA, AC 2/2/0" />
        <actuators lla="TRUE" lh="TRUE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="5">RL</location>
            <location index="11">CT</location>
            <location index="5">LL</location>
            <location index="10">CT</location>
        </jumpjets>
        <heatsinks number="15" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="0">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Autocannon/20</name>
            <type>ballistic</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="5">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/20</name>
            <type>ammunition</type>
            <location index="1">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/20</name>
            <type>ammunition</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/20</name>
            <type>ammunition</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="2">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Vindicator" model="VND-1AA &quot;Avenging Angel&quot;" tons="45" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>966</battle_value>
    <cost>3850532.5</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2863</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="225" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>7</hd>
        <ct>12</ct>
        <ctr>5</ctr>
        <lt>8</lt>
        <ltr>3</ltr>
        <rt>8</rt>
        <rtr>3</rtr>
        <la>5</la>
        <ra>5</ra>
        <ll>8</ll>
        <rl>8</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Vindicator VND-1AA "Avenging Angel" 45t, 5/8/5, Std FE, Std; 4.5T/47% Armor; 16 SHS; 1 ML, 1 SL, 1 PPC, 1 LRM5</info>
        <battleforce pv="10" wt="2" mv="5j" s="2" m="2" l="2" e="0" ov="0" armor="2" internal="4" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="5">
            <type>Standard Jump Jet</type>
            <location index="4">RL</location>
            <location index="11">CT</location>
            <location index="5">LL</location>
            <location index="4">LL</location>
            <location index="5">RL</location>
        </jumpjets>
        <heatsinks number="16" techbase="2">
            <type>Single Heat Sink</type>

            <location index="1">LT</location>
            <location index="0">LT</location>
            <location index="10">CT</location>
            <location index="3">RT</location>
            <location index="2">RT</location>
            <location index="0">RT</location>
            <location index="1">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-5</name>
            <type>missile</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-5</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Vindicator" model="VND-1R (Vong)" tons="45" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1024</battle_value>
    <cost>3167582.5</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3020</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="180" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>18</ct>
        <ctr>9</ctr>
        <lt>16</lt>
        <ltr>6</ltr>
        <rt>16</rt>
        <rtr>6</rtr>
        <la>14</la>
        <ra>14</ra>
        <ll>18</ll>
        <rl>18</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Vindicator VND-1R (Vong) 45t, 4/6/4, Std FE, Std; 9.0T/94% Armor; 16 SHS; 1 ML, 1 SL, 1 PPC, 1 LRM5</info>
        <battleforce pv="10" wt="2" mv="4j" s="2" m="2" l="2" e="0" ov="0" armor="5" internal="4" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="4">RL</location>
            <location index="10">CT</location>
            <location index="11">CT</location>
            <location index="4">LL</location>
        </jumpjets>
        <heatsinks number="16" techbase="2">
            <type>Single Heat Sink</type>

            <location index="3">LT</location>
            <location index="1">LT</location>
            <location index="0">LT</location>
            <location index="2">LT</location>
            <location index="2">RT</location>
            <location index="3">RT</location>
            <location index="0">RT</location>
            <location index="4">RT</location>
            <location index="1">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="5">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-5</name>
            <type>missile</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-5</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Vindicator" model="VND-1R" tons="45" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1024</battle_value>
    <cost>3167582.5</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2826</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="180" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>18</ct>
        <ctr>9</ctr>
        <lt>16</lt>
        <ltr>6</ltr>
        <rt>16</rt>
        <rtr>6</rtr>
        <la>14</la>
        <ra>14</ra>
        <ll>18</ll>
        <rl>18</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Vindicator VND-1R 45t, 4/6/4, Std FE, Std; 9.0T/94% Armor; 16 SHS; 1 ML, 1 SL, 1 PPC, 1 LRM5</info>
        <battleforce pv="10" wt="2" mv="4j" s="2" m="2" l="2" e="0" ov="0" armor="5" internal="4" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="4">RL</location>
            <location index="10">CT</location>
            <location index="11">CT</location>
            <location index="4">LL</location>
        </jumpjets>
        <heatsinks number="16" techbase="2">
            <type>Single Heat Sink</type>

            <location index="3">LT</location>
            <location index="1">LT</location>
            <location index="0">LT</location>
            <location index="2">LT</location>
            <location index="2">RT</location>
            <location index="3">RT</location>
            <location index="0">RT</location>
            <location index="4">RT</location>
            <location index="1">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-5</name>
            <type>missile</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-5</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Vindicator" model="VND-1SIC" tons="45" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1020</battle_value>
    <cost>3121182.5</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3031</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="180" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>18</ct>
        <ctr>9</ctr>
        <lt>16</lt>
        <ltr>6</ltr>
        <rt>16</rt>
        <rtr>6</rtr>
        <la>14</la>
        <ra>14</ra>
        <ll>18</ll>
        <rl>18</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Vindicator VND-1SIC 45t, 4/6/4, Std FE, Std; 9.0T/94% Armor; 15 SHS; 1 ML, 1 SL, 1 LRM10, 1 LL</info>
        <battleforce pv="10" wt="2" mv="4j" s="2" m="2" l="1" e="0" ov="0" armor="5" internal="4" abilities="SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="4">RL</location>
            <location index="10">CT</location>
            <location index="11">CT</location>
            <location index="4">LL</location>
        </jumpjets>
        <heatsinks number="15" techbase="2">
            <type>Single Heat Sink</type>

            <location index="3">LT</location>
            <location index="1">LT</location>
            <location index="0">LT</location>
            <location index="2">LT</location>
            <location index="2">RT</location>
            <location index="3">RT</location>
            <location index="0">RT</location>
            <location index="1">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-10</name>
            <type>missile</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="6">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Vindicator" model="VND-1X" tons="45" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1008</battle_value>
    <cost>3163370.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2826</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="180" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>18</ct>
        <ctr>9</ctr>
        <lt>16</lt>
        <ltr>6</ltr>
        <rt>16</rt>
        <rtr>6</rtr>
        <la>14</la>
        <ra>14</ra>
        <ll>18</ll>
        <rl>18</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Vindicator VND-1X 45t, 4/6/4, Std FE, Std; 9.0T/94% Armor; 15 SHS; 1 ML, 1 PPC, 2 MG, 1 LRM5</info>
        <battleforce pv="10" wt="2" mv="4j" s="2" m="2" l="2" e="0" ov="0" armor="5" internal="4" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="4">RL</location>
            <location index="10">CT</location>
            <location index="11">CT</location>
            <location index="4">LL</location>
        </jumpjets>
        <heatsinks number="15" techbase="2">
            <type>Single Heat Sink</type>

            <location index="3">LT</location>
            <location index="1">LT</location>
            <location index="0">LT</location>
            <location index="2">LT</location>
            <location index="2">RT</location>
            <location index="3">RT</location>
            <location index="0">RT</location>
            <location index="1">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-5</name>
            <type>missile</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-5</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="5">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Machine Gun (1/2)</name>
            <type>ammunition</type>
            <location index="6">LA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Vulcan" model="VL-2T" tons="40" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>642</battle_value>
    <cost>3462100.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2777</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="240" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>8</hd>
        <ct>10</ct>
        <ctr>6</ctr>
        <lt>8</lt>
        <ltr>4</ltr>
        <rt>8</rt>
        <rtr>4</rtr>
        <la>6</la>
        <ra>6</ra>
        <ll>10</ll>
        <rl>10</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Vulcan VL-2T 40t, 6/9/6, Std FE, Std; 5.0T/58% Armor; 10 SHS; 1 ML, 1 Flmr, 1 MG, 1 AC2</info>
        <battleforce pv="6" wt="2" mv="6j" s="2" m="1" l="1" e="0" ov="0" armor="3" internal="3" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="6">
            <type>Standard Jump Jet</type>
            <location index="1">LT</location>
            <location index="10">CT</location>
            <location index="2">RT</location>
            <location index="11">CT</location>
            <location index="1">RT</location>
            <location index="0">LT</location>
        </jumpjets>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="0">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Autocannon/2</name>
            <type>ballistic</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/2</name>
            <type>ammunition</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Machine Gun</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Vulcan" model="VL-5T" tons="40" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>942</battle_value>
    <cost>3557700.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2778</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="240" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>15</ct>
        <ctr>6</ctr>
        <lt>12</lt>
        <ltr>4</ltr>
        <rt>12</rt>
        <rtr>4</rtr>
        <la>11</la>
        <ra>11</ra>
        <ll>14</ll>
        <rl>14</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Vulcan VL-5T 40t, 6/9/6, Std FE, Std; 7.0T/82% Armor; 12 SHS; 4 ML, 1 Flmr, 1 MG</info>
        <battleforce pv="9" wt="2" mv="6j" s="2" m="2" l="0" e="0" ov="0" armor="4" internal="3" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="6">
            <type>Standard Jump Jet</type>
            <location index="1">LT</location>
            <location index="10">CT</location>
            <location index="0">RT</location>
            <location index="11">CT</location>
            <location index="1">RT</location>
            <location index="2">LT</location>
        </jumpjets>
        <heatsinks number="12" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">RL</location>
            <location index="0">LT</location>
            <location index="4">LL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Machine Gun</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Warhammer" model="WHM-6D" tons="70" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1471</battle_value>
    <cost>5945183.333333335</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="StarCorps Industries, Olivetti Weaponry, Vandenberg Mechanized Industries, Taurus Territorial Industries" location="Emris IV (StarCorps), Sudeten (Olivetti), Pinard (VMI), Taurus (TTI)">Inner Sphere</techbase>
    <year restricted="FALSE">2835</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="StarCorps 100" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="280" manufacturer="VOX" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Leviathon Plus" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>34</ct>
        <ctr>10</ctr>
        <lt>22</lt>
        <ltr>8</ltr>
        <rt>22</rt>
        <rtr>8</rtr>
        <la>22</la>
        <ra>22</ra>
        <ll>30</ll>
        <rl>30</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Warhammer WHM-6D 70t, 4/6/0, Std FE, Std; 14.0T/100% Armor; 20 SHS; 2 ML, 2 SL, 2 PPC</info>
        <battleforce pv="15" wt="3" mv="4" s="3" m="3" l="2" e="0" ov="0" armor="7" internal="6" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="20" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">LL</location>
            <location index="10">CT</location>
            <location index="5">LL</location>
            <location index="11">CT</location>
            <location index="0">RT</location>
            <location index="5">RL</location>
            <location index="4">RL</location>
            <location index="0">LT</location>
            <location index="1">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="1">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>O/P 3000 COMSET</commsystem>
        <tandtsystem>O/P 1500 ARB</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Warhammer" model="WHM-6K" tons="70" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1305</battle_value>
    <cost>6040183.333333335</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="StarCorps Industries, Olivetti Weaponry, Vandenberg Mechanized Industries, Taurus Territorial Industries" location="Emris IV (StarCorps), Sudeten (Olivetti), Pinard (VMI), Taurus (TTI)">Inner Sphere</techbase>
    <year restricted="FALSE">2830</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="StarCorps 100" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="280" manufacturer="VOX" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Leviathon Plus" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>22</ct>
        <ctr>9</ctr>
        <lt>17</lt>
        <ltr>8</ltr>
        <rt>17</rt>
        <rtr>8</rtr>
        <la>20</la>
        <ra>20</ra>
        <ll>15</ll>
        <rl>15</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Warhammer WHM-6K 70t, 4/6/0, Std FE, Std; 10.0T/74% Armor; 20 SHS; 2 ML, 2 SL, 2 PPC, 1 SRM6</info>
        <battleforce pv="13" wt="3" mv="4" s="3" m="3" l="2" e="0" ov="1" armor="5" internal="6" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="20" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">LL</location>
            <location index="2">LT</location>
            <location index="5">LL</location>
            <location index="1">LT</location>
            <location index="0">LT</location>
            <location index="5">RL</location>
            <location index="4">RL</location>
            <location index="10">CT</location>
            <location index="11">CT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="Magna">(IS) Small Laser</name>
            <type>energy</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Magna">(IS) Small Laser</name>
            <type>energy</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Martell">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Martell">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Donal">(IS) PPC</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Donal">(IS) PPC</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="4">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>O/P 3000 COMSET</commsystem>
        <tandtsystem>O/P 1500 ARB</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Warhammer" model="WHM-6L" tons="70" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1311</battle_value>
    <cost>6058883.333333335</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="StarCorps Industries, Olivetti Weaponry, Vandenberg Mechanized Industries, Taurus Territorial Industries" location="Emris IV (StarCorps), Sudeten (Olivetti), Pinard (VMI), Taurus (TTI)">Inner Sphere</techbase>
    <year restricted="FALSE">2825</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="StarCorps 100" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="280" manufacturer="VOX" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Leviathon Plus" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>22</ct>
        <ctr>9</ctr>
        <lt>17</lt>
        <ltr>8</ltr>
        <rt>17</rt>
        <rtr>8</rtr>
        <la>20</la>
        <ra>20</ra>
        <ll>15</ll>
        <rl>15</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Warhammer WHM-6L 70t, 4/6/0, Std FE, Std; 10.0T/74% Armor; 18 SHS; 2 Flmr, 2 ML, 2 SL, 2 PPC, 1 SRM6</info>
        <battleforce pv="13" wt="3" mv="4" s="3" m="2" l="1" e="0" ov="2" armor="5" internal="6" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="18" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">LL</location>
            <location index="11">CT</location>
            <location index="5">LL</location>
            <location index="10">CT</location>
            <location index="3">HD</location>
            <location index="5">RL</location>
            <location index="4">RL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="Magna">(IS) Small Laser</name>
            <type>energy</type>
            <location index="1">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Magna">(IS) Small Laser</name>
            <type>energy</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Martell">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Martell">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Donal">(IS) PPC</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Donal">(IS) PPC</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Holly Short Range Missile 6 Pack">(IS) SRM-6</name>
            <type>missile</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="2">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>O/P 3000 COMSET</commsystem>
        <tandtsystem>O/P 1500 ARB</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Warhammer" model="WHM-6R" tons="70" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1299</battle_value>
    <cost>6051383.333333335</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>0</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="StarCorps Industries, Olivetti Weaponry, Vandenberg Mechanized Industries, Taurus Territorial Industries" location="Emris IV (StarCorps), Sudeten (Olivetti), Pinard (VMI), Taurus (TTI)">Inner Sphere</techbase>
    <year restricted="FALSE">2515</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="StarCorps 100" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="280" manufacturer="VOX" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Leviathon Plus" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>22</ct>
        <ctr>9</ctr>
        <lt>17</lt>
        <ltr>8</ltr>
        <rt>17</rt>
        <rtr>8</rtr>
        <la>20</la>
        <ra>20</ra>
        <ll>15</ll>
        <rl>15</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Warhammer WHM-6R 70t, 4/6/0, Std FE, Std; 10.0T/74% Armor; 18 SHS; 2 ML, 2 SL, 2 PPC, 2 MG, 1 SRM6</info>
        <battleforce pv="13" wt="3" mv="4" s="3" m="3" l="2" e="0" ov="1" armor="5" internal="6" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="18" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">RL</location>
            <location index="5">RL</location>
            <location index="5">LL</location>
            <location index="4">LL</location>
            <location index="3">LA</location>
            <location index="3">HD</location>
            <location index="3">RA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="SperryBrowning">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="SperryBrowning">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Magna">(IS) Small Laser</name>
            <type>energy</type>
            <location index="1">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Magna">(IS) Small Laser</name>
            <type>energy</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Martell">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Martell">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Donal">(IS) PPC</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Donal">(IS) PPC</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Machine Gun</name>
            <type>ammunition</type>
            <location index="10">CT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>O/P 3000 COMSET</commsystem>
        <tandtsystem>O/P 1500 ARB</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Wasp" model="WSP-1A" tons="20" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>384</battle_value>
    <cost>1641240.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>0</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Irian Battlemechs Unlimited, Kali-Yama Weapons Industries, Defiance Industries" location="Shiro III (IBMU), Kalidasa (KYWI), Furillo (Defiance)">Inner Sphere</techbase>
    <year restricted="FALSE">2471</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="1A Type 3" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="120" manufacturer="Hermes" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Durallex Light" techbase="2">
        <type>Standard Armor</type>
        <hd>4</hd>
        <ct>6</ct>
        <ctr>4</ctr>
        <lt>6</lt>
        <ltr>2</ltr>
        <rt>6</rt>
        <rtr>2</rtr>
        <la>4</la>
        <ra>4</ra>
        <ll>5</ll>
        <rl>5</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Wasp WSP-1A 20t, 6/9/6, Std FE, Std; 3.0T/70% Armor; 10 SHS; 1 ML, 1 SRM2</info>
        <battleforce pv="4" wt="1" mv="6j" s="1" m="1" l="0" e="0" ov="0" armor="2" internal="2" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="6">
            <type>Standard Jump Jet</type>
            <location index="3">RT</location>
            <location index="4">RL</location>
            <location index="11">CT</location>
            <location index="10">CT</location>
            <location index="4">LL</location>
            <location index="3">LT</location>
        </jumpjets>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="0">LT</location>
            <location index="2">LT</location>
            <location index="1">LT</location>
            <location index="2">RT</location>
            <location index="1">RT</location>
            <location index="0">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="Diverse Optics Type 2">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-2</name>
            <type>missile</type>
            <location index="5">LL</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-2</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Rawlings 52</jumpjet_model>
        <commsystem>Duotech 65</commsystem>
        <tandtsystem>RadCom TXX</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Wasp" model="WSP-1D" tons="20" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>403</battle_value>
    <cost>1638240.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Irian Battlemechs Unlimited, Kali-Yama Weapons Industries, Defiance Industries" location="Shiro III (IBMU), Kalidasa (KYWI), Furillo (Defiance)">Inner Sphere</techbase>
    <year restricted="FALSE">2823</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="1A Type 3" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="120" manufacturer="Hermes" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Durallex Light" techbase="2">
        <type>Standard Armor</type>
        <hd>4</hd>
        <ct>6</ct>
        <ctr>4</ctr>
        <lt>6</lt>
        <ltr>2</ltr>
        <rt>6</rt>
        <rtr>2</rtr>
        <la>4</la>
        <ra>4</ra>
        <ll>5</ll>
        <rl>5</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Wasp WSP-1D 20t, 6/9/6, Std FE, Std; 3.0T/70% Armor; 10 SHS; 1 Flmr, 1 ML, 2 SL</info>
        <battleforce pv="4" wt="1" mv="6j" s="2" m="1" l="0" e="0" ov="0" armor="2" internal="2" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="6">
            <type>Standard Jump Jet</type>
            <location index="5">RT</location>
            <location index="4">RL</location>
            <location index="11">CT</location>
            <location index="10">CT</location>
            <location index="4">LL</location>
            <location index="1">LT</location>
        </jumpjets>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">RT</location>
            <location index="0">RT</location>
            <location index="1">RT</location>
            <location index="3">RT</location>
            <location index="2">RT</location>
            <location index="0">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="Diverse Optics Type 2">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="5">LL</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="3">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Rawlings 52</jumpjet_model>
        <commsystem>Duotech 65</commsystem>
        <tandtsystem>RadCom TXX</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Wasp" model="WSP-1K" tons="20" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>376</battle_value>
    <cost>1615240.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Irian Battlemechs Unlimited, Kali-Yama Weapons Industries, Defiance Industries" location="Shiro III (IBMU), Kalidasa (KYWI), Furillo (Defiance)">Inner Sphere</techbase>
    <year restricted="FALSE">2818</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="1A Type 3" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="120" manufacturer="Hermes" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Durallex Light" techbase="2">
        <type>Standard Armor</type>
        <hd>5</hd>
        <ct>7</ct>
        <ctr>4</ctr>
        <lt>7</lt>
        <ltr>2</ltr>
        <rt>7</rt>
        <rtr>2</rtr>
        <la>5</la>
        <ra>5</ra>
        <ll>6</ll>
        <rl>6</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Wasp WSP-1K 20t, 6/9/6, Std FE, Std; 3.5T/81% Armor; 10 SHS; 1 ML, 1 MG</info>
        <battleforce pv="4" wt="1" mv="6j" s="1" m="1" l="0" e="0" ov="0" armor="2" internal="2" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="6">
            <type>Standard Jump Jet</type>
            <location index="2">RT</location>
            <location index="5">RL</location>
            <location index="11">CT</location>
            <location index="10">CT</location>
            <location index="5">LL</location>
            <location index="2">LT</location>
        </jumpjets>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="0">LT</location>
            <location index="4">RL</location>
            <location index="1">RT</location>
            <location index="4">LL</location>
            <location index="0">RT</location>
            <location index="1">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="Diverse Optics Type 2">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Machine Gun</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Rawlings 52</jumpjet_model>
        <commsystem>Duotech 65</commsystem>
        <tandtsystem>RadCom TXX</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Wasp" model="WSP-1L" tons="20" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>335</battle_value>
    <cost>1651320.0</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Irian Battlemechs Unlimited, Kali-Yama Weapons Industries, Defiance Industries" location="Shiro III (IBMU), Kalidasa (KYWI), Furillo (Defiance)">Inner Sphere</techbase>
    <year restricted="FALSE">2799</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="1A Type 3" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="120" manufacturer="Hermes" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Durallex Light" techbase="2">
        <type>Standard Armor</type>
        <hd>4</hd>
        <ct>6</ct>
        <ctr>4</ctr>
        <lt>6</lt>
        <ltr>2</ltr>
        <rt>6</rt>
        <rtr>2</rtr>
        <la>4</la>
        <ra>4</ra>
        <ll>5</ll>
        <rl>5</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Wasp WSP-1L 20t, 6/9/6, Std FE, Std; 3.0T/70% Armor; 10 SHS; 1 SRM4</info>
        <battleforce pv="3" wt="1" mv="6j" s="1" m="1" l="0" e="0" ov="0" armor="2" internal="2" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="6">
            <type>Standard Jump Jet</type>
            <location index="2">RT</location>
            <location index="5">RL</location>
            <location index="11">CT</location>
            <location index="10">CT</location>
            <location index="5">LL</location>
            <location index="2">LT</location>
        </jumpjets>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="1">LT</location>
            <location index="0">LT</location>
            <location index="1">RT</location>
            <location index="4">RL</location>
            <location index="0">RT</location>
            <location index="4">LL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) SRM-4</name>
            <type>missile</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4</name>
            <type>ammunition</type>
            <location index="3">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Rawlings 52</jumpjet_model>
        <commsystem>Duotech 65</commsystem>
        <tandtsystem>RadCom TXX</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Wasp" model="WSP-1W" tons="20" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>373</battle_value>
    <cost>1633320.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Irian Battlemechs Unlimited, Kali-Yama Weapons Industries, Defiance Industries" location="Shiro III (IBMU), Kalidasa (KYWI), Furillo (Defiance)">Inner Sphere</techbase>
    <year restricted="FALSE">3009</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="1A Type 3" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="120" manufacturer="Hermes" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Durallex Light" techbase="2">
        <type>Standard Armor</type>
        <hd>4</hd>
        <ct>6</ct>
        <ctr>4</ctr>
        <lt>6</lt>
        <ltr>2</ltr>
        <rt>6</rt>
        <rtr>2</rtr>
        <la>4</la>
        <ra>4</ra>
        <ll>5</ll>
        <rl>5</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Wasp WSP-1W 20t, 6/9/6, Std FE, Std; 3.0T/70% Armor; 10 SHS; 6 SL</info>
        <battleforce pv="4" wt="1" mv="6j" s="2" m="0" l="0" e="0" ov="0" armor="2" internal="2" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="6">
            <type>Standard Jump Jet</type>
            <location index="2">RT</location>
            <location index="5">RL</location>
            <location index="11">CT</location>
            <location index="10">CT</location>
            <location index="5">LL</location>
            <location index="2">LT</location>
        </jumpjets>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="1">LT</location>
            <location index="0">LT</location>
            <location index="1">RT</location>
            <location index="4">RL</location>
            <location index="0">RT</location>
            <location index="4">LL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Rawlings 52</jumpjet_model>
        <commsystem>Duotech 65</commsystem>
        <tandtsystem>RadCom TXX</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Whitworth" model="WTH-0" tons="40" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>863</battle_value>
    <cost>2792633.3333333335</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2754</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="160" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>16</ct>
        <ctr>7</ctr>
        <lt>12</lt>
        <ltr>6</ltr>
        <rt>12</rt>
        <rtr>6</rtr>
        <la>12</la>
        <ra>12</ra>
        <ll>18</ll>
        <rl>18</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Whitworth WTH-0 40t, 4/6/4, Std FE, Std; 8.0T/93% Armor; 14 SHS; 2 ML, 1 Flmr, 2 SRM6</info>
        <battleforce pv="9" wt="2" mv="4j" s="3" m="3" l="0" e="0" ov="0" armor="4" internal="3" abilities="SRCH, ES, SEAL, SOA, SRM 1/1/0" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="5">LL</location>
            <location index="4">RL</location>
            <location index="5">RL</location>
            <location index="4">LL</location>
        </jumpjets>
        <heatsinks number="14" techbase="2">
            <type>Single Heat Sink</type>

            <location index="2">LT</location>
            <location index="1">LT</location>
            <location index="0">LT</location>
            <location index="10">CT</location>
            <location index="11">CT</location>
            <location index="0">RT</location>
            <location index="1">RT</location>
            <location index="2">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Whitworth" model="WTH-1" tons="40" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>982</battle_value>
    <cost>2888933.3333333335</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2689</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="160" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>16</ct>
        <ctr>7</ctr>
        <lt>12</lt>
        <ltr>6</ltr>
        <rt>12</rt>
        <rtr>6</rtr>
        <la>12</la>
        <ra>12</ra>
        <ll>18</ll>
        <rl>18</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Whitworth WTH-1 40t, 4/6/4, Std FE, Std; 8.0T/93% Armor; 10 SHS; 3 ML, 2 LRM10</info>
        <battleforce pv="10" wt="2" mv="4j" s="2" m="2" l="1" e="0" ov="1" armor="4" internal="3" abilities="SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="5">LL</location>
            <location index="4">RL</location>
            <location index="5">RL</location>
            <location index="4">LL</location>
        </jumpjets>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="1">RT</location>
            <location index="0">RT</location>
            <location index="0">LT</location>
            <location index="1">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-10</name>
            <type>missile</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-10</name>
            <type>missile</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="4">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Whitworth" model="WTH-1S" tons="40" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>917</battle_value>
    <cost>2838133.3333333335</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2610</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="160" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>16</ct>
        <ctr>7</ctr>
        <lt>12</lt>
        <ltr>6</ltr>
        <rt>12</rt>
        <rtr>6</rtr>
        <la>12</la>
        <ra>12</ra>
        <ll>18</ll>
        <rl>18</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Whitworth WTH-1S 40t, 4/6/4, Std FE, Std; 8.0T/93% Armor; 14 SHS; 3 ML, 2 SRM6</info>
        <battleforce pv="9" wt="2" mv="4j" s="3" m="3" l="0" e="0" ov="0" armor="4" internal="3" abilities="SRCH, ES, SEAL, SOA, SRM 1/1/0" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="5">LL</location>
            <location index="4">RL</location>
            <location index="5">RL</location>
            <location index="4">LL</location>
        </jumpjets>
        <heatsinks number="14" techbase="2">
            <type>Single Heat Sink</type>

            <location index="2">RT</location>
            <location index="0">RT</location>
            <location index="1">RT</location>
            <location index="1">LT</location>
            <location index="2">LT</location>
            <location index="0">LT</location>
            <location index="11">CT</location>
            <location index="10">CT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Wolfhound" model="WLF-1" tons="35" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>949</battle_value>
    <cost>2925180.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3028</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="210" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>16</ct>
        <ctr>6</ctr>
        <lt>11</lt>
        <ltr>5</ltr>
        <rt>11</rt>
        <rtr>5</rtr>
        <la>12</la>
        <ra>12</ra>
        <ll>16</ll>
        <rl>16</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Wolfhound WLF-1 35t, 6/9/0, Std FE, Std; 7.5T/100% Armor; 10 SHS; 4 ML, 1 LL</info>
        <battleforce pv="9" wt="1" mv="6" s="2" m="2" l="0" e="0" ov="1" armor="4" internal="3" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">LL</location>
            <location index="4">RL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Wolfhound" model="WLF-1A" tons="35" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>967</battle_value>
    <cost>2873880.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3028</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="210" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>16</ct>
        <ctr>6</ctr>
        <lt>11</lt>
        <ltr>5</ltr>
        <rt>11</rt>
        <rtr>5</rtr>
        <la>12</la>
        <ra>12</ra>
        <ll>16</ll>
        <rl>16</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Wolfhound WLF-1A 35t, 6/9/0, Std FE, Std; 7.5T/100% Armor; 11 SHS; 3 ML, 1 LL</info>
        <battleforce pv="10" wt="1" mv="6" s="2" m="2" l="0" e="0" ov="1" armor="4" internal="3" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="11" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="10">CT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Wolfhound" model="WLF-1B" tons="35" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>967</battle_value>
    <cost>2925180.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3028</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="210" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>16</ct>
        <ctr>6</ctr>
        <lt>11</lt>
        <ltr>5</ltr>
        <rt>11</rt>
        <rtr>5</rtr>
        <la>12</la>
        <ra>12</ra>
        <ll>16</ll>
        <rl>16</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Wolfhound WLF-1B 35t, 6/9/0, Std FE, Std; 7.5T/100% Armor; 10 SHS; 4 ML, 1 LL</info>
        <battleforce pv="10" wt="1" mv="6" s="2" m="2" l="0" e="0" ov="1" armor="4" internal="3" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">LL</location>
            <location index="4">RL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Wolverine" model="WVR-6K" tons="55" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1248</battle_value>
    <cost>4484494.166666667</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Kallon Industries, Victory Industries, Free Worlds Defense Industries" location="Nanking, Thermopolis (Kallon), Marduk (Victory), Gibson (FWDI)">Inner Sphere</techbase>
    <year restricted="FALSE">2598</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Crucis-A" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="275" manufacturer="CoreTek" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Maximillian 60" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>25</ct>
        <ctr>10</ctr>
        <lt>20</lt>
        <ltr>6</ltr>
        <rt>20</rt>
        <rtr>6</rtr>
        <la>18</la>
        <ra>18</ra>
        <ll>26</ll>
        <rl>26</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Wolverine WVR-6K 55t, 5/8/0, Std FE, Std; 11.5T/99% Armor; 14 SHS; 2 ML, 1 SL, 1 SRM6, 1 LL</info>
        <battleforce pv="12" wt="2" mv="5" s="3" m="3" l="0" e="0" ov="0" armor="6" internal="5" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="14" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="10">CT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="Magna Mk II">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="1">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="6">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="7">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="0">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Garret T11-b</commsystem>
        <tandtsystem>Sync Tracker (39-42071)</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Wolverine" model="WVR-6M" tons="55" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1291</battle_value>
    <cost>4850806.666666667</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Kallon Industries, Victory Industries, Free Worlds Defense Industries" location="Nanking, Thermopolis (Kallon), Marduk (Victory), Gibson (FWDI)">Inner Sphere</techbase>
    <year restricted="FALSE">2816</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Crucis-A" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="275" manufacturer="CoreTek" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Maximillian 60" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>25</ct>
        <ctr>8</ctr>
        <lt>20</lt>
        <ltr>6</ltr>
        <rt>20</rt>
        <rtr>6</rtr>
        <la>18</la>
        <ra>18</ra>
        <ll>19</ll>
        <rl>19</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Wolverine WVR-6M 55t, 5/8/5, Std FE, Std; 10.5T/91% Armor; 14 SHS; 2 ML, 1 SRM6, 1 LL</info>
        <battleforce pv="13" wt="2" mv="5j" s="2" m="2" l="0" e="0" ov="1" armor="6" internal="5" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="5">
            <type>Standard Jump Jet</type>
            <location index="2">RT</location>
            <location index="0">LT</location>
            <location index="1">RT</location>
            <location index="1">LT</location>
            <location index="10">CT</location>
        </jumpjets>
        <heatsinks number="14" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">RL</location>
            <location index="0">RT</location>
            <location index="4">LL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="6">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Northrup 12000</jumpjet_model>
        <commsystem>Garret T11-b</commsystem>
        <tandtsystem>Sync Tracker (39-42071)</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Wolverine" model="WVR-6R" tons="55" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1101</battle_value>
    <cost>4810356.666666667</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Kallon Industries, Victory Industries, Free Worlds Defense Industries" location="Nanking, Thermopolis (Kallon), Marduk (Victory), Gibson (FWDI)">Inner Sphere</techbase>
    <year restricted="FALSE">2575</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Crucis-A" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="275" manufacturer="CoreTek" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Maximillian 60" techbase="2">
        <type>Standard Armor</type>
        <hd>8</hd>
        <ct>20</ct>
        <ctr>8</ctr>
        <lt>20</lt>
        <ltr>6</ltr>
        <rt>20</rt>
        <rtr>6</rtr>
        <la>16</la>
        <ra>16</ra>
        <ll>16</ll>
        <rl>16</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Wolverine WVR-6R 55t, 5/8/5, Std FE, Std; 9.5T/82% Armor; 12 SHS; 1 ML, 1 SRM6, 1 AC5</info>
        <battleforce pv="11" wt="2" mv="5j" s="2" m="2" l="1" e="0" ov="0" armor="5" internal="5" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="5">
            <type>Standard Jump Jet</type>
            <location index="4">RL</location>
            <location index="4">LL</location>
            <location index="5">RL</location>
            <location index="10">CT</location>
            <location index="5">LL</location>
        </jumpjets>
        <heatsinks number="12" techbase="2">
            <type>Single Heat Sink</type>

            <location index="0">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="Whirlwind">(IS) Autocannon/5</name>
            <type>ballistic</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Magna Mk II">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/5</name>
            <type>ammunition</type>
            <location index="8">RA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Northrup 12000</jumpjet_model>
        <commsystem>Garret T11-b</commsystem>
        <tandtsystem>Sync Tracker (39-42071)</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Wyvern" model="WVE-6N" tons="45" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1005</battle_value>
    <cost>3211765.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Maltex Corporation" location="Errai">Inner Sphere</techbase>
    <year restricted="FALSE">2821</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Ost" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="180" manufacturer="GM" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Kilosh 1000" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>18</ct>
        <ctr>7</ctr>
        <lt>14</lt>
        <ltr>5</ltr>
        <rt>14</rt>
        <rtr>5</rtr>
        <la>12</la>
        <ra>12</ra>
        <ll>20</ll>
        <rl>20</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Wyvern WVE-6N 45t, 4/6/4, Std FE, Std; 8.5T/89% Armor; 12 SHS; 2 SL, 1 SRM6, 1 LRM10, 1 LL</info>
        <battleforce pv="10" wt="2" mv="4j" s="2" m="2" l="1" e="0" ov="1" armor="5" internal="4" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="2">RT</location>
            <location index="2">LT</location>
            <location index="4">RL</location>
            <location index="4">LL</location>
        </jumpjets>
        <heatsinks number="12" techbase="2">
            <type>Single Heat Sink</type>

            <location index="0">LT</location>
            <location index="3">HD</location>
            <location index="1">LT</location>
            <location index="0">RT</location>
            <location index="1">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="Nightwind">(IS) Large Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Starflash">(IS) Small Laser</name>
            <type>energy</type>
            <location index="6">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Starflash">(IS) Small Laser</name>
            <type>energy</type>
            <location index="7">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Jackson Dart-10">(IS) LRM-10</name>
            <type>missile</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Totschlagen-6">(IS) SRM-6</name>
            <type>missile</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Northrup 750</jumpjet_model>
        <commsystem>Ostmann AMB</commsystem>
        <tandtsystem>Scrambler-7 Series</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Zeus" model="ZEU-5S" tons="80" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1474</battle_value>
    <cost>8254800.000000001</cost>
    <rules_level>1</rules_level>
    <era>0</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2788</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="320" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>26</ct>
        <ctr>9</ctr>
        <lt>18</lt>
        <ltr>6</ltr>
        <rt>18</rt>
        <rtr>6</rtr>
        <la>22</la>
        <ra>22</ra>
        <ll>24</ll>
        <rl>24</rl>
    </armor>
    <baseloadout fcsa4="TRUE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Zeus ZEU-5S 80t, 4/6/0, Std FE, Std; 11.5T/74% Armor; 14 DHS; 2 ML, 1 LRM15, 1 ERLL, 1 UAC5</info>
        <battleforce pv="15" wt="4" mv="4" s="3" m="3" l="3" e="0" ov="0" armor="6" internal="6" abilities="SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="14" techbase="0">
            <type>Double Heat Sink</type>

            <location index="0">RT</location>
            <location index="0">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Laser</name>
            <type>energy</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Large Laser</name>
            <type>energy</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Ultra AC/5</name>
            <type>ballistic</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Ultra AC/5</name>
            <type>ammunition</type>
            <location index="9">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Ultra AC/5</name>
            <type>ammunition</type>
            <location index="8">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="3">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Zeus" model="ZEU-5T" tons="80" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1729</battle_value>
    <cost>8692200.0</cost>
    <rules_level>1</rules_level>
    <era>0</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2787</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="320" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>36</ct>
        <ctr>11</ctr>
        <lt>24</lt>
        <ltr>7</ltr>
        <rt>24</rt>
        <rtr>7</rtr>
        <la>23</la>
        <ra>23</ra>
        <ll>30</ll>
        <rl>30</rl>
        <location index="3">HD</location>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="6">LA</location>
        <location index="8">RA</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="11">CT</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
    </armor>
    <baseloadout fcsa4="TRUE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Zeus ZEU-5T 80t, 4/6/0, Std FE, Std; 12.5T/91% FF Armor; 17 DHS; 2 ML, 1 LRM15, 1 ERLL, 1 ERPPC</info>
        <battleforce pv="17" wt="4" mv="4" s="3" m="4" l="3" e="0" ov="0" armor="7" internal="6" abilities="SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="17" techbase="0">
            <type>Double Heat Sink</type>

            <location index="6">RT</location>
            <location index="0">LT</location>
            <location index="3">RT</location>
            <location index="3">LT</location>
            <location index="0">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Laser</name>
            <type>energy</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Large Laser</name>
            <type>energy</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="7">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER PPC</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Zeus" model="ZEU-6S" tons="80" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1348</battle_value>
    <cost>7590300.000000001</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2898</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="320" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>26</ct>
        <ctr>9</ctr>
        <lt>18</lt>
        <ltr>6</ltr>
        <rt>18</rt>
        <rtr>6</rtr>
        <la>22</la>
        <ra>22</ra>
        <ll>24</ll>
        <rl>24</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Zeus ZEU-6S 80t, 4/6/0, Std FE, Std; 11.5T/74% Armor; 17 SHS; 2 ML, 1 LRM15, 1 AC5, 1 LL</info>
        <battleforce pv="13" wt="4" mv="4" s="3" m="3" l="2" e="0" ov="0" armor="6" internal="6" abilities="SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="17" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="5">RL</location>
            <location index="5">LL</location>
            <location index="10">CT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Autocannon/5</name>
            <type>ballistic</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/5</name>
            <type>ammunition</type>
            <location index="7">LA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Zeus" model="ZEU-6T" tons="80" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1436</battle_value>
    <cost>7728000.000000001</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3026</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="320" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>26</ct>
        <ctr>9</ctr>
        <lt>18</lt>
        <ltr>6</ltr>
        <rt>18</rt>
        <rtr>6</rtr>
        <la>22</la>
        <ra>22</ra>
        <ll>24</ll>
        <rl>24</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3039u</source>
        <info>Zeus ZEU-6T 80t, 4/6/0, Std FE, Std; 11.5T/74% Armor; 19 SHS; 2 ML, 1 LRM15, 1 PPC, 1 LL</info>
        <battleforce pv="14" wt="4" mv="4" s="2" m="3" l="2" e="0" ov="0" armor="6" internal="6" abilities="SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="19" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="5">RL</location>
            <location index="5">LL</location>
            <location index="10">CT</location>
            <location index="0">RT</location>
            <location index="1">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Annihilator" model="ANH-1A" tons="100" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1434</battle_value>
    <cost>7672666.666666666</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3009</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="200" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>25</ct>
        <ctr>10</ctr>
        <lt>21</lt>
        <ltr>9</ltr>
        <rt>21</rt>
        <rtr>9</rtr>
        <la>24</la>
        <ra>24</ra>
        <ll>24</ll>
        <rl>24</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Annihilator ANH-1A 100t, 2/3/0, Std FE, Std; 12.5T/65% Armor; 18 SHS; 4 ML, 4 AC10</info>
        <battleforce pv="14" wt="4" mv="2" s="5" m="5" l="0" e="0" ov="0" armor="7" internal="8" abilities="SRCH, ES, SEAL, SOA, AC 3/3/0" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="18" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">RL</location>
            <location index="5">RL</location>
            <location index="4">LL</location>
            <location index="5">LL</location>
            <location index="1">LT</location>
            <location index="0">LT</location>
            <location index="2">LT</location>
            <location index="0">RT</location>
            <location index="2">RT</location>
            <location index="1">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="10">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="10">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Autocannon/10</name>
            <type>ballistic</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Autocannon/10</name>
            <type>ballistic</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Autocannon/10</name>
            <type>ballistic</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Autocannon/10</name>
            <type>ballistic</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/10</name>
            <type>ammunition</type>
            <location index="10">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/10</name>
            <type>ammunition</type>
            <location index="11">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/10</name>
            <type>ammunition</type>
            <location index="10">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/10</name>
            <type>ammunition</type>
            <location index="11">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Annihilator" model="ANH-2A" tons="100" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="K:\\CBT\\Record Sheets\\Mech Images\\Annihilator.gif">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1595</battle_value>
    <cost>9668666.666666666</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3048</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="200" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>25</ct>
        <ctr>10</ctr>
        <lt>21</lt>
        <ltr>9</ltr>
        <rt>21</rt>
        <rtr>9</rtr>
        <la>24</la>
        <ra>24</ra>
        <ll>24</ll>
        <rl>24</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Annihilator ANH-2A 100t, 2/3/0, Std FE, Std; 12.5T/65% Armor; 17 SHS; 4 MPL, 4 LB10</info>
        <battleforce pv="16" wt="4" mv="2" s="4" m="4" l="2" e="0" ov="2" armor="7" internal="8" abilities="CASE, SRCH, ES, SEAL, SOA, FLK 2/2/2" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="17" techbase="2">
            <type>Single Heat Sink</type>

            <location index="0">LT</location>
            <location index="1">LT</location>
            <location index="5">LL</location>
            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="3">HD</location>
            <location index="0">RT</location>
            <location index="1">RT</location>
            <location index="5">RL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="9">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="9">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LB 10-X AC</name>
            <type>ballistic</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LB 10-X AC</name>
            <type>ballistic</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LB 10-X AC</name>
            <type>ballistic</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LB 10-X AC</name>
            <type>ballistic</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Cluster)</name>
            <type>ammunition</type>
            <location index="9">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Cluster)</name>
            <type>ammunition</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Slug)</name>
            <type>ammunition</type>
            <location index="8">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Slug)</name>
            <type>ammunition</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="10">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="10">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Annihilator" model="ANH-3A" tons="100" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="K:\\CBT\\Record Sheets\\Mech Images\\Annihilator.gif">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1712</battle_value>
    <cost>1.2537333333333332E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>6</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3068</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="200" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="0">Compact Fusion Engine</engine>
    <gyro techbase="0">Compact Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Light Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>46</ct>
        <ctr>16</ctr>
        <lt>32</lt>
        <ltr>10</ltr>
        <rt>32</rt>
        <rtr>10</rtr>
        <la>34</la>
        <ra>34</ra>
        <ll>42</ll>
        <rl>42</rl>
        <location index="3">HD</location>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="9">LT</location>
        <location index="10">LT</location>
        <location index="11">CT</location>
        <location index="11">LT</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Annihilator ANH-3A 100t, 2/3/0, CFE, Std, Cmp Gyro; 18.5T/100% LFF Armor; 12 DHS; 8 LAC2, 4 ERML, TC</info>
        <battleforce pv="17" wt="4" mv="2" s="4" m="4" l="2" e="0" ov="0" armor="10" internal="10" abilities="CASE, SRCH, ES, SEAL, SOA, AC 2/2/2" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="12" techbase="0">
            <type>Double Heat Sink</type>

            <location index="8">CT</location>
            <location index="5">CT</location>
            <location index="3">LA</location>
            <location index="3">RA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="6">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="6">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Light AC/2</name>
            <type>ballistic</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Light AC/2</name>
            <type>ballistic</type>
            <location index="8">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Light AC/2</name>
            <type>ballistic</type>
            <location index="8">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Light AC/2</name>
            <type>ballistic</type>
            <location index="1">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Light AC/2</name>
            <type>ballistic</type>
            <location index="1">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Light AC/2</name>
            <type>ballistic</type>
            <location index="7">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Light AC/2</name>
            <type>ballistic</type>
            <location index="7">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Light AC/2</name>
            <type>ballistic</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Light AC/2</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Light AC/2</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Light AC/2</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Light AC/2</name>
            <type>ammunition</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Light AC/2</name>
            <type>ammunition</type>
            <location index="7">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Targeting Computer</name>
            <type>TargetingComputer</type>
            <location index="3">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Annihilator" model="ANH-4A" tons="100" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="K:\\CBT\\Record Sheets\\Mech Images\\Annihilator.gif">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1726</battle_value>
    <cost>1.0162666666666666E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>6</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3069</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="200" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="0">Compact Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>32</ct>
        <ctr>11</ctr>
        <lt>24</lt>
        <ltr>8</ltr>
        <rt>24</rt>
        <rtr>8</rtr>
        <la>26</la>
        <ra>26</ra>
        <ll>32</ll>
        <rl>32</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Annihilator ANH-4A 100t, 2/3/0, Std FE, Std, Cmp Gyro; 14.5T/76% Armor; 15 DHS; 2 LAC2, 2 HPPC, 2 LB10</info>
        <battleforce pv="17" wt="4" mv="2" s="4" m="5" l="5" e="0" ov="0" armor="8" internal="8" abilities="SRCH, ES, SEAL, SOA, FLK 1/1/1" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="15" techbase="0">
            <type>Double Heat Sink</type>

            <location index="2">LA</location>
            <location index="0">RT</location>
            <location index="2">RA</location>
            <location index="5">LA</location>
            <location index="5">RA</location>
            <location index="0">LT</location>
            <location index="8">CT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Heavy PPC</name>
            <type>energy</type>
            <location index="8">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Heavy PPC</name>
            <type>energy</type>
            <location index="8">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LB 10-X AC</name>
            <type>ballistic</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LB 10-X AC</name>
            <type>ballistic</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Light AC/2</name>
            <type>ballistic</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Light AC/2</name>
            <type>ballistic</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Slug)</name>
            <type>ammunition</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Slug)</name>
            <type>ammunition</type>
            <location index="9">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Cluster)</name>
            <type>ammunition</type>
            <location index="10">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Cluster)</name>
            <type>ammunition</type>
            <location index="10">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Light AC/2</name>
            <type>ammunition</type>
            <location index="11">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Light AC/2</name>
            <type>ammunition</type>
            <location index="11">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Annihilator" model="C 2" tons="100" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="K:\\CBT\\Record Sheets\\Mech Images\\Annihilator.gif">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>2722</battle_value>
    <cost>9741166.666666666</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Clan</techbase>
    <year restricted="FALSE">2850</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="1">
        <type>Endo-Steel</type>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="10">LT</location>
        <location index="11">LA</location>
        <location index="11">RA</location>
    </structure>
    <engine rating="200" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>41</ct>
        <ctr>14</ctr>
        <lt>26</lt>
        <ltr>7</ltr>
        <rt>26</rt>
        <rtr>7</rtr>
        <la>28</la>
        <ra>28</ra>
        <ll>35</ll>
        <rl>35</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Annihilator C 2 100t, 2/3/0, Std FE, ES; 16.0T/83% Armor; 11 DHS; 4 GR, 1 ERSL, 1 ERPPC</info>
        <battleforce pv="27" wt="4" mv="2" s="7" m="8" l="8" e="0" ov="0" armor="9" internal="8" abilities="CASE, SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>TRUE</clancase>
        <heatsinks number="11" techbase="1">
            <type>Double Heat Sink</type>

            <location index="0">RT</location>
            <location index="0">LT</location>
            <location index="2">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(CL) Gauss Rifle</name>
            <type>ballistic</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) Gauss Rifle</name>
            <type>ballistic</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) Gauss Rifle</name>
            <type>ballistic</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) Gauss Rifle</name>
            <type>ballistic</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) ER PPC</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) ER Small Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="9">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="8">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="10">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="10">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="9">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="8">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="11">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="10">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional>&lt;p style=&quot;margin-top: 0&quot;&gt;:br::br:&lt;/p&gt;</additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Annihilator" model="C" tons="100" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="K:\\CBT\\Record Sheets\\Mech Images\\Annihilator.gif">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>2520</battle_value>
    <cost>1.158E7</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Clan</techbase>
    <year restricted="FALSE">2848</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="1">
        <type>Endo-Steel</type>
        <location index="3">HD</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
    </structure>
    <engine rating="300" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>41</ct>
        <ctr>14</ctr>
        <lt>26</lt>
        <ltr>7</ltr>
        <rt>26</rt>
        <rtr>7</rtr>
        <la>28</la>
        <ra>28</ra>
        <ll>35</ll>
        <rl>35</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Annihilator C 100t, 3/5/0, Std FE, ES; 16.0T/83% Armor; 12 DHS; 4 UAC/10, 4 ERML</info>
        <battleforce pv="25" wt="4" mv="3" s="6" m="6" l="4" e="0" ov="3" armor="9" internal="8" abilities="CASE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>TRUE</clancase>
        <heatsinks number="12" techbase="1">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(CL) Ultra AC/10</name>
            <type>ballistic</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) Ultra AC/10</name>
            <type>ballistic</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) Ultra AC/10</name>
            <type>ballistic</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) Ultra AC/10</name>
            <type>ballistic</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) ER Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) ER Medium Laser</name>
            <type>energy</type>
            <location index="7">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) ER Medium Laser</name>
            <type>energy</type>
            <location index="7">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) ER Medium Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(CL) @ Ultra AC/10</name>
            <type>ammunition</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(CL) @ Ultra AC/10</name>
            <type>ammunition</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(CL) @ Ultra AC/10</name>
            <type>ammunition</type>
            <location index="7">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(CL) @ Ultra AC/10</name>
            <type>ammunition</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(CL) @ Ultra AC/10</name>
            <type>ammunition</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(CL) @ Ultra AC/10</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(CL) @ Ultra AC/10</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(CL) @ Ultra AC/10</name>
            <type>ammunition</type>
            <location index="7">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional>&lt;p style=&quot;margin-top: 0&quot;&gt;:br::br:&lt;/p&gt;:br:</additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Assassin" model="ASN-23" tons="40" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="K:\\CBT\\Record Sheets\\Mech Images\\Assassin.gif">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>740</battle_value>
    <cost>3900013.333333333</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3050</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="280" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>8</hd>
        <ct>12</ct>
        <ctr>4</ctr>
        <lt>10</lt>
        <ltr>2</ltr>
        <rt>10</rt>
        <rtr>2</rtr>
        <la>6</la>
        <ra>6</ra>
        <ll>6</ll>
        <rl>6</rl>
    </armor>
    <baseloadout fcsa4="TRUE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Assassin ASN-23 40t, 7/11/7, Std FE, Std; 4.5T/53% Armor; 10 SHS; 1 MPL, 1 LRM5</info>
        <battleforce pv="7" wt="2" mv="7j" s="1" m="2" l="1" e="0" ov="0" armor="2" internal="3" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="7">
            <type>Standard Jump Jet</type>
            <location index="10">CT</location>
            <location index="1">LT</location>
            <location index="0">RT</location>
            <location index="2">RT</location>
            <location index="1">RT</location>
            <location index="0">LT</location>
            <location index="2">LT</location>
        </jumpjets>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) LRM-5</name>
            <type>missile</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-5 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="5">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Assassin" model="ASN-30" tons="40" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="K:\\CBT\\Record Sheets\\Mech Images\\Assassin.gif">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>925</battle_value>
    <cost>6230880.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3061</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="4">LA</location>
        <location index="4">RA</location>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">LT</location>
        <location index="5">LA</location>
        <location index="5">RA</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="6">LT</location>
        <location index="6">LA</location>
        <location index="6">RA</location>
        <location index="11">CT</location>
        <location index="11">RT</location>
    </structure>
    <engine rating="280" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">Light Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>8</hd>
        <ct>14</ct>
        <ctr>4</ctr>
        <lt>11</lt>
        <ltr>4</ltr>
        <rt>11</rt>
        <rtr>4</rtr>
        <la>9</la>
        <ra>9</ra>
        <ll>15</ll>
        <rl>15</rl>
    </armor>
    <baseloadout fcsa4="TRUE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Assassin ASN-30 40t, 7/11/7, LFE, ES; 6.5T/76% Armor; 10 SHS; 1 ERML, 1 LB5</info>
        <battleforce pv="9" wt="2" mv="7j" s="1" m="1" l="1" e="0" ov="0" armor="3" internal="2" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="7">
            <type>Standard Jump Jet</type>
            <location index="10">CT</location>
            <location index="4">LT</location>
            <location index="3">RT</location>
            <location index="2">RT</location>
            <location index="4">RT</location>
            <location index="3">LT</location>
            <location index="2">LT</location>
        </jumpjets>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LB 5-X AC</name>
            <type>ballistic</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 5-X AC (Slug)</name>
            <type>ammunition</type>
            <location index="10">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional>&lt;p style=&quot;margin-top: 0&quot;&gt;:br::br:&lt;/p&gt;:br:</additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Assassin" model="ASN-99" tons="40" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="K:\\CBT\\Record Sheets\\Mech Images\\Assassin.gif">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1116</battle_value>
    <cost>7934013.333333332</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>6</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3068</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="280" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Stealth Armor</type>
        <hd>9</hd>
        <ct>16</ct>
        <ctr>5</ctr>
        <lt>14</lt>
        <ltr>5</ltr>
        <rt>14</rt>
        <rtr>5</rtr>
        <la>11</la>
        <ra>11</ra>
        <ll>19</ll>
        <rl>19</rl>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">RA</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="6">RA</location>
        <location index="7">RT</location>
        <location index="7">LA</location>
        <location index="8">RT</location>
        <location index="8">LA</location>
        <location index="10">LT</location>
        <location index="11">LT</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Assassin ASN-99 40t, 7/11/7, XLFE, Std; 8.0T/93% Stlth Armor; 10 DHS; 1 ECM, 1 LPPC, 1 Swrd, 1 TAG, 2 SRM2</info>
        <battleforce pv="11" wt="2" mv="7j" s="1" m="1" l="1" e="0" ov="0" armor="4" internal="2" abilities="ECM, STL, TAG, MEL, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="7">
            <type>Standard Jump Jet</type>
            <location index="10">CT</location>
            <location index="5">RT</location>
            <location index="4">LT</location>
            <location index="3">RT</location>
            <location index="3">LT</location>
            <location index="4">RT</location>
            <location index="5">LT</location>
        </jumpjets>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Light PPC</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-2</name>
            <type>missile</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-2</name>
            <type>missile</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">TAG</name>
            <type>equipment</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">Guardian ECM Suite</name>
            <type>equipment</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">Sword</name>
            <type>physical</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-2</name>
            <type>ammunition</type>
            <location index="7">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional>&lt;p style=&quot;margin-top: 0&quot;&gt;:br::br:&lt;/p&gt;:br:</additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Assassin" model="&quot;Servitor&quot;" tons="40" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="K:\\CBT\\Record Sheets\\Mech Images\\Assassin.gif">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1019</battle_value>
    <cost>7364563.333333332</cost>
    <rules_level>3</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3066</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="280" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>16</ct>
        <ctr>5</ctr>
        <lt>14</lt>
        <ltr>5</ltr>
        <rt>14</rt>
        <rtr>5</rtr>
        <la>11</la>
        <ra>11</ra>
        <ll>19</ll>
        <rl>19</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Assassin "Servitor" 40t, 7/11/7, XLFE, Std; 8.0T/93% Armor; 10 DHS; 1 SmlVbrBld, 1 SL, 1 MXPL, 2 SXPL, 2 SRM2</info>
        <battleforce pv="10" wt="2" mv="7j" s="2" m="2" l="0" e="0" ov="0" armor="4" internal="2" abilities="MEL, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="7">
            <type>Standard Jump Jet</type>
            <location index="10">CT</location>
            <location index="5">RT</location>
            <location index="4">LT</location>
            <location index="3">RT</location>
            <location index="3">LT</location>
            <location index="4">RT</location>
            <location index="5">LT</location>
        </jumpjets>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) SRM-2</name>
            <type>missile</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-2</name>
            <type>missile</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-2</name>
            <type>ammunition</type>
            <location index="7">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small X-Pulse Laser</name>
            <type>energy</type>
            <location index="4">LL</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small X-Pulse Laser</name>
            <type>energy</type>
            <location index="4">RL</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium X-Pulse Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">Small Vibroblade</name>
            <type>physical</type>
            <location index="4">LA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional>&lt;p style=&quot;margin-top: 0&quot;&gt;:br::br:&lt;/p&gt;:br:</additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Atlas" model="AS7-C" tons="100" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="K:\\CBT\\Record Sheets\\Mech Images\\Atlas.gif">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>2163</battle_value>
    <cost>2.2858E7</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3050</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="300" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>47</ct>
        <ctr>14</ctr>
        <lt>32</lt>
        <ltr>10</ltr>
        <rt>32</rt>
        <rtr>10</rtr>
        <la>34</la>
        <ra>34</ra>
        <ll>41</ll>
        <rl>41</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Atlas AS7-C 100t, 3/5/0, XLFE, Std; 19.0T/99% Armor; 21 SHS; 2 ERLL, 1 C3S, 1 GR, 1 MPL, 1 AMS, 1 LRM20</info>
        <battleforce pv="22" wt="4" mv="3" s="3" m="4" l="4" e="0" ov="1" armor="10" internal="4" abilities="AMS, C3S, CASE, MHQ1, SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="21" techbase="2">
            <type>Single Heat Sink</type>

            <location index="5">LA</location>
            <location index="4">LL</location>
            <location index="5">LL</location>
            <location index="4">LA</location>
            <location index="5">RL</location>
            <location index="4">RL</location>
            <location index="10">CT</location>
            <location index="4">RA</location>
            <location index="5">RA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) ER Large Laser</name>
            <type>energy</type>
            <location index="6">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Large Laser</name>
            <type>energy</type>
            <location index="6">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Gauss Rifle</name>
            <type>ballistic</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-20</name>
            <type>missile</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Anti-Missile System</name>
            <type>equipment</type>
            <location index="8">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20</name>
            <type>ammunition</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20</name>
            <type>ammunition</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="8">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="9">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Anti-Missile System</name>
            <type>ammunition</type>
            <location index="10">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">C3 Computer (Slave)</name>
            <type>equipment</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="11">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="10">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Atlas" model="AS7-CM" tons="100" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="K:\\CBT\\Record Sheets\\Mech Images\\Atlas.gif">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>2036</battle_value>
    <cost>2.5074E7</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3050</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="300" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>47</ct>
        <ctr>14</ctr>
        <lt>32</lt>
        <ltr>10</ltr>
        <rt>32</rt>
        <rtr>10</rtr>
        <la>34</la>
        <ra>34</ra>
        <ll>41</ll>
        <rl>41</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Atlas AS7-CM 100t, 3/5/0, XLFE, Std; 19.0T/99% Armor; 20 SHS; 1 ERLL, 1 GR, 2 MPL, 1 AMS, 1 LRM20, 1 C3M</info>
        <battleforce pv="20" wt="4" mv="3" s="3" m="4" l="4" e="0" ov="0" armor="10" internal="4" abilities="AMS, C3M, TAG, CASE, MHQ5, SRCH, ES, SEAL, SOA, LRM 1/1/1, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="20" techbase="2">
            <type>Single Heat Sink</type>

            <location index="5">LA</location>
            <location index="4">LL</location>
            <location index="5">LL</location>
            <location index="4">LA</location>
            <location index="5">RL</location>
            <location index="4">RL</location>
            <location index="5">RA</location>
            <location index="4">RA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) ER Large Laser</name>
            <type>energy</type>
            <location index="6">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Gauss Rifle</name>
            <type>ballistic</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-20</name>
            <type>missile</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Anti-Missile System</name>
            <type>equipment</type>
            <location index="6">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20</name>
            <type>ammunition</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20</name>
            <type>ammunition</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="8">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="9">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Anti-Missile System</name>
            <type>ammunition</type>
            <location index="10">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">C3 Computer (Master)</name>
            <type>equipment</type>
            <location index="7">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="11">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="10">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Atlas" model="AS7-K-DC" tons="100" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="K:\\CBT\\Record Sheets\\Mech Images\\Atlas.gif">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>2158</battle_value>
    <cost>2.3324E7</cost>
    <rules_level>2</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3050</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="300" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="TRUE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>47</ct>
        <ctr>14</ctr>
        <lt>32</lt>
        <ltr>10</ltr>
        <rt>32</rt>
        <rtr>10</rtr>
        <la>34</la>
        <ra>34</ra>
        <ll>41</ll>
        <rl>41</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Atlas AS7-K-DC 100t, 3/5/0, XLFE, Std; 19.0T/99% Armor; 20 SHS; 2 ERLL, 1 GR, 1 MPL, 1 AMS, 1 LRM20</info>
        <battleforce pv="22" wt="4" mv="3" s="3" m="3" l="3" e="0" ov="1" armor="10" internal="4" abilities="AMS, CASE, SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="20" techbase="2">
            <type>Single Heat Sink</type>

            <location index="5">LA</location>
            <location index="4">LA</location>
            <location index="4">LL</location>
            <location index="5">LL</location>
            <location index="4">RL</location>
            <location index="5">RL</location>
            <location index="4">RA</location>
            <location index="5">RA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) LRM-20</name>
            <type>missile</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20</name>
            <type>ammunition</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Large Laser</name>
            <type>energy</type>
            <location index="6">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Large Laser</name>
            <type>energy</type>
            <location index="6">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Anti-Missile System</name>
            <type>equipment</type>
            <location index="8">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Gauss Rifle</name>
            <type>ballistic</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Anti-Missile System</name>
            <type>ammunition</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="9">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="8">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="10">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="10">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Atlas" model="AS7-K" tons="100" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="K:\\CBT\\Record Sheets\\Mech Images\\Atlas.gif">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>2175</battle_value>
    <cost>2.2474E7</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3049</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="300" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>47</ct>
        <ctr>14</ctr>
        <lt>32</lt>
        <ltr>10</ltr>
        <rt>32</rt>
        <rtr>10</rtr>
        <la>34</la>
        <ra>34</ra>
        <ll>41</ll>
        <rl>41</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Atlas AS7-K 100t, 3/5/0, XLFE, Std; 19.0T/99% Armor; 20 SHS; 2 ERLL, 1 GR, 2 MPL, 1 AMS, 1 LRM20</info>
        <battleforce pv="22" wt="4" mv="3" s="3" m="3" l="3" e="0" ov="2" armor="10" internal="4" abilities="AMS, CASE, SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="20" techbase="2">
            <type>Single Heat Sink</type>

            <location index="5">LA</location>
            <location index="4">LA</location>
            <location index="4">LL</location>
            <location index="5">LL</location>
            <location index="4">RL</location>
            <location index="5">RL</location>
            <location index="4">RA</location>
            <location index="5">RA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) LRM-20</name>
            <type>missile</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20</name>
            <type>ammunition</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20</name>
            <type>ammunition</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Large Laser</name>
            <type>energy</type>
            <location index="6">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Large Laser</name>
            <type>energy</type>
            <location index="6">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Anti-Missile System</name>
            <type>equipment</type>
            <location index="8">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Gauss Rifle</name>
            <type>ballistic</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Anti-Missile System</name>
            <type>ammunition</type>
            <location index="10">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="9">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="8">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="11">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="10">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Atlas" model="AS7-S" tons="100" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="K:\\CBT\\Record Sheets\\Mech Images\\Atlas.gif">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1929</battle_value>
    <cost>9783000.0</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3050</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="300" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>47</ct>
        <ctr>14</ctr>
        <lt>32</lt>
        <ltr>10</ltr>
        <rt>32</rt>
        <rtr>10</rtr>
        <la>34</la>
        <ra>34</ra>
        <ll>41</ll>
        <rl>41</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Atlas AS7-S 100t, 3/5/0, Std FE, Std; 19.0T/99% Armor; 15 DHS; 4 ML, 2 SSRM2, 1 SRM6, 1 AC20, 1 LRM20</info>
        <battleforce pv="19" wt="4" mv="3" s="5" m="5" l="2" e="0" ov="0" armor="10" internal="8" abilities="SRCH, ES, SEAL, SOA, AC 2/2/0, LRM 1/1/1, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="15" techbase="0">
            <type>Double Heat Sink</type>

            <location index="7">LA</location>
            <location index="4">LA</location>
            <location index="4">RA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="7">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="10">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Autocannon/20</name>
            <type>ballistic</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-20</name>
            <type>missile</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="11">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20</name>
            <type>ammunition</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20</name>
            <type>ammunition</type>
            <location index="10">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/20</name>
            <type>ammunition</type>
            <location index="11">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/20</name>
            <type>ammunition</type>
            <location index="10">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/20</name>
            <type>ammunition</type>
            <location index="4">RL</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Streak SRM-2</name>
            <type>missile</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Streak SRM-2</name>
            <type>missile</type>
            <location index="7">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Streak SRM-2</name>
            <type>ammunition</type>
            <location index="4">LL</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Atlas" model="AS7-S2" tons="100" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="K:\\CBT\\Record Sheets\\Mech Images\\Atlas.gif">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>2378</battle_value>
    <cost>1.9134E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3061</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="300" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">Light Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>47</ct>
        <ctr>14</ctr>
        <lt>32</lt>
        <ltr>10</ltr>
        <rt>32</rt>
        <rtr>10</rtr>
        <la>34</la>
        <ra>34</ra>
        <ll>41</ll>
        <rl>41</rl>
    </armor>
    <baseloadout fcsa4="TRUE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Atlas AS7-S2 100t, 3/5/0, LFE, Std; 19.0T/99% Armor; 16 DHS; 1 ECM, 1 LRM15, 1 HGR, 2 ERLL</info>
        <battleforce pv="24" wt="4" mv="3" s="4" m="5" l="4" e="0" ov="0" armor="10" internal="5" abilities="ECM, CASE, SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="16" techbase="0">
            <type>Double Heat Sink</type>

            <location index="4">LA</location>
            <location index="4">RA</location>
            <location index="7">LA</location>
            <location index="7">RA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) ER Large Laser</name>
            <type>energy</type>
            <location index="10">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Large Laser</name>
            <type>energy</type>
            <location index="10">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Heavy Gauss Rifle</name>
            <type>ballistic</type>
            <splitlocation index="2" number="9">RT</splitlocation>
            <splitlocation index="10" number="2">CT</splitlocation>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">Guardian ECM Suite</name>
            <type>equipment</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="7">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Heavy Gauss Rifle</name>
            <type>ammunition</type>
            <location index="5">RL</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Heavy Gauss Rifle</name>
            <type>ammunition</type>
            <location index="4">RL</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Heavy Gauss Rifle</name>
            <type>ammunition</type>
            <location index="4">LL</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Heavy Gauss Rifle</name>
            <type>ammunition</type>
            <location index="5">LL</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="10">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="11">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Atlas" model="AS7-S3" tons="100" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="K:\\CBT\\Record Sheets\\Mech Images\\Atlas.gif">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>2378</battle_value>
    <cost>1.89395E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3062</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="300" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">Light Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>47</ct>
        <ctr>14</ctr>
        <lt>32</lt>
        <ltr>10</ltr>
        <rt>32</rt>
        <rtr>10</rtr>
        <la>34</la>
        <ra>34</ra>
        <ll>41</ll>
        <rl>41</rl>
    </armor>
    <baseloadout fcsa4="TRUE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Atlas AS7-S3 100t, 3/5/0, LFE, Std; 19.0T/99% Armor; 14 DHS; 1 ECM, 3 SL, 1 LRM15, 2 PPC, 1 GR, 1 AMS</info>
        <battleforce pv="24" wt="4" mv="3" s="5" m="5" l="5" e="0" ov="0" armor="10" internal="5" abilities="ECM, AMS, CASE, SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="14" techbase="0">
            <type>Double Heat Sink</type>

            <location index="4">LA</location>
            <location index="4">RA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Gauss Rifle</name>
            <type>ballistic</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">Guardian ECM Suite</name>
            <type>equipment</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="7">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="7">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="10">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="10">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Anti-Missile System</name>
            <type>equipment</type>
            <location index="11">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="7">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Anti-Missile System</name>
            <type>ammunition</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="11">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="9">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Atlas" model="C" tons="100" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>2174</battle_value>
    <cost>1.0006E7</cost>
    <rules_level>3</rules_level>
    <era>1</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Mixed</techbase>
    <year restricted="FALSE">3050</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="300" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>47</ct>
        <ctr>14</ctr>
        <lt>32</lt>
        <ltr>10</ltr>
        <rt>32</rt>
        <rtr>10</rtr>
        <la>34</la>
        <ra>34</ra>
        <ll>41</ll>
        <rl>41</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Atlas C 100t, 3/5/0, Std FE, Std; 19.0T/99% Armor; 20 SHS; 4 ML, 1 LRM20, 1 SSRM6, 1 UAC20</info>
        <battleforce pv="22" wt="4" mv="3" s="5" m="5" l="1" e="0" ov="2" armor="10" internal="8" abilities="SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="20" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">RL</location>
            <location index="4">RA</location>
            <location index="5">RL</location>
            <location index="4">LA</location>
            <location index="5">LL</location>
            <location index="4">LL</location>
            <location index="0">LT</location>
            <location index="3">HD</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(CL) Ultra AC/20</name>
            <type>ballistic</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) LRM-20</name>
            <type>missile</type>
            <location index="1">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="5">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="5">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) Streak SRM-6</name>
            <type>missile</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(CL) @ Ultra AC/20</name>
            <type>ammunition</type>
            <location index="9">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(CL) @ Ultra AC/20</name>
            <type>ammunition</type>
            <location index="8">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(CL) @ LRM-20</name>
            <type>ammunition</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(CL) @ LRM-20</name>
            <type>ammunition</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(CL) @ Streak SRM-6</name>
            <type>ammunition</type>
            <location index="7">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Awesome" model="AWS-10KM" tons="80" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="K:\\CBT\\Record Sheets\\Mech Images\\Awesome.gif">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1961</battle_value>
    <cost>1.153332E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>6</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3068</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="3">HD</location>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="10">CT</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="10">LA</location>
        <location index="11">CT</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
        <location index="11">LA</location>
        <location index="11">RA</location>
    </structure>
    <engine rating="240" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="0">Compact Fusion Engine</engine>
    <gyro techbase="0">Compact Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>30</ct>
        <ctr>20</ctr>
        <lt>24</lt>
        <ltr>10</ltr>
        <rt>24</rt>
        <rtr>10</rtr>
        <la>26</la>
        <ra>26</ra>
        <ll>34</ll>
        <rl>34</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Awesome AWS-10KM 80t, 3/5/0, CFE, ES, Cmp Gyro; 15.5T/100% Armor; 18 DHS; 1 ECM, 1 SNPPC, 2 HPPC</info>
        <battleforce pv="20" wt="4" mv="3" s="4" m="4" l="3" e="0" ov="0" armor="8" internal="8" abilities="ECM, ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="18" techbase="0">
            <type>Double Heat Sink</type>

            <location index="5">CT</location>
            <location index="0">RT</location>
            <location index="0">LT</location>
            <location index="7">LA</location>
            <location index="4">LA</location>
            <location index="3">RT</location>
            <location index="3">LT</location>
            <location index="6">RA</location>
            <location index="3">RA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Snub-Nose PPC</name>
            <type>energy</type>
            <location index="9">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Heavy PPC</name>
            <type>energy</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Heavy PPC</name>
            <type>energy</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">Guardian ECM Suite</name>
            <type>equipment</type>
            <location index="8">CT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Awesome" model="AWS-9M" tons="80" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="C:\\CJ\\HeavyMetalbv2\\Pro\\Bmps\\3025 Awesome1.jpg">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1812</battle_value>
    <cost>1.8046920000000004E7</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3049</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="320" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>30</ct>
        <ctr>20</ctr>
        <lt>24</lt>
        <ltr>10</ltr>
        <rt>24</rt>
        <rtr>10</rtr>
        <la>26</la>
        <ra>26</ra>
        <ll>34</ll>
        <rl>34</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Awesome AWS-9M 80t, 4/6/0, XLFE, Std; 15.5T/100% Armor; 20 DHS; 2 SSRM2, 1 SPL, 3 ERPPC, 1 MPL</info>
        <battleforce pv="18" wt="4" mv="4" s="4" m="4" l="3" e="0" ov="1" armor="8" internal="4" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="20" techbase="0">
            <type>Double Heat Sink</type>

            <location index="3">RT</location>
            <location index="3">LT</location>
            <location index="6">RA</location>
            <location index="3">RA</location>
            <location index="4">LA</location>
            <location index="7">LA</location>
            <location index="6">LT</location>
            <location index="6">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Pulse Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER PPC</name>
            <type>energy</type>
            <location index="9">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER PPC</name>
            <type>energy</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER PPC</name>
            <type>energy</type>
            <location index="9">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Streak SRM-2</name>
            <type>missile</type>
            <location index="10">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Streak SRM-2</name>
            <type>ammunition</type>
            <location index="4">LL</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Streak SRM-2</name>
            <type>missile</type>
            <location index="11">CT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Awesome" model="AWS-9Ma" tons="80" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="C:\\CJ\\HeavyMetalbv2\\Pro\\Bmps\\3025 Awesome1.jpg">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1836</battle_value>
    <cost>1.9106850000000004E7</cost>
    <rules_level>2</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3051</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="320" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="TRUE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>30</ct>
        <ctr>20</ctr>
        <lt>24</lt>
        <ltr>10</ltr>
        <rt>24</rt>
        <rtr>10</rtr>
        <la>26</la>
        <ra>26</ra>
        <ll>34</ll>
        <rl>34</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Awesome AWS-9Ma 80t, 4/6/0, XLFE, Std; 15.5T/100% Armor; 20 DHS; 1 ECM, 1 SL, 1 CommEqp, 3 ERPPC</info>
        <battleforce pv="18" wt="4" mv="4" s="3" m="3" l="3" e="0" ov="0" armor="8" internal="4" abilities="ECM, ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="20" techbase="0">
            <type>Double Heat Sink</type>

            <location index="3">RT</location>
            <location index="3">LT</location>
            <location index="6">RA</location>
            <location index="3">RA</location>
            <location index="3">LA</location>
            <location index="6">LA</location>
            <location index="6">LT</location>
            <location index="6">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) ER PPC</name>
            <type>energy</type>
            <location index="9">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER PPC</name>
            <type>energy</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER PPC</name>
            <type>energy</type>
            <location index="9">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="9">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">Guardian ECM Suite</name>
            <type>equipment</type>
            <location index="10">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">Communications Equipment</name>
            <type>equipment</type>
            <tons>2.0</tons>
            <location index="10">CT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Awesome" model="AWS-9Q (Klatt)" tons="80" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1805</battle_value>
    <cost>7554600.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>6</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3071</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="240" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>31</ct>
        <ctr>19</ctr>
        <lt>24</lt>
        <ltr>10</ltr>
        <rt>24</rt>
        <rtr>10</rtr>
        <la>26</la>
        <ra>26</ra>
        <ll>34</ll>
        <rl>34</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Awesome AWS-9Q (Klatt) 80t, 3/5/0, Std FE, Std; 15.5T/100% Armor; 19 DHS; 1 C3S, 4 PPC, 1 SPL</info>
        <battleforce pv="18" wt="4" mv="3" s="4" m="4" l="4" e="0" ov="0" armor="8" internal="6" abilities="C3S, ENE, MHQ1, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="19" techbase="0">
            <type>Double Heat Sink</type>

            <location index="0">RT</location>
            <location index="3">RA</location>
            <location index="3">LT</location>
            <location index="6">LA</location>
            <location index="6">RA</location>
            <location index="3">LA</location>
            <location index="3">RT</location>
            <location index="6">LT</location>
            <location index="6">RT</location>
            <location index="0">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="9">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="9">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="9">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">C3 Computer (Slave)</name>
            <type>equipment</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Pulse Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Awesome" model="AWS-9Q" tons="80" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1875</battle_value>
    <cost>7456050.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3057</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="240" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>31</ct>
        <ctr>19</ctr>
        <lt>24</lt>
        <ltr>10</ltr>
        <rt>24</rt>
        <rtr>10</rtr>
        <la>26</la>
        <ra>26</ra>
        <ll>34</ll>
        <rl>34</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Awesome AWS-9Q 80t, 3/5/0, Std FE, Std; 15.5T/100% Armor; 19 DHS; 1 ECM, 1 SL, 4 PPC</info>
        <battleforce pv="19" wt="4" mv="3" s="4" m="4" l="4" e="0" ov="0" armor="8" internal="6" abilities="ECM, ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="19" techbase="0">
            <type>Double Heat Sink</type>

            <location index="0">RT</location>
            <location index="3">RA</location>
            <location index="3">LT</location>
            <location index="6">LA</location>
            <location index="6">RA</location>
            <location index="3">LA</location>
            <location index="3">RT</location>
            <location index="6">LT</location>
            <location index="6">RT</location>
            <location index="0">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="9">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="9">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="9">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">Guardian ECM Suite</name>
            <type>equipment</type>
            <location index="10">CT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Axman" model="AXM-1N" tons="65" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1374</battle_value>
    <cost>1.182751E7</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3048</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="260" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>28</ct>
        <ctr>6</ctr>
        <lt>21</lt>
        <ltr>6</ltr>
        <rt>21</rt>
        <rtr>6</rtr>
        <la>17</la>
        <ra>17</ra>
        <ll>24</ll>
        <rl>24</rl>
        <location index="3">HD</location>
        <location index="6">LT</location>
        <location index="6">LA</location>
        <location index="7">LT</location>
        <location index="7">LA</location>
        <location index="8">LT</location>
        <location index="8">LA</location>
        <location index="9">LT</location>
        <location index="9">LA</location>
        <location index="10">LT</location>
        <location index="10">LA</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
        <location index="11">LA</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Axman AXM-1N 65t, 4/6/4, XLFE, Std; 10.0T/85% FF Armor; 10 DHS; 3 ML, 1 AC20, 1 Htcht, 1 LPL</info>
        <battleforce pv="14" wt="3" mv="4j" s="4" m="4" l="0" e="0" ov="0" armor="6" internal="3" abilities="MEL, CASE, SRCH, ES, SEAL, SOA, AC 2/2/0" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="5">RL</location>
            <location index="5">LL</location>
        </jumpjets>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Autocannon/20</name>
            <type>ballistic</type>
            <splitlocation index="3" number="8">RT</splitlocation>
            <splitlocation index="10" number="2">CT</splitlocation>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="5">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="6">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/20</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/20</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Large Pulse Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">Hatchet</name>
            <type>physical</type>
            <location index="7">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="5">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Axman" model="AXM-2N" tons="65" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1458</battle_value>
    <cost>1.195001E7</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3049</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="260" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>28</ct>
        <ctr>6</ctr>
        <lt>21</lt>
        <ltr>6</ltr>
        <rt>21</rt>
        <rtr>6</rtr>
        <la>17</la>
        <ra>17</ra>
        <ll>24</ll>
        <rl>24</rl>
        <location index="3">HD</location>
        <location index="6">RT</location>
        <location index="6">LA</location>
        <location index="7">RT</location>
        <location index="7">LA</location>
        <location index="8">RT</location>
        <location index="8">LA</location>
        <location index="9">LT</location>
        <location index="9">LA</location>
        <location index="10">CT</location>
        <location index="10">LT</location>
        <location index="10">LA</location>
        <location index="11">LT</location>
        <location index="11">LA</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Axman AXM-2N 65t, 4/6/4, XLFE, Std; 10.0T/85% FF Armor; 10 DHS; 3 ML, 2 LRM15, 1 LPL, 1 Htcht</info>
        <battleforce pv="15" wt="3" mv="4j" s="3" m="3" l="1" e="0" ov="0" armor="6" internal="3" abilities="MEL, CASE, SRCH, ES, SEAL, SOA, LRM 1/1/1, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="5">RL</location>
            <location index="5">LL</location>
        </jumpjets>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="5">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="6">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="7">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">Hatchet</name>
            <type>physical</type>
            <location index="7">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Large Pulse Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="8">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Axman" model="AXM-3S" tons="65" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1649</battle_value>
    <cost>1.078218E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3057</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="260" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">Light Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>28</ct>
        <ctr>6</ctr>
        <lt>21</lt>
        <ltr>6</ltr>
        <rt>21</rt>
        <rtr>6</rtr>
        <la>17</la>
        <ra>17</ra>
        <ll>24</ll>
        <rl>24</rl>
        <location index="3">HD</location>
        <location index="6">LA</location>
        <location index="7">LA</location>
        <location index="8">LA</location>
        <location index="9">LT</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">LT</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
        <location index="11">LA</location>
        <location index="11">RA</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Axman AXM-3S 65t, 4/6/4, LFE, Std; 10.0T/85% FF Armor; 10 DHS; 1 ECM, 3 ERML, 1 Htcht, 1 LB20</info>
        <battleforce pv="16" wt="3" mv="4j" s="3" m="3" l="0" e="0" ov="0" armor="6" internal="4" abilities="MEL, ECM, CASE, SRCH, ES, SEAL, SOA, FLK 1/1/0" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="5">RL</location>
            <location index="5">LL</location>
        </jumpjets>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) LB 20-X AC</name>
            <type>ballistic</type>
            <splitlocation index="2" number="9">RT</splitlocation>
            <splitlocation index="10" number="2">CT</splitlocation>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="5">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 20-X AC (Slug)</name>
            <type>ammunition</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 20-X AC (Slug)</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 20-X AC (Cluster)</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 20-X AC (Cluster)</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">Hatchet</name>
            <type>physical</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">Guardian ECM Suite</name>
            <type>equipment</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="8">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Axman" model="AXM-4D" tons="65" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1222</battle_value>
    <cost>1.161356E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>6</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3071</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="260" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>28</ct>
        <ctr>6</ctr>
        <lt>21</lt>
        <ltr>6</ltr>
        <rt>21</rt>
        <rtr>6</rtr>
        <la>17</la>
        <ra>17</ra>
        <ll>24</ll>
        <rl>24</rl>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="7">RT</location>
        <location index="8">RT</location>
        <location index="9">RT</location>
        <location index="9">RA</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="10">RA</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
        <location index="11">RA</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Axman AXM-4D 65t, 4/6/0, XLFE, Std; 10.0T/85% FF Armor; 10 SHS; 2 ML, 1 Htcht, 4 LAC5, TC</info>
        <battleforce pv="12" wt="3" mv="4" s="4" m="4" l="0" e="0" ov="0" armor="6" internal="3" abilities="MEL, CASE, SRCH, ES, SEAL, SOA, AC 2/2/0" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="5">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Light AC/5</name>
            <type>ballistic</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Light AC/5</name>
            <type>ballistic</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Light AC/5</name>
            <type>ballistic</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Light AC/5</name>
            <type>ballistic</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">Hatchet</name>
            <type>physical</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Light AC/5</name>
            <type>ammunition</type>
            <location index="7">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Light AC/5</name>
            <type>ammunition</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Targeting Computer</name>
            <type>TargetingComputer</type>
            <location index="6">LA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Banshee" model="BNC-5S (Sawyer)" tons="95" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>2094</battle_value>
    <cost>2.5395765E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3057</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="380" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>40</ct>
        <ctr>17</ctr>
        <lt>30</lt>
        <ltr>10</ltr>
        <rt>30</rt>
        <rtr>10</rtr>
        <la>21</la>
        <ra>21</ra>
        <ll>26</ll>
        <rl>26</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Banshee BNC-5S (Sawyer) 95t, 4/6/4, XLFE, Std; 15.0T/82% Armor; 13 DHS; 4 ML, 2 SL, 1 GR, 1 ERPPC, 1 SRM6</info>
        <battleforce pv="21" wt="4" mv="4j" s="5" m="4" l="2" e="0" ov="0" armor="8" internal="4" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="5">LL</location>
            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="5">RL</location>
        </jumpjets>
        <heatsinks number="13" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Laser</name>
            <type>energy</type>
            <location index="10">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="5">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Laser</name>
            <type>energy</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="6">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Gauss Rifle</name>
            <type>ballistic</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER PPC</name>
            <type>energy</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="11">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Banshee" model="BNC-5S (Vandergriff)" tons="95" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1853</battle_value>
    <cost>2.594059E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3062</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="380" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>40</ct>
        <ctr>17</ctr>
        <lt>30</lt>
        <ltr>10</ltr>
        <rt>30</rt>
        <rtr>10</rtr>
        <la>21</la>
        <ra>21</ra>
        <ll>26</ll>
        <rl>26</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Banshee BNC-5S (Vandergriff) 95t, 4/6/4, XLFE, Std; 15.0T/82% Armor; 14 DHS; 2 ML, 2 ERPPC, 1 LB10, 1 SRM4</info>
        <battleforce pv="19" wt="4" mv="4j" s="3" m="3" l="2" e="0" ov="2" armor="8" internal="4" abilities="SRCH, ES, SEAL, SOA, FLK 1/1/1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="5">LL</location>
            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="5">RL</location>
        </jumpjets>
        <heatsinks number="14" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="8">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="7">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER PPC</name>
            <type>energy</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER PPC</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-4</name>
            <type>missile</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LB 10-X AC</name>
            <type>ballistic</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Slug)</name>
            <type>ammunition</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4</name>
            <type>ammunition</type>
            <location index="5">RA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Banshee" model="BNC-5S" tons="95" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>2065</battle_value>
    <cost>2.5399665E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3053</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="380" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>40</ct>
        <ctr>17</ctr>
        <lt>30</lt>
        <ltr>10</ltr>
        <rt>30</rt>
        <rtr>10</rtr>
        <la>21</la>
        <ra>21</ra>
        <ll>26</ll>
        <rl>26</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Banshee BNC-5S 95t, 4/6/0, XLFE, Std; 15.0T/82% Armor; 14 DHS; 4 ML, 2 SL, 1 GR, 2 ERPPC, 1 SRM6</info>
        <battleforce pv="21" wt="4" mv="4" s="4" m="4" l="3" e="0" ov="1" armor="8" internal="4" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="14" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Laser</name>
            <type>energy</type>
            <location index="10">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="5">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Laser</name>
            <type>energy</type>
            <location index="9">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="6">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER PPC</name>
            <type>energy</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Gauss Rifle</name>
            <type>ballistic</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER PPC</name>
            <type>energy</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="11">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Banshee" model="BNC-6S" tons="95" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1889</battle_value>
    <cost>2.0662477500000004E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3062</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="4">LA</location>
        <location index="4">RA</location>
        <location index="5">LA</location>
        <location index="5">RA</location>
        <location index="6">LA</location>
        <location index="6">RA</location>
        <location index="7">LA</location>
        <location index="7">RA</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
    </structure>
    <engine rating="380" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">Light Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>38</ct>
        <ctr>11</ctr>
        <lt>30</lt>
        <ltr>9</ltr>
        <rt>30</rt>
        <rtr>9</rtr>
        <la>20</la>
        <ra>20</ra>
        <ll>28</ll>
        <rl>28</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Banshee BNC-6S 95t, 4/6/0, LFE, ES; 14.5T/79% Armor; 10 DHS; 1 SL, 1 HGR, 1 LB10</info>
        <battleforce pv="19" wt="4" mv="4" s="3" m="3" l="2" e="0" ov="0" armor="8" internal="5" abilities="CASE, SRCH, ES, SEAL, SOA, FLK 1/1/1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Heavy Gauss Rifle</name>
            <type>ballistic</type>
            <splitlocation index="2" number="9">LT</splitlocation>
            <splitlocation index="10" number="2">CT</splitlocation>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LB 10-X AC</name>
            <type>ballistic</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Slug)</name>
            <type>ammunition</type>
            <location index="8">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Cluster)</name>
            <type>ammunition</type>
            <location index="9">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Heavy Gauss Rifle</name>
            <type>ammunition</type>
            <location index="4">LL</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Heavy Gauss Rifle</name>
            <type>ammunition</type>
            <location index="10">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Heavy Gauss Rifle</name>
            <type>ammunition</type>
            <location index="4">RL</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Heavy Gauss Rifle</name>
            <type>ammunition</type>
            <location index="5">RL</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Heavy Gauss Rifle</name>
            <type>ammunition</type>
            <location index="5">LL</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="11">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="11">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Banshee" model="BNC-7S" tons="95" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>2126</battle_value>
    <cost>2.0724520000000004E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3063</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="3">HD</location>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">RT</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="6">RT</location>
        <location index="6">LA</location>
        <location index="6">RA</location>
        <location index="7">RT</location>
        <location index="8">RT</location>
        <location index="9">RT</location>
        <location index="10">RT</location>
        <location index="11">RT</location>
    </structure>
    <engine rating="380" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">Light Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>38</ct>
        <ctr>13</ctr>
        <lt>27</lt>
        <ltr>8</ltr>
        <rt>27</rt>
        <rtr>8</rtr>
        <la>27</la>
        <ra>27</ra>
        <ll>32</ll>
        <rl>32</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Banshee BNC-7S 95t, 4/6/0, LFE, ES; 15.5T/85% Armor; 14 DHS; 4 ERML, 1 GR, 1 SRM6, 1 ERPPC</info>
        <battleforce pv="21" wt="4" mv="4" s="4" m="4" l="2" e="0" ov="2" armor="8" internal="5" abilities="CASE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="14" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Gauss Rifle</name>
            <type>ballistic</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="5">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER PPC</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="10">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="11">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Banshee" model="BNC-8S" tons="95" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>2408</battle_value>
    <cost>2.9653215E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>6</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3070</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="380" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="0">Extra-Light Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <enhancement techbase="0">
        <type>TSM</type>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">LL</location>
        <location index="7">RA</location>
        <location index="8">RA</location>
        <location index="11">RT</location>
    </enhancement>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>44</ct>
        <ctr>15</ctr>
        <lt>30</lt>
        <ltr>10</ltr>
        <rt>30</rt>
        <rtr>10</rtr>
        <la>32</la>
        <ra>32</ra>
        <ll>38</ll>
        <rl>38</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Banshee BNC-8S 95t, 4[5]/6[8]/0 TSM, XLFE, Std, XL Gyro; 18.0T/98% Armor; 13 DHS; 1 SNPPC, 1 ERLL, 1 C3S, 1 ECM, 1 LB10, 1 ERSL, 2 ERML, 1 Htcht, 1 SL, 2 ML</info>
        <battleforce pv="24" wt="4" mv="4" s="4" m="3" l="1" e="0" ov="2" armor="10" internal="4" abilities="TSM, MEL, C3S, ECM, CASE, MHQ1, SRCH, ES, SEAL, SOA, FLK 0/0/0" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="13" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">Hatchet</name>
            <type>physical</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">C3 Computer (Slave)</name>
            <type>equipment</type>
            <location index="11">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LB 10-X AC</name>
            <type>ballistic</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">Guardian ECM Suite</name>
            <type>equipment</type>
            <location index="9">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Snub-Nose PPC</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Large Laser</name>
            <type>energy</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="7">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Small Laser</name>
            <type>energy</type>
            <location index="8">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="6">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Slug)</name>
            <type>ammunition</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Cluster)</name>
            <type>ammunition</type>
            <location index="10">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="11">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Banshee" model="BNC-9S" tons="95" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>2496</battle_value>
    <cost>1.715797E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>6</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3071</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="285" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">Light Fusion Engine</engine>
    <gyro techbase="0">Extra-Light Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>44</ct>
        <ctr>15</ctr>
        <lt>30</lt>
        <ltr>10</ltr>
        <rt>30</rt>
        <rtr>10</rtr>
        <la>32</la>
        <ra>32</ra>
        <ll>38</ll>
        <rl>38</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Banshee BNC-9S 95t, 3/5/0, LFE, Std, XL Gyro; 18.0T/98% Armor; 14 DHS; 1 ML, 1 GR, 3 ERML, 1 SRM6, 1 LPPC, 1 HPPC, TC</info>
        <battleforce pv="25" wt="4" mv="3" s="5" m="5" l="3" e="0" ov="2" armor="10" internal="5" abilities="CASE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="14" techbase="0">
            <type>Double Heat Sink</type>

            <location index="4">RA</location>
            <location index="7">RA</location>
            <location index="3">LA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Heavy PPC</name>
            <type>energy</type>
            <location index="6">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Light PPC</name>
            <type>energy</type>
            <location index="10">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="10">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="11">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Gauss Rifle</name>
            <type>ballistic</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="4">LL</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="5">LL</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="10">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="11">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Targeting Computer</name>
            <type>TargetingComputer</type>
            <location index="4">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Blackjack" model="BJ-2" tons="45" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1148</battle_value>
    <cost>3510075.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3052</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="180" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>18</ct>
        <ctr>9</ctr>
        <lt>15</lt>
        <ltr>6</ltr>
        <rt>15</rt>
        <rtr>6</rtr>
        <la>12</la>
        <ra>12</ra>
        <ll>17</ll>
        <rl>17</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Blackjack BJ-2 45t, 4/6/4, Std FE, Std; 8.5T/89% Armor; 11 DHS; 4 SSRM2, 2 ERLL</info>
        <battleforce pv="11" wt="2" mv="4j" s="3" m="3" l="2" e="0" ov="1" armor="5" internal="4" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="4">LL</location>
            <location index="5">LL</location>
            <location index="4">RL</location>
            <location index="5">RL</location>
        </jumpjets>
        <heatsinks number="11" techbase="0">
            <type>Double Heat Sink</type>

            <location index="0">LT</location>
            <location index="0">RT</location>
            <location index="3">RT</location>
            <location index="3">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) ER Large Laser</name>
            <type>energy</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Large Laser</name>
            <type>energy</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Streak SRM-2</name>
            <type>missile</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Streak SRM-2</name>
            <type>missile</type>
            <location index="7">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Streak SRM-2</name>
            <type>missile</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Streak SRM-2</name>
            <type>missile</type>
            <location index="7">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Streak SRM-2</name>
            <type>ammunition</type>
            <location index="8">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Blackjack" model="BJ-3" tons="45" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1271</battle_value>
    <cost>3592375.0</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3042</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="180" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>18</ct>
        <ctr>9</ctr>
        <lt>15</lt>
        <ltr>6</ltr>
        <rt>15</rt>
        <rtr>6</rtr>
        <la>12</la>
        <ra>12</ra>
        <ll>17</ll>
        <rl>17</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Blackjack BJ-3 45t, 4/6/4, Std FE, Std; 8.5T/89% Armor; 10 DHS; 4 ML, 2 PPC</info>
        <battleforce pv="13" wt="2" mv="4j" s="3" m="3" l="2" e="0" ov="1" armor="5" internal="4" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="4">LL</location>
            <location index="5">LL</location>
            <location index="4">RL</location>
            <location index="5">RL</location>
        </jumpjets>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

            <location index="0">LT</location>
            <location index="0">RT</location>
            <location index="3">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="5">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="5">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="2">RA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Blackjack" model="BJ-4" tons="45" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1063</battle_value>
    <cost>5270625.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3067</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="180" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">Light Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Light Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>18</ct>
        <ctr>8</ctr>
        <lt>15</lt>
        <ltr>6</ltr>
        <rt>15</rt>
        <rtr>6</rtr>
        <la>12</la>
        <ra>12</ra>
        <ll>17</ll>
        <rl>17</rl>
        <location index="3">HD</location>
        <location index="5">LA</location>
        <location index="5">RA</location>
        <location index="6">LA</location>
        <location index="6">RA</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Blackjack BJ-4 45t, 4/6/4, LFE, Std; 8.0T/88% LFF Armor; 10 DHS; 2 ERML, 2 LAC5, TC</info>
        <battleforce pv="11" wt="2" mv="4j" s="3" m="3" l="0" e="0" ov="0" armor="5" internal="2" abilities="CASE, SRCH, ES, SEAL, SOA, AC 1/1/0" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="4">LL</location>
            <location index="5">LL</location>
            <location index="4">RL</location>
            <location index="5">RL</location>
        </jumpjets>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

            <location index="2">RT</location>
            <location index="2">LT</location>
            <location index="5">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Light AC/5</name>
            <type>ballistic</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Light AC/5</name>
            <type>ballistic</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Light AC/5</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Light AC/5</name>
            <type>ammunition</type>
            <location index="8">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Light AC/5</name>
            <type>ammunition</type>
            <location index="9">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Light AC/5</name>
            <type>ammunition</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="10">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="10">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Targeting Computer</name>
            <type>TargetingComputer</type>
            <location index="7">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Caesar" model="CES-3R &quot;Archangel&quot;" tons="70" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>2098</battle_value>
    <cost>1.3525873333333336E7</cost>
    <rules_level>3</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Mixed</techbase>
    <year restricted="FALSE">3057</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="280" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>28</ct>
        <ctr>7</ctr>
        <lt>20</lt>
        <ltr>6</ltr>
        <rt>20</rt>
        <rtr>6</rtr>
        <la>22</la>
        <ra>22</ra>
        <ll>22</ll>
        <rl>22</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Caesar CES-3R "Archangel" 70t, 4/6/0, XLFE, Std; 11.5T/85% Armor; 17 DHS; 1 GR, 1 ERPPC, 5 MPL</info>
        <battleforce pv="21" wt="3" mv="4" s="6" m="6" l="3" e="0" ov="0" armor="6" internal="3" abilities="CASE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="17" techbase="0">
            <type>Double Heat Sink</type>

            <location index="7">LA</location>
            <location index="4">LA</location>
            <location index="3">LT</location>
            <location index="6">LT</location>
            <location index="4">RA</location>
            <location index="7">RA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(CL) Gauss Rifle</name>
            <type>ballistic</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="9">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="11">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="10">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) ER PPC</name>
            <type>energy</type>
            <location index="10">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="11">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="10">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="10">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Caesar" model="CES-3R &quot;Gertrude&quot;" tons="70" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1833</battle_value>
    <cost>1.4076673333333336E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3062</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="280" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>26</ct>
        <ctr>7</ctr>
        <lt>15</lt>
        <ltr>6</ltr>
        <rt>15</rt>
        <rtr>6</rtr>
        <la>21</la>
        <ra>21</ra>
        <ll>21</ll>
        <rl>21</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Caesar CES-3R "Gertrude" 70t, 4/6/0, XLFE, Std; 10.5T/77% Armor; 16 DHS; 1 GR, 8 ERML, 1 ERPPC</info>
        <battleforce pv="18" wt="3" mv="4" s="4" m="4" l="2" e="0" ov="3" armor="6" internal="3" abilities="CASE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="16" techbase="0">
            <type>Double Heat Sink</type>

            <location index="7">LA</location>
            <location index="4">LA</location>
            <location index="3">LT</location>
            <location index="6">LT</location>
            <location index="4">RA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="10">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="10">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="11">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="10">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="11">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER PPC</name>
            <type>energy</type>
            <location index="7">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Gauss Rifle</name>
            <type>ballistic</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="11">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="10">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="11">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Caesar" model="CES-3R" tons="70" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1578</battle_value>
    <cost>1.3396673333333336E7</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3049</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="280" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>26</ct>
        <ctr>7</ctr>
        <lt>15</lt>
        <ltr>6</ltr>
        <rt>15</rt>
        <rtr>6</rtr>
        <la>21</la>
        <ra>21</ra>
        <ll>21</ll>
        <rl>21</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Caesar CES-3R 70t, 4/6/0, XLFE, Std; 10.5T/77% Armor; 16 DHS; 1 GR, 1 ERPPC, 4 MPL</info>
        <battleforce pv="16" wt="3" mv="4" s="4" m="4" l="3" e="0" ov="0" armor="6" internal="3" abilities="CASE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="16" techbase="0">
            <type>Double Heat Sink</type>

            <location index="7">LA</location>
            <location index="4">LA</location>
            <location index="3">LT</location>
            <location index="6">LT</location>
            <location index="4">RA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="10">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="10">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="10">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER PPC</name>
            <type>energy</type>
            <location index="7">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Gauss Rifle</name>
            <type>ballistic</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="10">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="11">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="11">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Caesar" model="CES-3S" tons="70" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1909</battle_value>
    <cost>1.1500465000000002E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3067</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="3">HD</location>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">LA</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="6">LA</location>
        <location index="7">LT</location>
        <location index="7">LA</location>
        <location index="8">LT</location>
        <location index="8">LA</location>
        <location index="9">RA</location>
        <location index="10">RA</location>
        <location index="11">CT</location>
    </structure>
    <engine rating="280" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">Light Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Light Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>33</ct>
        <ctr>11</ctr>
        <lt>22</lt>
        <ltr>8</ltr>
        <rt>22</rt>
        <rtr>8</rtr>
        <la>22</la>
        <ra>22</ra>
        <ll>30</ll>
        <rl>30</rl>
        <location index="9">LT</location>
        <location index="9">LA</location>
        <location index="10">LT</location>
        <location index="10">LA</location>
        <location index="11">LT</location>
        <location index="11">LA</location>
        <location index="11">RA</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Caesar CES-3S 70t, 4/6/0, LFE, ES; 13.0T/100% LFF Armor; 12 DHS; 1 ECM, 1 GR, 1 ERSL, 2 MPL, 1 HPPC</info>
        <battleforce pv="19" wt="3" mv="4" s="4" m="5" l="3" e="0" ov="0" armor="7" internal="4" abilities="ECM, CASE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="12" techbase="0">
            <type>Double Heat Sink</type>

            <location index="2">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="8">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Heavy PPC</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) ER Small Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Gauss Rifle</name>
            <type>ballistic</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">Guardian ECM Suite</name>
            <type>equipment</type>
            <location index="9">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="11">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Caesar" model="CES-4R" tons="70" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>2024</battle_value>
    <cost>1.5686573333333338E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3067</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="280" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <enhancement techbase="0">
        <type>MASC</type>
        <location index="6">LT</location>
    </enhancement>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>28</ct>
        <ctr>11</ctr>
        <lt>22</lt>
        <ltr>8</ltr>
        <rt>22</rt>
        <rtr>8</rtr>
        <la>22</la>
        <ra>22</ra>
        <ll>27</ll>
        <rl>27</rl>
        <location index="3">HD</location>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="8">LA</location>
        <location index="9">LA</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
        <location index="11">CT</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
        <location index="11">LA</location>
        <location index="11">RA</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Caesar CES-4R 70t, 4/6[8]/0 MASC, XLFE, Std; 11.5T/95% FF Armor; 13 DHS; 1 SNPPC, 1 GR, 3 ERML, 2 MPL</info>
        <battleforce pv="20" wt="3" mv="5" s="5" m="5" l="2" e="0" ov="0" armor="7" internal="3" abilities="CASE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="13" techbase="0">
            <type>Double Heat Sink</type>

            <location index="4">RA</location>
            <location index="4">LA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="10">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="9">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="7">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Snub-Nose PPC</name>
            <type>energy</type>
            <location index="7">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Gauss Rifle</name>
            <type>ballistic</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="10">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Caesar" model="CES-4S" tons="70" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1739</battle_value>
    <cost>1.1518340000000002E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3064</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="3">HD</location>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="6">RA</location>
        <location index="7">LT</location>
        <location index="7">RA</location>
        <location index="8">LT</location>
        <location index="9">LT</location>
        <location index="9">LA</location>
        <location index="10">LT</location>
        <location index="10">LA</location>
        <location index="11">LT</location>
    </structure>
    <engine rating="280" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">Light Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>28</ct>
        <ctr>7</ctr>
        <lt>22</lt>
        <ltr>6</ltr>
        <rt>22</rt>
        <rtr>6</rtr>
        <la>19</la>
        <ra>19</ra>
        <ll>27</ll>
        <rl>27</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Caesar CES-4S 70t, 4/6/0, LFE, ES; 12.0T/88% Armor; 12 DHS; 1 HGR, 2 ERML, 2 MPL, 1 LPL</info>
        <battleforce pv="17" wt="3" mv="4" s="5" m="5" l="1" e="0" ov="1" armor="6" internal="4" abilities="CASE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="12" techbase="0">
            <type>Double Heat Sink</type>

            <location index="4">LA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Large Pulse Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="7">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="8">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Heavy Gauss Rifle</name>
            <type>ballistic</type>
            <splitlocation index="2" number="9">RT</splitlocation>
            <splitlocation index="10" number="2">CT</splitlocation>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Heavy Gauss Rifle</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Heavy Gauss Rifle</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Heavy Gauss Rifle</name>
            <type>ammunition</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="11">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Cataphract" model="CTF-3D" tons="70" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1325</battle_value>
    <cost>1.3583653333333336E7</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3050</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="280" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>26</ct>
        <ctr>9</ctr>
        <lt>16</lt>
        <ltr>9</ltr>
        <rt>16</rt>
        <rtr>9</rtr>
        <la>22</la>
        <ra>22</ra>
        <ll>19</ll>
        <rl>19</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Cataphract CTF-3D 70t, 4/6/4, XLFE, Std; 11.0T/81% Armor; 16 SHS; 4 ML, 1 UAC5, 1 LB10</info>
        <battleforce pv="13" wt="3" mv="4j" s="3" m="3" l="2" e="0" ov="0" armor="6" internal="3" abilities="CASE, SRCH, ES, SEAL, SOA, FLK 1/1/1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="5">LL</location>
            <location index="4">RL</location>
            <location index="4">LL</location>
            <location index="5">RL</location>
        </jumpjets>
        <heatsinks number="16" techbase="2">
            <type>Single Heat Sink</type>

            <location index="10">CT</location>
            <location index="3">LT</location>
            <location index="11">CT</location>
            <location index="3">HD</location>
            <location index="4">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Laser</name>
            <type>energy</type>
            <location index="9">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="8">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Laser</name>
            <type>energy</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LB 10-X AC</name>
            <type>ballistic</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Ultra AC/5</name>
            <type>ballistic</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Ultra AC/5</name>
            <type>ammunition</type>
            <location index="10">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Slug)</name>
            <type>ammunition</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Cluster)</name>
            <type>ammunition</type>
            <location index="7">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="11">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Cataphract" model="CTF-3L" tons="70" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1545</battle_value>
    <cost>1.5370703333333338E7</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3050</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="280" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <enhancement techbase="0">
        <type>MASC</type>
        <location index="7">LT</location>
    </enhancement>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>26</ct>
        <ctr>9</ctr>
        <lt>16</lt>
        <ltr>9</ltr>
        <rt>16</rt>
        <rtr>9</rtr>
        <la>22</la>
        <ra>22</ra>
        <ll>19</ll>
        <rl>19</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Cataphract CTF-3L 70t, 4/6[8]/0 MASC, XLFE, Std; 11.0T/81% Armor; 16 DHS; 4 MPL, 1 ERPPC, 1 LB10</info>
        <battleforce pv="15" wt="3" mv="5" s="3" m="3" l="2" e="0" ov="0" armor="6" internal="3" abilities="SRCH, ES, SEAL, SOA, FLK 1/1/1" />
        <actuators lla="TRUE" lh="TRUE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="16" techbase="0">
            <type>Double Heat Sink</type>

            <location index="3">LT</location>
            <location index="2">RA</location>
            <location index="5">RA</location>
            <location index="4">LA</location>
            <location index="7">LA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) ER PPC</name>
            <type>energy</type>
            <location index="8">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="10">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="11">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="9">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LB 10-X AC</name>
            <type>ballistic</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Slug)</name>
            <type>ammunition</type>
            <location index="10">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Cluster)</name>
            <type>ammunition</type>
            <location index="11">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Cataphract" model="CTF-3LL" tons="70" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1664</battle_value>
    <cost>1.5400703333333338E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>6</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3068</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="280" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <enhancement techbase="0">
        <type>MASC</type>
        <location index="7">LT</location>
    </enhancement>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>26</ct>
        <ctr>9</ctr>
        <lt>16</lt>
        <ltr>9</ltr>
        <rt>16</rt>
        <rtr>9</rtr>
        <la>22</la>
        <ra>22</ra>
        <ll>19</ll>
        <rl>19</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Cataphract CTF-3LL 70t, 4/6[8]/0 MASC, XLFE, Std; 11.0T/81% Armor; 16 DHS; 1 PR, 2 ERML, 2 MPL, 1 LB10</info>
        <battleforce pv="17" wt="3" mv="5" s="3" m="3" l="1" e="0" ov="0" armor="6" internal="3" abilities="SRCH, ES, SEAL, SOA, FLK 1/1/1" />
        <actuators lla="TRUE" lh="TRUE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="16" techbase="0">
            <type>Double Heat Sink</type>

            <location index="3">LT</location>
            <location index="2">RA</location>
            <location index="5">RA</location>
            <location index="4">LA</location>
            <location index="7">LA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="9">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LB 10-X AC</name>
            <type>ballistic</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Slug)</name>
            <type>ammunition</type>
            <location index="10">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Cluster)</name>
            <type>ammunition</type>
            <location index="11">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="10">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="10">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Plasma Rifle</name>
            <type>energy</type>
            <location index="8">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Plasma Rifle</name>
            <type>ammunition</type>
            <location index="11">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Plasma Rifle</name>
            <type>ammunition</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Plasma Rifle</name>
            <type>ammunition</type>
            <location index="11">CT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Cataphract" model="CTF-4L" tons="70" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>2002</battle_value>
    <cost>7998833.333333335</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3067</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="3">HD</location>
        <location index="5">LA</location>
        <location index="6">LA</location>
        <location index="6">RA</location>
        <location index="7">LA</location>
        <location index="7">RA</location>
        <location index="8">RT</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">RT</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">CT</location>
        <location index="11">CT</location>
    </structure>
    <engine rating="280" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Stealth Armor</type>
        <hd>9</hd>
        <ct>32</ct>
        <ctr>11</ctr>
        <lt>22</lt>
        <ltr>8</ltr>
        <rt>22</rt>
        <rtr>8</rtr>
        <la>22</la>
        <ra>22</ra>
        <ll>30</ll>
        <rl>30</rl>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
        <location index="11">LA</location>
        <location index="11">RA</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Cataphract CTF-4L 70t, 4/6/0, Std FE, ES; 13.5T/100% Stlth Armor; 13 DHS; 1 ECM, 2 ERML, 1 GR, 1 ERPPC</info>
        <battleforce pv="20" wt="3" mv="4" s="3" m="3" l="2" e="0" ov="1" armor="7" internal="6" abilities="ECM, STL, CASE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="13" techbase="0">
            <type>Double Heat Sink</type>

            <location index="0">LT</location>
            <location index="3">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Gauss Rifle</name>
            <type>ballistic</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER PPC</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">Guardian ECM Suite</name>
            <type>equipment</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="7">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="7">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Cataphract" model="CTF-4X" tons="70" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1216</battle_value>
    <cost>5216000.000000001</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3029</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="210" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>33</ct>
        <ctr>11</ctr>
        <lt>22</lt>
        <ltr>8</ltr>
        <rt>22</rt>
        <rtr>8</rtr>
        <la>22</la>
        <ra>22</ra>
        <ll>30</ll>
        <rl>30</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Cataphract CTF-4X 70t, 3/5/0, Std FE, Std; 14.0T/100% Armor; 16 SHS; 1 LRM5, 2 AC5, 1 LL</info>
        <battleforce pv="12" wt="3" mv="3" s="2" m="3" l="2" e="0" ov="0" armor="7" internal="6" abilities="SRCH, ES, SEAL, SOA, AC 1/1/1" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="16" techbase="2">
            <type>Single Heat Sink</type>

            <location index="1">RT</location>
            <location index="0">RT</location>
            <location index="0">LT</location>
            <location index="1">LT</location>
            <location index="4">RL</location>
            <location index="5">RL</location>
            <location index="5">LL</location>
            <location index="4">LL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Autocannon/5</name>
            <type>ballistic</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Autocannon/5</name>
            <type>ballistic</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-5</name>
            <type>missile</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/5</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/5</name>
            <type>ammunition</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/5</name>
            <type>ammunition</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/5</name>
            <type>ammunition</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-5</name>
            <type>ammunition</type>
            <location index="4">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Cataphract" model="CTF-5D" tons="70" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1742</battle_value>
    <cost>1.3951920000000002E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>6</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3070</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="280" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">Light Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Small Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Stealth Armor</type>
        <hd>9</hd>
        <ct>26</ct>
        <ctr>9</ctr>
        <lt>19</lt>
        <ltr>9</ltr>
        <rt>19</rt>
        <rtr>9</rtr>
        <la>22</la>
        <ra>22</ra>
        <ll>24</ll>
        <rl>24</rl>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
        <location index="11">LA</location>
        <location index="11">RA</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Cataphract CTF-5D 70t, 4/6/6, LFE, Std; 12.0T/88% Stlth Armor; 14 DHS; 1 ECM, 1 PR, 2 ERML, 1 LAC5</info>
        <battleforce pv="17" wt="3" mv="4/6j" s="3" m="3" l="0" e="0" ov="0" armor="6" internal="4" abilities="ECM, STL, CASE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="6">
            <type>Improved Jump Jet</type>
            <location index="2">RT</location>
            <location index="2">LT</location>
            <location index="6">LT</location>
            <location index="4">LT</location>
            <location index="4">RT</location>
            <location index="6">RT</location>
        </jumpjets>
        <heatsinks number="14" techbase="0">
            <type>Double Heat Sink</type>

            <location index="7">LA</location>
            <location index="4">LA</location>
            <location index="3">RA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="8">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="8">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Light AC/5</name>
            <type>ballistic</type>
            <location index="6">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Plasma Rifle</name>
            <type>energy</type>
            <location index="4">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Light AC/5</name>
            <type>ammunition</type>
            <location index="9">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Plasma Rifle</name>
            <type>ammunition</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Plasma Rifle</name>
            <type>ammunition</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">Guardian ECM Suite</name>
            <type>equipment</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="9">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Catapult" model="CPLT-C2" tons="65" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1346</battle_value>
    <cost>1.2307075E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3062</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="3">HD</location>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="6">LA</location>
        <location index="6">RA</location>
        <location index="7">LA</location>
        <location index="7">RA</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="10">CT</location>
        <location index="11">CT</location>
        <location index="11">LT</location>
    </structure>
    <engine rating="260" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>28</ct>
        <ctr>9</ctr>
        <lt>20</lt>
        <ltr>6</ltr>
        <rt>20</rt>
        <rtr>6</rtr>
        <la>17</la>
        <ra>17</ra>
        <ll>26</ll>
        <rl>26</rl>
    </armor>
    <baseloadout fcsa4="TRUE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Catapult CPLT-C2 65t, 4/6/4, XLFE, ES; 11.5T/87% Armor; 10 DHS; 2 LRM15, 2 LB2</info>
        <battleforce pv="13" wt="3" mv="4j" s="2" m="3" l="3" e="0" ov="0" armor="6" internal="3" abilities="SRCH, ES, SEAL, SOA, IF 2" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="3">RT</location>
            <location index="4">LT</location>
            <location index="3">LT</location>
            <location index="4">RT</location>
        </jumpjets>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="10">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="11">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="10">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LB 2-X AC</name>
            <type>ballistic</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LB 2-X AC</name>
            <type>ballistic</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 2-X AC (Slug)</name>
            <type>ammunition</type>
            <location index="9">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Catapult" model="CPLT-C3" tons="65" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1368</battle_value>
    <cost>5866125.0</cost>
    <rules_level>2</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3049</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="260" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>24</ct>
        <ctr>11</ctr>
        <lt>19</lt>
        <ltr>8</ltr>
        <rt>19</rt>
        <rtr>8</rtr>
        <la>13</la>
        <ra>13</ra>
        <ll>18</ll>
        <rl>18</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Catapult CPLT-C3 65t, 4/6/4, Std FE, Std; 10.0T/76% Armor; 15 SHS; 4 ML, 1 ArwIV</info>
        <battleforce pv="14" wt="3" mv="4j" s="3" m="2" l="0" e="0" ov="0" armor="5" internal="5" abilities="ARTAIS, SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="1">LT</location>
            <location index="0">LT</location>
            <location index="1">RT</location>
            <location index="0">RT</location>
        </jumpjets>
        <heatsinks number="15" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">RL</location>
            <location index="3">HD</location>
            <location index="5">RL</location>
            <location index="5">LL</location>
            <location index="4">LL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="8">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Arrow IV Missile</name>
            <type>artillery</type>
            <splitlocation index="2" number="6">RT</splitlocation>
            <splitlocation index="2" number="9">RA</splitlocation>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Arrow IV (Non-Homing)</name>
            <type>ammunition</type>
            <location index="9">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Catapult" model="CPLT-C4C" tons="65" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1449</battle_value>
    <cost>1.1975525E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3055</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="260" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>22</ct>
        <ctr>7</ctr>
        <lt>17</lt>
        <ltr>6</ltr>
        <rt>17</rt>
        <rtr>6</rtr>
        <la>15</la>
        <ra>15</ra>
        <ll>23</ll>
        <rl>23</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Catapult CPLT-C4C 65t, 4/6/4, XLFE, Std; 10.0T/76% Armor; 10 DHS; 1 SPL, 1 BAP, 2 LRM20, 1 LL</info>
        <battleforce pv="14" wt="3" mv="4j" s="3" m="3" l="3" e="0" ov="0" armor="5" internal="3" abilities="PRB, RCN, SRCH, ES, SEAL, SOA, LRM 1/2/2, IF 2" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="4">LT</location>
            <location index="3">LT</location>
            <location index="3">RT</location>
            <location index="4">RT</location>
        </jumpjets>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Small Pulse Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-20</name>
            <type>missile</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-20</name>
            <type>missile</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">Beagle Active Probe</name>
            <type>equipment</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20</name>
            <type>ammunition</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20</name>
            <type>ammunition</type>
            <location index="7">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20</name>
            <type>ammunition</type>
            <location index="7">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20</name>
            <type>ammunition</type>
            <location index="8">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Catapult" model="CPLT-C5" tons="65" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1748</battle_value>
    <cost>6524875.0</cost>
    <rules_level>2</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3061</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="2">LA</location>
        <location index="3">HD</location>
        <location index="3">LA</location>
        <location index="4">LA</location>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">LA</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="6">LA</location>
        <location index="7">LA</location>
        <location index="8">LA</location>
        <location index="11">LT</location>
        <location index="11">RA</location>
    </structure>
    <engine rating="260" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>28</ct>
        <ctr>11</ctr>
        <lt>22</lt>
        <ltr>8</ltr>
        <rt>22</rt>
        <rtr>8</rtr>
        <la>20</la>
        <ra>20</ra>
        <ll>26</ll>
        <rl>26</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Catapult CPLT-C5 65t, 4/6/4, Std FE, ES; 12.5T/95% Armor; 12 DHS; 4 ERML, 1 ArwIV</info>
        <battleforce pv="17" wt="3" mv="4j" s="4" m="2" l="0" e="0" ov="0" armor="7" internal="5" abilities="ARTAIS, CASE, SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="4">LT</location>
            <location index="3">LT</location>
            <location index="3">RT</location>
            <location index="4">RT</location>
        </jumpjets>
        <heatsinks number="12" techbase="0">
            <type>Double Heat Sink</type>

            <location index="0">LT</location>
            <location index="0">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="11">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Arrow IV Missile</name>
            <type>artillery</type>
            <splitlocation index="2" number="9">RA</splitlocation>
            <splitlocation index="5" number="6">RT</splitlocation>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Arrow IV (Non-Homing)</name>
            <type>ammunition</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Arrow IV (Non-Homing)</name>
            <type>ammunition</type>
            <location index="7">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Arrow IV (Homing)</name>
            <type>ammunition</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Arrow IV (Homing)</name>
            <type>ammunition</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="10">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Catapult" model="CPLT-C6" tons="65" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1874</battle_value>
    <cost>6416075.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>6</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3069</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="2">LA</location>
        <location index="3">HD</location>
        <location index="3">LA</location>
        <location index="4">LA</location>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">LA</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="6">LA</location>
        <location index="7">LA</location>
        <location index="8">RT</location>
        <location index="8">LA</location>
        <location index="9">RT</location>
    </structure>
    <engine rating="260" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>28</ct>
        <ctr>11</ctr>
        <lt>22</lt>
        <ltr>8</ltr>
        <rt>22</rt>
        <rtr>8</rtr>
        <la>20</la>
        <ra>20</ra>
        <ll>26</ll>
        <rl>26</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Catapult CPLT-C6 65t, 4/6/4, Std FE, ES; 12.5T/95% Armor; 15 DHS; 2 PR, 2 ERML</info>
        <battleforce pv="19" wt="3" mv="4j" s="3" m="3" l="0" e="0" ov="0" armor="7" internal="5" abilities="ENE, HT1, SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="6">RT</location>
            <location index="9">LT</location>
            <location index="7">RT</location>
            <location index="10">LT</location>
        </jumpjets>
        <heatsinks number="15" techbase="0">
            <type>Double Heat Sink</type>

            <location index="0">RT</location>
            <location index="0">LT</location>
            <location index="3">RT</location>
            <location index="3">LT</location>
            <location index="6">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Plasma Rifle</name>
            <type>energy</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Plasma Rifle</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Plasma Rifle</name>
            <type>ammunition</type>
            <location index="11">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Plasma Rifle</name>
            <type>ammunition</type>
            <location index="7">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Plasma Rifle</name>
            <type>ammunition</type>
            <location index="6">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Plasma Rifle</name>
            <type>ammunition</type>
            <location index="10">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Plasma Rifle</name>
            <type>ammunition</type>
            <location index="8">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Plasma Rifle</name>
            <type>ammunition</type>
            <location index="9">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="11">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Catapult" model="CPLT-H2" tons="65" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1473</battle_value>
    <cost>6034325.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3064</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="260" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>26</ct>
        <ctr>11</ctr>
        <lt>22</lt>
        <ltr>8</ltr>
        <rt>22</rt>
        <rtr>8</rtr>
        <la>13</la>
        <ra>13</ra>
        <ll>22</ll>
        <rl>22</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Catapult CPLT-H2 65t, 4/6/4, Std FE, Std; 11.0T/83% Armor; 13 DHS; 2 ML, 1 PPC, 8 RL20</info>
        <battleforce pv="15" wt="3" mv="4j" s="3" m="3" l="1" e="0" ov="0" armor="6" internal="5" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="3">RT</location>
            <location index="4">RT</location>
            <location index="3">LT</location>
            <location index="4">LT</location>
        </jumpjets>
        <heatsinks number="13" techbase="0">
            <type>Double Heat Sink</type>

            <location index="0">RT</location>
            <location index="0">LT</location>
            <location index="2">LA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="5">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rocket Launcher 20</name>
            <type>missile</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rocket Launcher 20</name>
            <type>missile</type>
            <location index="8">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rocket Launcher 20</name>
            <type>missile</type>
            <location index="5">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rocket Launcher 20</name>
            <type>missile</type>
            <location index="8">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rocket Launcher 20</name>
            <type>missile</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rocket Launcher 20</name>
            <type>missile</type>
            <location index="8">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rocket Launcher 20</name>
            <type>missile</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rocket Launcher 20</name>
            <type>missile</type>
            <location index="5">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Catapult" model="CPLT-K2K" tons="65" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1716</battle_value>
    <cost>1.3930674999999996E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3058</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="325" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>32</ct>
        <ctr>10</ctr>
        <lt>22</lt>
        <ltr>8</ltr>
        <rt>22</rt>
        <rtr>8</rtr>
        <la>20</la>
        <ra>20</ra>
        <ll>30</ll>
        <rl>30</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Catapult CPLT-K2K 65t, 5/8/0, XLFE, Std; 13.5T/100% Armor; 20 DHS; 2 ERML, 2 ERPPC</info>
        <battleforce pv="17" wt="3" mv="5" s="3" m="3" l="2" e="0" ov="0" armor="7" internal="3" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="20" techbase="0">
            <type>Double Heat Sink</type>

            <location index="2">RA</location>
            <location index="5">RA</location>
            <location index="2">LA</location>
            <location index="5">LA</location>
            <location index="3">RT</location>
            <location index="6">RT</location>
            <location index="3">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) ER PPC</name>
            <type>energy</type>
            <location index="8">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER PPC</name>
            <type>energy</type>
            <location index="8">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Catapult" model="CPLT-K3" tons="65" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1437</battle_value>
    <cost>5843925.0</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3047</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="260" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>24</ct>
        <ctr>11</ctr>
        <lt>20</lt>
        <ltr>8</ltr>
        <rt>20</rt>
        <rtr>8</rtr>
        <la>18</la>
        <ra>18</ra>
        <ll>20</ll>
        <rl>20</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Catapult CPLT-K3 65t, 4/6/0, Std FE, Std; 11.0T/83% Armor; 20 DHS; 2 ML, 2 MG, 2 ERPPC</info>
        <battleforce pv="14" wt="3" mv="4" s="4" m="3" l="2" e="0" ov="0" armor="6" internal="5" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="20" techbase="0">
            <type>Double Heat Sink</type>

            <location index="3">LT</location>
            <location index="0">LT</location>
            <location index="6">LT</location>
            <location index="0">RT</location>
            <location index="3">RT</location>
            <location index="6">RT</location>
            <location index="2">LA</location>
            <location index="5">LA</location>
            <location index="2">RA</location>
            <location index="5">RA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="9">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="10">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="10">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Machine Gun</name>
            <type>ammunition</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER PPC</name>
            <type>energy</type>
            <location index="8">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER PPC</name>
            <type>energy</type>
            <location index="8">LA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Catapult" model="CPLT-K4" tons="65" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1880</battle_value>
    <cost>5537400.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>6</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3068</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="195" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Light Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>32</ct>
        <ctr>10</ctr>
        <lt>22</lt>
        <ltr>8</ltr>
        <rt>22</rt>
        <rtr>8</rtr>
        <la>20</la>
        <ra>20</ra>
        <ll>30</ll>
        <rl>30</rl>
        <location index="3">HD</location>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Catapult CPLT-K4 65t, 3/5/3, Std FE, Std; 12.5T/100% LFF Armor; 17 DHS; 1 C3S, 2 ERML, 2 HPPC</info>
        <battleforce pv="19" wt="3" mv="3j" s="3" m="4" l="3" e="0" ov="0" armor="7" internal="5" abilities="C3S, ENE, MHQ1, SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="3">
            <type>Standard Jump Jet</type>
            <location index="9">RT</location>
            <location index="10">CT</location>
            <location index="9">LT</location>
        </jumpjets>
        <heatsinks number="17" techbase="0">
            <type>Double Heat Sink</type>

            <location index="0">LT</location>
            <location index="3">LT</location>
            <location index="6">LT</location>
            <location index="5">LA</location>
            <location index="3">RT</location>
            <location index="0">RT</location>
            <location index="6">RT</location>
            <location index="2">RA</location>
            <location index="5">RA</location>
            <location index="2">LA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="10">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="10">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Heavy PPC</name>
            <type>energy</type>
            <location index="8">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Heavy PPC</name>
            <type>energy</type>
            <location index="8">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">C3 Computer (Slave)</name>
            <type>equipment</type>
            <location index="11">CT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Catapult" model="CPLT-K5" tons="65" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1457</battle_value>
    <cost>1.2236875E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3058</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
        <location index="11">LA</location>
        <location index="11">RA</location>
    </structure>
    <engine rating="260" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>30</ct>
        <ctr>11</ctr>
        <lt>21</lt>
        <ltr>9</ltr>
        <rt>21</rt>
        <rtr>9</rtr>
        <la>20</la>
        <ra>20</ra>
        <ll>25</ll>
        <rl>25</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Catapult CPLT-K5 65t, 4/6/4, XLFE, ES; 12.5T/95% Armor; 12 DHS; 1 C3S, 2 MRM30, 2 MPL</info>
        <battleforce pv="15" wt="3" mv="4j" s="5" m="5" l="0" e="0" ov="0" armor="7" internal="3" abilities="C3S, CASE, MHQ1, SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="3">RT</location>
            <location index="4">RT</location>
            <location index="3">LT</location>
            <location index="4">LT</location>
        </jumpjets>
        <heatsinks number="12" techbase="0">
            <type>Double Heat Sink</type>

            <location index="2">RA</location>
            <location index="2">LA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">C3 Computer (Slave)</name>
            <type>equipment</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) MRM-30</name>
            <type>missile</type>
            <location index="5">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) MRM-30</name>
            <type>missile</type>
            <location index="5">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ MRM-30</name>
            <type>ammunition</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ MRM-30</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ MRM-30</name>
            <type>ammunition</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ MRM-30</name>
            <type>ammunition</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="7">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="7">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Centurion" model="CN10-B" tons="55" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1243</battle_value>
    <cost>5095053.333333333</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3057</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="4">LT</location>
        <location index="4">LA</location>
        <location index="5">LT</location>
        <location index="5">LA</location>
        <location index="6">LT</location>
        <location index="6">RT</location>
        <location index="6">LA</location>
        <location index="7">LT</location>
        <location index="7">RT</location>
        <location index="8">RT</location>
        <location index="9">RT</location>
        <location index="9">RA</location>
        <location index="10">RA</location>
        <location index="11">RA</location>
    </structure>
    <engine rating="220" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>28</ct>
        <ctr>7</ctr>
        <lt>20</lt>
        <ltr>6</ltr>
        <rt>20</rt>
        <rtr>6</rtr>
        <la>18</la>
        <ra>18</ra>
        <ll>26</ll>
        <rl>26</rl>
    </armor>
    <baseloadout fcsa4="TRUE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Centurion CN10-B 55t, 4/6/0, Std FE, ES; 11.5T/99% Armor; 10 SHS; 1 ML, 1 LRM10, 1 MPL, 1 LB10</info>
        <battleforce pv="12" wt="2" mv="4" s="2" m="2" l="2" e="0" ov="0" armor="6" internal="5" abilities="CASE, SRCH, ES, SEAL, SOA, IF 1, FLK 1/1/1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="0">LT</location>
            <location index="0">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LB 10-X AC</name>
            <type>ballistic</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-10</name>
            <type>missile</type>
            <location index="1">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Cluster)</name>
            <type>ammunition</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Slug)</name>
            <type>ammunition</type>
            <location index="1">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="5">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Centurion" model="CN9-D" tons="50" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1130</battle_value>
    <cost>9600500.0</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3049</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="4">LA</location>
        <location index="5">LA</location>
        <location index="6">LT</location>
        <location index="6">LA</location>
        <location index="7">LT</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="9">RA</location>
        <location index="10">RT</location>
        <location index="10">RA</location>
        <location index="11">RT</location>
        <location index="11">RA</location>
    </structure>
    <engine rating="300" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>18</ct>
        <ctr>7</ctr>
        <lt>13</lt>
        <ltr>6</ltr>
        <rt>13</rt>
        <rtr>6</rtr>
        <la>16</la>
        <ra>16</ra>
        <ll>16</ll>
        <rl>16</rl>
    </armor>
    <baseloadout fcsa4="TRUE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Centurion CN9-D 50t, 6/9/0, XLFE, ES; 8.5T/80% Armor; 10 SHS; 2 ML, 1 LRM10, 1 LB10</info>
        <battleforce pv="11" wt="2" mv="6" s="2" m="2" l="2" e="0" ov="0" armor="5" internal="2" abilities="CASE, SRCH, ES, SEAL, SOA, IF 1, FLK 1/1/1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-10</name>
            <type>missile</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LB 10-X AC</name>
            <type>ballistic</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Cluster)</name>
            <type>ammunition</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Slug)</name>
            <type>ammunition</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="7">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Centurion" model="CN9-D3" tons="50" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1324</battle_value>
    <cost>1.06505E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3052</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="4">LA</location>
        <location index="5">LA</location>
        <location index="6">LT</location>
        <location index="6">LA</location>
        <location index="7">LT</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="9">RA</location>
        <location index="10">RT</location>
        <location index="10">RA</location>
        <location index="11">RT</location>
        <location index="11">RA</location>
    </structure>
    <engine rating="300" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <enhancement techbase="0">
        <type>TSM</type>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="7">LA</location>
        <location index="8">LA</location>
    </enhancement>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>18</ct>
        <ctr>7</ctr>
        <lt>13</lt>
        <ltr>6</ltr>
        <rt>13</rt>
        <rtr>6</rtr>
        <la>16</la>
        <ra>16</ra>
        <ll>16</ll>
        <rl>16</rl>
    </armor>
    <baseloadout fcsa4="TRUE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Centurion CN9-D3 50t, 6[7]/9[11]/0 TSM, XLFE, ES; 8.5T/80% Armor; 10 SHS; 2 ML, 1 LRM10, 1 LB10</info>
        <battleforce pv="13" wt="2" mv="6" s="2" m="2" l="2" e="0" ov="0" armor="5" internal="2" abilities="TSM, CASE, SRCH, ES, SEAL, SOA, IF 1, FLK 1/1/1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) LB 10-X AC</name>
            <type>ballistic</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Slug)</name>
            <type>ammunition</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Cluster)</name>
            <type>ammunition</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-10</name>
            <type>missile</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="7">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Centurion" model="CN9-D3D" tons="50" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1373</battle_value>
    <cost>1.05685E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3060</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="4">RT</location>
        <location index="4">RL</location>
        <location index="5">RT</location>
        <location index="5">RL</location>
        <location index="6">RT</location>
        <location index="7">RT</location>
        <location index="8">RT</location>
        <location index="9">RT</location>
        <location index="10">CT</location>
        <location index="10">RT</location>
        <location index="10">RA</location>
        <location index="11">CT</location>
        <location index="11">RT</location>
        <location index="11">RA</location>
    </structure>
    <engine rating="300" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <enhancement techbase="0">
        <type>MASC</type>
        <location index="4">LA</location>
    </enhancement>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>20</ct>
        <ctr>6</ctr>
        <lt>15</lt>
        <ltr>5</ltr>
        <rt>15</rt>
        <rtr>5</rtr>
        <la>14</la>
        <ra>14</ra>
        <ll>20</ll>
        <rl>20</rl>
        <location index="3">HD</location>
        <location index="4">LL</location>
        <location index="5">LL</location>
        <location index="6">LT</location>
        <location index="7">LT</location>
        <location index="7">LA</location>
        <location index="8">LT</location>
        <location index="8">LA</location>
        <location index="9">LT</location>
        <location index="9">LA</location>
        <location index="10">LT</location>
        <location index="10">LA</location>
        <location index="11">LT</location>
        <location index="11">LA</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Centurion CN9-D3D 50t, 6/9[12]/0 MASC, XLFE, ES; 8.0T/85% FF Armor; 10 SHS; 1 ERML, 1 LRM10, 1 LGR</info>
        <battleforce pv="14" wt="2" mv="8" s="2" m="2" l="2" e="0" ov="0" armor="5" internal="2" abilities="SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) LRM-10</name>
            <type>missile</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Light Gauss Rifle</name>
            <type>ballistic</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Light Gauss Rifle</name>
            <type>ammunition</type>
            <location index="9">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Light Gauss Rifle</name>
            <type>ammunition</type>
            <location index="8">RA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Centurion" model="CN9-D4D" tons="50" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1369</battle_value>
    <cost>9666250.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3066</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="4">RT</location>
        <location index="4">RL</location>
        <location index="5">RT</location>
        <location index="5">RL</location>
        <location index="6">RT</location>
        <location index="7">RT</location>
        <location index="8">RT</location>
        <location index="9">RT</location>
        <location index="9">RA</location>
        <location index="10">CT</location>
        <location index="10">RT</location>
        <location index="10">RA</location>
        <location index="11">RT</location>
        <location index="11">RA</location>
    </structure>
    <engine rating="250" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <enhancement techbase="0">
        <type>MASC</type>
        <location index="2">LA</location>
    </enhancement>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>20</ct>
        <ctr>6</ctr>
        <lt>15</lt>
        <ltr>5</ltr>
        <rt>15</rt>
        <rtr>5</rtr>
        <la>14</la>
        <ra>14</ra>
        <ll>20</ll>
        <rl>20</rl>
        <location index="3">HD</location>
        <location index="4">LL</location>
        <location index="5">LA</location>
        <location index="5">LL</location>
        <location index="6">LA</location>
        <location index="7">LA</location>
        <location index="8">LA</location>
        <location index="9">LT</location>
        <location index="9">LA</location>
        <location index="10">LT</location>
        <location index="10">LA</location>
        <location index="11">CT</location>
        <location index="11">LT</location>
        <location index="11">LA</location>
    </armor>
    <baseloadout fcsa4="TRUE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Centurion CN9-D4D 50t, 5/8[10]/0 MASC, XLFE, ES; 8.0T/85% FF Armor; 10 SHS; 1 LRM15, 1 ERML, 1 LGR</info>
        <battleforce pv="14" wt="2" mv="6" s="2" m="3" l="2" e="0" ov="0" armor="5" internal="2" abilities="SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="FALSE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Light Gauss Rifle</name>
            <type>ballistic</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Light Gauss Rifle</name>
            <type>ammunition</type>
            <location index="8">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="7">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="8">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Centurion" model="CN9-D5" tons="50" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1738</battle_value>
    <cost>1.0992E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3062</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="4">LA</location>
        <location index="5">LA</location>
        <location index="6">LA</location>
        <location index="7">RT</location>
        <location index="7">LA</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="9">RA</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="10">RA</location>
        <location index="11">LT</location>
        <location index="11">RA</location>
    </structure>
    <engine rating="300" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <enhancement techbase="0">
        <type>MASC</type>
        <location index="6">LT</location>
    </enhancement>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>22</ct>
        <ctr>7</ctr>
        <lt>17</lt>
        <ltr>6</ltr>
        <rt>17</rt>
        <rtr>6</rtr>
        <la>16</la>
        <ra>16</ra>
        <ll>18</ll>
        <rl>18</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Centurion CN9-D5 50t, 6/9[12]/0 MASC, XLFE, ES; 9.5T/90% Armor; 10 DHS; 1 C3S, 1 RAC5, 2 ERML, TC</info>
        <battleforce pv="17" wt="2" mv="8" s="4" m="4" l="0" e="0" ov="0" armor="5" internal="2" abilities="C3S, CASE, MHQ1, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rotary AC/5</name>
            <type>ballistic</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Rotary AC/5</name>
            <type>ammunition</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Rotary AC/5</name>
            <type>ammunition</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Rotary AC/5</name>
            <type>ammunition</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">C3 Computer (Slave)</name>
            <type>equipment</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Targeting Computer</name>
            <type>TargetingComputer</type>
            <location index="3">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Centurion" model="CN9-D9" tons="50" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1638</battle_value>
    <cost>1.15535E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>6</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3071</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="4">LA</location>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">LA</location>
        <location index="5">RA</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="6">RA</location>
        <location index="9">LT</location>
        <location index="10">CT</location>
        <location index="10">LT</location>
        <location index="11">CT</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
    </structure>
    <engine rating="300" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="0">Compact Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Light Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>21</ct>
        <ctr>6</ctr>
        <lt>16</lt>
        <ltr>6</ltr>
        <rt>16</rt>
        <rtr>6</rtr>
        <la>16</la>
        <ra>16</ra>
        <ll>20</ll>
        <rl>20</rl>
        <location index="3">HD</location>
        <location index="6">LA</location>
        <location index="7">LA</location>
        <location index="7">RA</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">RA</location>
    </armor>
    <baseloadout fcsa4="TRUE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Centurion CN9-D9 50t, 6/9/6, XLFE, ES, Cmp Gyro; 9.0T/90% LFF Armor; 10 DHS; 1 PR, 2 ERML, 1 LRM10</info>
        <battleforce pv="16" wt="2" mv="6j" s="2" m="3" l="1" e="0" ov="0" armor="5" internal="2" abilities="CASE, SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="6">
            <type>Standard Jump Jet</type>
            <location index="3">LT</location>
            <location index="4">RT</location>
            <location index="5">RT</location>
            <location index="3">RT</location>
            <location index="5">LT</location>
            <location index="4">LT</location>
        </jumpjets>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="8">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="9">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Plasma Rifle</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-10</name>
            <type>missile</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Plasma Rifle</name>
            <type>ammunition</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Plasma Rifle</name>
            <type>ammunition</type>
            <location index="7">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="8">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="9">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="10">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Centurion" model="CN9-YLW2 &quot;Yen Lo Wang&quot;" tons="50" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1435</battle_value>
    <cost>7653499.999999999</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3051</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="200" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <enhancement techbase="0">
        <type>TSM</type>
        <location index="4">LT</location>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">RT</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
    </enhancement>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>18</ct>
        <ctr>7</ctr>
        <lt>13</lt>
        <ltr>6</ltr>
        <rt>13</rt>
        <rtr>6</rtr>
        <la>16</la>
        <ra>16</ra>
        <ll>16</ll>
        <rl>16</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Centurion CN9-YLW2 "Yen Lo Wang" 50t, 4[5]/6[8]/0 TSM, XLFE, Std; 8.5T/80% Armor; 10 SHS; 1 GR, 3 MPL, 1 Htcht</info>
        <battleforce pv="14" wt="2" mv="4" s="3" m="3" l="2" e="0" ov="0" armor="5" internal="2" abilities="TSM, MEL, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="3">LT</location>
            <location index="10">CT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="5">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Gauss Rifle</name>
            <type>ballistic</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">Hatchet</name>
            <type>physical</type>
            <location index="6">LA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Charger" model="CGR-2A2" tons="80" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1109</battle_value>
    <cost>7770120.000000001</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3064</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="400" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>26</ct>
        <ctr>6</ctr>
        <lt>20</lt>
        <ltr>5</ltr>
        <rt>20</rt>
        <rtr>5</rtr>
        <la>15</la>
        <ra>15</ra>
        <ll>20</ll>
        <rl>20</rl>
        <location index="2">LT</location>
        <location index="2">RT</location>
        <location index="3">LT</location>
        <location index="3">RT</location>
        <location index="4">LT</location>
        <location index="4">RT</location>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">LT</location>
        <location index="5">RT</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="10">CT</location>
        <location index="11">CT</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Charger CGR-2A2 80t, 5/8/0, Std FE, Std; 9.0T/65% FF Armor; 10 SHS; 1 ML, 5 RL10</info>
        <battleforce pv="11" wt="4" mv="5" s="1" m="1" l="1" e="0" ov="0" armor="5" internal="6" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rocket Launcher 10</name>
            <type>missile</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rocket Launcher 10</name>
            <type>missile</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rocket Launcher 10</name>
            <type>missile</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rocket Launcher 10</name>
            <type>missile</type>
            <location index="1">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rocket Launcher 10</name>
            <type>missile</type>
            <location index="1">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Charger" model="CGR-3K" tons="80" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1656</battle_value>
    <cost>2.124072E7</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3049</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="400" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>27</ct>
        <ctr>7</ctr>
        <lt>24</lt>
        <ltr>7</ltr>
        <rt>24</rt>
        <rtr>7</rtr>
        <la>15</la>
        <ra>15</ra>
        <ll>22</ll>
        <rl>22</rl>
        <location index="4">LA</location>
        <location index="5">LA</location>
        <location index="5">RA</location>
        <location index="6">LA</location>
        <location index="6">RA</location>
        <location index="7">LA</location>
        <location index="7">RA</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
        <location index="11">RA</location>
    </armor>
    <baseloadout fcsa4="TRUE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Charger CGR-3K 80t, 5/8/5, XLFE, Std; 10.0T/72% FF Armor; 12 DHS; 4 MPL, 1 LRM20</info>
        <battleforce pv="17" wt="4" mv="5j" s="4" m="5" l="2" e="0" ov="0" armor="6" internal="4" abilities="CASE, SRCH, ES, SEAL, SOA, IF 2" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="5">
            <type>Standard Jump Jet</type>
            <location index="5">LL</location>
            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="5">RL</location>
            <location index="10">CT</location>
        </jumpjets>
        <heatsinks number="12" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) LRM-20</name>
            <type>missile</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="9">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="6">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Charger" model="CGR-C" tons="80" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1592</battle_value>
    <cost>2.164272E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3053</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="400" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>27</ct>
        <ctr>7</ctr>
        <lt>24</lt>
        <ltr>7</ltr>
        <rt>24</rt>
        <rtr>7</rtr>
        <la>15</la>
        <ra>15</ra>
        <ll>22</ll>
        <rl>22</rl>
        <location index="4">LA</location>
        <location index="5">LA</location>
        <location index="5">RA</location>
        <location index="6">LA</location>
        <location index="6">RA</location>
        <location index="7">LA</location>
        <location index="7">RA</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
        <location index="11">RA</location>
    </armor>
    <baseloadout fcsa4="TRUE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Charger CGR-C 80t, 5/8/5, XLFE, Std; 10.0T/72% FF Armor; 12 DHS; 1 C3S, 3 MPL, 1 LRM20</info>
        <battleforce pv="16" wt="4" mv="5j" s="3" m="4" l="2" e="0" ov="0" armor="6" internal="4" abilities="C3S, CASE, MHQ1, SRCH, ES, SEAL, SOA, IF 2" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="5">
            <type>Standard Jump Jet</type>
            <location index="5">LL</location>
            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="5">RL</location>
            <location index="10">CT</location>
        </jumpjets>
        <heatsinks number="12" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-20</name>
            <type>missile</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">C3 Computer (Slave)</name>
            <type>equipment</type>
            <location index="9">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="7">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Charger" model="CGR-KMZ" tons="80" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1781</battle_value>
    <cost>2.0139720000000004E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>6</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3071</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="320" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Light Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>27</ct>
        <ctr>8</ctr>
        <lt>24</lt>
        <ltr>8</ltr>
        <rt>24</rt>
        <rtr>8</rtr>
        <la>17</la>
        <ra>17</ra>
        <ll>22</ll>
        <rl>22</rl>
        <location index="7">LA</location>
        <location index="8">LA</location>
        <location index="9">LA</location>
        <location index="10">LA</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
        <location index="11">LA</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Charger CGR-KMZ 80t, 4/6/6, XLFE, Std; 11.0T/75% LFF Armor; 12 DHS; 1 MML9, 2 LPPC, 1 HPPC, 1 Swrd</info>
        <battleforce pv="18" wt="4" mv="4/6j" s="3" m="3" l="3" e="0" ov="1" armor="6" internal="4" abilities="MEL, CASE, SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="6">
            <type>Improved Jump Jet</type>
            <location index="4">RL</location>
            <location index="3">LT</location>
            <location index="5">LT</location>
            <location index="3">RT</location>
            <location index="10">CT</location>
            <location index="4">LL</location>
        </jumpjets>
        <heatsinks number="12" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">Sword</name>
            <type>physical</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Heavy PPC</name>
            <type>energy</type>
            <location index="7">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Light PPC</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Light PPC</name>
            <type>energy</type>
            <location index="5">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) MML-9</name>
            <type>missile</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ MML-9 (LRM)</name>
            <type>ammunition</type>
            <location index="10">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ MML-9 (SRM)</name>
            <type>ammunition</type>
            <location index="11">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="10">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Charger" model="CGR-SA5" tons="80" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>2105</battle_value>
    <cost>1.766252E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3063</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="4">RA</location>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">RA</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="6">RA</location>
        <location index="7">LA</location>
        <location index="7">RA</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">RA</location>
    </structure>
    <engine rating="320" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">Light Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <enhancement techbase="0">
        <type>MASC</type>
        <location index="7">LT</location>
    </enhancement>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>40</ct>
        <ctr>10</ctr>
        <lt>27</lt>
        <ltr>7</ltr>
        <rt>27</rt>
        <rtr>7</rtr>
        <la>26</la>
        <ra>26</ra>
        <ll>34</ll>
        <rl>34</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Charger CGR-SA5 80t, 4/6[8]/0 MASC, LFE, ES; 15.5T/100% Armor; 12 DHS; 3 ERML, 2 SSRM6, 1 LB20</info>
        <battleforce pv="21" wt="4" mv="5" s="4" m="4" l="0" e="0" ov="1" armor="8" internal="4" abilities="CASE, SRCH, ES, SEAL, SOA, FLK 1/1/0" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="12" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) LB 20-X AC</name>
            <type>ballistic</type>
            <splitlocation index="2" number="9">RT</splitlocation>
            <splitlocation index="10" number="2">CT</splitlocation>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="11">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Streak SRM-6</name>
            <type>missile</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Streak SRM-6</name>
            <type>missile</type>
            <location index="5">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Streak SRM-6</name>
            <type>ammunition</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 20-X AC (Slug)</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 20-X AC (Slug)</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 20-X AC (Cluster)</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="11">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Cicada" model="CDA-3F" tons="40" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1329</battle_value>
    <cost>8687466.666666668</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3052</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="2">LA</location>
        <location index="2">RA</location>
        <location index="3">LA</location>
        <location index="3">RA</location>
        <location index="4">LA</location>
        <location index="4">RA</location>
        <location index="5">LA</location>
        <location index="5">RA</location>
        <location index="6">LA</location>
        <location index="6">RA</location>
        <location index="7">LA</location>
        <location index="7">RA</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
    </structure>
    <engine rating="320" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>15</ct>
        <ctr>6</ctr>
        <lt>15</lt>
        <ltr>5</ltr>
        <rt>15</rt>
        <rtr>5</rtr>
        <la>8</la>
        <ra>8</ra>
        <ll>15</ll>
        <rl>15</rl>
        <location index="6">RT</location>
        <location index="7">RT</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">CT</location>
        <location index="10">LT</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
        <location index="11">CT</location>
        <location index="11">LT</location>
        <location index="11">LA</location>
        <location index="11">RA</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Cicada CDA-3F 40t, 8/12/8, XLFE, ES; 6.5T/85% FF Armor; 10 DHS; 2 ML, 1 ERPPC</info>
        <battleforce pv="13" wt="2" mv="8j" s="2" m="2" l="1" e="0" ov="0" armor="4" internal="2" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="8">
            <type>Standard Jump Jet</type>
            <location index="4">LT</location>
            <location index="3">LT</location>
            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="4">RT</location>
            <location index="3">RT</location>
            <location index="5">RL</location>
            <location index="5">LL</location>
        </jumpjets>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER PPC</name>
            <type>energy</type>
            <location index="5">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional>&lt;p style=&quot;margin-top: 0&quot;&gt;:br::br:&lt;/p&gt;:br:</additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Cicada" model="CDA-3G" tons="40" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1270</battle_value>
    <cost>8841466.666666666</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3056</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="2">LA</location>
        <location index="2">RA</location>
        <location index="3">LA</location>
        <location index="3">RA</location>
        <location index="4">LA</location>
        <location index="4">RA</location>
        <location index="5">LA</location>
        <location index="5">RA</location>
        <location index="6">LA</location>
        <location index="6">RA</location>
        <location index="7">LA</location>
        <location index="7">RA</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
    </structure>
    <engine rating="320" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>16</ct>
        <ctr>6</ctr>
        <lt>15</lt>
        <ltr>5</ltr>
        <rt>15</rt>
        <rtr>5</rtr>
        <la>10</la>
        <ra>10</ra>
        <ll>17</ll>
        <rl>17</rl>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">CT</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
        <location index="11">CT</location>
        <location index="11">LA</location>
        <location index="11">RA</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Cicada CDA-3G 40t, 8/12/8, XLFE, ES; 7.0T/91% FF Armor; 10 DHS; 2 ML, 1 ERLL, 1 BAP</info>
        <battleforce pv="13" wt="2" mv="8j" s="2" m="2" l="1" e="0" ov="0" armor="4" internal="2" abilities="PRB, RCN, ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="8">
            <type>Standard Jump Jet</type>
            <location index="4">LT</location>
            <location index="3">LT</location>
            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="3">RT</location>
            <location index="4">RT</location>
            <location index="5">RL</location>
            <location index="5">LL</location>
        </jumpjets>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="7">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Large Laser</name>
            <type>energy</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">Beagle Active Probe</name>
            <type>equipment</type>
            <location index="6">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional>&lt;p style=&quot;margin-top: 0&quot;&gt;:br::br:&lt;/p&gt;:br:</additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Cicada" model="CDA-3M" tons="40" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>812</battle_value>
    <cost>7738866.666666667</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3049</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="320" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>11</ct>
        <ctr>6</ctr>
        <lt>6</lt>
        <ltr>3</ltr>
        <rt>6</rt>
        <rtr>3</rtr>
        <la>4</la>
        <ra>4</ra>
        <ll>6</ll>
        <rl>6</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Cicada CDA-3M 40t, 8/12/0, XLFE, Std; 4.0T/47% Armor; 10 DHS; 2 ML, 1 SPL, 1 UAC5</info>
        <battleforce pv="8" wt="2" mv="8" s="2" m="2" l="1" e="0" ov="0" armor="2" internal="2" abilities="CASE, SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Pulse Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Ultra AC/5</name>
            <type>ballistic</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Ultra AC/5</name>
            <type>ammunition</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="5">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Cicada" model="CDA-3MA" tons="40" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1158</battle_value>
    <cost>7862866.666666667</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>6</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3068</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="320" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>17</ct>
        <ctr>6</ctr>
        <lt>15</lt>
        <ltr>5</ltr>
        <rt>15</rt>
        <rtr>5</rtr>
        <la>12</la>
        <ra>12</ra>
        <ll>20</ll>
        <rl>20</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Cicada CDA-3MA 40t, 8/12/0, XLFE, Std; 8.5T/99% Armor; 10 DHS; 2 ML, 1 SNPPC, 1 SPL</info>
        <battleforce pv="12" wt="2" mv="8" s="3" m="2" l="0" e="0" ov="0" armor="5" internal="2" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Pulse Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Snub-Nose PPC</name>
            <type>energy</type>
            <location index="3">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional>&lt;p style=&quot;margin-top: 0&quot;&gt;:br::br:&lt;/p&gt;:br:</additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Cicada" model="CDA-3P" tons="40" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1502</battle_value>
    <cost>7082133.333333332</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>6</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3070</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="280" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>16</ct>
        <ctr>5</ctr>
        <lt>14</lt>
        <ltr>5</ltr>
        <rt>14</rt>
        <rtr>5</rtr>
        <la>11</la>
        <ra>11</ra>
        <ll>19</ll>
        <rl>19</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Cicada CDA-3P 40t, 7/11/0, XLFE, Std; 8.0T/93% Armor; 10 DHS; 4 ERML, 1 HPPC</info>
        <battleforce pv="15" wt="2" mv="7" s="2" m="3" l="1" e="0" ov="1" armor="4" internal="2" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="7">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Heavy PPC</name>
            <type>energy</type>
            <location index="3">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional>&lt;p style=&quot;margin-top: 0&quot;&gt;:br::br:&lt;/p&gt;:br:</additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Clint" model="CLNT-2-3U" tons="40" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1081</battle_value>
    <cost>3951079.9999999995</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3050</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="240" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>11</ct>
        <ctr>4</ctr>
        <lt>6</lt>
        <ltr>4</ltr>
        <rt>6</rt>
        <rtr>4</rtr>
        <la>6</la>
        <ra>6</ra>
        <ll>8</ll>
        <rl>8</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Clint CLNT-2-3U 40t, 6/9/6, Std FE, Std; 4.5T/53% Armor; 10 DHS; 2 MPL, 1 ERPPC</info>
        <battleforce pv="11" wt="2" mv="6j" s="2" m="2" l="1" e="0" ov="1" armor="2" internal="3" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="6">
            <type>Standard Jump Jet</type>
            <location index="5">LL</location>
            <location index="4">RL</location>
            <location index="4">LL</location>
            <location index="3">RT</location>
            <location index="5">RL</location>
            <location index="0">LT</location>
        </jumpjets>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

            <location index="0">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) ER PPC</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="1">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Clint" model="CLNT-2-3UL" tons="40" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1245</battle_value>
    <cost>3981079.9999999995</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>6</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3069</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="240" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>11</ct>
        <ctr>4</ctr>
        <lt>6</lt>
        <ltr>4</ltr>
        <rt>6</rt>
        <rtr>4</rtr>
        <la>6</la>
        <ra>6</ra>
        <ll>8</ll>
        <rl>8</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Clint CLNT-2-3UL 40t, 6/9/6, Std FE, Std; 4.5T/53% Armor; 10 DHS; 1 PR, 2 ERML</info>
        <battleforce pv="12" wt="2" mv="6j" s="2" m="2" l="0" e="0" ov="0" armor="2" internal="3" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="6">
            <type>Standard Jump Jet</type>
            <location index="5">LL</location>
            <location index="4">RL</location>
            <location index="4">LL</location>
            <location index="3">RT</location>
            <location index="5">RL</location>
            <location index="0">LT</location>
        </jumpjets>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

            <location index="0">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="1">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Plasma Rifle</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Plasma Rifle</name>
            <type>ammunition</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Plasma Rifle</name>
            <type>ammunition</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Plasma Rifle</name>
            <type>ammunition</type>
            <location index="5">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Clint" model="CLNT-3-3T" tons="40" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>901</battle_value>
    <cost>3785079.9999999995</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>6</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3069</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="240" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>12</ct>
        <ctr>4</ctr>
        <lt>12</lt>
        <ltr>4</ltr>
        <rt>12</rt>
        <rtr>4</rtr>
        <la>9</la>
        <ra>9</ra>
        <ll>16</ll>
        <rl>16</rl>
        <location index="2">LT</location>
        <location index="3">LT</location>
        <location index="4">LT</location>
        <location index="5">LT</location>
        <location index="5">RT</location>
        <location index="6">LT</location>
        <location index="6">RT</location>
        <location index="7">LT</location>
        <location index="7">RT</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="11">CT</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Clint CLNT-3-3T 40t, 6/9/6, Std FE, Std; 6.0T/78% FF Armor; 10 SHS; 2 ML, 1 LAC5</info>
        <battleforce pv="9" wt="2" mv="6j" s="2" m="2" l="0" e="0" ov="0" armor="4" internal="3" abilities="CASE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="6">
            <type>Standard Jump Jet</type>
            <location index="4">RL</location>
            <location index="5">RL</location>
            <location index="5">LL</location>
            <location index="1">RT</location>
            <location index="0">LT</location>
            <location index="4">LL</location>
        </jumpjets>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="0">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Light AC/5</name>
            <type>ballistic</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Light AC/5</name>
            <type>ammunition</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Light AC/5</name>
            <type>ammunition</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="1">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="4">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Clint" model="CLNT-5U" tons="40" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1275</battle_value>
    <cost>6324080.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3062</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="3">HD</location>
        <location index="6">LT</location>
        <location index="6">RT</location>
        <location index="7">LT</location>
        <location index="7">RT</location>
        <location index="7">LA</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
    </structure>
    <engine rating="240" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">Light Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>16</ct>
        <ctr>5</ctr>
        <lt>14</lt>
        <ltr>5</ltr>
        <rt>14</rt>
        <rtr>5</rtr>
        <la>11</la>
        <ra>11</ra>
        <ll>19</ll>
        <rl>19</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Clint CLNT-5U 40t, 6/9/6, LFE, ES; 8.0T/93% Armor; 12 DHS; 1 ERLL, 1 C3S, 3 ERML, 1 TAG</info>
        <battleforce pv="13" wt="2" mv="6j" s="2" m="2" l="1" e="0" ov="1" armor="4" internal="2" abilities="C3S, TAG, ENE, MHQ1, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="6">
            <type>Standard Jump Jet</type>
            <location index="4">RL</location>
            <location index="5">LL</location>
            <location index="5">RT</location>
            <location index="2">LT</location>
            <location index="5">RL</location>
            <location index="4">LL</location>
        </jumpjets>
        <heatsinks number="12" techbase="0">
            <type>Double Heat Sink</type>

            <location index="2">RT</location>
            <location index="4">LA</location>
            <location index="3">RA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) ER Large Laser</name>
            <type>energy</type>
            <location index="6">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">C3 Computer (Slave)</name>
            <type>equipment</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">TAG</name>
            <type>equipment</type>
            <location index="11">CT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Clint" model="CLNT-6S" tons="40" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1334</battle_value>
    <cost>6213130.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>6</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3069</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="2">RT</location>
        <location index="3">RT</location>
        <location index="4">LT</location>
        <location index="4">RT</location>
        <location index="5">LT</location>
        <location index="5">RT</location>
        <location index="6">LT</location>
        <location index="6">RT</location>
        <location index="7">LT</location>
        <location index="7">RT</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="10">LT</location>
    </structure>
    <engine rating="280" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">Light Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Small Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Heavy Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>16</ct>
        <ctr>5</ctr>
        <lt>14</lt>
        <ltr>5</ltr>
        <rt>14</rt>
        <rtr>5</rtr>
        <la>11</la>
        <ra>11</ra>
        <ll>19</ll>
        <rl>19</rl>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">HD</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="7">LA</location>
        <location index="7">RA</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">RT</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">CT</location>
        <location index="10">RT</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
        <location index="11">CT</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
        <location index="11">LA</location>
        <location index="11">RA</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Clint CLNT-6S 40t, 7/11/0, LFE, ES; 6.5T/93% HFF Armor; 12 DHS; 2 ERLL, 2 ERML, 1 ERSL</info>
        <battleforce pv="13" wt="2" mv="7" s="3" m="3" l="2" e="0" ov="0" armor="4" internal="2" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="12" techbase="0">
            <type>Double Heat Sink</type>

            <location index="4">LA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) ER Large Laser</name>
            <type>energy</type>
            <location index="5">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Large Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Small Laser</name>
            <type>energy</type>
            <location index="4">HD</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Commando" model="COM-1B" tons="25" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>616</battle_value>
    <cost>1813250.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3015</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="150" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>6</hd>
        <ct>8</ct>
        <ctr>4</ctr>
        <lt>6</lt>
        <ltr>3</ltr>
        <rt>6</rt>
        <rtr>3</rtr>
        <la>6</la>
        <ra>6</ra>
        <ll>8</ll>
        <rl>8</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Commando COM-1B 25t, 6/9/0, Std FE, Std; 4.0T/72% Armor; 10 SHS; 1 ML, 1 LL, 1 SRM2</info>
        <battleforce pv="6" wt="1" mv="6" s="2" m="2" l="0" e="0" ov="0" armor="2" internal="2" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="1">LT</location>
            <location index="0">LT</location>
            <location index="0">RT</location>
            <location index="1">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-2</name>
            <type>missile</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-2</name>
            <type>ammunition</type>
            <location index="2">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Commando" model="COM-1C" tons="25" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>458</battle_value>
    <cost>1743500.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3020</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="150" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>6</hd>
        <ct>8</ct>
        <ctr>4</ctr>
        <lt>6</lt>
        <ltr>3</ltr>
        <rt>6</rt>
        <rtr>3</rtr>
        <la>6</la>
        <ra>6</ra>
        <ll>8</ll>
        <rl>8</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Commando COM-1C 25t, 6/9/0, Std FE, Std; 4.0T/72% Armor; 10 SHS; 1 ML, 1 AC2</info>
        <battleforce pv="5" wt="1" mv="6" s="1" m="1" l="1" e="0" ov="0" armor="2" internal="2" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="1">LT</location>
            <location index="0">LT</location>
            <location index="0">RT</location>
            <location index="1">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Autocannon/2</name>
            <type>ballistic</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/2</name>
            <type>ammunition</type>
            <location index="2">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Commando" model="COM-4H" tons="25" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>691</battle_value>
    <cost>1923750.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3058</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="150" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>6</hd>
        <ct>8</ct>
        <ctr>4</ctr>
        <lt>6</lt>
        <ltr>3</ltr>
        <rt>6</rt>
        <rtr>3</rtr>
        <la>6</la>
        <ra>6</ra>
        <ll>8</ll>
        <rl>8</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Commando COM-4H 25t, 6/9/0, Std FE, Std; 4.0T/72% Armor; 10 SHS; 6 RL15, 2 ML</info>
        <battleforce pv="7" wt="1" mv="6" s="2" m="2" l="0" e="0" ov="0" armor="2" internal="2" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="1">LT</location>
            <location index="0">LT</location>
            <location index="1">RT</location>
            <location index="0">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="5">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rocket Launcher 15</name>
            <type>missile</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rocket Launcher 15</name>
            <type>missile</type>
            <location index="6">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rocket Launcher 15</name>
            <type>missile</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rocket Launcher 15</name>
            <type>missile</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rocket Launcher 15</name>
            <type>missile</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rocket Launcher 15</name>
            <type>missile</type>
            <location index="4">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Commando" model="COM-5S" tons="25" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>557</battle_value>
    <cost>2179500.0</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3050</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="3">LT</location>
        <location index="4">LT</location>
        <location index="4">RT</location>
        <location index="5">LT</location>
        <location index="5">RT</location>
        <location index="6">LT</location>
        <location index="6">RT</location>
        <location index="7">LT</location>
        <location index="7">RT</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="10">RT</location>
    </structure>
    <engine rating="150" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>5</hd>
        <ct>8</ct>
        <ctr>2</ctr>
        <lt>6</lt>
        <ltr>2</ltr>
        <rt>6</rt>
        <rtr>2</rtr>
        <la>5</la>
        <ra>5</ra>
        <ll>6</ll>
        <rl>6</rl>
        <location index="5">LA</location>
        <location index="5">RA</location>
        <location index="6">LA</location>
        <location index="6">RA</location>
        <location index="7">LA</location>
        <location index="7">RA</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
        <location index="11">LA</location>
        <location index="11">RA</location>
    </armor>
    <baseloadout fcsa4="TRUE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Commando COM-5S 25t, 6/9/0, Std FE, ES; 3.0T/60% FF Armor; 10 SHS; 1 ML, 1 SSRM2, 1 SRM6</info>
        <battleforce pv="6" wt="1" mv="6" s="2" m="2" l="0" e="0" ov="0" armor="2" internal="2" abilities="CASE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">LL</location>
            <location index="5">LL</location>
            <location index="4">RL</location>
            <location index="5">RL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="1">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Streak SRM-2</name>
            <type>missile</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Streak SRM-2</name>
            <type>ammunition</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="3">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Commando" model="COM-7B" tons="25" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>602</battle_value>
    <cost>2769500.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>6</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3068</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="4">HD</location>
        <location index="4">RA</location>
        <location index="5">HD</location>
        <location index="5">RA</location>
        <location index="6">RA</location>
        <location index="7">RT</location>
        <location index="7">LA</location>
        <location index="8">RT</location>
        <location index="8">LA</location>
        <location index="9">RT</location>
        <location index="9">LA</location>
        <location index="10">RT</location>
        <location index="10">LA</location>
        <location index="11">LT</location>
    </structure>
    <engine rating="150" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">Light Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Small Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>8</ct>
        <ctr>3</ctr>
        <lt>8</lt>
        <ltr>2</ltr>
        <rt>8</rt>
        <rtr>2</rtr>
        <la>6</la>
        <ra>6</ra>
        <ll>10</ll>
        <rl>10</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Commando COM-7B 25t, 6/9/4, LFE, ES; 4.5T/81% Armor; 10 DHS; 2 Flmr, 1 ERML, 2 SRM4</info>
        <battleforce pv="6" wt="1" mv="6/3j" s="3" m="2" l="0" e="0" ov="0" armor="2" internal="1" abilities="CASE, SRCH, ES, SEAL, SOA, SRM 1/1/0" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="4">LL</location>
            <location index="5">LL</location>
            <location index="4">RL</location>
            <location index="5">RL</location>
        </jumpjets>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

            <location index="5">LT</location>
            <location index="8">LT</location>
            <location index="2">RT</location>
            <location index="2">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="5">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="6">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-4</name>
            <type>missile</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-4</name>
            <type>missile</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4</name>
            <type>ammunition</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="6">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Commando" model="COM-7S" tons="25" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>658</battle_value>
    <cost>2938000.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3059</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="5">LT</location>
        <location index="5">RT</location>
        <location index="6">LT</location>
        <location index="6">RT</location>
        <location index="7">LT</location>
        <location index="7">RT</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
    </structure>
    <engine rating="150" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">Light Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>5</hd>
        <ct>8</ct>
        <ctr>3</ctr>
        <lt>7</lt>
        <ltr>2</ltr>
        <rt>7</rt>
        <rtr>2</rtr>
        <la>5</la>
        <ra>5</ra>
        <ll>6</ll>
        <rl>6</rl>
    </armor>
    <baseloadout fcsa4="TRUE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Commando COM-7S 25t, 6/9/0, LFE, ES; 3.5T/63% Armor; 10 DHS; 2 SSRM2, 2 ERML, 1 SRM4</info>
        <battleforce pv="7" wt="1" mv="6" s="3" m="3" l="0" e="0" ov="0" armor="2" internal="1" abilities="CASE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

            <location index="4">RA</location>
            <location index="2">LT</location>
            <location index="4">LA</location>
            <location index="7">LA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="10">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="11">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-4</name>
            <type>missile</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Streak SRM-2</name>
            <type>missile</type>
            <location index="7">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Streak SRM-2</name>
            <type>missile</type>
            <location index="8">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Streak SRM-2</name>
            <type>ammunition</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="4">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Cyclops" model="CP-11-A-DC" tons="90" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1507</battle_value>
    <cost>1.005656E7</cost>
    <rules_level>2</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3045</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="360" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="TRUE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>30</ct>
        <ctr>11</ctr>
        <lt>20</lt>
        <ltr>8</ltr>
        <rt>20</rt>
        <rtr>8</rtr>
        <la>10</la>
        <ra>10</ra>
        <ll>17</ll>
        <rl>17</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Cyclops CP-11-A-DC 90t, 4/6/0, Std FE, Std; 10.0T/57% Armor; 11 SHS; 2 ML, 1 GR, 1 LRM10, 1 SRM4</info>
        <battleforce pv="15" wt="4" mv="4" s="3" m="4" l="2" e="0" ov="0" armor="5" internal="7" abilities="SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="11" techbase="2">
            <type>Single Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-10</name>
            <type>missile</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-4</name>
            <type>missile</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4</name>
            <type>ammunition</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Gauss Rifle</name>
            <type>ballistic</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="7">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Cyclops" model="CP-11-A" tons="90" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1547</battle_value>
    <cost>9160360.0</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3045</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="360" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>30</ct>
        <ctr>11</ctr>
        <lt>20</lt>
        <ltr>8</ltr>
        <rt>20</rt>
        <rtr>8</rtr>
        <la>10</la>
        <ra>10</ra>
        <ll>17</ll>
        <rl>17</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Cyclops CP-11-A 90t, 4/6/0, Std FE, Std; 10.0T/57% Armor; 12 SHS; 2 ML, 1 GR, 1 LRM10, 1 SRM4</info>
        <battleforce pv="15" wt="4" mv="4" s="4" m="4" l="3" e="0" ov="0" armor="5" internal="7" abilities="SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="12" techbase="2">
            <type>Single Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-10</name>
            <type>missile</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-4</name>
            <type>missile</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4</name>
            <type>ammunition</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Gauss Rifle</name>
            <type>ballistic</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="8">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="7">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Cyclops" model="CP-11-C" tons="90" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1553</battle_value>
    <cost>1.179836E7</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3050</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="360" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>34</ct>
        <ctr>11</ctr>
        <lt>24</lt>
        <ltr>8</ltr>
        <rt>24</rt>
        <rtr>8</rtr>
        <la>15</la>
        <ra>15</ra>
        <ll>22</ll>
        <rl>22</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Cyclops CP-11-C 90t, 4/6/0, Std FE, Std; 12.0T/69% Armor; 12 SHS; 2 ML, 1 GR, 1 SRM4, 1 C3M</info>
        <battleforce pv="16" wt="4" mv="4" s="3" m="4" l="2" e="0" ov="0" armor="6" internal="7" abilities="C3M, TAG, MHQ5, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="12" techbase="2">
            <type>Single Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Gauss Rifle</name>
            <type>ballistic</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-4</name>
            <type>missile</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4</name>
            <type>ammunition</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="7">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="8">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">C3 Computer (Master)</name>
            <type>equipment</type>
            <location index="0">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Cyclops" model="CP-11-G" tons="90" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1981</battle_value>
    <cost>1.016436E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3058</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="2">LT</location>
        <location index="3">LT</location>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">LA</location>
        <location index="5">RA</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="6">RA</location>
        <location index="7">RA</location>
        <location index="8">RA</location>
        <location index="9">RA</location>
        <location index="10">RA</location>
        <location index="11">RA</location>
    </structure>
    <engine rating="360" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>40</ct>
        <ctr>13</ctr>
        <lt>28</lt>
        <ltr>9</ltr>
        <rt>28</rt>
        <rtr>9</rtr>
        <la>29</la>
        <ra>29</ra>
        <ll>37</ll>
        <rl>37</rl>
        <location index="4">LT</location>
        <location index="5">LT</location>
        <location index="6">LT</location>
        <location index="6">LA</location>
        <location index="7">LT</location>
        <location index="7">LA</location>
        <location index="8">LT</location>
        <location index="8">LA</location>
        <location index="9">LT</location>
        <location index="9">LA</location>
        <location index="10">LT</location>
        <location index="10">LA</location>
        <location index="11">LT</location>
        <location index="11">LA</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Cyclops CP-11-G 90t, 4/6/0, Std FE, ES; 15.0T/96% FF Armor; 11 DHS; 1 SSRM4, 2 ERML, 1 GR, 1 LRM10</info>
        <battleforce pv="20" wt="4" mv="4" s="4" m="4" l="3" e="0" ov="0" armor="9" internal="7" abilities="CASE, SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="11" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Gauss Rifle</name>
            <type>ballistic</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="7">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="8">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-10</name>
            <type>missile</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Streak SRM-4</name>
            <type>missile</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Streak SRM-4</name>
            <type>ammunition</type>
            <location index="9">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="10">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="11">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Cyclops" model="CP-11-H" tons="90" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1525</battle_value>
    <cost>9657560.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3064</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="360" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>35</ct>
        <ctr>11</ctr>
        <lt>26</lt>
        <ltr>8</ltr>
        <rt>26</rt>
        <rtr>8</rtr>
        <la>20</la>
        <ra>20</ra>
        <ll>26</ll>
        <rl>26</rl>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">CT</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
        <location index="11">CT</location>
        <location index="11">LA</location>
        <location index="11">RA</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Cyclops CP-11-H 90t, 4/6/0, Std FE, Std; 12.0T/77% FF Armor; 12 DHS; 2 ML, 1 AC20, 6 RL20</info>
        <battleforce pv="15" wt="4" mv="4" s="4" m="4" l="0" e="0" ov="0" armor="7" internal="7" abilities="SRCH, ES, SEAL, SOA, AC 2/2/0" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="12" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="7">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="7">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Autocannon/20</name>
            <type>ballistic</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/20</name>
            <type>ammunition</type>
            <location index="10">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/20</name>
            <type>ammunition</type>
            <location index="11">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rocket Launcher 20</name>
            <type>missile</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rocket Launcher 20</name>
            <type>missile</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rocket Launcher 20</name>
            <type>missile</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rocket Launcher 20</name>
            <type>missile</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rocket Launcher 20</name>
            <type>missile</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rocket Launcher 20</name>
            <type>missile</type>
            <location index="6">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Cyclops" model="CP-12-K" tons="90" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1699</battle_value>
    <cost>1.000176E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3059</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">LA</location>
        <location index="5">RA</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="6">LA</location>
        <location index="6">RA</location>
        <location index="7">LA</location>
        <location index="7">RA</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
    </structure>
    <engine rating="360" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>34</ct>
        <ctr>11</ctr>
        <lt>22</lt>
        <ltr>7</ltr>
        <rt>22</rt>
        <rtr>7</rtr>
        <la>23</la>
        <ra>23</ra>
        <ll>29</ll>
        <rl>29</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Cyclops CP-12-K 90t, 4/6/0, Std FE, ES; 13.5T/77% Armor; 12 DHS; 1 SSRM4, 1 MRM20, 2 ERML, 1 UAC10</info>
        <battleforce pv="17" wt="4" mv="4" s="5" m="5" l="2" e="0" ov="0" armor="7" internal="7" abilities="CASE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="12" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Ultra AC/10</name>
            <type>ballistic</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) MRM-20</name>
            <type>missile</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Streak SRM-4</name>
            <type>missile</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Streak SRM-4</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ MRM-20</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Ultra AC/10</name>
            <type>ammunition</type>
            <location index="7">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Ultra AC/10</name>
            <type>ammunition</type>
            <location index="8">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="9">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Dervish" model="DV-7D" tons="55" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1412</battle_value>
    <cost>5478816.666666667</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3047</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="5">LT</location>
        <location index="5">RT</location>
        <location index="6">LT</location>
        <location index="6">RT</location>
        <location index="7">LT</location>
        <location index="7">RT</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="11">CT</location>
        <location index="11">RT</location>
    </structure>
    <engine rating="275" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>21</ct>
        <ctr>5</ctr>
        <lt>19</lt>
        <ltr>5</ltr>
        <rt>19</rt>
        <rtr>5</rtr>
        <la>13</la>
        <ra>13</ra>
        <ll>17</ll>
        <rl>17</rl>
        <location index="5">LA</location>
        <location index="5">RA</location>
        <location index="6">LA</location>
        <location index="6">RA</location>
        <location index="7">LA</location>
        <location index="7">RA</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
        <location index="11">LA</location>
        <location index="11">RA</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Dervish DV-7D 55t, 5/8/5, Std FE, ES; 8.0T/77% FF Armor; 10 DHS; 2 ML, 2 SSRM2, 2 LRM10</info>
        <battleforce pv="14" wt="2" mv="5j" s="3" m="3" l="2" e="0" ov="0" armor="5" internal="5" abilities="CASE, SRCH, ES, SEAL, SOA, LRM 1/1/1, IF 1" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="5">
            <type>Standard Jump Jet</type>
            <location index="5">LL</location>
            <location index="4">RL</location>
            <location index="5">RL</location>
            <location index="10">CT</location>
            <location index="4">LL</location>
        </jumpjets>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) LRM-10</name>
            <type>missile</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-10</name>
            <type>missile</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Streak SRM-2</name>
            <type>missile</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Streak SRM-2</name>
            <type>missile</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Streak SRM-2</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Streak SRM-2</name>
            <type>ammunition</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="4">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Dervish" model="DV-8D" tons="55" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1765</battle_value>
    <cost>1.1022316666666668E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3062</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="5">LA</location>
        <location index="5">RA</location>
        <location index="6">LA</location>
        <location index="6">RA</location>
        <location index="7">LA</location>
        <location index="7">RA</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
        <location index="11">LA</location>
        <location index="11">RA</location>
    </structure>
    <engine rating="275" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>26</ct>
        <ctr>7</ctr>
        <lt>20</lt>
        <ltr>6</ltr>
        <rt>20</rt>
        <rtr>6</rtr>
        <la>15</la>
        <ra>15</ra>
        <ll>22</ll>
        <rl>22</rl>
    </armor>
    <baseloadout fcsa4="TRUE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Dervish DV-8D 55t, 5/8/5, XLFE, ES; 10.5T/91% Armor; 10 DHS; 2 LRM15, 4 ERML</info>
        <battleforce pv="18" wt="2" mv="5j" s="3" m="3" l="2" e="0" ov="2" armor="6" internal="3" abilities="CASE, SRCH, ES, SEAL, SOA, IF 2" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="5">
            <type>Standard Jump Jet</type>
            <location index="5">LL</location>
            <location index="4">RL</location>
            <location index="5">RL</location>
            <location index="10">CT</location>
            <location index="4">LL</location>
        </jumpjets>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="7">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="7">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="8">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="9">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Dervish" model="DV-9D" tons="55" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1518</battle_value>
    <cost>1.0579116666666668E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>6</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3068</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="5">LA</location>
        <location index="5">RA</location>
        <location index="6">LA</location>
        <location index="6">RA</location>
        <location index="7">LA</location>
        <location index="7">RA</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
        <location index="11">LA</location>
        <location index="11">RA</location>
    </structure>
    <engine rating="275" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>26</ct>
        <ctr>9</ctr>
        <lt>20</lt>
        <ltr>6</ltr>
        <rt>20</rt>
        <rtr>6</rtr>
        <la>18</la>
        <ra>18</ra>
        <ll>26</ll>
        <rl>26</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Dervish DV-9D 55t, 5/8/5, XLFE, ES; 11.5T/99% Armor; 11 DHS; 4 ERML, 4 MML5, TC</info>
        <battleforce pv="15" wt="2" mv="5j" s="4" m="3" l="1" e="0" ov="2" armor="6" internal="3" abilities="CASE, SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="5">
            <type>Standard Jump Jet</type>
            <location index="5">LL</location>
            <location index="4">RL</location>
            <location index="5">RL</location>
            <location index="10">CT</location>
            <location index="4">LL</location>
        </jumpjets>
        <heatsinks number="11" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) MML-5</name>
            <type>missile</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) MML-5</name>
            <type>missile</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) MML-5</name>
            <type>missile</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) MML-5</name>
            <type>missile</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ MML-5 (LRM)</name>
            <type>ammunition</type>
            <location index="9">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ MML-5 (LRM)</name>
            <type>ammunition</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ MML-5 (SRM)</name>
            <type>ammunition</type>
            <location index="10">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ MML-5 (SRM)</name>
            <type>ammunition</type>
            <location index="10">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ MML-5 (LRM)</name>
            <type>ammunition</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="11">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="11">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Targeting Computer</name>
            <type>TargetingComputer</type>
            <location index="3">HD</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Dragon" model="DRG-5N" tons="60" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1223</battle_value>
    <cost>5219080.0</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3047</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="300" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>27</ct>
        <ctr>12</ctr>
        <lt>16</lt>
        <ltr>8</ltr>
        <rt>16</rt>
        <rtr>8</rtr>
        <la>14</la>
        <ra>14</ra>
        <ll>18</ll>
        <rl>18</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Dragon DRG-5N 60t, 5/8/0, Std FE, Std; 10.0T/80% Armor; 10 SHS; 1 ML, 1 UAC5, 1 LRM10</info>
        <battleforce pv="12" wt="3" mv="5" s="2" m="2" l="2" e="0" ov="0" armor="5" internal="5" abilities="CASE, SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-10</name>
            <type>missile</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="1">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Ultra AC/5</name>
            <type>ballistic</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Ultra AC/5</name>
            <type>ammunition</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="1">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional>&lt;p style=&quot;margin-top: 0&quot;&gt;:br::br:&lt;/p&gt;:br:</additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Dragon" model="DRG-7N" tons="60" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1495</battle_value>
    <cost>1.12671E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3063</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="4">LT</location>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">LT</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="6">LT</location>
        <location index="7">LT</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="10">RT</location>
        <location index="11">RT</location>
    </structure>
    <engine rating="300" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>27</ct>
        <ctr>12</ctr>
        <lt>16</lt>
        <ltr>8</ltr>
        <rt>16</rt>
        <rtr>8</rtr>
        <la>14</la>
        <ra>14</ra>
        <ll>18</ll>
        <rl>18</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Dragon DRG-7N 60t, 5/8/0, XLFE, ES; 10.0T/80% Armor; 10 SHS; 1 ML, 1 MRM10, 1 GR, 1 AC5</info>
        <battleforce pv="15" wt="3" mv="5" s="3" m="4" l="2" e="0" ov="0" armor="5" internal="3" abilities="CASE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Autocannon/5</name>
            <type>ballistic</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Gauss Rifle</name>
            <type>ballistic</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) MRM-10</name>
            <type>missile</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ MRM-10</name>
            <type>ammunition</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/5</name>
            <type>ammunition</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="7">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional>&lt;p style=&quot;margin-top: 0&quot;&gt;:br::br:&lt;/p&gt;:br:</additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Enforcer" model="ENF-5D" tons="50" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1308</battle_value>
    <cost>8804875.0</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3050</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="250" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>23</ct>
        <ctr>7</ctr>
        <lt>19</lt>
        <ltr>5</ltr>
        <rt>19</rt>
        <rtr>5</rtr>
        <la>16</la>
        <ra>16</ra>
        <ll>21</ll>
        <rl>21</rl>
        <location index="5">LA</location>
        <location index="6">LT</location>
        <location index="7">LT</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="9">RA</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="10">RA</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
        <location index="11">RA</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Enforcer ENF-5D 50t, 5/8/5, XLFE, Std; 9.0T/95% FF Armor; 12 SHS; 1 SL, 1 ERLL, 1 LB10</info>
        <battleforce pv="13" wt="2" mv="5j" s="2" m="2" l="2" e="0" ov="0" armor="5" internal="2" abilities="CASE, SRCH, ES, SEAL, SOA, FLK 1/1/1" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="5">
            <type>Standard Jump Jet</type>
            <location index="3">LT</location>
            <location index="4">RT</location>
            <location index="10">CT</location>
            <location index="3">RT</location>
            <location index="4">LT</location>
        </jumpjets>
        <heatsinks number="12" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">LL</location>
            <location index="4">RL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Large Laser</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LB 10-X AC</name>
            <type>ballistic</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Slug)</name>
            <type>ammunition</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Cluster)</name>
            <type>ammunition</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="7">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional>&lt;p style=&quot;margin-top: 0&quot;&gt;:br::br:&lt;/p&gt;:br:</additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Falcon" model="FLC-4P" tons="30" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>649</battle_value>
    <cost>2307290.0</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3050</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="180" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>14</ct>
        <ctr>3</ctr>
        <lt>12</lt>
        <ltr>2</ltr>
        <rt>12</rt>
        <rtr>2</rtr>
        <la>8</la>
        <ra>8</ra>
        <ll>13</ll>
        <rl>13</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Falcon FLC-4P 30t, 6/9/5, Std FE, Std; 6.0T/91% Armor; 12 SHS; 2 SL, 1 MPL, 1 AMS</info>
        <battleforce pv="6" wt="1" mv="6/3j" s="2" m="1" l="0" e="0" ov="0" armor="3" internal="3" abilities="AMS, ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="5">
            <type>Standard Jump Jet</type>
            <location index="0">LT</location>
            <location index="1">RT</location>
            <location index="0">RT</location>
            <location index="1">LT</location>
            <location index="10">CT</location>
        </jumpjets>
        <heatsinks number="12" techbase="2">
            <type>Single Heat Sink</type>

            <location index="5">LL</location>
            <location index="5">RL</location>
            <location index="3">HD</location>
            <location index="4">RL</location>
            <location index="4">LL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="5">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Anti-Missile System</name>
            <type>equipment</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Anti-Missile System</name>
            <type>ammunition</type>
            <location index="2">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Falcon" model="FLC-5P" tons="30" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>945</battle_value>
    <cost>3758040.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3065</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="180" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">Light Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <enhancement techbase="0">
        <type>MASC</type>
        <location index="4">RT</location>
    </enhancement>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>8</hd>
        <ct>9</ct>
        <ctr>3</ctr>
        <lt>8</lt>
        <ltr>3</ltr>
        <rt>8</rt>
        <rtr>3</rtr>
        <la>8</la>
        <ra>8</ra>
        <ll>11</ll>
        <rl>11</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Falcon FLC-5P 30t, 6/9[12]/5 MASC, LFE, Std; 5.0T/76% Armor; 12 SHS; 2 ERML, 1 MPL, TC</info>
        <battleforce pv="9" wt="1" mv="8/3j" s="2" m="2" l="0" e="0" ov="0" armor="3" internal="2" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="5">
            <type>Standard Jump Jet</type>
            <location index="2">RT</location>
            <location index="3">RT</location>
            <location index="2">LT</location>
            <location index="3">LT</location>
            <location index="10">CT</location>
        </jumpjets>
        <heatsinks number="12" techbase="2">
            <type>Single Heat Sink</type>

            <location index="3">HD</location>
            <location index="5">RL</location>
            <location index="4">RL</location>
            <location index="5">LL</location>
            <location index="4">LL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="5">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Targeting Computer</name>
            <type>TargetingComputer</type>
            <location index="4">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Falcon" model="FLC-6C" tons="30" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1290</battle_value>
    <cost>2692090.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Clan</techbase>
    <year restricted="FALSE">3065</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="180" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="1">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>14</ct>
        <ctr>3</ctr>
        <lt>12</lt>
        <ltr>2</ltr>
        <rt>12</rt>
        <rtr>2</rtr>
        <la>8</la>
        <ra>8</ra>
        <ll>13</ll>
        <rl>13</rl>
        <location index="4">RT</location>
        <location index="5">LT</location>
        <location index="5">LA</location>
        <location index="6">LA</location>
        <location index="6">RA</location>
        <location index="7">RA</location>
        <location index="11">CT</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Falcon FLC-6C 30t, 6/9/5, Std FE, Std; 5.0T/91% FF Armor; 10 DHS; 1 ERLL, 2 ERSL, 1 AMS, TC</info>
        <battleforce pv="13" wt="1" mv="6/3j" s="3" m="3" l="2" e="0" ov="0" armor="3" internal="3" abilities="AMS, ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>TRUE</clancase>
        <jumpjets number="5">
            <type>Standard Jump Jet</type>
            <location index="0">RT</location>
            <location index="1">RT</location>
            <location index="2">LT</location>
            <location index="10">CT</location>
            <location index="3">LT</location>
        </jumpjets>
        <heatsinks number="10" techbase="1">
            <type>Double Heat Sink</type>

            <location index="0">LT</location>
            <location index="4">RL</location>
            <location index="4">LL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(CL) ER Large Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) ER Small Laser</name>
            <type>energy</type>
            <location index="5">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) ER Small Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) Anti-Missile System</name>
            <type>equipment</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(CL) @ Anti-Missile System</name>
            <type>ammunition</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) Targeting Computer</name>
            <type>TargetingComputer</type>
            <location index="3">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional>&lt;p style=&quot;margin-top: 0&quot;&gt;:br::br:&lt;/p&gt;:br:</additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Firefly" model="C" tons="30" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1290</battle_value>
    <cost>2433300.0</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Clan</techbase>
    <year restricted="FALSE">2839</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="1">
        <type>Endo-Steel</type>
        <location index="3">HD</location>
        <location index="4">LT</location>
        <location index="5">RT</location>
        <location index="5">LA</location>
        <location index="5">RA</location>
        <location index="6">LA</location>
        <location index="6">RA</location>
    </structure>
    <engine rating="150" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>8</hd>
        <ct>15</ct>
        <ctr>5</ctr>
        <lt>11</lt>
        <ltr>3</ltr>
        <rt>11</rt>
        <rtr>3</rtr>
        <la>10</la>
        <ra>10</ra>
        <ll>14</ll>
        <rl>14</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Firefly C 30t, 5/8/4, Std FE, ES; 6.5T/99% Armor; 10 DHS; 4 ERSL, 3 MPL, 1 SRM2</info>
        <battleforce pv="13" wt="1" mv="5/3j" s="5" m="5" l="0" e="0" ov="0" armor="3" internal="3" abilities="CASE, SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>TRUE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="4">LL</location>
            <location index="5">RL</location>
            <location index="4">RL</location>
            <location index="5">LL</location>
        </jumpjets>
        <heatsinks number="10" techbase="1">
            <type>Double Heat Sink</type>

            <location index="0">LT</location>
            <location index="2">LA</location>
            <location index="0">RT</location>
            <location index="2">RA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(CL) ER Small Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) ER Small Laser</name>
            <type>energy</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) ER Small Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) ER Small Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) SRM-2</name>
            <type>missile</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-2</name>
            <type>ammunition</type>
            <location index="4">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional>&lt;p style=&quot;margin-top: 0&quot;&gt;:br::br:&lt;/p&gt;:br:</additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Firefly" model="FFL-4A" tons="30" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>831</battle_value>
    <cost>2194500.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2801</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="150" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>8</hd>
        <ct>15</ct>
        <ctr>5</ctr>
        <lt>11</lt>
        <ltr>3</ltr>
        <rt>11</rt>
        <rtr>3</rtr>
        <la>10</la>
        <ra>10</ra>
        <ll>14</ll>
        <rl>14</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Firefly FFL-4A 30t, 5/8/4, Std FE, Std; 6.5T/99% Armor; 10 SHS; 3 ML, 4 SL, 1 LRM5</info>
        <battleforce pv="8" wt="1" mv="5/3j" s="2" m="2" l="1" e="0" ov="0" armor="3" internal="3" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="4">RL</location>
            <location index="5">LL</location>
            <location index="4">LL</location>
            <location index="5">RL</location>
        </jumpjets>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="1">LT</location>
            <location index="0">RT</location>
            <location index="1">RT</location>
            <location index="0">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-5</name>
            <type>missile</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-5</name>
            <type>ammunition</type>
            <location index="4">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Firefly" model="FFL-4B" tons="30" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>870</battle_value>
    <cost>2333000.0</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3047</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="150" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>8</hd>
        <ct>15</ct>
        <ctr>5</ctr>
        <lt>11</lt>
        <ltr>3</ltr>
        <rt>11</rt>
        <rtr>3</rtr>
        <la>10</la>
        <ra>10</ra>
        <ll>14</ll>
        <rl>14</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Firefly FFL-4B 30t, 5/8/4, Std FE, Std; 6.5T/99% Armor; 10 SHS; 3 ML, 1 LRM5, 1 AMS</info>
        <battleforce pv="9" wt="1" mv="5/3j" s="2" m="2" l="1" e="0" ov="0" armor="3" internal="3" abilities="AMS, CASE, SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="4">LL</location>
            <location index="5">LL</location>
            <location index="5">RL</location>
            <location index="4">RL</location>
        </jumpjets>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="1">LT</location>
            <location index="0">LT</location>
            <location index="0">RT</location>
            <location index="1">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-5</name>
            <type>missile</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-5</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Anti-Missile System</name>
            <type>equipment</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Anti-Missile System</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="5">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional>&lt;p style=&quot;margin-top: 0&quot;&gt;:br::br:&lt;/p&gt;:br:</additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Firefly" model="FFL-4C" tons="30" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>806</battle_value>
    <cost>3865800.0</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3048</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="3">HD</location>
        <location index="3">LA</location>
        <location index="4">LA</location>
        <location index="5">LA</location>
        <location index="5">RA</location>
        <location index="6">LA</location>
        <location index="6">RA</location>
        <location index="7">LA</location>
        <location index="7">RA</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="10">LT</location>
        <location index="11">CT</location>
        <location index="11">LT</location>
    </structure>
    <engine rating="150" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>8</hd>
        <ct>15</ct>
        <ctr>5</ctr>
        <lt>11</lt>
        <ltr>3</ltr>
        <rt>11</rt>
        <rtr>3</rtr>
        <la>10</la>
        <ra>10</ra>
        <ll>14</ll>
        <rl>14</rl>
    </armor>
    <baseloadout fcsa4="TRUE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Firefly FFL-4C 30t, 5/8/4, XLFE, ES; 6.5T/99% Armor; 10 DHS; 3 MPL, 1 LRM5, 1 AMS</info>
        <battleforce pv="8" wt="1" mv="5/3j" s="3" m="3" l="1" e="0" ov="0" armor="3" internal="1" abilities="AMS, CASE, SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="4">LL</location>
            <location index="5">LL</location>
            <location index="5">RL</location>
            <location index="4">RL</location>
        </jumpjets>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

            <location index="3">RT</location>
            <location index="3">LT</location>
            <location index="6">RT</location>
            <location index="2">RA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) LRM-5</name>
            <type>missile</type>
            <location index="10">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-5 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="7">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="9">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Anti-Missile System</name>
            <type>equipment</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Anti-Missile System</name>
            <type>ammunition</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="9">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Firefly" model="FFL-4D" tons="30" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>897</battle_value>
    <cost>3552500.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3067</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="2">RA</location>
        <location index="3">LA</location>
        <location index="3">RA</location>
        <location index="4">LA</location>
        <location index="4">RA</location>
        <location index="5">LA</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="11">CT</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
    </structure>
    <engine rating="150" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">Light Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>15</ct>
        <ctr>5</ctr>
        <lt>11</lt>
        <ltr>3</ltr>
        <rt>11</rt>
        <rtr>3</rtr>
        <la>10</la>
        <ra>10</ra>
        <ll>14</ll>
        <rl>14</rl>
        <location index="3">HD</location>
        <location index="5">RA</location>
        <location index="6">LA</location>
        <location index="6">RA</location>
        <location index="7">LA</location>
        <location index="7">RA</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
        <location index="11">LA</location>
        <location index="11">RA</location>
    </armor>
    <baseloadout fcsa4="TRUE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Firefly FFL-4D 30t, 5/8/4, LFE, ES; 6.0T/100% FF Armor; 12 SHS; 3 ERML, 1 LRM5, 1 AMS</info>
        <battleforce pv="9" wt="1" mv="5/3j" s="2" m="2" l="1" e="0" ov="0" armor="4" internal="2" abilities="AMS, CASE, SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="4">LL</location>
            <location index="5">LL</location>
            <location index="5">RL</location>
            <location index="4">RL</location>
        </jumpjets>
        <heatsinks number="12" techbase="2">
            <type>Single Heat Sink</type>

            <location index="2">LT</location>
            <location index="3">LT</location>
            <location index="4">RT</location>
            <location index="2">RT</location>
            <location index="3">RT</location>
            <location index="4">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-5</name>
            <type>missile</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Anti-Missile System</name>
            <type>equipment</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Anti-Missile System</name>
            <type>ammunition</type>
            <location index="7">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-5 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="8">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Firefly" model="FFL-4DA" tons="30" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>921</battle_value>
    <cost>3680500.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3067</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="2">RA</location>
        <location index="3">LA</location>
        <location index="3">RA</location>
        <location index="4">LA</location>
        <location index="4">RA</location>
        <location index="5">LA</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="11">CT</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
    </structure>
    <engine rating="150" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">Light Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>15</ct>
        <ctr>5</ctr>
        <lt>11</lt>
        <ltr>3</ltr>
        <rt>11</rt>
        <rtr>3</rtr>
        <la>10</la>
        <ra>10</ra>
        <ll>14</ll>
        <rl>14</rl>
        <location index="3">HD</location>
        <location index="5">RA</location>
        <location index="6">LA</location>
        <location index="6">RA</location>
        <location index="7">LA</location>
        <location index="7">RA</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
        <location index="11">LA</location>
        <location index="11">RA</location>
    </armor>
    <baseloadout fcsa4="TRUE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Firefly FFL-4DA 30t, 5/8/4, LFE, ES; 6.0T/100% FF Armor; 12 SHS; 1 ECM, 3 ERML, 1 LRM5</info>
        <battleforce pv="9" wt="1" mv="5/3j" s="2" m="2" l="1" e="0" ov="0" armor="4" internal="2" abilities="ECM, CASE, SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="5">LL</location>
            <location index="5">RL</location>
        </jumpjets>
        <heatsinks number="12" techbase="2">
            <type>Single Heat Sink</type>

            <location index="2">LT</location>
            <location index="2">LA</location>
            <location index="3">LT</location>
            <location index="4">RT</location>
            <location index="2">RT</location>
            <location index="3">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">Guardian ECM Suite</name>
            <type>equipment</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-5</name>
            <type>missile</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-5 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="8">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional>&lt;p style=&quot;margin-top: 0&quot;&gt;:br::br:&lt;/p&gt;</additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Firestarter" model="FS9-B" tons="35" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>668</battle_value>
    <cost>6623100.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>6</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3070</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="3">HD</location>
        <location index="5">LA</location>
        <location index="5">RA</location>
        <location index="6">LA</location>
        <location index="6">RA</location>
        <location index="7">LA</location>
        <location index="7">RA</location>
        <location index="8">RT</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">RT</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="11">LT</location>
    </structure>
    <engine rating="210" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">Light Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Light Ferro-Fibrous</type>
        <hd>8</hd>
        <ct>14</ct>
        <ctr>5</ctr>
        <lt>9</lt>
        <ltr>3</ltr>
        <rt>9</rt>
        <rtr>3</rtr>
        <la>9</la>
        <ra>9</ra>
        <ll>12</ll>
        <rl>12</rl>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
        <location index="11">RT</location>
        <location index="11">LA</location>
        <location index="11">RA</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Firestarter FS9-B 35t, 6/9/8, LFE, ES; 5.5T/78% LFF Armor; 10 SHS; 3 Flmr, 1 C3I, 1 ERML</info>
        <battleforce pv="7" wt="1" mv="6/8j" s="1" m="1" l="0" e="0" ov="0" armor="3" internal="2" abilities="C3I, ENE, MHQ2, HT1, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="8">
            <type>Improved Jump Jet</type>
            <location index="4">LT</location>
            <location index="2">LT</location>
            <location index="6">LT</location>
            <location index="4">RT</location>
            <location index="4">RL</location>
            <location index="4">LL</location>
            <location index="2">RT</location>
            <location index="6">RT</location>
        </jumpjets>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="3">RA</location>
            <location index="3">LA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Flamer</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">Improved C3 Computer</name>
            <type>equipment</type>
            <location index="8">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Firestarter" model="FS9-C" tons="35" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>940</battle_value>
    <cost>3329100.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3063</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="210" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>15</ct>
        <ctr>6</ctr>
        <lt>11</lt>
        <ltr>5</ltr>
        <rt>11</rt>
        <rtr>5</rtr>
        <la>8</la>
        <ra>8</ra>
        <ll>10</ll>
        <rl>10</rl>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">LT</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="6">LT</location>
        <location index="7">LT</location>
        <location index="7">RT</location>
        <location index="8">RT</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">RT</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Firestarter FS9-C 35t, 6/9/6, Std FE, Std; 5.5T/82% FF Armor; 10 DHS; 2 RL15, 2 Flmr, 2 ML, 4 RL10</info>
        <battleforce pv="9" wt="1" mv="6j" s="2" m="2" l="1" e="0" ov="0" armor="3" internal="3" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="6">
            <type>Standard Jump Jet</type>
            <location index="0">LT</location>
            <location index="1">LT</location>
            <location index="1">RT</location>
            <location index="0">RT</location>
            <location index="2">LT</location>
            <location index="2">RT</location>
        </jumpjets>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

            <location index="3">RA</location>
            <location index="3">LA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="6">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="6">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="7">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="7">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rocket Launcher 15</name>
            <type>missile</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rocket Launcher 15</name>
            <type>missile</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rocket Launcher 10</name>
            <type>missile</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rocket Launcher 10</name>
            <type>missile</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rocket Launcher 10</name>
            <type>missile</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rocket Launcher 10</name>
            <type>missile</type>
            <location index="11">CT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Firestarter" model="FS9-P" tons="35" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>775</battle_value>
    <cost>3247425.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3064</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="3">HD</location>
        <location index="6">LT</location>
        <location index="6">RT</location>
        <location index="7">LT</location>
        <location index="7">RT</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="11">CT</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
    </structure>
    <engine rating="210" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>13</ct>
        <ctr>4</ctr>
        <lt>10</lt>
        <ltr>3</ltr>
        <rt>10</rt>
        <rtr>3</rtr>
        <la>7</la>
        <ra>7</ra>
        <ll>7</ll>
        <rl>7</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Firestarter FS9-P 35t, 6/9/6, Std FE, ES; 5.0T/67% Armor; 10 DHS; 4 Flmr, 2 ML, 2 SL, 2 SRM2</info>
        <battleforce pv="8" wt="1" mv="6j" s="3" m="2" l="0" e="0" ov="0" armor="3" internal="3" abilities="HT1, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="6">
            <type>Standard Jump Jet</type>
            <location index="0">LT</location>
            <location index="1">LT</location>
            <location index="1">RT</location>
            <location index="0">RT</location>
            <location index="2">LT</location>
            <location index="2">RT</location>
        </jumpjets>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

            <location index="3">RA</location>
            <location index="3">LA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="6">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="7">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="6">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="7">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-2</name>
            <type>missile</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-2</name>
            <type>missile</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-2</name>
            <type>ammunition</type>
            <location index="10">CT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional>&lt;p style=&quot;margin-top: 0&quot;&gt;:br::br:&lt;/p&gt;:br:</additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Firestarter" model="FS9-S" tons="35" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>754</battle_value>
    <cost>3510987.5</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3049</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="4">RT</location>
        <location index="5">RT</location>
        <location index="5">LA</location>
        <location index="6">RT</location>
        <location index="6">LA</location>
        <location index="7">LT</location>
        <location index="7">RT</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="11">LT</location>
    </structure>
    <engine rating="210" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>13</ct>
        <ctr>6</ctr>
        <lt>10</lt>
        <ltr>6</ltr>
        <rt>10</rt>
        <rtr>6</rtr>
        <la>7</la>
        <ra>7</ra>
        <ll>7</ll>
        <rl>7</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Firestarter FS9-S 35t, 6/9/6, Std FE, ES; 5.5T/74% Armor; 10 SHS; 2 ML, 4 Flmr, 1 SL, 1 BAP, 1 AMS</info>
        <battleforce pv="8" wt="1" mv="6j" s="2" m="1" l="0" e="0" ov="0" armor="3" internal="3" abilities="AMS, PRB, RCN, ENE, HT1, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="6">
            <type>Standard Jump Jet</type>
            <location index="0">LT</location>
            <location index="1">LT</location>
            <location index="1">RT</location>
            <location index="0">RT</location>
            <location index="2">LT</location>
            <location index="2">RT</location>
        </jumpjets>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">LL</location>
            <location index="4">RL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Flamer</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Anti-Missile System</name>
            <type>equipment</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">Beagle Active Probe</name>
            <type>equipment</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Anti-Missile System</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Firestarter" model="FS9-S1" tons="35" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>820</battle_value>
    <cost>3510987.5</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3049</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="4">RT</location>
        <location index="5">RT</location>
        <location index="5">LA</location>
        <location index="6">RT</location>
        <location index="6">LA</location>
        <location index="7">LT</location>
        <location index="7">RT</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="11">LT</location>
    </structure>
    <engine rating="210" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>13</ct>
        <ctr>6</ctr>
        <lt>10</lt>
        <ltr>6</ltr>
        <rt>10</rt>
        <rtr>6</rtr>
        <la>7</la>
        <ra>7</ra>
        <ll>7</ll>
        <rl>7</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Firestarter FS9-S1 35t, 6/9/6, Std FE, ES; 5.5T/74% Armor; 10 SHS; 1 ECM, 2 ML, 4 Flmr, 1 SL, 1 AMS</info>
        <battleforce pv="8" wt="1" mv="6j" s="2" m="1" l="0" e="0" ov="0" armor="3" internal="3" abilities="AMS, ECM, ENE, HT1, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="6">
            <type>Standard Jump Jet</type>
            <location index="0">LT</location>
            <location index="1">LT</location>
            <location index="1">RT</location>
            <location index="0">RT</location>
            <location index="2">LT</location>
            <location index="2">RT</location>
        </jumpjets>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">LL</location>
            <location index="4">RL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Flamer</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Anti-Missile System</name>
            <type>equipment</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">Guardian ECM Suite</name>
            <type>equipment</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Anti-Missile System</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Firestarter" model="FS9-S2" tons="35" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>842</battle_value>
    <cost>3737112.5000000005</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>6</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3075</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="4">RT</location>
        <location index="5">RT</location>
        <location index="6">RT</location>
        <location index="7">LT</location>
        <location index="7">RT</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="8">LA</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="9">LA</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="11">LT</location>
    </structure>
    <engine rating="210" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Light Ferro-Fibrous</type>
        <hd>8</hd>
        <ct>14</ct>
        <ctr>5</ctr>
        <lt>9</lt>
        <ltr>3</ltr>
        <rt>9</rt>
        <rtr>3</rtr>
        <la>9</la>
        <ra>9</ra>
        <ll>12</ll>
        <rl>12</rl>
        <location index="8">RA</location>
        <location index="9">RA</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
        <location index="11">RT</location>
        <location index="11">LA</location>
        <location index="11">RA</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Firestarter FS9-S2 35t, 6/9/6, Std FE, ES; 5.5T/78% LFF Armor; 10 DHS; 4 Flmr, 1 SL, 2 ERML, 1 BAP, 1 AMS</info>
        <battleforce pv="8" wt="1" mv="6j" s="2" m="1" l="0" e="0" ov="0" armor="3" internal="3" abilities="AMS, PRB, RCN, ENE, HT1, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="6">
            <type>Standard Jump Jet</type>
            <location index="0">LT</location>
            <location index="1">LT</location>
            <location index="1">RT</location>
            <location index="0">RT</location>
            <location index="2">LT</location>
            <location index="2">RT</location>
        </jumpjets>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

            <location index="3">LA</location>
            <location index="3">RA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Flamer</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="7">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="7">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="6">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="6">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Anti-Missile System</name>
            <type>equipment</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">Beagle Active Probe</name>
            <type>equipment</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Anti-Missile System</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Firestarter" model="FS9-S3" tons="35" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>908</battle_value>
    <cost>3737112.5000000005</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>6</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3075</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="4">RT</location>
        <location index="5">RT</location>
        <location index="6">RT</location>
        <location index="7">LT</location>
        <location index="7">RT</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="8">LA</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="9">LA</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="11">LT</location>
    </structure>
    <engine rating="210" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Light Ferro-Fibrous</type>
        <hd>8</hd>
        <ct>14</ct>
        <ctr>5</ctr>
        <lt>9</lt>
        <ltr>3</ltr>
        <rt>9</rt>
        <rtr>3</rtr>
        <la>9</la>
        <ra>9</ra>
        <ll>12</ll>
        <rl>12</rl>
        <location index="8">RA</location>
        <location index="9">RA</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
        <location index="11">RT</location>
        <location index="11">LA</location>
        <location index="11">RA</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Firestarter FS9-S3 35t, 6/9/6, Std FE, ES; 5.5T/78% LFF Armor; 10 DHS; 1 ECM, 4 Flmr, 1 SL, 2 ERML, 1 AMS</info>
        <battleforce pv="9" wt="1" mv="6j" s="2" m="1" l="0" e="0" ov="0" armor="3" internal="3" abilities="AMS, ECM, ENE, HT1, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="6">
            <type>Standard Jump Jet</type>
            <location index="0">LT</location>
            <location index="1">LT</location>
            <location index="1">RT</location>
            <location index="0">RT</location>
            <location index="2">LT</location>
            <location index="2">RT</location>
        </jumpjets>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

            <location index="3">LA</location>
            <location index="3">RA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Flamer</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="7">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="7">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="6">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="6">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Anti-Missile System</name>
            <type>equipment</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Anti-Missile System</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">Guardian ECM Suite</name>
            <type>equipment</type>
            <location index="5">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Flea" model="FLE-17" tons="20" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>510</battle_value>
    <cost>1728000.0</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3049</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="2">LT</location>
        <location index="2">RT</location>
        <location index="3">LT</location>
        <location index="3">RT</location>
        <location index="4">LT</location>
        <location index="4">RT</location>
        <location index="5">LT</location>
        <location index="5">RT</location>
        <location index="6">LT</location>
        <location index="6">RT</location>
        <location index="7">LT</location>
        <location index="7">RT</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
    </structure>
    <engine rating="120" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <enhancement techbase="0">
        <type>MASC</type>
        <location index="11">CT</location>
    </enhancement>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>5</hd>
        <ct>8</ct>
        <ctr>3</ctr>
        <lt>5</lt>
        <ltr>3</ltr>
        <rt>5</rt>
        <rtr>3</rtr>
        <la>4</la>
        <ra>4</ra>
        <ll>4</ll>
        <rl>4</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Flea FLE-17 20t, 6/9[12]/0 MASC, Std FE, ES; 3.0T/70% Armor; 10 SHS; 1 Flmr, 2 SL, 2 MPL</info>
        <battleforce pv="5" wt="1" mv="8" s="2" m="2" l="0" e="0" ov="0" armor="2" internal="2" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="5">LL</location>
            <location index="5">RL</location>
            <location index="0">LT</location>
            <location index="4">RL</location>
            <location index="4">LL</location>
            <location index="0">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(R) (IS) Small Laser</name>
            <type>energy</type>
            <location index="1">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Small Laser</name>
            <type>energy</type>
            <location index="1">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="2">RA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Flea" model="FLE-19" tons="20" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>381</battle_value>
    <cost>1482250.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>6</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3068</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="2">LT</location>
        <location index="2">RT</location>
        <location index="3">LT</location>
        <location index="3">RT</location>
        <location index="4">LT</location>
        <location index="4">RT</location>
        <location index="5">LT</location>
        <location index="5">RT</location>
        <location index="6">LT</location>
        <location index="6">RT</location>
        <location index="7">LT</location>
        <location index="7">RT</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
    </structure>
    <engine rating="120" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>5</hd>
        <ct>8</ct>
        <ctr>3</ctr>
        <lt>5</lt>
        <ltr>3</ltr>
        <rt>5</rt>
        <rtr>3</rtr>
        <la>4</la>
        <ra>4</ra>
        <ll>4</ll>
        <rl>4</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Flea FLE-19 20t, 6/9/0, Std FE, ES; 3.0T/70% Armor; 10 SHS; 1 Flmr, 1 RL10, 2 MGA+4LMG</info>
        <battleforce pv="4" wt="1" mv="6" s="1" m="1" l="1" e="0" ov="0" armor="2" internal="2" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="5">LL</location>
            <location index="5">RL</location>
            <location index="0">LT</location>
            <location index="4">RL</location>
            <location index="4">LL</location>
            <location index="0">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) MG Array (4 Light Machine Gun)</name>
            <type>mgarray</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) MG Array (4 Light Machine Gun)</name>
            <type>mgarray</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rocket Launcher 10</name>
            <type>missile</type>
            <location index="1">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Light Machine Gun (1/2)</name>
            <type>ammunition</type>
            <location index="1">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Flea" model="FLE-20" tons="20" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>791</battle_value>
    <cost>2346000.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>6</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3071</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="3">HD</location>
        <location index="6">LA</location>
        <location index="6">RA</location>
        <location index="7">LA</location>
        <location index="7">RA</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="11">CT</location>
    </structure>
    <engine rating="120" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <enhancement techbase="0">
        <type>MASC</type>
        <location index="10">CT</location>
    </enhancement>
    <armor manufacturer="Unknown" techbase="0">
        <type>Stealth Armor</type>
        <hd>5</hd>
        <ct>7</ct>
        <ctr>2</ctr>
        <lt>4</lt>
        <ltr>2</ltr>
        <rt>4</rt>
        <rtr>2</rtr>
        <la>3</la>
        <ra>3</ra>
        <ll>4</ll>
        <rl>4</rl>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
        <location index="11">LA</location>
        <location index="11">RA</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Flea FLE-20 20t, 6/9[12]/0 MASC, Std FE, ES; 2.5T/58% Stlth Armor; 10 DHS; 1 ECM, 2 ERML, 1 LPPC</info>
        <battleforce pv="8" wt="1" mv="8" s="2" m="2" l="1" e="0" ov="0" armor="1" internal="2" abilities="ECM, STL, ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

            <location index="2">RA</location>
            <location index="0">LT</location>
            <location index="0">RT</location>
            <location index="3">RT</location>
            <location index="3">LT</location>
            <location index="2">LA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">Guardian ECM Suite</name>
            <type>equipment</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="5">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="5">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Light PPC</name>
            <type>energy</type>
            <location index="6">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Flea" model="&quot;Fire Ant&quot;" tons="20" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>332</battle_value>
    <cost>1605500.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3063</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="2">LT</location>
        <location index="2">RT</location>
        <location index="3">LT</location>
        <location index="3">RT</location>
        <location index="4">LT</location>
        <location index="4">RT</location>
        <location index="5">LT</location>
        <location index="5">RT</location>
        <location index="6">LT</location>
        <location index="6">RT</location>
        <location index="7">LT</location>
        <location index="7">RT</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
    </structure>
    <engine rating="120" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <enhancement techbase="0">
        <type>MASC</type>
        <location index="11">CT</location>
    </enhancement>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>5</hd>
        <ct>7</ct>
        <ctr>2</ctr>
        <lt>4</lt>
        <ltr>2</ltr>
        <rt>4</rt>
        <rtr>2</rtr>
        <la>3</la>
        <ra>3</ra>
        <ll>4</ll>
        <rl>4</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Flea "Fire Ant" 20t, 6/9[12]/0 MASC, Std FE, ES; 2.5T/58% Armor; 10 SHS; 3 Flmr, 6 MG</info>
        <battleforce pv="3" wt="1" mv="8" s="2" m="0" l="0" e="0" ov="0" armor="1" internal="2" abilities="HT1, SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="5">RL</location>
            <location index="0">LT</location>
            <location index="0">RT</location>
            <location index="4">RL</location>
            <location index="5">LL</location>
            <location index="4">LL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="1">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="1">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Machine Gun (1/2)</name>
            <type>ammunition</type>
            <location index="10">CT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Grand Dragon" model="DRG-5K-DC" tons="60" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1322</battle_value>
    <cost>1.403568E7</cost>
    <rules_level>2</rules_level>
    <era>1</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3050</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="360" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="TRUE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>27</ct>
        <ctr>12</ctr>
        <lt>16</lt>
        <ltr>8</ltr>
        <rt>16</rt>
        <rtr>8</rtr>
        <la>14</la>
        <ra>14</ra>
        <ll>18</ll>
        <rl>18</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Grand Dragon DRG-5K-DC 60t, 6/9/0, XLFE, Std; 10.0T/80% Armor; 11 DHS; 2 ML, 1 ERPPC, 1 LRM10</info>
        <battleforce pv="13" wt="3" mv="6" s="2" m="3" l="2" e="0" ov="0" armor="5" internal="3" abilities="CASE, SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="11" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-10</name>
            <type>missile</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER PPC</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="6">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional>&lt;p style=&quot;margin-top: 0&quot;&gt;:br::br:&lt;/p&gt;:br:</additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Grand Dragon" model="DRG-5K" tons="60" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1358</battle_value>
    <cost>1.331888E7</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3050</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="360" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>27</ct>
        <ctr>12</ctr>
        <lt>16</lt>
        <ltr>8</ltr>
        <rt>16</rt>
        <rtr>8</rtr>
        <la>14</la>
        <ra>14</ra>
        <ll>18</ll>
        <rl>18</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Grand Dragon DRG-5K 60t, 6/9/0, XLFE, Std; 10.0T/80% Armor; 13 DHS; 3 ML, 1 ERPPC, 1 LRM10</info>
        <battleforce pv="14" wt="3" mv="6" s="2" m="3" l="2" e="0" ov="0" armor="5" internal="3" abilities="CASE, SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="13" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-10</name>
            <type>missile</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER PPC</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="6">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional>&lt;p style=&quot;margin-top: 0&quot;&gt;:br::br:&lt;/p&gt;:br:</additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Grand Dragon" model="DRG-7K" tons="60" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1702</battle_value>
    <cost>1.563828E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3063</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="3">HD</location>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="7">LT</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
    </structure>
    <engine rating="360" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <enhancement techbase="0">
        <type>MASC</type>
        <location index="4">RT</location>
    </enhancement>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>27</ct>
        <ctr>12</ctr>
        <lt>16</lt>
        <ltr>8</ltr>
        <rt>16</rt>
        <rtr>8</rtr>
        <la>14</la>
        <ra>14</ra>
        <ll>18</ll>
        <rl>18</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Grand Dragon DRG-7K 60t, 6/9[12]/0 MASC, XLFE, ES; 10.0T/80% Armor; 15 DHS; 1 MRM10, 1 C3S, 3 ERML, 1 ERPPC</info>
        <battleforce pv="17" wt="3" mv="8" s="3" m="3" l="1" e="0" ov="1" armor="5" internal="3" abilities="C3S, CASE, MHQ1, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="15" techbase="0">
            <type>Double Heat Sink</type>

            <location index="3">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="6">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="5">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER PPC</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) MRM-10</name>
            <type>missile</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ MRM-10</name>
            <type>ammunition</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">C3 Computer (Slave)</name>
            <type>equipment</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="7">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional>&lt;p style=&quot;margin-top: 0&quot;&gt;:br::br:&lt;/p&gt;:br:</additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Grand Dragon" model="DRG-9KC" tons="60" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1147</battle_value>
    <cost>1.559988E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>6</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3071</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="360" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Light Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>24</ct>
        <ctr>8</ctr>
        <lt>16</lt>
        <ltr>6</ltr>
        <rt>16</rt>
        <rtr>6</rtr>
        <la>16</la>
        <ra>16</ra>
        <ll>22</ll>
        <rl>22</rl>
        <location index="3">HD</location>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">LA</location>
        <location index="5">RA</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Grand Dragon DRG-9KC 60t, 6/9/0, XLFE, Std; 9.5T/80% LFF Armor; 13 DHS; 2 ML, 1 SNPPC, 1 C3M, 1 MML5</info>
        <battleforce pv="11" wt="3" mv="6" s="3" m="2" l="1" e="0" ov="0" armor="5" internal="3" abilities="C3M, TAG, MHQ5, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="13" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">C3 Computer (Master)</name>
            <type>equipment</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Laser</name>
            <type>energy</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Snub-Nose PPC</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) MML-5</name>
            <type>missile</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ MML-5 (SRM)</name>
            <type>ammunition</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ MML-5 (LRM)</name>
            <type>ammunition</type>
            <location index="7">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Grand Dragon" model="DRG-C" tons="60" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1322</battle_value>
    <cost>1.365488E7</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3050</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="360" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>27</ct>
        <ctr>12</ctr>
        <lt>16</lt>
        <ltr>8</ltr>
        <rt>16</rt>
        <rtr>8</rtr>
        <la>14</la>
        <ra>14</ra>
        <ll>18</ll>
        <rl>18</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Grand Dragon DRG-C 60t, 6/9/0, XLFE, Std; 10.0T/80% Armor; 13 DHS; 2 ML, 1 C3S, 1 ERPPC, 1 LRM10</info>
        <battleforce pv="13" wt="3" mv="6" s="2" m="3" l="2" e="0" ov="0" armor="5" internal="3" abilities="C3S, CASE, MHQ1, SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="13" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-10</name>
            <type>missile</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER PPC</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">C3 Computer (Slave)</name>
            <type>equipment</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="6">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Grasshopper" model="GHR-5J" tons="70" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1354</battle_value>
    <cost>6262073.333333335</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3049</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="280" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>30</ct>
        <ctr>13</ctr>
        <lt>20</lt>
        <ltr>10</ltr>
        <rt>20</rt>
        <rtr>10</rtr>
        <la>22</la>
        <ra>22</ra>
        <ll>26</ll>
        <rl>26</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Grasshopper GHR-5J 70t, 4/6/4, Std FE, Std; 13.0T/96% Armor; 22 SHS; 2 ML, 1 SSRM2, 1 ERLL, 1 AMS</info>
        <battleforce pv="14" wt="3" mv="4j" s="3" m="3" l="1" e="0" ov="0" armor="7" internal="6" abilities="AMS, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="4">LL</location>
            <location index="5">LL</location>
            <location index="4">RL</location>
            <location index="5">RL</location>
        </jumpjets>
        <heatsinks number="22" techbase="2">
            <type>Single Heat Sink</type>

            <location index="1">LT</location>
            <location index="0">LT</location>
            <location index="3">LT</location>
            <location index="4">LT</location>
            <location index="2">LT</location>
            <location index="5">LT</location>
            <location index="6">LT</location>
            <location index="0">RT</location>
            <location index="1">RT</location>
            <location index="2">RT</location>
            <location index="3">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Anti-Missile System</name>
            <type>equipment</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Large Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Streak SRM-2</name>
            <type>missile</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Streak SRM-2</name>
            <type>ammunition</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Streak SRM-2</name>
            <type>ammunition</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Anti-Missile System</name>
            <type>ammunition</type>
            <location index="7">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Grasshopper" model="GHR-5N" tons="70" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1511</battle_value>
    <cost>6160573.333333335</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3040</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="280" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>30</ct>
        <ctr>13</ctr>
        <lt>20</lt>
        <ltr>10</ltr>
        <rt>20</rt>
        <rtr>10</rtr>
        <la>22</la>
        <ra>22</ra>
        <ll>26</ll>
        <rl>26</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Grasshopper GHR-5N 70t, 4/6/4, Std FE, Std; 13.0T/96% Armor; 22 SHS; 5 ML, 1 PPC</info>
        <battleforce pv="15" wt="3" mv="4j" s="3" m="4" l="1" e="0" ov="0" armor="7" internal="6" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="5">RL</location>
            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="5">LL</location>
        </jumpjets>
        <heatsinks number="22" techbase="2">
            <type>Single Heat Sink</type>

            <location index="0">RT</location>
            <location index="4">RA</location>
            <location index="1">RT</location>
            <location index="11">CT</location>
            <location index="10">CT</location>
            <location index="4">LA</location>
            <location index="4">LT</location>
            <location index="0">LT</location>
            <location index="1">LT</location>
            <location index="2">LT</location>
            <location index="3">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="5">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="5">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="2">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Grasshopper" model="GHR-6K" tons="70" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1597</battle_value>
    <cost>7322973.333333335</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3064</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
        <location index="11">LA</location>
        <location index="11">RA</location>
    </structure>
    <engine rating="280" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>30</ct>
        <ctr>13</ctr>
        <lt>20</lt>
        <ltr>10</ltr>
        <rt>20</rt>
        <rtr>10</rtr>
        <la>22</la>
        <ra>22</ra>
        <ll>26</ll>
        <rl>26</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Grasshopper GHR-6K 70t, 4/6/4, Std FE, ES; 13.0T/96% Armor; 17 DHS; 1 SSRM4, 1 ERLL, 1 C3S, 5 MPL</info>
        <battleforce pv="16" wt="3" mv="4j" s="5" m="5" l="1" e="0" ov="0" armor="7" internal="6" abilities="C3S, CASE, MHQ1, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="4">LL</location>
            <location index="5">LL</location>
            <location index="4">RL</location>
            <location index="5">RL</location>
        </jumpjets>
        <heatsinks number="17" techbase="0">
            <type>Double Heat Sink</type>

            <location index="0">LT</location>
            <location index="3">RT</location>
            <location index="0">RT</location>
            <location index="4">RA</location>
            <location index="4">LA</location>
            <location index="3">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) ER Large Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="7">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="7">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="7">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">C3 Computer (Slave)</name>
            <type>equipment</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Streak SRM-4</name>
            <type>missile</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Streak SRM-4</name>
            <type>ammunition</type>
            <location index="7">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="8">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Grasshopper" model="GHR-7K &quot;Gravedigger&quot;" tons="70" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>2084</battle_value>
    <cost>9465373.333333334</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>6</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3071</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="6">LA</location>
        <location index="6">RA</location>
        <location index="7">LA</location>
        <location index="7">RA</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">LT</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
        <location index="11">LT</location>
        <location index="11">LA</location>
        <location index="11">RA</location>
    </structure>
    <engine rating="280" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <enhancement techbase="0">
        <type>TSM</type>
        <location index="5">RT</location>
        <location index="6">RT</location>
        <location index="7">RT</location>
        <location index="8">RT</location>
        <location index="9">RT</location>
        <location index="10">RT</location>
    </enhancement>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>30</ct>
        <ctr>13</ctr>
        <lt>20</lt>
        <ltr>10</ltr>
        <rt>20</rt>
        <rtr>10</rtr>
        <la>22</la>
        <ra>22</ra>
        <ll>26</ll>
        <rl>26</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Grasshopper GHR-7K "Gravedigger" 70t, 4[5]/6[8]/4 TSM, Std FE, ES; 13.0T/96% Armor; 14 DHS; 1 ECM, 2 SNPPC, 2 MPL, 2 LPPC</info>
        <battleforce pv="21" wt="3" mv="4j" s="3" m="3" l="1" e="0" ov="1" armor="7" internal="6" abilities="TSM, ECM, ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="4">LL</location>
            <location index="5">LL</location>
            <location index="4">RL</location>
            <location index="5">RL</location>
        </jumpjets>
        <heatsinks number="14" techbase="0">
            <type>Double Heat Sink</type>

            <location index="3">LT</location>
            <location index="0">RT</location>
            <location index="0">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Light PPC</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Light PPC</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Snub-Nose PPC</name>
            <type>energy</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Snub-Nose PPC</name>
            <type>energy</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">Guardian ECM Suite</name>
            <type>equipment</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Grasshopper" model="GHR-7K" tons="70" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1806</battle_value>
    <cost>8176773.333333335</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>6</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3068</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="5">RT</location>
        <location index="6">RT</location>
        <location index="7">RT</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
        <location index="11">LA</location>
        <location index="11">RA</location>
    </structure>
    <engine rating="280" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>30</ct>
        <ctr>13</ctr>
        <lt>20</lt>
        <ltr>10</ltr>
        <rt>20</rt>
        <rtr>10</rtr>
        <la>22</la>
        <ra>22</ra>
        <ll>26</ll>
        <rl>26</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Grasshopper GHR-7K 70t, 4/6/4, Std FE, ES; 13.0T/96% Armor; 16 DHS; 1 ECM, 1 C3S, 2 SNPPC, 1 ERML, 2 LPPC</info>
        <battleforce pv="18" wt="3" mv="4j" s="3" m="3" l="1" e="0" ov="0" armor="7" internal="6" abilities="C3S, ECM, ENE, MHQ1, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="4">LL</location>
            <location index="5">LL</location>
            <location index="4">RL</location>
            <location index="5">RL</location>
        </jumpjets>
        <heatsinks number="16" techbase="0">
            <type>Double Heat Sink</type>

            <location index="4">LA</location>
            <location index="0">RT</location>
            <location index="0">LT</location>
            <location index="4">RA</location>
            <location index="3">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Light PPC</name>
            <type>energy</type>
            <location index="7">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Light PPC</name>
            <type>energy</type>
            <location index="7">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Snub-Nose PPC</name>
            <type>energy</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Snub-Nose PPC</name>
            <type>energy</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">C3 Computer (Slave)</name>
            <type>equipment</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">Guardian ECM Suite</name>
            <type>equipment</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Grasshopper" model="GHR-C" tons="70" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1366</battle_value>
    <cost>6633073.333333335</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3052</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="280" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>30</ct>
        <ctr>13</ctr>
        <lt>20</lt>
        <ltr>10</ltr>
        <rt>20</rt>
        <rtr>10</rtr>
        <la>22</la>
        <ra>22</ra>
        <ll>26</ll>
        <rl>26</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Grasshopper GHR-C 70t, 4/6/4, Std FE, Std; 13.0T/96% Armor; 22 SHS; 2 ML, 1 SSRM2, 1 ERLL, 1 C3S, 1 AMS</info>
        <battleforce pv="14" wt="3" mv="4j" s="3" m="3" l="1" e="0" ov="0" armor="7" internal="6" abilities="AMS, C3S, MHQ1, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="4">LL</location>
            <location index="5">LL</location>
            <location index="4">RL</location>
            <location index="5">RL</location>
        </jumpjets>
        <heatsinks number="22" techbase="2">
            <type>Single Heat Sink</type>

            <location index="3">RT</location>
            <location index="1">LT</location>
            <location index="4">LT</location>
            <location index="0">LT</location>
            <location index="2">LT</location>
            <location index="3">LT</location>
            <location index="5">LT</location>
            <location index="6">LT</location>
            <location index="0">RT</location>
            <location index="1">RT</location>
            <location index="2">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Anti-Missile System</name>
            <type>equipment</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Large Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Streak SRM-2</name>
            <type>missile</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Anti-Missile System</name>
            <type>ammunition</type>
            <location index="7">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Streak SRM-2</name>
            <type>ammunition</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">C3 Computer (Slave)</name>
            <type>equipment</type>
            <location index="6">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Hatamoto-Chi" model="HTM-27T" tons="80" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1607</battle_value>
    <cost>8193720.000000001</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3042</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="3">HD</location>
        <location index="6">LA</location>
        <location index="7">LA</location>
        <location index="7">RA</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">CT</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
        <location index="11">CT</location>
        <location index="11">LA</location>
        <location index="11">RA</location>
    </structure>
    <engine rating="320" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>34</ct>
        <ctr>16</ctr>
        <lt>25</lt>
        <ltr>9</ltr>
        <rt>25</rt>
        <rtr>9</rtr>
        <la>26</la>
        <ra>26</ra>
        <ll>34</ll>
        <rl>34</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Hatamoto-Chi HTM-27T 80t, 4/6/0, Std FE, ES; 15.5T/100% Armor; 18 SHS; 2 PPC, 2 SRM6</info>
        <battleforce pv="16" wt="4" mv="4" s="3" m="3" l="2" e="0" ov="0" armor="8" internal="6" abilities="CASE, SRCH, ES, SEAL, SOA, SRM 1/1/0" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="18" techbase="2">
            <type>Single Heat Sink</type>

            <location index="5">RL</location>
            <location index="4">RL</location>
            <location index="5">LL</location>
            <location index="4">LL</location>
            <location index="0">RT</location>
            <location index="0">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="1">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="1">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="4">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Hatamoto-Chi" model="HTM-28T (Shin)" tons="80" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>2213</battle_value>
    <cost>9775320.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3060</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="240" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <enhancement techbase="0">
        <type>TSM</type>
        <location index="5">RT</location>
        <location index="6">RT</location>
        <location index="7">RT</location>
        <location index="8">RT</location>
        <location index="9">RT</location>
        <location index="10">RT</location>
    </enhancement>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>34</ct>
        <ctr>16</ctr>
        <lt>25</lt>
        <ltr>9</ltr>
        <rt>25</rt>
        <rtr>9</rtr>
        <la>26</la>
        <ra>26</ra>
        <ll>34</ll>
        <rl>34</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Hatamoto-Chi HTM-28T (Shin) 80t, 3[4]/5[6]/3 TSM, Std FE, Std; 15.5T/100% Armor; 10 DHS; 1 C3S, 1 GR, 6 MPL, 1 Swrd</info>
        <battleforce pv="22" wt="4" mv="3j" s="5" m="5" l="2" e="0" ov="1" armor="8" internal="6" abilities="TSM, MEL, C3S, CASE, MHQ1, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="3">
            <type>Standard Jump Jet</type>
            <location index="10">CT</location>
            <location index="3">RT</location>
            <location index="0">LT</location>
        </jumpjets>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

            <location index="0">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Gauss Rifle</name>
            <type>ballistic</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="1">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">Sword</name>
            <type>physical</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">C3 Computer (Slave)</name>
            <type>equipment</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="7">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="10">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="4">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Hatamoto-Chi" model="HTM-28T" tons="80" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>2039</battle_value>
    <cost>8397000.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3059</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="240" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>34</ct>
        <ctr>16</ctr>
        <lt>25</lt>
        <ltr>9</ltr>
        <rt>25</rt>
        <rtr>9</rtr>
        <la>26</la>
        <ra>26</ra>
        <ll>34</ll>
        <rl>34</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Hatamoto-Chi HTM-28T 80t, 3/5/3, Std FE, Std; 15.5T/100% Armor; 17 DHS; 1 C3S, 2 ERML, 2 ERPPC, 2 SSRM6</info>
        <battleforce pv="20" wt="4" mv="3j" s="4" m="4" l="2" e="0" ov="2" armor="8" internal="6" abilities="C3S, CASE, MHQ1, SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="3">
            <type>Standard Jump Jet</type>
            <location index="10">CT</location>
            <location index="6">RT</location>
            <location index="6">LT</location>
        </jumpjets>
        <heatsinks number="17" techbase="0">
            <type>Double Heat Sink</type>

            <location index="5">RA</location>
            <location index="0">RT</location>
            <location index="2">LA</location>
            <location index="2">RA</location>
            <location index="0">LT</location>
            <location index="5">LA</location>
            <location index="3">RT</location>
            <location index="3">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) ER PPC</name>
            <type>energy</type>
            <location index="8">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER PPC</name>
            <type>energy</type>
            <location index="8">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="11">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="11">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">C3 Computer (Slave)</name>
            <type>equipment</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Streak SRM-6</name>
            <type>missile</type>
            <location index="7">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Streak SRM-6</name>
            <type>missile</type>
            <location index="7">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Streak SRM-6</name>
            <type>ammunition</type>
            <location index="9">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Streak SRM-6</name>
            <type>ammunition</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="10">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="10">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Hatamoto-Hi" model="HTM-27U" tons="80" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1627</battle_value>
    <cost>8107320.000000001</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3047</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="2">LT</location>
        <location index="2">RT</location>
        <location index="3">LT</location>
        <location index="3">RT</location>
        <location index="4">LT</location>
        <location index="4">RT</location>
        <location index="5">LT</location>
        <location index="5">RT</location>
        <location index="6">LT</location>
        <location index="6">RT</location>
        <location index="7">LT</location>
        <location index="7">RT</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
    </structure>
    <engine rating="320" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>34</ct>
        <ctr>16</ctr>
        <lt>25</lt>
        <ltr>9</ltr>
        <rt>25</rt>
        <rtr>9</rtr>
        <la>26</la>
        <ra>26</ra>
        <ll>34</ll>
        <rl>34</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Hatamoto-Hi HTM-27U 80t, 4/6/0, Std FE, ES; 15.5T/100% Armor; 19 SHS; 2 PPC, 4 MPL</info>
        <battleforce pv="16" wt="4" mv="4" s="3" m="3" l="2" e="0" ov="2" armor="8" internal="6" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="19" techbase="2">
            <type>Single Heat Sink</type>

            <location index="5">RL</location>
            <location index="4">RL</location>
            <location index="4">LL</location>
            <location index="5">LL</location>
            <location index="3">HD</location>
            <location index="10">CT</location>
            <location index="11">CT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="1">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="1">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional>&lt;p style=&quot;margin-top: 0&quot;&gt;:br::br:&lt;/p&gt;:br:</additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Hatamoto-Hi" model="HTM-C" tons="80" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1627</battle_value>
    <cost>8553720.0</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3050</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="2">LT</location>
        <location index="2">RT</location>
        <location index="3">LT</location>
        <location index="3">RT</location>
        <location index="4">LT</location>
        <location index="4">RT</location>
        <location index="5">LT</location>
        <location index="5">RT</location>
        <location index="6">LT</location>
        <location index="6">RT</location>
        <location index="7">LT</location>
        <location index="7">RT</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
    </structure>
    <engine rating="320" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>34</ct>
        <ctr>16</ctr>
        <lt>25</lt>
        <ltr>9</ltr>
        <rt>25</rt>
        <rtr>9</rtr>
        <la>26</la>
        <ra>26</ra>
        <ll>34</ll>
        <rl>34</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Hatamoto-Hi HTM-C 80t, 4/6/0, Std FE, ES; 15.5T/100% Armor; 18 SHS; 1 C3S, 2 PPC, 4 MPL</info>
        <battleforce pv="16" wt="4" mv="4" s="3" m="3" l="2" e="0" ov="2" armor="8" internal="6" abilities="C3S, ENE, MHQ1, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="18" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="5">LL</location>
            <location index="10">CT</location>
            <location index="11">CT</location>
            <location index="5">RL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="1">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="1">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">C3 Computer (Slave)</name>
            <type>equipment</type>
            <location index="3">HD</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional>&lt;p style=&quot;margin-top: 0&quot;&gt;:br::br:&lt;/p&gt;:br:</additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Hatamoto-Hi" model="HTM-CM" tons="80" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1573</battle_value>
    <cost>1.058772E7</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3050</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="2">LT</location>
        <location index="3">LT</location>
        <location index="4">LT</location>
        <location index="5">LT</location>
        <location index="5">RT</location>
        <location index="6">LT</location>
        <location index="6">RT</location>
        <location index="7">LT</location>
        <location index="7">RT</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="9">RT</location>
        <location index="10">RT</location>
        <location index="11">RT</location>
    </structure>
    <engine rating="320" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>34</ct>
        <ctr>16</ctr>
        <lt>25</lt>
        <ltr>9</ltr>
        <rt>25</rt>
        <rtr>9</rtr>
        <la>26</la>
        <ra>26</ra>
        <ll>34</ll>
        <rl>34</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Hatamoto-Hi HTM-CM 80t, 4/6/0, Std FE, ES; 15.5T/100% Armor; 18 SHS; 2 PPC, 2 MPL, 1 C3M</info>
        <battleforce pv="16" wt="4" mv="4" s="2" m="3" l="2" e="0" ov="1" armor="8" internal="6" abilities="C3M, TAG, ENE, MHQ5, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="18" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="5">LL</location>
            <location index="10">CT</location>
            <location index="11">CT</location>
            <location index="5">RL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="1">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">C3 Computer (Master)</name>
            <type>equipment</type>
            <location index="0">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional>&lt;p style=&quot;margin-top: 0&quot;&gt;:br::br:&lt;/p&gt;:br:</additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Hatamoto-Kaze" model="HTM-27V" tons="80" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1595</battle_value>
    <cost>8079720.000000001</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3048</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="4">LT</location>
        <location index="4">RT</location>
        <location index="5">LT</location>
        <location index="5">RT</location>
        <location index="6">LT</location>
        <location index="6">RT</location>
        <location index="7">LT</location>
        <location index="7">RT</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
    </structure>
    <engine rating="320" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>34</ct>
        <ctr>16</ctr>
        <lt>25</lt>
        <ltr>9</ltr>
        <rt>25</rt>
        <rtr>9</rtr>
        <la>26</la>
        <ra>26</ra>
        <ll>34</ll>
        <rl>34</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Hatamoto-Kaze HTM-27V 80t, 4/6/0, Std FE, ES; 15.5T/100% Armor; 18 SHS; 2 PPC, 2 LRM5</info>
        <battleforce pv="16" wt="4" mv="4" s="2" m="3" l="3" e="0" ov="0" armor="8" internal="6" abilities="CASE, SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="18" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">RL</location>
            <location index="11">CT</location>
            <location index="5">RL</location>
            <location index="10">CT</location>
            <location index="5">LL</location>
            <location index="4">LL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-5</name>
            <type>missile</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-5</name>
            <type>missile</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-5</name>
            <type>ammunition</type>
            <location index="1">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-5</name>
            <type>ammunition</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-5</name>
            <type>ammunition</type>
            <location index="1">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-5</name>
            <type>ammunition</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="3">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional>&lt;p style=&quot;margin-top: 0&quot;&gt;:br::br:&lt;/p&gt;:br:</additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Hatamoto-Ku" model="HTM-27W" tons="80" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1563</battle_value>
    <cost>7901220.000000001</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3048</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="0">LT</location>
        <location index="1">LT</location>
        <location index="2">LT</location>
        <location index="3">HD</location>
        <location index="3">LT</location>
        <location index="4">LT</location>
        <location index="5">LT</location>
        <location index="5">RT</location>
        <location index="6">RT</location>
        <location index="7">RT</location>
        <location index="8">RT</location>
        <location index="9">RT</location>
        <location index="10">RT</location>
        <location index="11">RT</location>
    </structure>
    <engine rating="320" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>34</ct>
        <ctr>16</ctr>
        <lt>25</lt>
        <ltr>9</ltr>
        <rt>25</rt>
        <rtr>9</rtr>
        <la>26</la>
        <ra>26</ra>
        <ll>34</ll>
        <rl>34</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Hatamoto-Ku HTM-27W 80t, 4/6/0, Std FE, ES; 15.5T/100% Armor; 18 SHS; 2 PPC, 1 AC5</info>
        <battleforce pv="16" wt="4" mv="4" s="2" m="3" l="3" e="0" ov="0" armor="8" internal="6" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="18" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">RL</location>
            <location index="5">RL</location>
            <location index="11">CT</location>
            <location index="10">CT</location>
            <location index="4">LL</location>
            <location index="5">LL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Autocannon/5</name>
            <type>ballistic</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/5</name>
            <type>ammunition</type>
            <location index="4">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional>&lt;p style=&quot;margin-top: 0&quot;&gt;:br::br:&lt;/p&gt;:br:</additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Hatamoto-Mizo" model="HTM-27Y" tons="80" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1675</battle_value>
    <cost>8046120.000000001</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3050</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="3">LT</location>
        <location index="3">RT</location>
        <location index="4">LT</location>
        <location index="4">RT</location>
        <location index="5">LT</location>
        <location index="5">RT</location>
        <location index="6">LT</location>
        <location index="6">RT</location>
        <location index="7">LT</location>
        <location index="7">RT</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
    </structure>
    <engine rating="320" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>34</ct>
        <ctr>16</ctr>
        <lt>25</lt>
        <ltr>9</ltr>
        <rt>25</rt>
        <rtr>9</rtr>
        <la>26</la>
        <ra>26</ra>
        <ll>34</ll>
        <rl>34</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Hatamoto-Mizo HTM-27Y 80t, 4/6/0, Std FE, ES; 15.5T/100% Armor; 22 SHS; 1 ERLL, 2 PPC</info>
        <battleforce pv="17" wt="4" mv="4" s="2" m="3" l="3" e="0" ov="0" armor="8" internal="6" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="22" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="5">LL</location>
            <location index="10">CT</location>
            <location index="11">CT</location>
            <location index="5">RL</location>
            <location index="0">LT</location>
            <location index="1">LT</location>
            <location index="0">RT</location>
            <location index="2">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Large Laser</name>
            <type>energy</type>
            <location index="1">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Hatchetman" model="HCT-5D" tons="45" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1012</battle_value>
    <cost>5808340.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3062</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="180" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>8</hd>
        <ct>15</ct>
        <ctr>5</ctr>
        <lt>12</lt>
        <ltr>4</ltr>
        <rt>12</rt>
        <rtr>4</rtr>
        <la>10</la>
        <ra>10</ra>
        <ll>16</ll>
        <rl>16</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Hatchetman HCT-5D 45t, 4/6/4, XLFE, Std; 7.0T/73% Armor; 10 DHS; 1 ERML, 1 UAC10, 1 Htcht, TC</info>
        <battleforce pv="10" wt="2" mv="4j" s="3" m="3" l="2" e="0" ov="0" armor="4" internal="2" abilities="MEL, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="5">RL</location>
            <location index="5">LL</location>
        </jumpjets>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

            <location index="7">LA</location>
            <location index="4">LA</location>
            <location index="3">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">Hatchet</name>
            <type>physical</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Ultra AC/10</name>
            <type>ballistic</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Ultra AC/10</name>
            <type>ammunition</type>
            <location index="11">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Ultra AC/10</name>
            <type>ammunition</type>
            <location index="10">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Targeting Computer</name>
            <type>TargetingComputer</type>
            <location index="7">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Hatchetman" model="HCT-5DD" tons="45" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1112</battle_value>
    <cost>6944540.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3065</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="225" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>21</ct>
        <ctr>7</ctr>
        <lt>16</lt>
        <ltr>6</ltr>
        <rt>16</rt>
        <rtr>6</rtr>
        <la>14</la>
        <ra>14</ra>
        <ll>22</ll>
        <rl>22</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Hatchetman HCT-5DD 45t, 5/8/5, XLFE, Std; 10.0T/100% Armor; 10 DHS; 1 ERML, 1 RAC2, 1 Htcht, TC</info>
        <battleforce pv="11" wt="2" mv="5j" s="2" m="2" l="1" e="0" ov="0" armor="5" internal="2" abilities="MEL, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="5">
            <type>Standard Jump Jet</type>
            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="5">RL</location>
            <location index="5">LL</location>
            <location index="10">CT</location>
        </jumpjets>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

            <location index="3">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">Hatchet</name>
            <type>physical</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rotary AC/2</name>
            <type>ballistic</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Rotary AC/2</name>
            <type>ammunition</type>
            <location index="7">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Rotary AC/2</name>
            <type>ammunition</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Targeting Computer</name>
            <type>TargetingComputer</type>
            <location index="7">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Hatchetman" model="HCT-5K" tons="45" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1070</battle_value>
    <cost>6251090.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>6</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3075</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="180" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>19</ct>
        <ctr>6</ctr>
        <lt>16</lt>
        <ltr>5</ltr>
        <rt>16</rt>
        <rtr>5</rtr>
        <la>13</la>
        <ra>13</ra>
        <ll>21</ll>
        <rl>21</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Hatchetman HCT-5K 45t, 4/6/4, XLFE, Std; 9.0T/94% Armor; 10 DHS; 1 C3S, 1 ERML, 1 MRM30, 2 MPL, 1 Swrd</info>
        <battleforce pv="11" wt="2" mv="4j" s="4" m="4" l="0" e="0" ov="0" armor="5" internal="2" abilities="MEL, C3S, CASE, MHQ1, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="5">LL</location>
            <location index="4">LL</location>
            <location index="5">RL</location>
            <location index="4">RL</location>
        </jumpjets>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

            <location index="6">LT</location>
            <location index="4">LA</location>
            <location index="3">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="7">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) MRM-30</name>
            <type>missile</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">Sword</name>
            <type>physical</type>
            <location index="5">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">C3 Computer (Slave)</name>
            <type>equipment</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ MRM-30</name>
            <type>ammunition</type>
            <location index="9">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ MRM-30</name>
            <type>ammunition</type>
            <location index="8">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="10">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Hatchetman" model="HCT-5S" tons="45" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1039</battle_value>
    <cost>6129840.0</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3049</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="180" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>21</ct>
        <ctr>6</ctr>
        <lt>16</lt>
        <ltr>6</ltr>
        <rt>16</rt>
        <rtr>6</rtr>
        <la>14</la>
        <ra>14</ra>
        <ll>22</ll>
        <rl>22</rl>
        <location index="5">LT</location>
        <location index="5">LA</location>
        <location index="6">LT</location>
        <location index="6">LA</location>
        <location index="7">LT</location>
        <location index="7">LA</location>
        <location index="8">LT</location>
        <location index="8">RA</location>
        <location index="9">LT</location>
        <location index="9">RA</location>
        <location index="10">CT</location>
        <location index="10">LT</location>
        <location index="10">RA</location>
        <location index="11">LT</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Hatchetman HCT-5S 45t, 4/6/4, XLFE, Std; 8.5T/99% FF Armor; 10 SHS; 3 MPL, 1 LB10, 1 Htcht</info>
        <battleforce pv="10" wt="2" mv="4j" s="2" m="2" l="1" e="0" ov="1" armor="5" internal="2" abilities="MEL, CASE, SRCH, ES, SEAL, SOA, FLK 1/1/1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="5">RL</location>
            <location index="5">LL</location>
        </jumpjets>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="3">LT</location>
            <location index="3">RT</location>
            <location index="3">HD</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LB 10-X AC</name>
            <type>ballistic</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Slug)</name>
            <type>ammunition</type>
            <location index="10">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">Hatchet</name>
            <type>physical</type>
            <location index="5">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="11">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Hatchetman" model="HCT-6D" tons="45" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1611</battle_value>
    <cost>7657090.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3062</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="5">LA</location>
        <location index="6">LA</location>
        <location index="7">LA</location>
        <location index="7">RA</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">LT</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">LT</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
        <location index="11">CT</location>
        <location index="11">LT</location>
    </structure>
    <engine rating="225" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>21</ct>
        <ctr>6</ctr>
        <lt>16</lt>
        <ltr>6</ltr>
        <rt>16</rt>
        <rtr>6</rtr>
        <la>14</la>
        <ra>14</ra>
        <ll>22</ll>
        <rl>22</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Hatchetman HCT-6D 45t, 5/8/5, XLFE, ES; 9.5T/99% Armor; 10 DHS; 1 ECM, 1 RAC5, 3 ERML, 1 Htcht</info>
        <battleforce pv="16" wt="2" mv="5j" s="4" m="4" l="0" e="0" ov="0" armor="5" internal="2" abilities="MEL, ECM, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="5">
            <type>Standard Jump Jet</type>
            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="5">RL</location>
            <location index="5">LL</location>
            <location index="10">CT</location>
        </jumpjets>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

            <location index="3">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">Hatchet</name>
            <type>physical</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rotary AC/5</name>
            <type>ballistic</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Rotary AC/5</name>
            <type>ammunition</type>
            <location index="9">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Rotary AC/5</name>
            <type>ammunition</type>
            <location index="10">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">Guardian ECM Suite</name>
            <type>equipment</type>
            <location index="7">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Hatchetman" model="HCT-6S" tons="45" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1174</battle_value>
    <cost>5540840.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3064</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="180" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">Light Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>21</ct>
        <ctr>6</ctr>
        <lt>16</lt>
        <ltr>6</ltr>
        <rt>16</rt>
        <rtr>6</rtr>
        <la>14</la>
        <ra>14</ra>
        <ll>22</ll>
        <rl>22</rl>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">LT</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">CT</location>
        <location index="10">LT</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
        <location index="11">CT</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
        <location index="11">LA</location>
        <location index="11">RA</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Hatchetman HCT-6S 45t, 4/6/4, LFE, Std; 8.5T/99% FF Armor; 10 DHS; 3 ERML, 1 LB10, 1 Htcht</info>
        <battleforce pv="12" wt="2" mv="4j" s="3" m="3" l="1" e="0" ov="0" armor="5" internal="2" abilities="MEL, CASE, SRCH, ES, SEAL, SOA, FLK 1/1/1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="5">RL</location>
            <location index="5">LL</location>
        </jumpjets>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

            <location index="5">LT</location>
            <location index="2">LT</location>
            <location index="4">LA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="7">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LB 10-X AC</name>
            <type>ballistic</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Slug)</name>
            <type>ammunition</type>
            <location index="8">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Cluster)</name>
            <type>ammunition</type>
            <location index="9">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">Hatchet</name>
            <type>physical</type>
            <location index="5">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="10">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Hermes II" model="HER-5C" tons="40" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="C:\\CJ\\HeavyMetalbv2\\Pro\\Bmps\\hermes_ii.jpg">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1119</battle_value>
    <cost>7795579.999999999</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3062</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="3">LT</location>
        <location index="4">LT</location>
        <location index="5">LT</location>
        <location index="5">RT</location>
        <location index="6">LT</location>
        <location index="6">RT</location>
        <location index="7">LT</location>
        <location index="7">RT</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="10">RT</location>
        <location index="11">RT</location>
    </structure>
    <engine rating="240" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>17</ct>
        <ctr>6</ctr>
        <lt>14</lt>
        <ltr>5</ltr>
        <rt>14</rt>
        <rtr>5</rtr>
        <la>11</la>
        <ra>11</ra>
        <ll>14</ll>
        <rl>14</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Hermes II HER-5C 40t, 6/9/0, XLFE, ES; 7.5T/88% Armor; 10 DHS; 1 C3I, 1 ERLL, 1 iNarc, 3 ERML</info>
        <battleforce pv="11" wt="2" mv="6" s="2" m="2" l="1" e="0" ov="1" armor="4" internal="2" abilities="INARC, C3I, MHQ2, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

            <location index="3">LA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) ER Large Laser</name>
            <type>energy</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="6">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="5">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) iNarc Launcher</name>
            <type>missile</type>
            <location index="6">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">Improved C3 Computer</name>
            <type>equipment</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ iNarc (Homing)</name>
            <type>ammunition</type>
            <location index="10">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ iNarc (Homing)</name>
            <type>ammunition</type>
            <location index="9">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ iNarc (Homing)</name>
            <type>ammunition</type>
            <location index="11">LA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Hermes II" model="HER-5ME &quot;Mercury Elite&quot;" tons="40" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>847</battle_value>
    <cost>3484560.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3056</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="5">LT</location>
        <location index="5">RT</location>
        <location index="6">LT</location>
        <location index="6">RT</location>
        <location index="7">LT</location>
        <location index="7">RT</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
    </structure>
    <engine rating="240" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>8</hd>
        <ct>14</ct>
        <ctr>4</ctr>
        <lt>12</lt>
        <ltr>4</ltr>
        <rt>12</rt>
        <rtr>4</rtr>
        <la>9</la>
        <ra>9</ra>
        <ll>14</ll>
        <rl>14</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Hermes II HER-5ME "Mercury Elite" 40t, 6/9/0, Std FE, ES; 6.5T/76% Armor; 10 DHS; 1 LGR</info>
        <battleforce pv="8" wt="2" mv="6" s="1" m="1" l="1" e="0" ov="0" armor="3" internal="3" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

            <location index="0">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Light Gauss Rifle</name>
            <type>ballistic</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Light Gauss Rifle</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Light Gauss Rifle</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Hermes II" model="HER-5S" tons="40" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>857</battle_value>
    <cost>3452580.0</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3050</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="4">LT</location>
        <location index="5">LT</location>
        <location index="5">RT</location>
        <location index="6">LT</location>
        <location index="6">RT</location>
        <location index="7">LT</location>
        <location index="7">RT</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="11">RT</location>
    </structure>
    <engine rating="240" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>17</ct>
        <ctr>6</ctr>
        <lt>14</lt>
        <ltr>5</ltr>
        <rt>14</rt>
        <rtr>5</rtr>
        <la>11</la>
        <ra>11</ra>
        <ll>14</ll>
        <rl>14</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Hermes II HER-5S 40t, 6/9/0, Std FE, ES; 7.5T/88% Armor; 10 DHS; 1 Flmr, 1 UAC5, 1 MPL</info>
        <battleforce pv="9" wt="2" mv="6" s="2" m="2" l="1" e="0" ov="0" armor="4" internal="3" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

            <location index="0">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Ultra AC/5</name>
            <type>ballistic</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Ultra AC/5</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Hermes II" model="HER-5SA" tons="40" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>924</battle_value>
    <cost>6308580.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>6</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3068</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="3">HD</location>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="7">LT</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
    </structure>
    <engine rating="240" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>18</ct>
        <ctr>5</ctr>
        <lt>14</lt>
        <ltr>5</ltr>
        <rt>14</rt>
        <rtr>5</rtr>
        <la>11</la>
        <ra>11</ra>
        <ll>18</ll>
        <rl>18</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Hermes II HER-5SA 40t, 6/9/0, XLFE, ES; 8.0T/93% Armor; 10 DHS; 1 Flmr, 1 UAC5, 1 LPL</info>
        <battleforce pv="9" wt="2" mv="6" s="2" m="2" l="1" e="0" ov="0" armor="4" internal="2" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

            <location index="3">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Ultra AC/5</name>
            <type>ballistic</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Ultra AC/5</name>
            <type>ammunition</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Large Pulse Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Hermes II" model="HER-6D" tons="40" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1106</battle_value>
    <cost>3588580.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3062</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="4">LT</location>
        <location index="5">LT</location>
        <location index="6">LT</location>
        <location index="6">RT</location>
        <location index="7">LT</location>
        <location index="7">RT</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
    </structure>
    <engine rating="240" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>17</ct>
        <ctr>6</ctr>
        <lt>14</lt>
        <ltr>5</ltr>
        <rt>14</rt>
        <rtr>5</rtr>
        <la>11</la>
        <ra>11</ra>
        <ll>14</ll>
        <rl>14</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Hermes II HER-6D 40t, 6/9/0, Std FE, ES; 7.5T/88% Armor; 10 DHS; 1 Flmr, 1 RAC5, 1 ERML</info>
        <battleforce pv="11" wt="2" mv="6" s="3" m="3" l="0" e="0" ov="0" armor="4" internal="3" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

            <location index="0">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rotary AC/5</name>
            <type>ballistic</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Rotary AC/5</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Hoplite" model="C" tons="55" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1736</battle_value>
    <cost>5138408.333333333</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Clan</techbase>
    <year restricted="FALSE">2840</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="220" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="1">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>26</ct>
        <ctr>10</ctr>
        <lt>18</lt>
        <ltr>8</ltr>
        <rt>18</rt>
        <rtr>8</rtr>
        <la>18</la>
        <ra>18</ra>
        <ll>26</ll>
        <rl>26</rl>
        <location index="4">LT</location>
        <location index="4">LA</location>
        <location index="5">LT</location>
        <location index="5">LA</location>
        <location index="6">LT</location>
        <location index="6">RA</location>
        <location index="7">RA</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Hoplite C 55t, 4/6/0, Std FE, Std; 10.0T/100% FF Armor; 10 DHS; 1 UAC/10, 1 LRM15, 1 ERML, 2 MPL</info>
        <battleforce pv="17" wt="2" mv="4" s="5" m="5" l="3" e="0" ov="0" armor="6" internal="5" abilities="CASE, SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>TRUE</clancase>
        <heatsinks number="10" techbase="1">
            <type>Double Heat Sink</type>

            <location index="0">RT</location>
            <location index="0">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(CL) Ultra AC/10</name>
            <type>ballistic</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) ER Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) LRM-15</name>
            <type>missile</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(CL) @ Ultra AC/10</name>
            <type>ammunition</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(CL) @ Ultra AC/10</name>
            <type>ammunition</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(CL) @ Ultra AC/10</name>
            <type>ammunition</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(CL) @ LRM-15</name>
            <type>ammunition</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(CL) @ LRM-15</name>
            <type>ammunition</type>
            <location index="6">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional>&lt;p style=&quot;margin-top: 0&quot;&gt;:br::br:&lt;/p&gt;:br:</additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Hoplite" model="HOP-4B" tons="55" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1162</battle_value>
    <cost>4322758.333333333</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2682</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="220" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>26</ct>
        <ctr>9</ctr>
        <lt>18</lt>
        <ltr>8</ltr>
        <rt>18</rt>
        <rtr>8</rtr>
        <la>18</la>
        <ra>18</ra>
        <ll>26</ll>
        <rl>26</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Hoplite HOP-4B 55t, 4/6/0, Std FE, Std; 11.5T/99% Armor; 16 SHS; 1 LRM15, 1 PPC</info>
        <battleforce pv="12" wt="2" mv="4" s="2" m="2" l="2" e="0" ov="0" armor="6" internal="5" abilities="SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="16" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">RL</location>
            <location index="5">RL</location>
            <location index="5">LL</location>
            <location index="4">LL</location>
            <location index="0">LT</location>
            <location index="3">LT</location>
            <location index="1">LT</location>
            <location index="2">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="11">CT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Hoplite" model="HOP-4C" tons="55" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>986</battle_value>
    <cost>4074008.333333333</cost>
    <rules_level>0</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2584</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="220" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>26</ct>
        <ctr>9</ctr>
        <lt>18</lt>
        <ltr>8</ltr>
        <rt>18</rt>
        <rtr>8</rtr>
        <la>18</la>
        <ra>18</ra>
        <ll>26</ll>
        <rl>26</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Hoplite HOP-4C 55t, 4/6/0, Std FE, Std; 11.5T/99% Armor; 16 SHS; 1 AC10, 1 LRM5</info>
        <battleforce pv="10" wt="2" mv="4" s="2" m="2" l="1" e="0" ov="0" armor="6" internal="5" abilities="SRCH, ES, SEAL, SOA, AC 1/1/0" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="16" techbase="2">
            <type>Single Heat Sink</type>

            <location index="5">LL</location>
            <location index="4">LL</location>
            <location index="5">RL</location>
            <location index="4">RL</location>
            <location index="1">LT</location>
            <location index="2">LT</location>
            <location index="0">LT</location>
            <location index="3">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Autocannon/10</name>
            <type>ballistic</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-5</name>
            <type>missile</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/10</name>
            <type>ammunition</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-5</name>
            <type>ammunition</type>
            <location index="10">CT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Hoplite" model="HOP-4D" tons="55" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1022</battle_value>
    <cost>4410008.333333333</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3050</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="220" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>26</ct>
        <ctr>9</ctr>
        <lt>18</lt>
        <ltr>8</ltr>
        <rt>18</rt>
        <rtr>8</rtr>
        <la>18</la>
        <ra>18</ra>
        <ll>26</ll>
        <rl>26</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Hoplite HOP-4D 55t, 4/6/0, Std FE, Std; 11.5T/99% Armor; 16 SHS; 1 LRM5, 1 LB10</info>
        <battleforce pv="10" wt="2" mv="4" s="1" m="1" l="1" e="0" ov="0" armor="6" internal="5" abilities="SRCH, ES, SEAL, SOA, FLK 1/1/1" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="16" techbase="2">
            <type>Single Heat Sink</type>

            <location index="0">LT</location>
            <location index="4">RL</location>
            <location index="1">RT</location>
            <location index="5">RL</location>
            <location index="0">RT</location>
            <location index="5">LL</location>
            <location index="4">LL</location>
            <location index="1">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) LRM-5</name>
            <type>missile</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-5</name>
            <type>ammunition</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LB 10-X AC</name>
            <type>ballistic</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Slug)</name>
            <type>ammunition</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Cluster)</name>
            <type>ammunition</type>
            <location index="3">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Hornet" model="HNT-161" tons="20" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>511</battle_value>
    <cost>1397500.0000000002</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3052</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="4">RT</location>
        <location index="5">RT</location>
        <location index="6">LT</location>
        <location index="6">RT</location>
        <location index="7">LT</location>
        <location index="7">RT</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
    </structure>
    <engine rating="100" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>7</hd>
        <ct>8</ct>
        <ctr>3</ctr>
        <lt>8</lt>
        <ltr>2</ltr>
        <rt>8</rt>
        <rtr>2</rtr>
        <la>6</la>
        <ra>6</ra>
        <ll>6</ll>
        <rl>6</rl>
        <location index="3">LA</location>
        <location index="3">RA</location>
        <location index="4">LA</location>
        <location index="4">RA</location>
        <location index="5">LA</location>
        <location index="5">RA</location>
        <location index="6">LA</location>
        <location index="6">RA</location>
        <location index="7">LA</location>
        <location index="7">RA</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Hornet HNT-161 20t, 5/8/5, Std FE, ES; 3.5T/90% FF Armor; 10 SHS; 1 ML, 1 SL, 1 LRM5</info>
        <battleforce pv="5" wt="1" mv="5j" s="1" m="1" l="1" e="0" ov="0" armor="2" internal="2" abilities="CASE, SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="5">
            <type>Standard Jump Jet</type>
            <location index="4">LL</location>
            <location index="5">LL</location>
            <location index="10">CT</location>
            <location index="4">RL</location>
            <location index="5">RL</location>
        </jumpjets>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="1">LT</location>
            <location index="0">LT</location>
            <location index="2">LT</location>
            <location index="1">RT</location>
            <location index="0">RT</location>
            <location index="2">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-5</name>
            <type>missile</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-5</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-5</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="5">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Hornet" model="HNT-171" tons="20" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>535</battle_value>
    <cost>1429200.0000000002</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3051</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="3">RT</location>
        <location index="4">RT</location>
        <location index="5">RT</location>
        <location index="6">LT</location>
        <location index="6">RT</location>
        <location index="7">LT</location>
        <location index="7">RT</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="10">LT</location>
        <location index="11">CT</location>
        <location index="11">LT</location>
    </structure>
    <engine rating="100" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>7</hd>
        <ct>8</ct>
        <ctr>3</ctr>
        <lt>8</lt>
        <ltr>2</ltr>
        <rt>8</rt>
        <rtr>2</rtr>
        <la>6</la>
        <ra>6</ra>
        <ll>6</ll>
        <rl>6</rl>
        <location index="2">LA</location>
        <location index="3">LA</location>
        <location index="3">RA</location>
        <location index="4">LA</location>
        <location index="4">RA</location>
        <location index="5">LA</location>
        <location index="5">RA</location>
        <location index="6">LA</location>
        <location index="6">RA</location>
        <location index="7">LA</location>
        <location index="7">RA</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">RA</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Hornet HNT-171 20t, 5/8/3, Std FE, ES; 3.5T/90% FF Armor; 10 SHS; 1 ML, 1 LRM5, 1 AMS</info>
        <battleforce pv="5" wt="1" mv="5/2j" s="1" m="1" l="1" e="0" ov="0" armor="2" internal="2" abilities="AMS, CASE, SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="3">
            <type>Standard Jump Jet</type>
            <location index="1">LT</location>
            <location index="1">RT</location>
            <location index="10">CT</location>
        </jumpjets>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="0">RT</location>
            <location index="0">LT</location>
            <location index="5">RL</location>
            <location index="5">LL</location>
            <location index="4">LL</location>
            <location index="4">RL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-5</name>
            <type>missile</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-5</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-5</name>
            <type>ammunition</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Anti-Missile System</name>
            <type>equipment</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Anti-Missile System</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="5">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Hunchback" model="HBK-5H" tons="50" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1097</battle_value>
    <cost>3814500.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3064</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="200" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>24</ct>
        <ctr>5</ctr>
        <lt>20</lt>
        <ltr>4</ltr>
        <rt>20</rt>
        <rtr>4</rtr>
        <la>14</la>
        <ra>14</ra>
        <ll>19</ll>
        <rl>19</rl>
        <location index="5">LA</location>
        <location index="5">RA</location>
        <location index="6">LA</location>
        <location index="6">RA</location>
        <location index="7">LA</location>
        <location index="7">RA</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
        <location index="11">LA</location>
        <location index="11">RA</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Hunchback HBK-5H 50t, 4/6/0, Std FE, Std; 8.5T/90% FF Armor; 10 SHS; 2 ML, 1 AC20, 3 RL20</info>
        <battleforce pv="11" wt="2" mv="4" s="3" m="3" l="0" e="0" ov="0" armor="5" internal="4" abilities="CASE, SRCH, ES, SEAL, SOA, AC 2/2/0" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">RL</location>
            <location index="4">LL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Autocannon/20</name>
            <type>ballistic</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/20</name>
            <type>ammunition</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/20</name>
            <type>ammunition</type>
            <location index="10">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rocket Launcher 20</name>
            <type>missile</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rocket Launcher 20</name>
            <type>missile</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rocket Launcher 20</name>
            <type>missile</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="11">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional>&lt;p style=&quot;margin-top: 0&quot;&gt;:br::br:&lt;/p&gt;:br:</additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Hunchback" model="HBK-5M" tons="50" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1056</battle_value>
    <cost>3638000.0</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3046</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="200" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>26</ct>
        <ctr>5</ctr>
        <lt>20</lt>
        <ltr>4</ltr>
        <rt>20</rt>
        <rtr>4</rtr>
        <la>16</la>
        <ra>16</ra>
        <ll>20</ll>
        <rl>20</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Hunchback HBK-5M 50t, 4/6/0, Std FE, Std; 10.0T/95% Armor; 13 DHS; 2 ML, 1 SPL, 1 AC20</info>
        <battleforce pv="11" wt="2" mv="4" s="3" m="3" l="0" e="0" ov="0" armor="5" internal="4" abilities="CASE, SRCH, ES, SEAL, SOA, AC 2/2/0" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="13" techbase="0">
            <type>Double Heat Sink</type>

            <location index="7">LA</location>
            <location index="4">LA</location>
            <location index="0">LT</location>
            <location index="4">RA</location>
            <location index="7">RA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Autocannon/20</name>
            <type>ballistic</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="10">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="10">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/20</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Pulse Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="4">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional>&lt;p style=&quot;margin-top: 0&quot;&gt;:br::br:&lt;/p&gt;:br:</additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Hunchback" model="HBK-5N" tons="50" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1041</battle_value>
    <cost>3565875.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3057</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="200" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>26</ct>
        <ctr>5</ctr>
        <lt>20</lt>
        <ltr>4</ltr>
        <rt>20</rt>
        <rtr>4</rtr>
        <la>16</la>
        <ra>16</ra>
        <ll>20</ll>
        <rl>20</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Hunchback HBK-5N 50t, 4/6/0, Std FE, Std; 10.0T/95% Armor; 13 DHS; 2 ML, 1 SL, 1 AC20</info>
        <battleforce pv="10" wt="2" mv="4" s="4" m="3" l="0" e="0" ov="0" armor="5" internal="4" abilities="SRCH, ES, SEAL, SOA, AC 2/2/0" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="13" techbase="0">
            <type>Double Heat Sink</type>

            <location index="4">LA</location>
            <location index="4">RA</location>
            <location index="7">RA</location>
            <location index="0">LT</location>
            <location index="7">LA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Autocannon/20</name>
            <type>ballistic</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="10">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="10">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/20</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/20</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional>&lt;p style=&quot;margin-top: 0&quot;&gt;:br::br:&lt;/p&gt;:br:</additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Hunchback" model="HBK-5P" tons="50" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1340</battle_value>
    <cost>4594000.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>6</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3069</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="200" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>24</ct>
        <ctr>8</ctr>
        <lt>18</lt>
        <ltr>6</ltr>
        <rt>18</rt>
        <rtr>6</rtr>
        <la>16</la>
        <ra>16</ra>
        <ll>24</ll>
        <rl>24</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Hunchback HBK-5P 50t, 4/6/0, Std FE, Std; 11.0T/100% Armor; 18 DHS; 1 ECM, 1 C3S, 6 ERML, 2 MPL</info>
        <battleforce pv="13" wt="2" mv="4" s="5" m="5" l="0" e="0" ov="0" armor="6" internal="4" abilities="C3S, ECM, ENE, MHQ1, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="18" techbase="0">
            <type>Double Heat Sink</type>

            <location index="4">LA</location>
            <location index="4">RA</location>
            <location index="7">RA</location>
            <location index="0">LT</location>
            <location index="7">LA</location>
            <location index="0">RT</location>
            <location index="3">LT</location>
            <location index="3">RT</location>
            <location index="6">RT</location>
            <location index="6">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="10">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="10">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="10">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="9">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="10">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="11">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="11">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">C3 Computer (Slave)</name>
            <type>equipment</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">Guardian ECM Suite</name>
            <type>equipment</type>
            <location index="10">CT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional>&lt;p style=&quot;margin-top: 0&quot;&gt;:br::br:&lt;/p&gt;:br:</additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Hunchback" model="HBK-5S" tons="50" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1350</battle_value>
    <cost>6556875.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3058</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="7">LT</location>
        <location index="8">LT</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">LT</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">LT</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
        <location index="11">LA</location>
        <location index="11">RA</location>
    </structure>
    <engine rating="200" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">Light Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>24</ct>
        <ctr>8</ctr>
        <lt>18</lt>
        <ltr>6</ltr>
        <rt>18</rt>
        <rtr>6</rtr>
        <la>16</la>
        <ra>16</ra>
        <ll>24</ll>
        <rl>24</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Hunchback HBK-5S 50t, 4/6/4, LFE, ES; 11.0T/100% Armor; 10 DHS; 1 SL, 2 MPL, 1 LB20</info>
        <battleforce pv="14" wt="2" mv="4j" s="3" m="3" l="0" e="0" ov="0" armor="6" internal="3" abilities="CASE, SRCH, ES, SEAL, SOA, FLK 1/1/0" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="5">RL</location>
            <location index="5">LL</location>
        </jumpjets>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

            <location index="4">RA</location>
            <location index="4">LA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="7">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="7">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LB 20-X AC</name>
            <type>ballistic</type>
            <splitlocation index="2" number="9">RT</splitlocation>
            <splitlocation index="10" number="2">CT</splitlocation>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 20-X AC (Slug)</name>
            <type>ammunition</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 20-X AC (Slug)</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 20-X AC (Cluster)</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 20-X AC (Cluster)</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="6">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional>&lt;p style=&quot;margin-top: 0&quot;&gt;:br::br:&lt;/p&gt;:br:</additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Hunchback" model="HBK-5SG" tons="50" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1577</battle_value>
    <cost>7750000.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>6</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3068</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="3">HD</location>
        <location index="6">LT</location>
        <location index="6">LA</location>
        <location index="6">RA</location>
        <location index="7">LT</location>
        <location index="7">LA</location>
        <location index="7">RA</location>
        <location index="8">LT</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
    </structure>
    <engine rating="200" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">Light Fusion Engine</engine>
    <gyro techbase="0">Compact Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Stealth Armor</type>
        <hd>9</hd>
        <ct>23</ct>
        <ctr>8</ctr>
        <lt>18</lt>
        <ltr>6</ltr>
        <rt>18</rt>
        <rtr>6</rtr>
        <la>16</la>
        <ra>16</ra>
        <ll>24</ll>
        <rl>24</rl>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
        <location index="11">LA</location>
        <location index="11">RA</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Hunchback HBK-5SG 50t, 4/6/4, LFE, ES, Cmp Gyro; 10.5T/99% Stlth Armor; 10 DHS; 1 ECM, 1 GR, 2 MPL</info>
        <battleforce pv="16" wt="2" mv="4j" s="3" m="3" l="2" e="0" ov="0" armor="6" internal="3" abilities="ECM, STL, SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="8">CT</location>
            <location index="9">CT</location>
            <location index="3">LT</location>
            <location index="2">LT</location>
        </jumpjets>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

            <location index="2">RA</location>
            <location index="2">LA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="5">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="5">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Gauss Rifle</name>
            <type>ballistic</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">Guardian ECM Suite</name>
            <type>equipment</type>
            <location index="10">CT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional>&lt;p style=&quot;margin-top: 0&quot;&gt;:br::br:&lt;/p&gt;:br:</additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Hunchback" model="HBK-5SS" tons="50" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1342</battle_value>
    <cost>8665875.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>6</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3068</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="7">LA</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">RT</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">CT</location>
        <location index="10">RT</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
        <location index="11">CT</location>
        <location index="11">RT</location>
        <location index="11">LA</location>
        <location index="11">RA</location>
    </structure>
    <engine rating="200" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">Light Fusion Engine</engine>
    <gyro techbase="0">Heavy-Duty Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>24</ct>
        <ctr>8</ctr>
        <lt>18</lt>
        <ltr>6</ltr>
        <rt>18</rt>
        <rtr>6</rtr>
        <la>16</la>
        <ra>16</ra>
        <ll>24</ll>
        <rl>24</rl>
    </armor>
    <baseloadout fcsa4="TRUE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Hunchback HBK-5SS 50t, 4/6/4, LFE, ES, HD Gyro; 11.0T/100% Armor; 10 DHS; 3 ERML, 1 ERSL, 2 MML9</info>
        <battleforce pv="13" wt="2" mv="4j" s="4" m="3" l="2" e="0" ov="1" armor="6" internal="3" abilities="CASE, SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="4">LL</location>
            <location index="5">LL</location>
            <location index="4">RL</location>
            <location index="5">RL</location>
        </jumpjets>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

            <location index="3">LA</location>
            <location index="4">RA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) ER Small Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="8">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="6">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="7">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) MML-9</name>
            <type>missile</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) MML-9</name>
            <type>missile</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ MML-9 (LRM Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ MML-9 (SRM Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ MML-9 (SRM Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="10">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="11">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional>&lt;p style=&quot;margin-top: 0&quot;&gt;:br::br:&lt;/p&gt;:br:</additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Hunchback" model="HBK-6N" tons="50" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1130</battle_value>
    <cost>3641000.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3059</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="200" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>26</ct>
        <ctr>5</ctr>
        <lt>20</lt>
        <ltr>4</ltr>
        <rt>20</rt>
        <rtr>4</rtr>
        <la>16</la>
        <ra>16</ra>
        <ll>20</ll>
        <rl>20</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Hunchback HBK-6N 50t, 4/6/0, Std FE, Std; 10.0T/95% Armor; 10 DHS; 2 ML, 1 SPL, 1 AC20</info>
        <battleforce pv="11" wt="2" mv="4" s="4" m="3" l="0" e="0" ov="0" armor="5" internal="4" abilities="CASE, SRCH, ES, SEAL, SOA, AC 2/2/0" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

            <location index="4">LA</location>
            <location index="4">RA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Autocannon/20</name>
            <type>ballistic</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="7">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="7">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/20</name>
            <type>ammunition</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/20</name>
            <type>ammunition</type>
            <location index="1">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Pulse Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/20</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/20</name>
            <type>ammunition</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="4">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional>&lt;p style=&quot;margin-top: 0&quot;&gt;:br::br:&lt;/p&gt;:br:</additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Hunchback" model="HBK-6S" tons="50" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1380</battle_value>
    <cost>9813000.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3063</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="5">LA</location>
        <location index="5">RA</location>
        <location index="6">LA</location>
        <location index="6">RA</location>
        <location index="7">LA</location>
        <location index="7">RA</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
        <location index="11">LA</location>
        <location index="11">RA</location>
    </structure>
    <engine rating="300" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>26</ct>
        <ctr>5</ctr>
        <lt>20</lt>
        <ltr>4</ltr>
        <rt>20</rt>
        <rtr>4</rtr>
        <la>16</la>
        <ra>16</ra>
        <ll>20</ll>
        <rl>20</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Hunchback HBK-6S 50t, 6/9/0, XLFE, ES; 10.0T/95% Armor; 10 DHS; 2 ERML, 1 SRM6, 1 LB20</info>
        <battleforce pv="14" wt="2" mv="6" s="3" m="3" l="0" e="0" ov="0" armor="5" internal="2" abilities="SRCH, ES, SEAL, SOA, FLK 1/1/0" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) LB 20-X AC</name>
            <type>ballistic</type>
            <splitlocation index="3" number="9">RT</splitlocation>
            <splitlocation index="10" number="2">CT</splitlocation>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 20-X AC (Slug)</name>
            <type>ammunition</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 20-X AC (Cluster)</name>
            <type>ammunition</type>
            <location index="7">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional>&lt;p style=&quot;margin-top: 0&quot;&gt;:br::br:&lt;/p&gt;:br:</additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Imp" model="C" tons="100" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>3070</battle_value>
    <cost>1.083E7</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Clan</techbase>
    <year restricted="FALSE">2863</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="300" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>44</ct>
        <ctr>15</ctr>
        <lt>32</lt>
        <ltr>10</ltr>
        <rt>32</rt>
        <rtr>10</rtr>
        <la>34</la>
        <ra>34</ra>
        <ll>42</ll>
        <rl>42</rl>
    </armor>
    <baseloadout fcsa4="TRUE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Imp C 100t, 3/5/0, Std FE, Std; 19.0T/99% Armor; 25 DHS; 5 MPL, 2 ERPPC, 1 LRM20</info>
        <battleforce pv="31" wt="4" mv="3" s="8" m="8" l="5" e="0" ov="1" armor="10" internal="8" abilities="CASE, SRCH, ES, SEAL, SOA, IF 2" />
        <actuators lla="FALSE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>TRUE</clancase>
        <heatsinks number="25" techbase="1">
            <type>Double Heat Sink</type>

            <location index="4">LA</location>
            <location index="2">LA</location>
            <location index="6">LA</location>
            <location index="0">LT</location>
            <location index="2">LT</location>
            <location index="4">LT</location>
            <location index="4">RL</location>
            <location index="4">LL</location>
            <location index="3">RA</location>
            <location index="0">RT</location>
            <location index="4">RT</location>
            <location index="2">RT</location>
            <location index="6">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(CL) ER PPC</name>
            <type>energy</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) ER PPC</name>
            <type>energy</type>
            <location index="8">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="8">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="10">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) LRM-20</name>
            <type>missile</type>
            <location index="5">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(CL) @ LRM-20 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(CL) @ LRM-20 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="10">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(CL) @ LRM-20 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="11">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional>&lt;p style=&quot;margin-top: 0&quot;&gt;:br::br:&lt;/p&gt;:br:</additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Imp" model="IMP-2E" tons="100" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1969</battle_value>
    <cost>9390000.0</cost>
    <rules_level>0</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3005</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="300" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>40</ct>
        <ctr>21</ctr>
        <lt>30</lt>
        <ltr>12</ltr>
        <rt>30</rt>
        <rtr>12</rtr>
        <la>25</la>
        <ra>25</ra>
        <ll>42</ll>
        <rl>42</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Imp IMP-2E 100t, 3/5/0, Std FE, Std; 18.0T/94% Armor; 30 SHS; 5 ML, 1 LRM15, 2 PPC</info>
        <battleforce pv="20" wt="4" mv="3" s="4" m="5" l="3" e="0" ov="1" armor="10" internal="8" abilities="SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="FALSE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="30" techbase="2">
            <type>Single Heat Sink</type>

            <location index="1">LT</location>
            <location index="0">LT</location>
            <location index="5">LT</location>
            <location index="3">LT</location>
            <location index="4">LT</location>
            <location index="4">LL</location>
            <location index="5">LL</location>
            <location index="5">RL</location>
            <location index="4">RL</location>
            <location index="2">LT</location>
            <location index="3">HD</location>
            <location index="3">RT</location>
            <location index="4">RT</location>
            <location index="0">RT</location>
            <location index="1">RT</location>
            <location index="5">RT</location>
            <location index="6">RT</location>
            <location index="2">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="7">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="10">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="10">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Imp" model="IMP-3E" tons="100" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1906</battle_value>
    <cost>2.227E7</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3050</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="300" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>40</ct>
        <ctr>21</ctr>
        <lt>30</lt>
        <ltr>12</ltr>
        <rt>30</rt>
        <rtr>12</rtr>
        <la>25</la>
        <ra>25</ra>
        <ll>42</ll>
        <rl>42</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Imp IMP-3E 100t, 3/5/0, XLFE, Std; 18.0T/94% Armor; 30 SHS; 2 ML, 1 LRM15, 2 MPL, 2 ERPPC, 1 LPL</info>
        <battleforce pv="19" wt="4" mv="3" s="4" m="4" l="2" e="0" ov="3" armor="10" internal="4" abilities="CASE, SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="FALSE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="30" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="4">LA</location>
            <location index="3">RA</location>
            <location index="4">LT</location>
            <location index="4">RA</location>
            <location index="4">RT</location>
            <location index="5">RA</location>
            <location index="3">RT</location>
            <location index="3">HD</location>
            <location index="3">LT</location>
            <location index="5">RT</location>
            <location index="6">RT</location>
            <location index="5">LL</location>
            <location index="5">LT</location>
            <location index="2">LA</location>
            <location index="3">LA</location>
            <location index="5">RL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Large Pulse Laser</name>
            <type>energy</type>
            <location index="5">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER PPC</name>
            <type>energy</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER PPC</name>
            <type>energy</type>
            <location index="7">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="6">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="10">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="11">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="10">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="11">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Imp" model="IMP-4E" tons="100" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>2263</battle_value>
    <cost>1.0867E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3060</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="300" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>42</ct>
        <ctr>15</ctr>
        <lt>31</lt>
        <ltr>10</ltr>
        <rt>31</rt>
        <rtr>10</rtr>
        <la>33</la>
        <ra>33</ra>
        <ll>41</ll>
        <rl>41</rl>
    </armor>
    <baseloadout fcsa4="TRUE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Imp IMP-4E 100t, 3/5/0, Std FE, Std; 18.5T/96% Armor; 21 DHS; 3 ERML, 2 ERSL, 2 ERPPC, 2 MPL, 1 LRM20</info>
        <battleforce pv="23" wt="4" mv="3" s="5" m="5" l="3" e="0" ov="3" armor="10" internal="8" abilities="CASE, SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="21" techbase="0">
            <type>Double Heat Sink</type>

            <location index="2">LA</location>
            <location index="2">RA</location>
            <location index="0">LT</location>
            <location index="0">RT</location>
            <location index="5">LA</location>
            <location index="3">RT</location>
            <location index="6">RT</location>
            <location index="8">LA</location>
            <location index="3">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="11">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER PPC</name>
            <type>energy</type>
            <location index="9">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER PPC</name>
            <type>energy</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-20</name>
            <type>missile</type>
            <location index="5">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="10">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="11">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Small Laser</name>
            <type>energy</type>
            <location index="4">RL</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Small Laser</name>
            <type>energy</type>
            <location index="4">LL</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="11">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="JagerMech" model="JM6-DD" tons="65" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>965</battle_value>
    <cost>1.1406925E7</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3049</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="260" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>18</ct>
        <ctr>5</ctr>
        <lt>17</lt>
        <ltr>5</ltr>
        <rt>17</rt>
        <rtr>5</rtr>
        <la>8</la>
        <ra>8</ra>
        <ll>12</ll>
        <rl>12</rl>
        <location index="7">LT</location>
        <location index="7">RT</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>JagerMech JM6-DD 65t, 4/6/0, XLFE, Std; 6.5T/55% FF Armor; 10 SHS; 2 UAC5, 2 MPL, 2 AC2</info>
        <battleforce pv="10" wt="3" mv="4" s="3" m="3" l="2" e="0" ov="1" armor="4" internal="3" abilities="CASE, SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Autocannon/2</name>
            <type>ballistic</type>
            <location index="7">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Autocannon/2</name>
            <type>ballistic</type>
            <location index="7">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/2</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/2</name>
            <type>ammunition</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Ultra AC/5</name>
            <type>ballistic</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Ultra AC/5</name>
            <type>ballistic</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Ultra AC/5</name>
            <type>ammunition</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Ultra AC/5</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="6">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="JagerMech" model="JM6-DG" tons="65" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1625</battle_value>
    <cost>1.1622425E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3054</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="260" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>18</ct>
        <ctr>5</ctr>
        <lt>17</lt>
        <ltr>5</ltr>
        <rt>17</rt>
        <rtr>5</rtr>
        <la>8</la>
        <ra>8</ra>
        <ll>12</ll>
        <rl>12</rl>
        <location index="7">LT</location>
        <location index="7">RT</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
        <location index="11">LA</location>
        <location index="11">RA</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>JagerMech JM6-DG 65t, 4/6/0, XLFE, Std; 6.5T/55% FF Armor; 10 DHS; 2 ML, 2 GR</info>
        <battleforce pv="16" wt="3" mv="4" s="4" m="4" l="3" e="0" ov="0" armor="4" internal="3" abilities="CASE, SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Gauss Rifle</name>
            <type>ballistic</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Gauss Rifle</name>
            <type>ballistic</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="6">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="JagerMech" model="JM6-DGr" tons="65" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1661</battle_value>
    <cost>1.1754425E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3058</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="260" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>18</ct>
        <ctr>5</ctr>
        <lt>17</lt>
        <ltr>5</ltr>
        <rt>17</rt>
        <rtr>5</rtr>
        <la>8</la>
        <ra>8</ra>
        <ll>12</ll>
        <rl>12</rl>
        <location index="7">LT</location>
        <location index="7">RT</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
        <location index="11">LA</location>
        <location index="11">RA</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>JagerMech JM6-DGr 65t, 4/6/0, XLFE, Std; 6.5T/55% FF Armor; 10 DHS; 2 ERML, 2 GR</info>
        <battleforce pv="17" wt="3" mv="4" s="4" m="4" l="3" e="0" ov="0" armor="4" internal="3" abilities="CASE, SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Gauss Rifle</name>
            <type>ballistic</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Gauss Rifle</name>
            <type>ballistic</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="6">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="JagerMech" model="JM6-H" tons="65" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1348</battle_value>
    <cost>5877925.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3064</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="260" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>29</ct>
        <ctr>7</ctr>
        <lt>24</lt>
        <ltr>5</ltr>
        <rt>24</rt>
        <rtr>5</rtr>
        <la>16</la>
        <ra>16</ra>
        <ll>22</ll>
        <rl>22</rl>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="6">RT</location>
        <location index="7">LT</location>
        <location index="7">RT</location>
        <location index="8">LT</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>JagerMech JM6-H 65t, 4/6/0, Std FE, Std; 10.0T/85% FF Armor; 10 DHS; 6 RL15, 2 LB2, 2 LL</info>
        <battleforce pv="13" wt="3" mv="4" s="3" m="3" l="1" e="0" ov="0" armor="6" internal="5" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) LB 2-X AC</name>
            <type>ballistic</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LB 2-X AC</name>
            <type>ballistic</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rocket Launcher 15</name>
            <type>missile</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rocket Launcher 15</name>
            <type>missile</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rocket Launcher 15</name>
            <type>missile</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rocket Launcher 15</name>
            <type>missile</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rocket Launcher 15</name>
            <type>missile</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rocket Launcher 15</name>
            <type>missile</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 2-X AC (Slug)</name>
            <type>ammunition</type>
            <location index="6">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="JagerMech" model="JM7-D" tons="70" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1500</battle_value>
    <cost>1.3729633333333336E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3057</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="280" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>34</ct>
        <ctr>6</ctr>
        <lt>24</lt>
        <ltr>6</ltr>
        <rt>24</rt>
        <rtr>6</rtr>
        <la>22</la>
        <ra>22</ra>
        <ll>22</ll>
        <rl>22</rl>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
        <location index="11">LA</location>
        <location index="11">RA</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>JagerMech JM7-D 70t, 4/6/0, XLFE, Std; 11.0T/91% FF Armor; 13 DHS; 2 ERLL, 2 UAC5, 2 MPL</info>
        <battleforce pv="15" wt="3" mv="4" s="4" m="4" l="3" e="0" ov="1" armor="7" internal="3" abilities="CASE, SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="13" techbase="0">
            <type>Double Heat Sink</type>

            <location index="3">LT</location>
            <location index="3">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Ultra AC/5</name>
            <type>ballistic</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Ultra AC/5</name>
            <type>ballistic</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Large Laser</name>
            <type>energy</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Large Laser</name>
            <type>energy</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Ultra AC/5</name>
            <type>ammunition</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Ultra AC/5</name>
            <type>ammunition</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="7">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="7">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="JagerMech" model="JM7-F" tons="70" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1774</battle_value>
    <cost>1.3681433333333336E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3062</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="280" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>34</ct>
        <ctr>6</ctr>
        <lt>24</lt>
        <ltr>6</ltr>
        <rt>24</rt>
        <rtr>6</rtr>
        <la>22</la>
        <ra>22</ra>
        <ll>22</ll>
        <rl>22</rl>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">LT</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
        <location index="11">LT</location>
        <location index="11">LA</location>
        <location index="11">RA</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>JagerMech JM7-F 70t, 4/6/0, XLFE, Std; 11.0T/91% FF Armor; 12 DHS; 1 ECM, 2 RAC5, 2 MPL, TC</info>
        <battleforce pv="18" wt="3" mv="4" s="6" m="6" l="0" e="0" ov="0" armor="7" internal="3" abilities="ECM, CASE, SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="12" techbase="0">
            <type>Double Heat Sink</type>

            <location index="3">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rotary AC/5</name>
            <type>ballistic</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rotary AC/5</name>
            <type>ballistic</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Rotary AC/5</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Rotary AC/5</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Rotary AC/5</name>
            <type>ammunition</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Rotary AC/5</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">Guardian ECM Suite</name>
            <type>equipment</type>
            <location index="7">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Targeting Computer</name>
            <type>TargetingComputer</type>
            <location index="6">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="JagerMech" model="JM7-G" tons="70" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1275</battle_value>
    <cost>1.3521533333333336E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>6</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3068</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="280" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>34</ct>
        <ctr>6</ctr>
        <lt>24</lt>
        <ltr>6</ltr>
        <rt>24</rt>
        <rtr>6</rtr>
        <la>22</la>
        <ra>22</ra>
        <ll>22</ll>
        <rl>22</rl>
        <location index="7">LT</location>
        <location index="7">RT</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
        <location index="11">LA</location>
        <location index="11">RA</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>JagerMech JM7-G 70t, 4/6/0, XLFE, Std; 11.0T/91% FF Armor; 11 DHS; 2 ERML, 2 RAC2, 2 LB2</info>
        <battleforce pv="13" wt="3" mv="4" s="3" m="3" l="2" e="0" ov="0" armor="7" internal="3" abilities="CASE, SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="11" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Rotary AC/2</name>
            <type>ballistic</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rotary AC/2</name>
            <type>ballistic</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LB 2-X AC</name>
            <type>ballistic</type>
            <location index="5">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LB 2-X AC</name>
            <type>ballistic</type>
            <location index="5">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 2-X AC (Cluster)</name>
            <type>ammunition</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 2-X AC (Slug)</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Rotary AC/2</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Rotary AC/2</name>
            <type>ammunition</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Rotary AC/2</name>
            <type>ammunition</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Rotary AC/2</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="6">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Javelin" model="JVN-10P" tons="30" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>590</battle_value>
    <cost>2346640.0</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3049</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="180" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>6</hd>
        <ct>8</ct>
        <ctr>2</ctr>
        <lt>8</lt>
        <ltr>2</ltr>
        <rt>8</rt>
        <rtr>2</rtr>
        <la>6</la>
        <ra>6</ra>
        <ll>8</ll>
        <rl>8</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Javelin JVN-10P 30t, 6/9/6, Std FE, Std; 4.0T/61% Armor; 10 SHS; 2 SSRM2, 1 SRM6</info>
        <battleforce pv="6" wt="1" mv="6j" s="2" m="2" l="0" e="0" ov="0" armor="2" internal="3" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="6">
            <type>Standard Jump Jet</type>
            <location index="4">RL</location>
            <location index="5">RL</location>
            <location index="10">CT</location>
            <location index="11">CT</location>
            <location index="4">LL</location>
            <location index="5">LL</location>
        </jumpjets>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="0">RT</location>
            <location index="0">LT</location>
            <location index="3">HD</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="1">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Streak SRM-2</name>
            <type>missile</type>
            <location index="1">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Streak SRM-2</name>
            <type>missile</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Streak SRM-2</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Javelin" model="JVN-11A &quot;Fire Javelin&quot;" tons="30" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1073</battle_value>
    <cost>2642640.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3053</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="180" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>11</ct>
        <ctr>3</ctr>
        <lt>9</lt>
        <ltr>3</ltr>
        <rt>9</rt>
        <rtr>3</rtr>
        <la>9</la>
        <ra>9</ra>
        <ll>12</ll>
        <rl>12</rl>
        <location index="4">LA</location>
        <location index="5">LT</location>
        <location index="5">LA</location>
        <location index="6">LT</location>
        <location index="6">LA</location>
        <location index="7">LT</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Javelin JVN-11A "Fire Javelin" 30t, 6/9/6, Std FE, Std; 5.0T/85% FF Armor; 10 DHS; 7 ML</info>
        <battleforce pv="11" wt="1" mv="6j" s="4" m="4" l="0" e="0" ov="0" armor="3" internal="3" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="6">
            <type>Standard Jump Jet</type>
            <location index="5">RL</location>
            <location index="11">CT</location>
            <location index="5">LL</location>
            <location index="10">CT</location>
            <location index="4">LL</location>
            <location index="4">RL</location>
        </jumpjets>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

            <location index="0">RT</location>
            <location index="3">RT</location>
            <location index="0">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="5">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="6">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="7">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Javelin" model="JVN-11B" tons="30" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>678</battle_value>
    <cost>2857840.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3054</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="180" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>11</ct>
        <ctr>3</ctr>
        <lt>9</lt>
        <ltr>3</ltr>
        <rt>9</rt>
        <rtr>3</rtr>
        <la>9</la>
        <ra>9</ra>
        <ll>12</ll>
        <rl>12</rl>
        <location index="3">HD</location>
        <location index="4">LA</location>
        <location index="4">RA</location>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="8">LT</location>
        <location index="9">LT</location>
        <location index="10">CT</location>
        <location index="10">LT</location>
        <location index="11">CT</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Javelin JVN-11B 30t, 6/9/0, Std FE, Std; 5.0T/85% FF Armor; 10 DHS; 1 ECM, 1 BAP, 2 SRM4</info>
        <battleforce pv="7" wt="1" mv="6" s="2" m="2" l="0" e="0" ov="0" armor="3" internal="3" abilities="ECM, PRB, RCN, CASE, SRCH, ES, SEAL, SOA, SRM 1/1/0" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

            <location index="0">RT</location>
            <location index="3">RT</location>
            <location index="0">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) SRM-4</name>
            <type>missile</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-4</name>
            <type>missile</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4</name>
            <type>ammunition</type>
            <location index="7">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">Guardian ECM Suite</name>
            <type>equipment</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">Beagle Active Probe</name>
            <type>equipment</type>
            <location index="8">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="7">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="10">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Javelin" model="JVN-11D" tons="30" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1104</battle_value>
    <cost>4504240.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3062</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="7">LA</location>
        <location index="7">RA</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
        <location index="11">LA</location>
        <location index="11">RA</location>
    </structure>
    <engine rating="180" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>8</hd>
        <ct>15</ct>
        <ctr>5</ctr>
        <lt>10</lt>
        <ltr>4</ltr>
        <rt>10</rt>
        <rtr>4</rtr>
        <la>8</la>
        <ra>8</ra>
        <ll>12</ll>
        <rl>12</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Javelin JVN-11D 30t, 6/9/6, XLFE, ES; 6.0T/91% Armor; 11 DHS; 1 C3S, 3 ERML, 2 MPL, TC</info>
        <battleforce pv="11" wt="1" mv="6j" s="3" m="3" l="0" e="0" ov="1" armor="3" internal="1" abilities="C3S, ENE, MHQ1, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="6">
            <type>Standard Jump Jet</type>
            <location index="5">RL</location>
            <location index="11">CT</location>
            <location index="5">LL</location>
            <location index="10">CT</location>
            <location index="4">LL</location>
            <location index="4">RL</location>
        </jumpjets>
        <heatsinks number="11" techbase="0">
            <type>Double Heat Sink</type>

            <location index="4">LA</location>
            <location index="3">RT</location>
            <location index="4">RA</location>
            <location index="3">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="7">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="7">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="8">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">C3 Computer (Slave)</name>
            <type>equipment</type>
            <location index="9">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Targeting Computer</name>
            <type>TargetingComputer</type>
            <location index="8">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Javelin" model="JVN-11F" tons="30" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>827</battle_value>
    <cost>4110690.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>6</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3070</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
        <location index="11">LA</location>
        <location index="11">RA</location>
    </structure>
    <engine rating="180" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>8</hd>
        <ct>15</ct>
        <ctr>5</ctr>
        <lt>10</lt>
        <ltr>4</ltr>
        <rt>10</rt>
        <rtr>4</rtr>
        <la>8</la>
        <ra>8</ra>
        <ll>12</ll>
        <rl>12</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Javelin JVN-11F 30t, 6/9/6, XLFE, ES; 6.0T/91% Armor; 10 DHS; 2 MGA+3MG, 1 SNPPC</info>
        <battleforce pv="8" wt="1" mv="6j" s="2" m="1" l="0" e="0" ov="0" armor="3" internal="1" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="6">
            <type>Standard Jump Jet</type>
            <location index="5">RL</location>
            <location index="11">CT</location>
            <location index="5">LL</location>
            <location index="10">CT</location>
            <location index="4">LL</location>
            <location index="4">RL</location>
        </jumpjets>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

            <location index="6">LT</location>
            <location index="3">LT</location>
            <location index="3">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) MG Array (3 Machine Gun)</name>
            <type>mgarray</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) MG Array (3 Machine Gun)</name>
            <type>mgarray</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Machine Gun</name>
            <type>ammunition</type>
            <location index="8">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Snub-Nose PPC</name>
            <type>energy</type>
            <location index="6">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Jenner" model="JR7-C" tons="35" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>832</battle_value>
    <cost>3580425.0</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3050</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="245" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>7</hd>
        <ct>9</ct>
        <ctr>2</ctr>
        <lt>8</lt>
        <ltr>4</ltr>
        <rt>8</rt>
        <rtr>4</rtr>
        <la>4</la>
        <ra>4</ra>
        <ll>6</ll>
        <rl>6</rl>
        <location index="3">LT</location>
        <location index="4">LT</location>
        <location index="4">RT</location>
        <location index="5">LT</location>
        <location index="5">RT</location>
        <location index="6">LT</location>
        <location index="6">RT</location>
        <location index="7">LT</location>
        <location index="7">RT</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="10">RT</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Jenner JR7-C 35t, 7/11/5, Std FE, Std; 3.5T/52% FF Armor; 10 SHS; 3 ML, 1 C3S, 1 SRM4</info>
        <battleforce pv="8" wt="1" mv="7/3j" s="2" m="2" l="0" e="0" ov="1" armor="2" internal="3" abilities="C3S, CASE, MHQ1, SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="5">
            <type>Standard Jump Jet</type>
            <location index="0">LT</location>
            <location index="1">LT</location>
            <location index="1">RT</location>
            <location index="0">RT</location>
            <location index="10">CT</location>
        </jumpjets>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="3">HD</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-4</name>
            <type>missile</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4</name>
            <type>ammunition</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">C3 Computer (Slave)</name>
            <type>equipment</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="3">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Jenner" model="JR7-C2" tons="35" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>769</battle_value>
    <cost>6478500.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>6</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3071</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="245" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="0">Extra-Light Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Small Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Heavy Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>14</ct>
        <ctr>4</ctr>
        <lt>11</lt>
        <ltr>4</ltr>
        <rt>11</rt>
        <rtr>4</rtr>
        <la>11</la>
        <ra>11</ra>
        <ll>15</ll>
        <rl>15</rl>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">LA</location>
        <location index="5">RA</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="6">LA</location>
        <location index="6">RA</location>
        <location index="7">LA</location>
        <location index="7">RA</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">LT</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Jenner JR7-C2 35t, 7/11/6, XLFE, Std, XL Gyro; 5.5T/92% HFF Armor; 10 SHS; 1 C3S, 3 MML5</info>
        <battleforce pv="8" wt="1" mv="7/4j" s="2" m="2" l="1" e="0" ov="0" armor="4" internal="2" abilities="C3S, CASE, MHQ1, SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="6">
            <type>Standard Jump Jet</type>
            <location index="3">RT</location>
            <location index="3">LT</location>
            <location index="4">RT</location>
            <location index="5">RT</location>
            <location index="4">LT</location>
            <location index="5">LT</location>
        </jumpjets>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">HD</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">C3 Computer (Slave)</name>
            <type>equipment</type>
            <location index="5">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) MML-5</name>
            <type>missile</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) MML-5</name>
            <type>missile</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) MML-5</name>
            <type>missile</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ MML-5 (LRM)</name>
            <type>ammunition</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ MML-5 (SRM)</name>
            <type>ammunition</type>
            <location index="8">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ MML-5 (SRM)</name>
            <type>ammunition</type>
            <location index="7">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="9">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Jenner" model="JR7-C3" tons="35" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1069</battle_value>
    <cost>3804525.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>6</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3068</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="245" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>8</hd>
        <ct>12</ct>
        <ctr>3</ctr>
        <lt>9</lt>
        <ltr>3</ltr>
        <rt>9</rt>
        <rtr>3</rtr>
        <la>9</la>
        <ra>9</ra>
        <ll>12</ll>
        <rl>12</rl>
        <location index="5">LT</location>
        <location index="5">RT</location>
        <location index="6">LT</location>
        <location index="6">RT</location>
        <location index="7">LT</location>
        <location index="7">RT</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Jenner JR7-C3 35t, 7/11/5, Std FE, Std; 5.0T/75% FF Armor; 11 DHS; 1 C3S, 4 ERML</info>
        <battleforce pv="11" wt="1" mv="7/3j" s="2" m="2" l="0" e="0" ov="0" armor="3" internal="3" abilities="C3S, ENE, MHQ1, SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="5">
            <type>Standard Jump Jet</type>
            <location index="3">RT</location>
            <location index="3">LT</location>
            <location index="4">RT</location>
            <location index="10">CT</location>
            <location index="4">LT</location>
        </jumpjets>
        <heatsinks number="11" techbase="0">
            <type>Double Heat Sink</type>

            <location index="0">LT</location>
            <location index="0">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">C3 Computer (Slave)</name>
            <type>equipment</type>
            <location index="3">HD</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Jenner" model="JR7-K" tons="35" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>889</battle_value>
    <cost>3296925.0</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3047</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="245" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>7</hd>
        <ct>9</ct>
        <ctr>2</ctr>
        <lt>8</lt>
        <ltr>4</ltr>
        <rt>8</rt>
        <rtr>4</rtr>
        <la>4</la>
        <ra>4</ra>
        <ll>6</ll>
        <rl>6</rl>
        <location index="2">LT</location>
        <location index="3">LT</location>
        <location index="4">LT</location>
        <location index="4">RT</location>
        <location index="5">LT</location>
        <location index="5">RT</location>
        <location index="6">LT</location>
        <location index="6">RT</location>
        <location index="7">LT</location>
        <location index="7">RT</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="9">RT</location>
        <location index="10">RT</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Jenner JR7-K 35t, 7/11/5, Std FE, Std; 3.5T/52% FF Armor; 10 SHS; 4 ML, 1 SRM4</info>
        <battleforce pv="9" wt="1" mv="7/3j" s="2" m="2" l="0" e="0" ov="1" armor="2" internal="3" abilities="CASE, SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="5">
            <type>Standard Jump Jet</type>
            <location index="10">CT</location>
            <location index="0">LT</location>
            <location index="1">LT</location>
            <location index="0">RT</location>
            <location index="1">RT</location>
        </jumpjets>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="3">HD</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-4</name>
            <type>missile</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4</name>
            <type>ammunition</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="3">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Katana (Crockett)" model="CRK-5003-2" tons="85" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1629</battle_value>
    <cost>7734825.0</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3035</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="255" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>31</ct>
        <ctr>12</ctr>
        <lt>23</lt>
        <ltr>8</ltr>
        <rt>23</rt>
        <rtr>8</rtr>
        <la>19</la>
        <ra>19</ra>
        <ll>24</ll>
        <rl>24</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Katana (Crockett) CRK-5003-2 85t, 3/5/3, Std FE, Std; 12.5T/76% Armor; 20 SHS; 2 SL, 2 SRM6, 1 LB10, 2 LL</info>
        <battleforce pv="16" wt="4" mv="3j" s="4" m="3" l="1" e="0" ov="0" armor="7" internal="7" abilities="SRCH, ES, SEAL, SOA, SRM 1/1/0, FLK 1/1/1" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="3">
            <type>Standard Jump Jet</type>
            <location index="10">CT</location>
            <location index="5">RL</location>
            <location index="5">LL</location>
        </jumpjets>
        <heatsinks number="20" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="0">LT</location>
            <location index="1">LT</location>
            <location index="2">LT</location>
            <location index="3">LT</location>
            <location index="0">RT</location>
            <location index="2">RT</location>
            <location index="1">RT</location>
            <location index="3">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="5">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="5">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="7">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LB 10-X AC</name>
            <type>ballistic</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Cluster)</name>
            <type>ammunition</type>
            <location index="9">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Slug)</name>
            <type>ammunition</type>
            <location index="8">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Katana (Crockett)" model="CRK-5003-C" tons="85" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1618</battle_value>
    <cost>8155700.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3057</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="255" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>31</ct>
        <ctr>12</ctr>
        <lt>23</lt>
        <ltr>8</ltr>
        <rt>23</rt>
        <rtr>8</rtr>
        <la>19</la>
        <ra>19</ra>
        <ll>24</ll>
        <rl>24</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Katana (Crockett) CRK-5003-C 85t, 3/5/3, Std FE, Std; 12.5T/76% Armor; 20 SHS; 1 C3S, 2 SRM6, 1 LB10, 2 LL</info>
        <battleforce pv="16" wt="4" mv="3j" s="4" m="4" l="1" e="0" ov="0" armor="7" internal="7" abilities="C3S, MHQ1, SRCH, ES, SEAL, SOA, SRM 1/1/0, FLK 1/1/1" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="3">
            <type>Standard Jump Jet</type>
            <location index="10">CT</location>
            <location index="5">RL</location>
            <location index="5">LL</location>
        </jumpjets>
        <heatsinks number="20" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">RL</location>
            <location index="4">LL</location>
            <location index="0">RT</location>
            <location index="0">LT</location>
            <location index="1">RT</location>
            <location index="2">RT</location>
            <location index="1">LT</location>
            <location index="2">LT</location>
            <location index="3">RT</location>
            <location index="3">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LB 10-X AC</name>
            <type>ballistic</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="7">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Slug)</name>
            <type>ammunition</type>
            <location index="8">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Cluster)</name>
            <type>ammunition</type>
            <location index="9">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">C3 Computer (Slave)</name>
            <type>equipment</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="6">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Katana (Crockett)" model="CRK-5003-CJ" tons="85" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1844</battle_value>
    <cost>1.0448775E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>6</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3071</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="255" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>31</ct>
        <ctr>12</ctr>
        <lt>23</lt>
        <ltr>8</ltr>
        <rt>23</rt>
        <rtr>8</rtr>
        <la>19</la>
        <ra>19</ra>
        <ll>24</ll>
        <rl>24</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Katana (Crockett) CRK-5003-CJ 85t, 3/5/5, Std FE, Std; 12.5T/76% Armor; 15 DHS; 1 C3S, 2 ERML, 2 LPPC, 2 SRM6, 1 LB10</info>
        <battleforce pv="18" wt="4" mv="3/5j" s="4" m="5" l="2" e="0" ov="0" armor="7" internal="7" abilities="C3S, MHQ1, SRCH, ES, SEAL, SOA, SRM 2/2/0, FLK 1/1/1" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="5">
            <type>Improved Jump Jet</type>
            <location index="0">LT</location>
            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="3">RT</location>
            <location index="10">CT</location>
        </jumpjets>
        <heatsinks number="15" techbase="0">
            <type>Double Heat Sink</type>

            <location index="0">RT</location>
            <location index="3">RA</location>
            <location index="3">LA</location>
            <location index="6">RA</location>
            <location index="6">LA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) LB 10-X AC</name>
            <type>ballistic</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="7">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="8">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Slug)</name>
            <type>ammunition</type>
            <location index="9">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Cluster)</name>
            <type>ammunition</type>
            <location index="10">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">C3 Computer (Slave)</name>
            <type>equipment</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="11">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="11">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Light PPC</name>
            <type>energy</type>
            <location index="9">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Light PPC</name>
            <type>energy</type>
            <location index="9">LA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Katana (Crockett)" model="CRK-5003-CM" tons="85" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1554</battle_value>
    <cost>1.02932E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3057</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="255" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>31</ct>
        <ctr>12</ctr>
        <lt>23</lt>
        <ltr>8</ltr>
        <rt>23</rt>
        <rtr>8</rtr>
        <la>19</la>
        <ra>19</ra>
        <ll>24</ll>
        <rl>24</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Katana (Crockett) CRK-5003-CM 85t, 3/5/3, Std FE, Std; 12.5T/76% Armor; 20 SHS; 1 SRM6, 1 LB10, 1 C3M, 2 LL</info>
        <battleforce pv="16" wt="4" mv="3j" s="3" m="3" l="1" e="0" ov="0" armor="7" internal="7" abilities="C3M, TAG, MHQ5, SRCH, ES, SEAL, SOA, FLK 1/1/1" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="3">
            <type>Standard Jump Jet</type>
            <location index="10">CT</location>
            <location index="5">RL</location>
            <location index="5">LL</location>
        </jumpjets>
        <heatsinks number="20" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">RL</location>
            <location index="4">LL</location>
            <location index="0">RT</location>
            <location index="0">LT</location>
            <location index="1">RT</location>
            <location index="2">RT</location>
            <location index="1">LT</location>
            <location index="2">LT</location>
            <location index="3">RT</location>
            <location index="3">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Large Laser</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LB 10-X AC</name>
            <type>ballistic</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Slug)</name>
            <type>ammunition</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Cluster)</name>
            <type>ammunition</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">C3 Computer (Master)</name>
            <type>equipment</type>
            <location index="7">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Mauler" model="MAL-1K" tons="90" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1622</battle_value>
    <cost>1.91038E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>6</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3069</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="270" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>33</ct>
        <ctr>10</ctr>
        <lt>28</lt>
        <ltr>10</ltr>
        <rt>28</rt>
        <rtr>10</rtr>
        <la>24</la>
        <ra>24</ra>
        <ll>24</ll>
        <rl>24</rl>
        <location index="3">HD</location>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="7">RA</location>
        <location index="8">RA</location>
        <location index="9">RA</location>
        <location index="10">CT</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
        <location index="11">CT</location>
        <location index="11">LA</location>
        <location index="11">RA</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Mauler MAL-1K 90t, 3/5/0, XLFE, Std; 12.5T/80% FF Armor; 11 DHS; 2 LRM15, 2 SNPPC, 4 LAC5</info>
        <battleforce pv="16" wt="4" mv="3" s="4" m="4" l="2" e="0" ov="0" armor="7" internal="4" abilities="SRCH, ES, SEAL, SOA, AC 1/1/0, LRM 1/1/1, IF 1" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="11" techbase="0">
            <type>Double Heat Sink</type>

            <location index="3">LA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Snub-Nose PPC</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Snub-Nose PPC</name>
            <type>energy</type>
            <location index="6">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Light AC/5</name>
            <type>ballistic</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Light AC/5</name>
            <type>ballistic</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Light AC/5</name>
            <type>ballistic</type>
            <location index="8">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Light AC/5</name>
            <type>ballistic</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="11">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="10">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="11">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="10">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Light AC/5</name>
            <type>ammunition</type>
            <location index="9">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Light AC/5</name>
            <type>ammunition</type>
            <location index="8">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Light AC/5</name>
            <type>ammunition</type>
            <location index="5">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Light AC/5</name>
            <type>ammunition</type>
            <location index="6">RA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Mauler" model="MAL-1R" tons="90" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1460</battle_value>
    <cost>1.82898E7</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3048</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="270" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>27</ct>
        <ctr>10</ctr>
        <lt>26</lt>
        <ltr>10</ltr>
        <rt>26</rt>
        <rtr>10</rtr>
        <la>22</la>
        <ra>22</ra>
        <ll>22</ll>
        <rl>22</rl>
        <location index="3">HD</location>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">RA</location>
        <location index="6">RA</location>
        <location index="7">RA</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">CT</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
        <location index="11">CT</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Mauler MAL-1R 90t, 3/5/0, XLFE, Std; 11.5T/74% FF Armor; 11 DHS; 2 LRM15, 2 ERLL, 4 AC2</info>
        <battleforce pv="15" wt="4" mv="3" s="2" m="3" l="3" e="0" ov="1" armor="7" internal="4" abilities="CASE, SRCH, ES, SEAL, SOA, LRM 1/1/1, IF 1" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="11" techbase="0">
            <type>Double Heat Sink</type>

            <location index="3">LA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Autocannon/2</name>
            <type>ballistic</type>
            <location index="7">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Autocannon/2</name>
            <type>ballistic</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Autocannon/2</name>
            <type>ballistic</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Autocannon/2</name>
            <type>ballistic</type>
            <location index="7">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="10">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="10">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="9">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/2</name>
            <type>ammunition</type>
            <location index="8">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/2</name>
            <type>ammunition</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Large Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Large Laser</name>
            <type>energy</type>
            <location index="6">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="11">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="11">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Mauler" model="MAL-2R" tons="90" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1586</battle_value>
    <cost>8912200.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3060</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="270" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>27</ct>
        <ctr>10</ctr>
        <lt>26</lt>
        <ltr>10</ltr>
        <rt>26</rt>
        <rtr>10</rtr>
        <la>22</la>
        <ra>22</ra>
        <ll>22</ll>
        <rl>22</rl>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">CT</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
        <location index="11">CT</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
        <location index="11">LA</location>
        <location index="11">RA</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Mauler MAL-2R 90t, 3/5/0, Std FE, Std; 11.5T/74% FF Armor; 12 DHS; 4 ERML, 2 LRM10, 4 UAC2</info>
        <battleforce pv="16" wt="4" mv="3" s="3" m="4" l="2" e="0" ov="1" armor="7" internal="7" abilities="CASE, SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="12" techbase="0">
            <type>Double Heat Sink</type>

            <location index="3">LA</location>
            <location index="3">RA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Ultra AC/2</name>
            <type>ballistic</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Ultra AC/2</name>
            <type>ballistic</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Ultra AC/2</name>
            <type>ballistic</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Ultra AC/2</name>
            <type>ballistic</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="7">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="6">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="6">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="7">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-10</name>
            <type>missile</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-10</name>
            <type>missile</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Ultra AC/2</name>
            <type>ammunition</type>
            <location index="8">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Ultra AC/2</name>
            <type>ammunition</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="9">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="10">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="10">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Mauler" model="MAL-3R" tons="90" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1877</battle_value>
    <cost>1.9298475E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3062</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="270" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>44</ct>
        <ctr>14</ctr>
        <lt>28</lt>
        <ltr>10</ltr>
        <rt>28</rt>
        <rtr>10</rtr>
        <la>30</la>
        <ra>30</ra>
        <ll>38</ll>
        <rl>38</rl>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
        <location index="11">LA</location>
        <location index="11">RA</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Mauler MAL-3R 90t, 3/5/3, XLFE, Std; 16.0T/100% FF Armor; 10 DHS; 1 SL, 2 LRM15, 1 C3S, 2 LB10</info>
        <battleforce pv="19" wt="4" mv="3j" s="3" m="3" l="3" e="0" ov="0" armor="9" internal="4" abilities="C3S, MHQ1, SRCH, ES, SEAL, SOA, LRM 1/2/2, IF 2, FLK 1/1/1" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="3">
            <type>Standard Jump Jet</type>
            <location index="10">CT</location>
            <location index="3">RT</location>
            <location index="3">LT</location>
        </jumpjets>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="7">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="8">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="7">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">C3 Computer (Slave)</name>
            <type>equipment</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LB 10-X AC</name>
            <type>ballistic</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LB 10-X AC</name>
            <type>ballistic</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Slug)</name>
            <type>ammunition</type>
            <location index="9">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Slug)</name>
            <type>ammunition</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Cluster)</name>
            <type>ammunition</type>
            <location index="10">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Cluster)</name>
            <type>ammunition</type>
            <location index="10">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Mauler" model="MAL-C" tons="90" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1473</battle_value>
    <cost>1.87638E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3051</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="270" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>27</ct>
        <ctr>10</ctr>
        <lt>26</lt>
        <ltr>10</ltr>
        <rt>26</rt>
        <rtr>10</rtr>
        <la>22</la>
        <ra>22</ra>
        <ll>22</ll>
        <rl>22</rl>
        <location index="3">HD</location>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">RA</location>
        <location index="6">RA</location>
        <location index="7">RA</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">CT</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
        <location index="11">CT</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Mauler MAL-C 90t, 3/5/0, XLFE, Std; 11.5T/74% FF Armor; 11 DHS; 2 LRM15, 2 ERLL, 1 C3S, 4 AC2</info>
        <battleforce pv="15" wt="4" mv="3" s="2" m="3" l="3" e="0" ov="1" armor="7" internal="4" abilities="C3S, CASE, MHQ1, SRCH, ES, SEAL, SOA, LRM 1/1/1, IF 1" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="11" techbase="0">
            <type>Double Heat Sink</type>

            <location index="3">LA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) ER Large Laser</name>
            <type>energy</type>
            <location index="6">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Large Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Autocannon/2</name>
            <type>ballistic</type>
            <location index="7">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Autocannon/2</name>
            <type>ballistic</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Autocannon/2</name>
            <type>ballistic</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Autocannon/2</name>
            <type>ballistic</type>
            <location index="7">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="10">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="9">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ AC/2</name>
            <type>ammunition</type>
            <location index="8">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">C3 Computer (Slave)</name>
            <type>equipment</type>
            <location index="10">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="11">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="11">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional>&lt;p style=&quot;margin-top: 0&quot;&gt;:br::br:&lt;/p&gt;:br:</additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Orion" model="ON1-K (Kerensky)" tons="75" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1821</battle_value>
    <cost>7557750.0</cost>
    <rules_level>1</rules_level>
    <era>4</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2753</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="300" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>36</ct>
        <ctr>10</ctr>
        <lt>22</lt>
        <ltr>10</ltr>
        <rt>22</rt>
        <rtr>10</rtr>
        <la>24</la>
        <ra>24</ra>
        <ll>32</ll>
        <rl>32</rl>
        <location index="4">LT</location>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">LT</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="6">LT</location>
        <location index="7">LT</location>
        <location index="8">LT</location>
        <location index="9">LT</location>
        <location index="10">CT</location>
        <location index="10">LT</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
    </armor>
    <baseloadout fcsa4="TRUE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Orion ON1-K (Kerensky) 75t, 4/6/0, Std FE, Std; 13.0T/100% FF Armor; 10 DHS; 2 ML, 1 SNPPC, 1 GR, 1 SRM4</info>
        <battleforce pv="18" wt="3" mv="4" s="4" m="4" l="2" e="0" ov="0" armor="8" internal="6" abilities="CASE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Gauss Rifle</name>
            <type>ballistic</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-4</name>
            <type>missile</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Snub-Nose PPC</name>
            <type>energy</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="7">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="8">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="9">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="10">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Orion" model="ON1-M-DC" tons="75" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1444</battle_value>
    <cost>1.639075E7</cost>
    <rules_level>2</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3053</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="300" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="TRUE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>36</ct>
        <ctr>9</ctr>
        <lt>22</lt>
        <ltr>10</ltr>
        <rt>22</rt>
        <rtr>10</rtr>
        <la>21</la>
        <ra>21</ra>
        <ll>32</ll>
        <rl>32</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Orion ON1-M-DC 75t, 4/6/0, XLFE, Std; 14.0T/97% Armor; 10 DHS; 2 ML, 1 Narc, 1 SRM4, 1 LRM20, 1 LB10</info>
        <battleforce pv="14" wt="3" mv="4" s="3" m="4" l="2" e="0" ov="0" armor="7" internal="3" abilities="SNARC, CASE, SRCH, ES, SEAL, SOA, LRM 1/1/1, IF 1, FLK 1/1/1" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) LB 10-X AC</name>
            <type>ballistic</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="8">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-20</name>
            <type>missile</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-4</name>
            <type>missile</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Narc Missile Beacon</name>
            <type>missile</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Slug)</name>
            <type>ammunition</type>
            <location index="9">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Cluster)</name>
            <type>ammunition</type>
            <location index="10">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Narc (Homing)</name>
            <type>ammunition</type>
            <location index="7">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20 (Narc Capable)</name>
            <type>ammunition</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20 (Narc Capable)</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4 (Narc Capable)</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="11">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Orion" model="ON1-M" tons="75" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1414</battle_value>
    <cost>1.558625E7</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3049</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="300" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>36</ct>
        <ctr>9</ctr>
        <lt>22</lt>
        <ltr>10</ltr>
        <rt>22</rt>
        <rtr>10</rtr>
        <la>21</la>
        <ra>21</ra>
        <ll>32</ll>
        <rl>32</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Orion ON1-M 75t, 4/6/0, XLFE, Std; 14.0T/97% Armor; 11 DHS; 2 ML, 1 Narc, 1 SRM4, 1 LRM20, 1 LB10</info>
        <battleforce pv="14" wt="3" mv="4" s="3" m="4" l="2" e="0" ov="0" armor="7" internal="3" abilities="SNARC, CASE, SRCH, ES, SEAL, SOA, LRM 1/1/1, IF 1, FLK 1/1/1" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="11" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) LB 10-X AC</name>
            <type>ballistic</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="8">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-20</name>
            <type>missile</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-4</name>
            <type>missile</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Narc Missile Beacon</name>
            <type>missile</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Slug)</name>
            <type>ammunition</type>
            <location index="9">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Cluster)</name>
            <type>ammunition</type>
            <location index="10">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Narc (Homing)</name>
            <type>ammunition</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Narc (Homing)</name>
            <type>ammunition</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20 (Narc Capable)</name>
            <type>ammunition</type>
            <location index="7">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20 (Narc Capable)</name>
            <type>ammunition</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4 (Narc Capable)</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4 (Narc Capable)</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="10">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="11">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Orion" model="ON1-MA" tons="75" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1501</battle_value>
    <cost>1.585425E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3052</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="300" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>36</ct>
        <ctr>9</ctr>
        <lt>22</lt>
        <ltr>10</ltr>
        <rt>22</rt>
        <rtr>10</rtr>
        <la>21</la>
        <ra>21</ra>
        <ll>32</ll>
        <rl>32</rl>
    </armor>
    <baseloadout fcsa4="TRUE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Orion ON1-MA 75t, 4/6/0, XLFE, Std; 14.0T/97% Armor; 11 DHS; 1 SRM6, 2 MPL, 1 LRM20, 1 LB10</info>
        <battleforce pv="15" wt="3" mv="4" s="4" m="5" l="3" e="0" ov="0" armor="7" internal="3" abilities="CASE, SRCH, ES, SEAL, SOA, IF 2, FLK 1/1/1" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="11" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) LB 10-X AC</name>
            <type>ballistic</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-20</name>
            <type>missile</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Slug)</name>
            <type>ammunition</type>
            <location index="9">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Cluster)</name>
            <type>ammunition</type>
            <location index="10">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="9">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="7">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="10">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="11">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Orion" model="ON1-MB" tons="75" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1472</battle_value>
    <cost>1.54955E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3058</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="300" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>36</ct>
        <ctr>9</ctr>
        <lt>22</lt>
        <ltr>10</ltr>
        <rt>22</rt>
        <rtr>10</rtr>
        <la>21</la>
        <ra>21</ra>
        <ll>32</ll>
        <rl>32</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Orion ON1-MB 75t, 4/6/0, XLFE, Std; 14.0T/97% Armor; 11 DHS; 1 Narc, 2 ERML, 1 LRM20, 1 SRM4, 1 LGR</info>
        <battleforce pv="15" wt="3" mv="4" s="3" m="4" l="2" e="0" ov="0" armor="7" internal="3" abilities="SNARC, CASE, SRCH, ES, SEAL, SOA, LRM 1/1/1, IF 1" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="11" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Narc Missile Beacon</name>
            <type>missile</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Light Gauss Rifle</name>
            <type>ballistic</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-4</name>
            <type>missile</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="8">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-20</name>
            <type>missile</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4 (Narc Capable)</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4 (Narc Capable)</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Narc (Homing)</name>
            <type>ammunition</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Narc (Homing)</name>
            <type>ammunition</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20 (Narc Capable)</name>
            <type>ammunition</type>
            <location index="7">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20 (Narc Capable)</name>
            <type>ammunition</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Light Gauss Rifle</name>
            <type>ammunition</type>
            <location index="8">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="10">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="9">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Orion" model="ON1-MC" tons="75" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1501</battle_value>
    <cost>1.569375E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3056</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="300" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>36</ct>
        <ctr>9</ctr>
        <lt>22</lt>
        <ltr>10</ltr>
        <rt>22</rt>
        <rtr>10</rtr>
        <la>21</la>
        <ra>21</ra>
        <ll>32</ll>
        <rl>32</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Orion ON1-MC 75t, 4/6/0, XLFE, Std; 14.0T/97% Armor; 11 DHS; 2 ML, 1 C3S, 1 MRM20, 1 MRM30, 1 LB10</info>
        <battleforce pv="15" wt="3" mv="4" s="5" m="5" l="1" e="0" ov="0" armor="7" internal="3" abilities="C3S, CASE, MHQ1, SRCH, ES, SEAL, SOA, FLK 1/1/1" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="11" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) LB 10-X AC</name>
            <type>ballistic</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="6">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) MRM-30</name>
            <type>missile</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) MRM-20</name>
            <type>missile</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Slug)</name>
            <type>ammunition</type>
            <location index="9">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Cluster)</name>
            <type>ammunition</type>
            <location index="10">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ MRM-20</name>
            <type>ammunition</type>
            <location index="10">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ MRM-30</name>
            <type>ammunition</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ MRM-30</name>
            <type>ammunition</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">C3 Computer (Slave)</name>
            <type>equipment</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="11">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="11">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Orion" model="ON1-MD" tons="75" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1597</battle_value>
    <cost>1.53295E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3062</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="300" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>36</ct>
        <ctr>9</ctr>
        <lt>22</lt>
        <ltr>10</ltr>
        <rt>22</rt>
        <rtr>10</rtr>
        <la>21</la>
        <ra>21</ra>
        <ll>32</ll>
        <rl>32</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Orion ON1-MD 75t, 4/6/0, XLFE, Std; 14.0T/97% Armor; 11 DHS; 2 ML, 1 Narc, 1 RAC5, 1 LRM20, 1 SRM4</info>
        <battleforce pv="16" wt="3" mv="4" s="5" m="5" l="2" e="0" ov="0" armor="7" internal="3" abilities="SNARC, CASE, SRCH, ES, SEAL, SOA, LRM 1/1/1, IF 1" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="11" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="8">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-4</name>
            <type>missile</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-20</name>
            <type>missile</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Narc Missile Beacon</name>
            <type>missile</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rotary AC/5</name>
            <type>ballistic</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4 (Narc Capable)</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Narc (Homing)</name>
            <type>ammunition</type>
            <location index="10">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Narc (Homing)</name>
            <type>ammunition</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20 (Narc Capable)</name>
            <type>ammunition</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-20 (Narc Capable)</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Rotary AC/5</name>
            <type>ammunition</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Rotary AC/5</name>
            <type>ammunition</type>
            <location index="7">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Rotary AC/5</name>
            <type>ammunition</type>
            <location index="9">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Rotary AC/5</name>
            <type>ammunition</type>
            <location index="10">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="11">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="11">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Orion" model="ON2-M" tons="75" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="C:\\CJ\\HeavyMetalbv2\\Pro\\Bmps\\3025 Orion1.jpg">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1828</battle_value>
    <cost>7615000.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3063</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="4">LA</location>
        <location index="4">RA</location>
        <location index="5">LA</location>
        <location index="5">RA</location>
        <location index="6">LA</location>
        <location index="6">RA</location>
        <location index="7">LA</location>
        <location index="7">RA</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
    </structure>
    <engine rating="300" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>35</ct>
        <ctr>10</ctr>
        <lt>22</lt>
        <ltr>10</ltr>
        <rt>22</rt>
        <rtr>10</rtr>
        <la>21</la>
        <ra>21</ra>
        <ll>32</ll>
        <rl>32</rl>
        <location index="3">HD</location>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="8">LT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="10">CT</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="11">CT</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
    </armor>
    <baseloadout fcsa4="TRUE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Orion ON2-M 75t, 4/6/0, Std FE, ES; 12.5T/97% FF Armor; 10 DHS; 1 LRM15, 1 GR, 3 MPL</info>
        <battleforce pv="18" wt="3" mv="4" s="4" m="5" l="3" e="0" ov="0" armor="7" internal="6" abilities="CASE, SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Gauss Rifle</name>
            <type>ballistic</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="7">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="8">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="7">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Panther" model="PNT-10K" tons="35" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>838</battle_value>
    <cost>2897460.0000000005</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3051</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="4">RT</location>
        <location index="4">LA</location>
        <location index="5">RT</location>
        <location index="6">LT</location>
        <location index="6">RT</location>
        <location index="7">LT</location>
        <location index="7">RT</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="11">LT</location>
    </structure>
    <engine rating="140" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>14</ct>
        <ctr>7</ctr>
        <lt>10</lt>
        <ltr>5</ltr>
        <rt>10</rt>
        <rtr>5</rtr>
        <la>10</la>
        <ra>10</ra>
        <ll>12</ll>
        <rl>12</rl>
    </armor>
    <baseloadout fcsa4="TRUE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Panther PNT-10K 35t, 4/6/4, Std FE, ES; 6.5T/87% Armor; 13 SHS; 1 ERPPC, 1 SRM4</info>
        <battleforce pv="8" wt="1" mv="4j" s="2" m="2" l="1" e="0" ov="0" armor="3" internal="3" abilities="CASE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="5">LL</location>
            <location index="5">RL</location>
            <location index="4">RL</location>
            <location index="4">LL</location>
        </jumpjets>
        <heatsinks number="13" techbase="2">
            <type>Single Heat Sink</type>

            <location index="3">LT</location>
            <location index="1">RT</location>
            <location index="0">RT</location>
            <location index="2">LT</location>
            <location index="1">LT</location>
            <location index="2">RT</location>
            <location index="3">RT</location>
            <location index="0">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) ER PPC</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-4</name>
            <type>missile</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="5">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Panther" model="PNT-10KA" tons="35" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>797</battle_value>
    <cost>2762460.0000000005</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3055</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="4">RT</location>
        <location index="4">LA</location>
        <location index="5">RT</location>
        <location index="6">LT</location>
        <location index="6">RT</location>
        <location index="7">LT</location>
        <location index="7">RT</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="11">LT</location>
    </structure>
    <engine rating="140" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>14</ct>
        <ctr>7</ctr>
        <lt>10</lt>
        <ltr>5</ltr>
        <rt>10</rt>
        <rtr>5</rtr>
        <la>10</la>
        <ra>10</ra>
        <ll>12</ll>
        <rl>12</rl>
    </armor>
    <baseloadout fcsa4="TRUE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Panther PNT-10KA 35t, 4/6/4, Std FE, ES; 6.5T/87% Armor; 13 SHS; 1 PPC, 1 SRM4</info>
        <battleforce pv="8" wt="1" mv="4j" s="2" m="2" l="1" e="0" ov="0" armor="3" internal="3" abilities="CASE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="5">LL</location>
            <location index="5">RL</location>
            <location index="4">RL</location>
            <location index="4">LL</location>
        </jumpjets>
        <heatsinks number="13" techbase="2">
            <type>Single Heat Sink</type>

            <location index="3">LT</location>
            <location index="1">RT</location>
            <location index="0">RT</location>
            <location index="2">LT</location>
            <location index="1">LT</location>
            <location index="2">RT</location>
            <location index="3">RT</location>
            <location index="0">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) SRM-4</name>
            <type>missile</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="5">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Panther" model="PNT-12A" tons="35" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>982</battle_value>
    <cost>2862360.0000000005</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3064</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="5">LT</location>
        <location index="5">RT</location>
        <location index="6">LT</location>
        <location index="6">RT</location>
        <location index="7">LT</location>
        <location index="7">RT</location>
        <location index="7">LA</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="11">RT</location>
    </structure>
    <engine rating="140" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>16</ct>
        <ctr>6</ctr>
        <lt>12</lt>
        <ltr>4</ltr>
        <rt>12</rt>
        <rtr>4</rtr>
        <la>12</la>
        <ra>12</ra>
        <ll>16</ll>
        <rl>16</rl>
    </armor>
    <baseloadout fcsa4="TRUE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Panther PNT-12A 35t, 4/6/4, Std FE, ES; 7.5T/100% Armor; 10 DHS; 1 SSRM4, 1 ERLL, 1 MPL, TC</info>
        <battleforce pv="10" wt="1" mv="4j" s="3" m="3" l="1" e="0" ov="0" armor="4" internal="3" abilities="CASE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="5">LL</location>
            <location index="5">RL</location>
            <location index="4">RL</location>
            <location index="4">LL</location>
        </jumpjets>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

            <location index="7">RA</location>
            <location index="0">LT</location>
            <location index="4">LA</location>
            <location index="0">RT</location>
            <location index="4">RA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Streak SRM-4</name>
            <type>missile</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Large Laser</name>
            <type>energy</type>
            <location index="10">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Streak SRM-4</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Targeting Computer</name>
            <type>TargetingComputer</type>
            <location index="3">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Panther" model="PNT-14S" tons="35" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>825</battle_value>
    <cost>3313260.0000000005</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>6</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3069</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="4">HD</location>
        <location index="4">LA</location>
        <location index="5">HD</location>
        <location index="5">LA</location>
        <location index="6">LA</location>
        <location index="6">RA</location>
        <location index="7">RA</location>
        <location index="8">RT</location>
        <location index="8">RA</location>
        <location index="9">RT</location>
        <location index="10">CT</location>
        <location index="10">RT</location>
        <location index="11">CT</location>
        <location index="11">RT</location>
    </structure>
    <engine rating="140" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Small Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>16</ct>
        <ctr>5</ctr>
        <lt>11</lt>
        <ltr>4</ltr>
        <rt>11</rt>
        <rtr>4</rtr>
        <la>11</la>
        <ra>11</ra>
        <ll>15</ll>
        <rl>15</rl>
    </armor>
    <baseloadout fcsa4="TRUE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Panther PNT-14S 35t, 4/6/6, Std FE, ES; 7.0T/94% Armor; 13 SHS; 2 RL15, 1 SNPPC</info>
        <battleforce pv="8" wt="1" mv="4/6j" s="2" m="1" l="0" e="0" ov="0" armor="4" internal="3" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="6">
            <type>Improved Jump Jet</type>
            <location index="4">LL</location>
            <location index="6">RT</location>
            <location index="4">LT</location>
            <location index="4">RT</location>
            <location index="4">RL</location>
            <location index="6">LT</location>
        </jumpjets>
        <heatsinks number="13" techbase="2">
            <type>Single Heat Sink</type>

            <location index="0">LT</location>
            <location index="3">RT</location>
            <location index="1">RT</location>
            <location index="0">RT</location>
            <location index="1">LT</location>
            <location index="3">LT</location>
            <location index="2">RT</location>
            <location index="2">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Snub-Nose PPC</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rocket Launcher 15</name>
            <type>missile</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rocket Launcher 15</name>
            <type>missile</type>
            <location index="10">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Panther" model="PNT-16K" tons="35" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>988</battle_value>
    <cost>3041235.0000000005</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>6</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3070</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="6">RT</location>
        <location index="7">RT</location>
        <location index="7">LA</location>
        <location index="7">RA</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
    </structure>
    <engine rating="140" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Light Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>14</ct>
        <ctr>7</ctr>
        <lt>10</lt>
        <ltr>5</ltr>
        <rt>10</rt>
        <rtr>5</rtr>
        <la>11</la>
        <ra>11</ra>
        <ll>14</ll>
        <rl>14</rl>
        <location index="3">HD</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
        <location index="11">LA</location>
        <location index="11">RA</location>
    </armor>
    <baseloadout fcsa4="TRUE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Panther PNT-16K 35t, 4/6/4, Std FE, ES; 6.5T/92% LFF Armor; 10 DHS; 2 SSRM4, 1 ERPPC</info>
        <battleforce pv="10" wt="1" mv="4j" s="3" m="3" l="1" e="0" ov="0" armor="4" internal="3" abilities="CASE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="4">RL</location>
            <location index="5">RL</location>
            <location index="5">LL</location>
            <location index="4">LL</location>
        </jumpjets>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

            <location index="4">LA</location>
            <location index="0">LT</location>
            <location index="0">RT</location>
            <location index="3">RT</location>
            <location index="3">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) ER PPC</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Streak SRM-4</name>
            <type>missile</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Streak SRM-4</name>
            <type>missile</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Streak SRM-4</name>
            <type>ammunition</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="7">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Panther" model="PNT-C" tons="35" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>833</battle_value>
    <cost>3072960.0000000005</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3051</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="4">RT</location>
        <location index="4">LA</location>
        <location index="5">RT</location>
        <location index="6">LT</location>
        <location index="6">RT</location>
        <location index="7">LT</location>
        <location index="7">RT</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="11">LT</location>
    </structure>
    <engine rating="140" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>14</ct>
        <ctr>7</ctr>
        <lt>10</lt>
        <ltr>5</ltr>
        <rt>10</rt>
        <rtr>5</rtr>
        <la>10</la>
        <ra>10</ra>
        <ll>12</ll>
        <rl>12</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Panther PNT-C 35t, 4/6/4, Std FE, ES; 6.5T/87% Armor; 13 SHS; 1 C3S, 1 ERPPC, 1 SRM4</info>
        <battleforce pv="8" wt="1" mv="4j" s="2" m="2" l="1" e="0" ov="0" armor="3" internal="3" abilities="C3S, CASE, MHQ1, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="5">LL</location>
            <location index="5">RL</location>
            <location index="4">RL</location>
            <location index="4">LL</location>
        </jumpjets>
        <heatsinks number="13" techbase="2">
            <type>Single Heat Sink</type>

            <location index="2">LT</location>
            <location index="1">RT</location>
            <location index="0">RT</location>
            <location index="1">LT</location>
            <location index="0">LT</location>
            <location index="2">RT</location>
            <location index="3">RT</location>
            <location index="3">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) ER PPC</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-4</name>
            <type>missile</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">C3 Computer (Slave)</name>
            <type>equipment</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="5">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Panther" model="PNT-CA" tons="35" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>787</battle_value>
    <cost>2937960.0000000005</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3055</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="4">RT</location>
        <location index="4">LA</location>
        <location index="5">RT</location>
        <location index="6">LT</location>
        <location index="6">RT</location>
        <location index="7">LT</location>
        <location index="7">RT</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="11">LT</location>
    </structure>
    <engine rating="140" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>14</ct>
        <ctr>7</ctr>
        <lt>10</lt>
        <ltr>5</ltr>
        <rt>10</rt>
        <rtr>5</rtr>
        <la>10</la>
        <ra>10</ra>
        <ll>12</ll>
        <rl>12</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Panther PNT-CA 35t, 4/6/4, Std FE, ES; 6.5T/87% Armor; 13 SHS; 1 PPC, 1 C3S, 1 SRM4</info>
        <battleforce pv="8" wt="1" mv="4j" s="2" m="2" l="1" e="0" ov="0" armor="3" internal="3" abilities="C3S, CASE, MHQ1, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="5">LL</location>
            <location index="5">RL</location>
            <location index="4">RL</location>
            <location index="4">LL</location>
        </jumpjets>
        <heatsinks number="13" techbase="2">
            <type>Single Heat Sink</type>

            <location index="2">LT</location>
            <location index="1">RT</location>
            <location index="0">RT</location>
            <location index="1">LT</location>
            <location index="0">LT</location>
            <location index="2">RT</location>
            <location index="3">RT</location>
            <location index="3">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) SRM-4</name>
            <type>missile</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">C3 Computer (Slave)</name>
            <type>equipment</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="5">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Quickdraw" model="QKD-5K" tons="60" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1265</battle_value>
    <cost>5699960.0</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3049</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="300" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>18</ct>
        <ctr>8</ctr>
        <lt>17</lt>
        <ltr>8</ltr>
        <rt>17</rt>
        <rtr>8</rtr>
        <la>13</la>
        <ra>13</ra>
        <ll>16</ll>
        <rl>16</rl>
        <location index="3">HD</location>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">LA</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="6">LA</location>
        <location index="7">LA</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">LA</location>
        <location index="10">LT</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Quickdraw QKD-5K 60t, 5/8/5, Std FE, Std; 8.0T/71% FF Armor; 17 DHS; 6 ML, 1 SRM4</info>
        <battleforce pv="13" wt="3" mv="5j" s="3" m="3" l="0" e="0" ov="0" armor="5" internal="5" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="5">
            <type>Standard Jump Jet</type>
            <location index="10">CT</location>
            <location index="6">LT</location>
            <location index="6">RT</location>
            <location index="7">RT</location>
            <location index="7">LT</location>
        </jumpjets>
        <heatsinks number="17" techbase="0">
            <type>Double Heat Sink</type>

            <location index="0">RT</location>
            <location index="0">LT</location>
            <location index="3">RT</location>
            <location index="4">RA</location>
            <location index="3">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) SRM-4</name>
            <type>missile</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4</name>
            <type>ammunition</type>
            <location index="10">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Laser</name>
            <type>energy</type>
            <location index="9">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="7">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Laser</name>
            <type>energy</type>
            <location index="8">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Quickdraw" model="QKD-5K2" tons="60" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1312</battle_value>
    <cost>5699960.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3057</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="300" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>18</ct>
        <ctr>8</ctr>
        <lt>17</lt>
        <ltr>8</ltr>
        <rt>17</rt>
        <rtr>8</rtr>
        <la>13</la>
        <ra>13</ra>
        <ll>16</ll>
        <rl>16</rl>
        <location index="3">HD</location>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">LA</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="6">LA</location>
        <location index="7">LA</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">LA</location>
        <location index="10">LT</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Quickdraw QKD-5K2 60t, 5/8/5, Std FE, Std; 8.0T/71% FF Armor; 17 DHS; 6 ML, 1 SRM4</info>
        <battleforce pv="13" wt="3" mv="5j" s="3" m="3" l="0" e="0" ov="0" armor="5" internal="5" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="5">
            <type>Standard Jump Jet</type>
            <location index="10">CT</location>
            <location index="6">LT</location>
            <location index="6">RT</location>
            <location index="7">RT</location>
            <location index="7">LT</location>
        </jumpjets>
        <heatsinks number="17" techbase="0">
            <type>Double Heat Sink</type>

            <location index="0">RT</location>
            <location index="0">LT</location>
            <location index="3">RT</location>
            <location index="4">RA</location>
            <location index="3">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(R) (IS) SRM-4</name>
            <type>missile</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4</name>
            <type>ammunition</type>
            <location index="10">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="9">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="7">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="8">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Quickdraw" model="QKD-5M" tons="60" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1237</battle_value>
    <cost>5728560.0</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3049</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="300" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>18</ct>
        <ctr>8</ctr>
        <lt>17</lt>
        <ltr>8</ltr>
        <rt>17</rt>
        <rtr>8</rtr>
        <la>13</la>
        <ra>13</ra>
        <ll>16</ll>
        <rl>16</rl>
        <location index="5">LA</location>
        <location index="5">RA</location>
        <location index="6">LA</location>
        <location index="6">RA</location>
        <location index="7">LA</location>
        <location index="7">RA</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
        <location index="11">LA</location>
        <location index="11">RA</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Quickdraw QKD-5M 60t, 5/8/5, Std FE, Std; 8.0T/71% FF Armor; 13 DHS; 4 ML, 1 SRM4OS, 1 LRM10</info>
        <battleforce pv="12" wt="3" mv="5j" s="2" m="2" l="1" e="0" ov="0" armor="5" internal="5" abilities="CASE, SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="5">
            <type>Standard Jump Jet</type>
            <location index="4">LT</location>
            <location index="1">RT</location>
            <location index="0">RT</location>
            <location index="3">LT</location>
            <location index="10">CT</location>
        </jumpjets>
        <heatsinks number="13" techbase="0">
            <type>Double Heat Sink</type>

            <location index="0">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) LRM-10</name>
            <type>missile</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="7">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-4 (OS)</name>
            <type>missile</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="8">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Quickdraw" model="QKD-8K" tons="60" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1583</battle_value>
    <cost>6238160.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3064</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="4">LT</location>
        <location index="5">LT</location>
        <location index="5">LA</location>
        <location index="5">RA</location>
        <location index="6">LT</location>
        <location index="6">RA</location>
        <location index="7">LT</location>
        <location index="7">RA</location>
        <location index="8">LT</location>
        <location index="8">RA</location>
        <location index="9">LT</location>
        <location index="9">RA</location>
        <location index="10">RT</location>
        <location index="11">CT</location>
    </structure>
    <engine rating="300" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>22</ct>
        <ctr>7</ctr>
        <lt>16</lt>
        <ltr>5</ltr>
        <rt>16</rt>
        <rtr>5</rtr>
        <la>15</la>
        <ra>15</ra>
        <ll>21</ll>
        <rl>21</rl>
        <location index="3">HD</location>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="6">LA</location>
        <location index="7">LA</location>
        <location index="8">LA</location>
        <location index="9">LA</location>
        <location index="10">LT</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Quickdraw QKD-8K 60t, 5/8/5, Std FE, ES; 8.5T/76% FF Armor; 12 DHS; 4 ERML, 1 MRM30</info>
        <battleforce pv="16" wt="3" mv="5j" s="3" m="3" l="0" e="0" ov="1" armor="5" internal="5" abilities="CASE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="5">
            <type>Standard Jump Jet</type>
            <location index="1">LT</location>
            <location index="1">RT</location>
            <location index="0">RT</location>
            <location index="0">LT</location>
            <location index="10">CT</location>
        </jumpjets>
        <heatsinks number="12" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) MRM-30</name>
            <type>missile</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ MRM-30</name>
            <type>ammunition</type>
            <location index="7">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ MRM-30</name>
            <type>ammunition</type>
            <location index="8">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="9">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Quickdraw" model="QKD-C" tons="60" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1225</battle_value>
    <cost>6035960.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3053</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="300" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>18</ct>
        <ctr>8</ctr>
        <lt>17</lt>
        <ltr>8</ltr>
        <rt>17</rt>
        <rtr>8</rtr>
        <la>13</la>
        <ra>13</ra>
        <ll>16</ll>
        <rl>16</rl>
        <location index="3">HD</location>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">LA</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="6">LA</location>
        <location index="7">LA</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">LA</location>
        <location index="10">LT</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Quickdraw QKD-C 60t, 5/8/5, Std FE, Std; 8.0T/71% FF Armor; 17 DHS; 5 ML, 1 C3S, 1 SRM4</info>
        <battleforce pv="12" wt="3" mv="5j" s="3" m="3" l="0" e="0" ov="0" armor="5" internal="5" abilities="C3S, MHQ1, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="5">
            <type>Standard Jump Jet</type>
            <location index="7">LT</location>
            <location index="7">RT</location>
            <location index="6">RT</location>
            <location index="6">LT</location>
            <location index="10">CT</location>
        </jumpjets>
        <heatsinks number="17" techbase="0">
            <type>Double Heat Sink</type>

            <location index="3">LT</location>
            <location index="4">RA</location>
            <location index="0">RT</location>
            <location index="3">RT</location>
            <location index="0">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="7">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Laser</name>
            <type>energy</type>
            <location index="8">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-4</name>
            <type>missile</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">C3 Computer (Slave)</name>
            <type>equipment</type>
            <location index="10">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4</name>
            <type>ammunition</type>
            <location index="9">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Raven" model="RVN-3L" tons="35" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>708</battle_value>
    <cost>5665125.0</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3048</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="210" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>6</hd>
        <ct>11</ct>
        <ctr>3</ctr>
        <lt>11</lt>
        <ltr>3</ltr>
        <rt>11</rt>
        <rtr>3</rtr>
        <la>8</la>
        <ra>8</ra>
        <ll>8</ll>
        <rl>8</rl>
        <location index="4">LA</location>
        <location index="4">RA</location>
        <location index="5">LA</location>
        <location index="5">RA</location>
        <location index="6">LA</location>
        <location index="6">RA</location>
        <location index="7">LA</location>
        <location index="7">RA</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Raven RVN-3L 35t, 6/9/0, XLFE, Std; 4.5T/67% FF Armor; 11 SHS; 1 ECM, 2 ML, 1 Narc, 1 BAP, 1 SRM6, 1 TAG</info>
        <battleforce pv="7" wt="1" mv="6" s="2" m="2" l="0" e="0" ov="0" armor="3" internal="2" abilities="SNARC, PRB, RCN, ECM, TAG, CASE, SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="11" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="3">HD</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Narc Missile Beacon</name>
            <type>missile</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">Beagle Active Probe</name>
            <type>equipment</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">Guardian ECM Suite</name>
            <type>equipment</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">TAG</name>
            <type>equipment</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6 (Narc Capable)</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Narc (Homing)</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Narc (Homing)</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="8">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Raven" model="RVN-3M" tons="35" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>659</battle_value>
    <cost>5193150.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3053</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="210" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>6</hd>
        <ct>11</ct>
        <ctr>3</ctr>
        <lt>11</lt>
        <ltr>3</ltr>
        <rt>11</rt>
        <rtr>3</rtr>
        <la>8</la>
        <ra>8</ra>
        <ll>8</ll>
        <rl>8</rl>
        <location index="3">RA</location>
        <location index="4">LA</location>
        <location index="4">RA</location>
        <location index="5">LA</location>
        <location index="5">RA</location>
        <location index="6">LA</location>
        <location index="6">RA</location>
        <location index="7">LA</location>
        <location index="7">RA</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">LA</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Raven RVN-3M 35t, 6/9/0, XLFE, Std; 4.5T/67% FF Armor; 10 SHS; 2 SL, 1 Narc, 1 LRM15, 1 SPL</info>
        <battleforce pv="7" wt="1" mv="6" s="2" m="1" l="1" e="0" ov="0" armor="3" internal="2" abilities="SNARC, CASE, SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">LL</location>
            <location index="4">RL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Narc Missile Beacon</name>
            <type>missile</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Pulse Laser</name>
            <type>energy</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15 (Narc Capable)</name>
            <type>ammunition</type>
            <location index="7">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15 (Narc Capable)</name>
            <type>ammunition</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Narc (Homing)</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Narc (Homing)</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="8">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Raven" model="RVN-4L" tons="35" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>873</battle_value>
    <cost>6067425.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3062</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="210" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Stealth Armor</type>
        <hd>9</hd>
        <ct>15</ct>
        <ctr>4</ctr>
        <lt>10</lt>
        <ltr>4</ltr>
        <rt>10</rt>
        <rtr>4</rtr>
        <la>10</la>
        <ra>10</ra>
        <ll>10</ll>
        <rl>10</rl>
        <location index="4">RA</location>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">RA</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="7">LA</location>
        <location index="8">LT</location>
        <location index="8">LA</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="10">RT</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Raven RVN-4L 35t, 6/9/0, XLFE, Std; 6.0T/81% Stlth Armor; 10 DHS; 1 ECM, 1 Narc, 2 ERML, 1 BAP, 1 SRM6, 1 TAG</info>
        <battleforce pv="9" wt="1" mv="6" s="2" m="2" l="0" e="0" ov="0" armor="3" internal="2" abilities="ECM, STL, SNARC, TAG, PRB, RCN, SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

            <location index="2">LA</location>
            <location index="3">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Narc Missile Beacon</name>
            <type>missile</type>
            <location index="5">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">TAG</name>
            <type>equipment</type>
            <location index="8">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">Guardian ECM Suite</name>
            <type>equipment</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">Beagle Active Probe</name>
            <type>equipment</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Narc (Homing)</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Narc (Homing)</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6 (Narc Capable)</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Raven" model="RVN-4LC" tons="35" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>871</battle_value>
    <cost>6150112.5</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3062</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="210" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Stealth Armor</type>
        <hd>9</hd>
        <ct>15</ct>
        <ctr>4</ctr>
        <lt>10</lt>
        <ltr>4</ltr>
        <rt>10</rt>
        <rtr>4</rtr>
        <la>10</la>
        <ra>10</ra>
        <ll>10</ll>
        <rl>10</rl>
        <location index="4">RA</location>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">RA</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="7">LA</location>
        <location index="8">LT</location>
        <location index="8">LA</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="10">RT</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Raven RVN-4LC 35t, 6/9/0, XLFE, Std; 6.0T/81% Stlth Armor; 10 DHS; 1 ECM, 1 Narc, 1 C3S, 2 ERML, 1 ERSL, 1 SRM6, 1 TAG</info>
        <battleforce pv="9" wt="1" mv="6" s="2" m="2" l="0" e="0" ov="1" armor="3" internal="2" abilities="ECM, STL, SNARC, TAG, C3S, MHQ1, SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

            <location index="2">LA</location>
            <location index="3">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Narc Missile Beacon</name>
            <type>missile</type>
            <location index="5">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">TAG</name>
            <type>equipment</type>
            <location index="8">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">Guardian ECM Suite</name>
            <type>equipment</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Narc (Homing)</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Narc (Homing)</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">C3 Computer (Slave)</name>
            <type>equipment</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Small Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6 (Narc Capable)</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Raven" model="RVN-SR &quot;Shattered Raven&quot;" tons="35" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>601</battle_value>
    <cost>5518812.5</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>6</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3068</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="210" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>6</hd>
        <ct>11</ct>
        <ctr>3</ctr>
        <lt>11</lt>
        <ltr>3</ltr>
        <rt>11</rt>
        <rtr>3</rtr>
        <la>8</la>
        <ra>8</ra>
        <ll>8</ll>
        <rl>8</rl>
        <location index="4">LA</location>
        <location index="5">LA</location>
        <location index="6">LA</location>
        <location index="7">LA</location>
        <location index="7">RA</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">LT</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
        <location index="11">LA</location>
        <location index="11">RA</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Raven RVN-SR "Shattered Raven" 35t, 6/9/0, XLFE, Std; 4.5T/67% FF Armor; 11 SHS; 1 ECM, 1 Narc, 1 BAP, 1 SRM6, 1 MGA+4MG</info>
        <battleforce pv="6" wt="1" mv="6" s="2" m="1" l="0" e="0" ov="0" armor="3" internal="2" abilities="ECM, PRB, RCN, SNARC, CASE, SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="11" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">LL</location>
            <location index="3">HD</location>
            <location index="4">RL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">Guardian ECM Suite</name>
            <type>equipment</type>
            <location index="7">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">Beagle Active Probe</name>
            <type>equipment</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Narc Missile Beacon</name>
            <type>missile</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) MG Array (4 Machine Gun)</name>
            <type>mgarray</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Machine Gun (1/2)</name>
            <type>ammunition</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Narc (Homing)</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Narc (Homing)</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6 (Narc Capable)</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="9">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Raven" model="RVN-SS &quot;Shattered Raven&quot;" tons="35" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>904</battle_value>
    <cost>5698012.5</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>6</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3069</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="210" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>6</hd>
        <ct>11</ct>
        <ctr>3</ctr>
        <lt>11</lt>
        <ltr>3</ltr>
        <rt>11</rt>
        <rtr>3</rtr>
        <la>8</la>
        <ra>8</ra>
        <ll>8</ll>
        <rl>8</rl>
        <location index="4">RA</location>
        <location index="5">LA</location>
        <location index="5">RA</location>
        <location index="6">LA</location>
        <location index="6">RA</location>
        <location index="7">LA</location>
        <location index="7">RA</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
        <location index="11">LA</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Raven RVN-SS "Shattered Raven" 35t, 6/9/0, XLFE, Std; 4.5T/67% FF Armor; 12 SHS; 4 ML, 1 ECM, 1 SL, 1 BAP, 1 TAG, 1 SSRM6</info>
        <battleforce pv="9" wt="1" mv="6" s="3" m="3" l="0" e="0" ov="1" armor="3" internal="2" abilities="TAG, ECM, PRB, RCN, CASE, SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="12" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">LL</location>
            <location index="3">HD</location>
            <location index="4">RL</location>
            <location index="5">RL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">TAG</name>
            <type>equipment</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">Guardian ECM Suite</name>
            <type>equipment</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">Beagle Active Probe</name>
            <type>equipment</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Streak SRM-6</name>
            <type>missile</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Streak SRM-6</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="6">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Shogun" model="C" tons="85" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>3032</battle_value>
    <cost>9844050.0</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>2</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Clan</techbase>
    <year restricted="FALSE">2850</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="255" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="1">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>40</ct>
        <ctr>11</ctr>
        <lt>27</lt>
        <ltr>9</ltr>
        <rt>27</rt>
        <rtr>9</rtr>
        <la>27</la>
        <ra>27</ra>
        <ll>27</ll>
        <rl>27</rl>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="9">LT</location>
        <location index="10">LT</location>
        <location index="11">CT</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
    </armor>
    <baseloadout fcsa4="TRUE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Shogun C 85t, 3/5/3, Std FE, Std; 12.5T/91% FF Armor; 16 DHS; 1 ERPPC, 2 LRM20, 4 SSRM6</info>
        <battleforce pv="30" wt="4" mv="3j" s="7" m="7" l="4" e="0" ov="2" armor="8" internal="7" abilities="CASE, SRCH, ES, SEAL, SOA, IF 3" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>TRUE</clancase>
        <jumpjets number="3">
            <type>Standard Jump Jet</type>
            <location index="10">CT</location>
            <location index="4">RL</location>
            <location index="4">LL</location>
        </jumpjets>
        <heatsinks number="16" techbase="1">
            <type>Double Heat Sink</type>

            <location index="5">LA</location>
            <location index="3">LA</location>
            <location index="0">LT</location>
            <location index="3">RA</location>
            <location index="5">RA</location>
            <location index="0">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(CL) LRM-20</name>
            <type>missile</type>
            <location index="7">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) LRM-20</name>
            <type>missile</type>
            <location index="7">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) Streak SRM-6</name>
            <type>missile</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) Streak SRM-6</name>
            <type>missile</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) Streak SRM-6</name>
            <type>missile</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) Streak SRM-6</name>
            <type>missile</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) ER PPC</name>
            <type>energy</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(CL) @ Streak SRM-6</name>
            <type>ammunition</type>
            <location index="8">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(CL) @ Streak SRM-6</name>
            <type>ammunition</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(CL) @ LRM-20 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="7">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(CL) @ LRM-20 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="9">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(CL) @ LRM-20 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(CL) @ LRM-20 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="10">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional>&lt;p style=&quot;margin-top: 0&quot;&gt;:br::br:&lt;/p&gt;:br:</additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Shogun" model="SHG-2E" tons="85" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1823</battle_value>
    <cost>7858100.0</cost>
    <rules_level>1</rules_level>
    <era>4</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3005</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="255" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>40</ct>
        <ctr>11</ctr>
        <lt>28</lt>
        <ltr>8</ltr>
        <rt>28</rt>
        <rtr>8</rtr>
        <la>22</la>
        <ra>22</ra>
        <ll>36</ll>
        <rl>36</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Shogun SHG-2E 85t, 3/5/3, Std FE, Std; 15.5T/94% Armor; 17 SHS; 2 LRM15, 1 PPC, 2 SRM6</info>
        <battleforce pv="18" wt="4" mv="3j" s="2" m="3" l="2" e="0" ov="0" armor="8" internal="7" abilities="CASE, SRCH, ES, SEAL, SOA, SRM 1/1/0, IF 1" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="3">
            <type>Standard Jump Jet</type>
            <location index="11">CT</location>
            <location index="5">RL</location>
            <location index="5">LL</location>
        </jumpjets>
        <heatsinks number="17" techbase="2">
            <type>Single Heat Sink</type>

            <location index="1">LT</location>
            <location index="0">LT</location>
            <location index="2">LT</location>
            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="10">CT</location>
            <location index="3">HD</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) PPC</name>
            <type>energy</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="7">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="7">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Shogun" model="SHG-2F (Trisha)" tons="85" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1733</battle_value>
    <cost>8280900.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>6</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3071</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="255" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>40</ct>
        <ctr>11</ctr>
        <lt>28</lt>
        <ltr>8</ltr>
        <rt>28</rt>
        <rtr>8</rtr>
        <la>22</la>
        <ra>22</ra>
        <ll>36</ll>
        <rl>36</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Shogun SHG-2F (Trisha) 85t, 3/5/3, Std FE, Std; 15.5T/94% Armor; 16 SHS; 1 SNPPC, 2 LB5, 2 SRM6</info>
        <battleforce pv="17" wt="4" mv="3j" s="3" m="3" l="1" e="0" ov="0" armor="8" internal="7" abilities="CASE, SRCH, ES, SEAL, SOA, SRM 1/1/0, FLK 0/1/1" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="3">
            <type>Standard Jump Jet</type>
            <location index="11">CT</location>
            <location index="5">RL</location>
            <location index="5">LL</location>
        </jumpjets>
        <heatsinks number="16" techbase="2">
            <type>Single Heat Sink</type>

            <location index="1">LT</location>
            <location index="0">LT</location>
            <location index="4">RL</location>
            <location index="4">LL</location>
            <location index="10">CT</location>
            <location index="3">HD</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LB 5-X AC</name>
            <type>ballistic</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LB 5-X AC</name>
            <type>ballistic</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Snub-Nose PPC</name>
            <type>energy</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 5-X AC (Slug)</name>
            <type>ammunition</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 5-X AC (Cluster)</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="6">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Shogun" model="SHG-2F" tons="85" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1804</battle_value>
    <cost>8043100.0</cost>
    <rules_level>1</rules_level>
    <era>4</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3029</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="255" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>40</ct>
        <ctr>11</ctr>
        <lt>28</lt>
        <ltr>8</ltr>
        <rt>28</rt>
        <rtr>8</rtr>
        <la>22</la>
        <ra>22</ra>
        <ll>36</ll>
        <rl>36</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Shogun SHG-2F 85t, 3/5/3, Std FE, Std; 15.5T/94% Armor; 17 SHS; 2 LRM15, 1 ERPPC, 2 SRM6</info>
        <battleforce pv="18" wt="4" mv="3j" s="2" m="3" l="2" e="0" ov="1" armor="8" internal="7" abilities="CASE, SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="3">
            <type>Standard Jump Jet</type>
            <location index="11">CT</location>
            <location index="5">RL</location>
            <location index="5">LL</location>
        </jumpjets>
        <heatsinks number="17" techbase="2">
            <type>Single Heat Sink</type>

            <location index="1">LT</location>
            <location index="0">LT</location>
            <location index="4">RL</location>
            <location index="4">LL</location>
            <location index="10">CT</location>
            <location index="0">RT</location>
            <location index="1">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="7">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="8">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER PPC</name>
            <type>energy</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="9">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Spider" model="SDR-7K" tons="30" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>752</battle_value>
    <cost>4506840.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3067</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="3">HD</location>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="6">RT</location>
        <location index="7">RT</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
    </structure>
    <engine rating="240" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">Light Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>15</ct>
        <ctr>4</ctr>
        <lt>10</lt>
        <ltr>3</ltr>
        <rt>10</rt>
        <rtr>3</rtr>
        <la>9</la>
        <ra>9</ra>
        <ll>13</ll>
        <rl>13</rl>
        <location index="4">LA</location>
        <location index="4">RA</location>
        <location index="5">LA</location>
        <location index="5">RA</location>
        <location index="6">LA</location>
        <location index="6">RA</location>
        <location index="7">LA</location>
        <location index="7">RA</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Spider SDR-7K 30t, 8/12/8, LFE, ES; 5.5T/93% FF Armor; 10 DHS; 2 MPL</info>
        <battleforce pv="8" wt="1" mv="8j" s="2" m="2" l="0" e="0" ov="0" armor="3" internal="2" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="8">
            <type>Standard Jump Jet</type>
            <location index="8">LT</location>
            <location index="3">RT</location>
            <location index="6">LT</location>
            <location index="4">RT</location>
            <location index="7">LT</location>
            <location index="2">RT</location>
            <location index="5">RT</location>
            <location index="5">LT</location>
        </jumpjets>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

            <location index="2">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Spider" model="SDR-7K2" tons="30" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>884</battle_value>
    <cost>4480840.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>6</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3068</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="3">HD</location>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="6">RT</location>
        <location index="7">RT</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="11">RT</location>
    </structure>
    <engine rating="240" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">Light Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>11</ct>
        <ctr>3</ctr>
        <lt>9</lt>
        <ltr>3</ltr>
        <rt>9</rt>
        <rtr>3</rtr>
        <la>9</la>
        <ra>9</ra>
        <ll>12</ll>
        <rl>12</rl>
        <location index="4">LA</location>
        <location index="4">RA</location>
        <location index="5">LA</location>
        <location index="5">RA</location>
        <location index="6">LA</location>
        <location index="6">RA</location>
        <location index="7">LA</location>
        <location index="7">RA</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Spider SDR-7K2 30t, 8/12/7, LFE, ES; 5.0T/85% FF Armor; 10 DHS; 1 ERLL</info>
        <battleforce pv="9" wt="1" mv="8/5j" s="1" m="1" l="1" e="0" ov="0" armor="3" internal="2" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="7">
            <type>Standard Jump Jet</type>
            <location index="7">LT</location>
            <location index="3">RT</location>
            <location index="5">LT</location>
            <location index="4">RT</location>
            <location index="6">LT</location>
            <location index="2">RT</location>
            <location index="5">RT</location>
        </jumpjets>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

            <location index="2">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) ER Large Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Spider" model="SDR-7KC" tons="30" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>732</battle_value>
    <cost>4870840.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>6</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3070</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="6">RT</location>
        <location index="7">RT</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
        <location index="11">RA</location>
    </structure>
    <engine rating="240" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">Light Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>15</ct>
        <ctr>4</ctr>
        <lt>10</lt>
        <ltr>3</ltr>
        <rt>10</rt>
        <rtr>3</rtr>
        <la>9</la>
        <ra>9</ra>
        <ll>13</ll>
        <rl>13</rl>
        <location index="4">LA</location>
        <location index="4">RA</location>
        <location index="5">LA</location>
        <location index="5">RA</location>
        <location index="6">LA</location>
        <location index="6">RA</location>
        <location index="7">LA</location>
        <location index="7">RA</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Spider SDR-7KC 30t, 8/12/8, LFE, ES; 5.5T/93% FF Armor; 10 DHS; 1 C3S, 1 LPPC</info>
        <battleforce pv="7" wt="1" mv="8j" s="1" m="1" l="1" e="0" ov="0" armor="3" internal="2" abilities="C3S, ENE, MHQ1, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="8">
            <type>Standard Jump Jet</type>
            <location index="8">LT</location>
            <location index="3">RT</location>
            <location index="6">LT</location>
            <location index="4">RT</location>
            <location index="7">LT</location>
            <location index="2">RT</location>
            <location index="5">RT</location>
            <location index="5">LT</location>
        </jumpjets>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

            <location index="2">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Light PPC</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">C3 Computer (Slave)</name>
            <type>equipment</type>
            <location index="3">HD</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Spider" model="SDR-7M" tons="30" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>621</battle_value>
    <cost>3115840.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3051</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="4">LT</location>
        <location index="4">RT</location>
        <location index="5">LT</location>
        <location index="5">RT</location>
        <location index="6">LT</location>
        <location index="6">RT</location>
        <location index="7">LT</location>
        <location index="7">RT</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
    </structure>
    <engine rating="240" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>6</hd>
        <ct>8</ct>
        <ctr>3</ctr>
        <lt>6</lt>
        <ltr>2</ltr>
        <rt>6</rt>
        <rtr>2</rtr>
        <la>5</la>
        <ra>5</ra>
        <ll>5</ll>
        <rl>5</rl>
        <location index="4">LA</location>
        <location index="4">RA</location>
        <location index="5">LA</location>
        <location index="5">RA</location>
        <location index="6">LA</location>
        <location index="6">RA</location>
        <location index="7">LA</location>
        <location index="7">RA</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Spider SDR-7M 30t, 8/12/8, Std FE, ES; 3.0T/50% FF Armor; 10 SHS; 2 MPL</info>
        <battleforce pv="6" wt="1" mv="8j" s="2" m="2" l="0" e="0" ov="0" armor="2" internal="3" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="8">
            <type>Standard Jump Jet</type>
            <location index="2">LT</location>
            <location index="1">RT</location>
            <location index="0">LT</location>
            <location index="2">RT</location>
            <location index="1">LT</location>
            <location index="0">RT</location>
            <location index="3">RT</location>
            <location index="3">LT</location>
        </jumpjets>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="3">HD</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Spider" model="SDR-8M" tons="30" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>621</battle_value>
    <cost>3193840.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3060</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="3">HD</location>
        <location index="4">RT</location>
        <location index="5">RT</location>
        <location index="6">RT</location>
        <location index="7">LT</location>
        <location index="7">RT</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
    </structure>
    <engine rating="240" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>6</hd>
        <ct>8</ct>
        <ctr>3</ctr>
        <lt>6</lt>
        <ltr>2</ltr>
        <rt>6</rt>
        <rtr>2</rtr>
        <la>5</la>
        <ra>5</ra>
        <ll>5</ll>
        <rl>5</rl>
        <location index="4">LA</location>
        <location index="4">RA</location>
        <location index="5">LA</location>
        <location index="5">RA</location>
        <location index="6">LA</location>
        <location index="6">RA</location>
        <location index="7">LA</location>
        <location index="7">RA</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Spider SDR-8M 30t, 8/12/8, Std FE, ES; 3.0T/50% FF Armor; 10 DHS; 2 MPL</info>
        <battleforce pv="6" wt="1" mv="8j" s="2" m="2" l="0" e="0" ov="0" armor="2" internal="3" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="8">
            <type>Standard Jump Jet</type>
            <location index="6">LT</location>
            <location index="1">RT</location>
            <location index="4">LT</location>
            <location index="2">RT</location>
            <location index="5">LT</location>
            <location index="0">RT</location>
            <location index="3">RT</location>
            <location index="3">LT</location>
        </jumpjets>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

            <location index="0">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Spider" model="SDR-C" tons="30" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>616</battle_value>
    <cost>3414840.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3054</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="4">LT</location>
        <location index="5">LT</location>
        <location index="5">RT</location>
        <location index="6">LT</location>
        <location index="6">RT</location>
        <location index="7">LT</location>
        <location index="7">RT</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="11">RT</location>
    </structure>
    <engine rating="240" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>6</hd>
        <ct>8</ct>
        <ctr>3</ctr>
        <lt>6</lt>
        <ltr>2</ltr>
        <rt>6</rt>
        <rtr>2</rtr>
        <la>5</la>
        <ra>5</ra>
        <ll>5</ll>
        <rl>5</rl>
        <location index="4">LA</location>
        <location index="4">RA</location>
        <location index="5">LA</location>
        <location index="5">RA</location>
        <location index="6">LA</location>
        <location index="6">RA</location>
        <location index="7">LA</location>
        <location index="7">RA</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Spider SDR-C 30t, 8/12/8, Std FE, ES; 3.0T/50% FF Armor; 10 SHS; 1 ML, 1 C3S, 1 MPL</info>
        <battleforce pv="6" wt="1" mv="8j" s="2" m="2" l="0" e="0" ov="0" armor="2" internal="3" abilities="C3S, ENE, MHQ1, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="8">
            <type>Standard Jump Jet</type>
            <location index="2">LT</location>
            <location index="1">RT</location>
            <location index="0">LT</location>
            <location index="2">RT</location>
            <location index="1">LT</location>
            <location index="0">RT</location>
            <location index="3">RT</location>
            <location index="3">LT</location>
        </jumpjets>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="3">HD</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">C3 Computer (Slave)</name>
            <type>equipment</type>
            <location index="4">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Stalker" model="STK-3F Jamison" tons="85" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>2707</battle_value>
    <cost>9479975.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Clan</techbase>
    <year restricted="FALSE">3054</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="255" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="1">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>40</ct>
        <ctr>14</ctr>
        <lt>28</lt>
        <ltr>7</ltr>
        <rt>28</rt>
        <rtr>7</rtr>
        <la>28</la>
        <ra>28</ra>
        <ll>35</ll>
        <rl>35</rl>
        <location index="3">HD</location>
        <location index="7">LT</location>
        <location index="8">LT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Stalker STK-3F Jamison 85t, 3/5/0, Std FE, Std; 13.5T/98% FF Armor; 18 DHS; 4 ERML, 2 LRM20, 2 SSRM6, 2 LPL</info>
        <battleforce pv="27" wt="4" mv="3" s="6" m="6" l="3" e="0" ov="3" armor="9" internal="7" abilities="CASE, SRCH, ES, SEAL, SOA, LRM 1/1/1, IF 1" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>TRUE</clancase>
        <heatsinks number="18" techbase="1">
            <type>Double Heat Sink</type>

            <location index="2">RA</location>
            <location index="2">LA</location>
            <location index="0">LT</location>
            <location index="10">CT</location>
            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="0">RT</location>
            <location index="2">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(CL) Large Pulse Laser</name>
            <type>energy</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) Large Pulse Laser</name>
            <type>energy</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) ER Medium Laser</name>
            <type>energy</type>
            <location index="9">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) ER Medium Laser</name>
            <type>energy</type>
            <location index="8">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) ER Medium Laser</name>
            <type>energy</type>
            <location index="8">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) ER Medium Laser</name>
            <type>energy</type>
            <location index="9">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) LRM-20</name>
            <type>missile</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) LRM-20</name>
            <type>missile</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) Streak SRM-6</name>
            <type>missile</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) Streak SRM-6</name>
            <type>missile</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(CL) @ Streak SRM-6</name>
            <type>ammunition</type>
            <location index="8">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(CL) @ Streak SRM-6</name>
            <type>ammunition</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(CL) @ LRM-20</name>
            <type>ammunition</type>
            <location index="10">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(CL) @ LRM-20</name>
            <type>ammunition</type>
            <location index="10">LA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Stalker" model="STK-5M" tons="85" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1655</battle_value>
    <cost>7968125.0</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3050</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="255" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>36</ct>
        <ctr>11</ctr>
        <lt>27</lt>
        <ltr>9</ltr>
        <rt>27</rt>
        <rtr>9</rtr>
        <la>25</la>
        <ra>25</ra>
        <ll>27</ll>
        <rl>27</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Stalker STK-5M 85t, 3/5/0, Std FE, Std; 14.5T/88% Armor; 17 DHS; 4 ML, 1 Narc, 1 ERLL, 2 SRM6, 2 LRM10</info>
        <battleforce pv="17" wt="4" mv="3" s="5" m="6" l="2" e="0" ov="0" armor="8" internal="7" abilities="SNARC, SRCH, ES, SEAL, SOA, SRM 2/2/0, LRM 1/1/1, IF 1" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="17" techbase="0">
            <type>Double Heat Sink</type>

            <location index="0">LT</location>
            <location index="2">LA</location>
            <location index="3">LT</location>
            <location index="2">RA</location>
            <location index="0">RT</location>
            <location index="3">RT</location>
            <location index="6">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="8">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="8">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="7">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="7">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-10</name>
            <type>missile</type>
            <location index="5">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-10</name>
            <type>missile</type>
            <location index="5">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="9">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Large Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Narc Missile Beacon</name>
            <type>missile</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Narc (Homing)</name>
            <type>ammunition</type>
            <location index="4">LL</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Narc (Homing)</name>
            <type>ammunition</type>
            <location index="5">LL</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6 (Narc Capable)</name>
            <type>ammunition</type>
            <location index="11">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6 (Narc Capable)</name>
            <type>ammunition</type>
            <location index="10">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10 (Narc Capable)</name>
            <type>ammunition</type>
            <location index="10">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10 (Narc Capable)</name>
            <type>ammunition</type>
            <location index="9">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10 (Narc Capable)</name>
            <type>ammunition</type>
            <location index="9">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10 (Narc Capable)</name>
            <type>ammunition</type>
            <location index="10">RA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Stalker" model="STK-5S" tons="85" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1423</battle_value>
    <cost>1.6036175E7</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3050</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="255" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>36</ct>
        <ctr>11</ctr>
        <lt>25</lt>
        <ltr>7</ltr>
        <rt>25</rt>
        <rtr>7</rtr>
        <la>23</la>
        <ra>23</ra>
        <ll>25</ll>
        <rl>25</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Stalker STK-5S 85t, 3/5/0, XLFE, Std; 13.5T/82% Armor; 20 SHS; 4 ML, 2 SRM6, 2 LRM10, 1 AMS, 2 LPL</info>
        <battleforce pv="14" wt="4" mv="3" s="3" m="3" l="1" e="0" ov="4" armor="7" internal="4" abilities="AMS, CASE, SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="20" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="4">RT</location>
            <location index="5">RT</location>
            <location index="3">RT</location>
            <location index="11">CT</location>
            <location index="3">LT</location>
            <location index="10">CT</location>
            <location index="5">LL</location>
            <location index="5">RL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="5">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="5">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-10</name>
            <type>missile</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-10</name>
            <type>missile</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="8">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="10">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="10">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="6">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="6">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Large Pulse Laser</name>
            <type>energy</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Large Pulse Laser</name>
            <type>energy</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Anti-Missile System</name>
            <type>equipment</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Anti-Missile System</name>
            <type>ammunition</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="11">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="11">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Stalker" model="STK-6M" tons="85" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>2029</battle_value>
    <cost>8811725.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3062</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="255" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>40</ct>
        <ctr>14</ctr>
        <lt>27</lt>
        <ltr>9</ltr>
        <rt>27</rt>
        <rtr>9</rtr>
        <la>28</la>
        <ra>28</ra>
        <ll>36</ll>
        <rl>36</rl>
    </armor>
    <baseloadout fcsa4="TRUE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Stalker STK-6M 85t, 3/5/0, Std FE, Std; 16.5T/100% Armor; 18 DHS; 2 LRM15, 1 ERLL, 5 ERML</info>
        <battleforce pv="20" wt="4" mv="3" s="4" m="5" l="3" e="0" ov="1" armor="9" internal="7" abilities="CASE, SRCH, ES, SEAL, SOA, IF 2" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="18" techbase="0">
            <type>Double Heat Sink</type>

            <location index="0">LT</location>
            <location index="2">LA</location>
            <location index="6">LT</location>
            <location index="2">RA</location>
            <location index="0">RT</location>
            <location index="3">RT</location>
            <location index="6">RT</location>
            <location index="3">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) ER Large Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="10">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="9">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="9">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="10">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="5">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="5">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="10">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="9">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="10">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="11">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="11">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="11">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="11">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Stalker" model="STK-7D" tons="85" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1872</battle_value>
    <cost>8249037.5</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3062</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="255" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>36</ct>
        <ctr>11</ctr>
        <lt>25</lt>
        <ltr>7</ltr>
        <rt>25</rt>
        <rtr>7</rtr>
        <la>23</la>
        <ra>23</ra>
        <ll>25</ll>
        <rl>25</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Stalker STK-7D 85t, 3/5/0, Std FE, Std; 13.5T/82% Armor; 18 DHS; 2 SSRM4, 1 SL, 4 MPL, 2 ERPPC, TC</info>
        <battleforce pv="19" wt="4" mv="3" s="6" m="5" l="2" e="0" ov="2" armor="7" internal="7" abilities="CASE, SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="18" techbase="0">
            <type>Double Heat Sink</type>

            <location index="5">RA</location>
            <location index="5">LA</location>
            <location index="2">RA</location>
            <location index="0">RT</location>
            <location index="3">RT</location>
            <location index="2">LA</location>
            <location index="8">RA</location>
            <location index="8">LA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Streak SRM-4</name>
            <type>missile</type>
            <location index="11">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Streak SRM-4</name>
            <type>missile</type>
            <location index="11">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER PPC</name>
            <type>energy</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER PPC</name>
            <type>energy</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="9">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Streak SRM-4</name>
            <type>ammunition</type>
            <location index="10">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="11">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Targeting Computer</name>
            <type>TargetingComputer</type>
            <location index="4">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Stalker" model="STK-8S" tons="85" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>2020</battle_value>
    <cost>1.4021025E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3064</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="255" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">Light Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>36</ct>
        <ctr>11</ctr>
        <lt>25</lt>
        <ltr>7</ltr>
        <rt>25</rt>
        <rtr>7</rtr>
        <la>23</la>
        <ra>23</ra>
        <ll>25</ll>
        <rl>25</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Stalker STK-8S 85t, 3/5/0, LFE, Std; 13.5T/82% Armor; 16 DHS; 1 HGR, 2 ERML, 2 MPL, 2 ERPPC</info>
        <battleforce pv="20" wt="4" mv="3" s="4" m="5" l="2" e="0" ov="2" armor="7" internal="5" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="16" techbase="0">
            <type>Double Heat Sink</type>

            <location index="2">LT</location>
            <location index="5">RA</location>
            <location index="5">LA</location>
            <location index="2">RA</location>
            <location index="2">LA</location>
            <location index="5">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) ER PPC</name>
            <type>energy</type>
            <location index="8">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER PPC</name>
            <type>energy</type>
            <location index="8">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="11">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="11">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="11">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Heavy Gauss Rifle</name>
            <type>ballistic</type>
            <splitlocation index="2" number="9">RT</splitlocation>
            <splitlocation index="10" number="2">CT</splitlocation>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Heavy Gauss Rifle</name>
            <type>ammunition</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Heavy Gauss Rifle</name>
            <type>ammunition</type>
            <location index="10">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Heavy Gauss Rifle</name>
            <type>ammunition</type>
            <location index="11">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Trebuchet" model="TBT-3C" tons="50" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1342</battle_value>
    <cost>9876000.0</cost>
    <rules_level>1</rules_level>
    <era>0</era>
    <productionera>1</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">2780</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="3">HD</location>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">RA</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="6">LT</location>
        <location index="6">RA</location>
        <location index="7">LT</location>
        <location index="9">LA</location>
        <location index="10">RT</location>
        <location index="10">LA</location>
        <location index="11">CT</location>
        <location index="11">RT</location>
    </structure>
    <engine rating="300" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>20</ct>
        <ctr>7</ctr>
        <lt>10</lt>
        <ltr>5</ltr>
        <rt>10</rt>
        <rtr>5</rtr>
        <la>10</la>
        <ra>10</ra>
        <ll>13</ll>
        <rl>13</rl>
    </armor>
    <baseloadout fcsa4="TRUE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Trebuchet TBT-3C 50t, 6/9/0, XLFE, ES; 7.0T/66% Armor; 10 DHS; 4 ML, 2 LRM15</info>
        <battleforce pv="13" wt="2" mv="6" s="4" m="5" l="3" e="0" ov="0" armor="4" internal="2" abilities="CASE, SRCH, ES, SEAL, SOA, IF 2" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="8">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="7">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="8">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="9">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Trebuchet" model="TBT-7M" tons="50" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1348</battle_value>
    <cost>8940500.0</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3048</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="5">RA</location>
        <location index="6">RA</location>
        <location index="7">RA</location>
        <location index="8">RT</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="9">LA</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="10">LA</location>
        <location index="11">LT</location>
        <location index="11">LA</location>
    </structure>
    <engine rating="250" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>22</ct>
        <ctr>7</ctr>
        <lt>11</lt>
        <ltr>5</ltr>
        <rt>11</rt>
        <rtr>5</rtr>
        <la>10</la>
        <ra>10</ra>
        <ll>15</ll>
        <rl>15</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Trebuchet TBT-7M 50t, 5/8/5, XLFE, ES; 7.5T/71% Armor; 10 DHS; 3 ML, 1 Narc, 2 LRM15</info>
        <battleforce pv="13" wt="2" mv="5j" s="3" m="3" l="2" e="0" ov="0" armor="4" internal="2" abilities="SNARC, CASE, SRCH, ES, SEAL, SOA, LRM 1/1/1, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="5">
            <type>Standard Jump Jet</type>
            <location index="10">CT</location>
            <location index="4">RL</location>
            <location index="4">LL</location>
            <location index="5">LL</location>
            <location index="5">RL</location>
        </jumpjets>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="7">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Narc Missile Beacon</name>
            <type>missile</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Narc (Homing)</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Narc (Homing)</name>
            <type>ammunition</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15 (Narc Capable)</name>
            <type>ammunition</type>
            <location index="7">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15 (Narc Capable)</name>
            <type>ammunition</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="7">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Trebuchet" model="TBT-8B" tons="50" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1314</battle_value>
    <cost>9915000.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>6</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3071</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="3">HD</location>
        <location index="7">LT</location>
        <location index="7">RA</location>
        <location index="8">LT</location>
        <location index="8">RA</location>
        <location index="9">LT</location>
        <location index="9">RA</location>
        <location index="10">LT</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
        <location index="11">CT</location>
        <location index="11">LT</location>
        <location index="11">LA</location>
        <location index="11">RA</location>
    </structure>
    <engine rating="300" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>20</ct>
        <ctr>7</ctr>
        <lt>10</lt>
        <ltr>5</ltr>
        <rt>10</rt>
        <rtr>5</rtr>
        <la>10</la>
        <ra>10</ra>
        <ll>13</ll>
        <rl>13</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Trebuchet TBT-8B 50t, 6/9/5, XLFE, ES; 7.0T/66% Armor; 13 DHS; 4 ERML, 4 MML5</info>
        <battleforce pv="13" wt="2" mv="6/3j" s="4" m="3" l="1" e="0" ov="1" armor="4" internal="2" abilities="CASE, SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="5">
            <type>Standard Jump Jet</type>
            <location index="10">CT</location>
            <location index="4">RL</location>
            <location index="4">LL</location>
            <location index="5">LL</location>
            <location index="5">RL</location>
        </jumpjets>
        <heatsinks number="13" techbase="0">
            <type>Double Heat Sink</type>

            <location index="3">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="6">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="5">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) MML-5</name>
            <type>missile</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) MML-5</name>
            <type>missile</type>
            <location index="7">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) MML-5</name>
            <type>missile</type>
            <location index="9">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) MML-5</name>
            <type>missile</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ MML-5 (LRM)</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ MML-5 (LRM)</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ MML-5 (SRM)</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="6">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Trebuchet" model="TBT-9K" tons="50" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1329</battle_value>
    <cost>8906000.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3059</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="5">RA</location>
        <location index="6">RT</location>
        <location index="6">RA</location>
        <location index="7">RT</location>
        <location index="7">RA</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">LT</location>
        <location index="9">LA</location>
        <location index="10">LT</location>
        <location index="10">LA</location>
        <location index="11">LA</location>
    </structure>
    <engine rating="250" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>25</ct>
        <ctr>6</ctr>
        <lt>18</lt>
        <ltr>5</ltr>
        <rt>18</rt>
        <rtr>5</rtr>
        <la>12</la>
        <ra>12</ra>
        <ll>17</ll>
        <rl>17</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Trebuchet TBT-9K 50t, 5/8/5, XLFE, ES; 9.0T/85% Armor; 10 DHS; 1 ML, 1 C3S, 2 MRM20, 2 MPL</info>
        <battleforce pv="13" wt="2" mv="5j" s="4" m="4" l="0" e="0" ov="1" armor="5" internal="2" abilities="C3S, CASE, MHQ1, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="5">
            <type>Standard Jump Jet</type>
            <location index="10">CT</location>
            <location index="4">RL</location>
            <location index="4">LL</location>
            <location index="5">LL</location>
            <location index="5">RL</location>
        </jumpjets>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="7">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) MRM-20</name>
            <type>missile</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) MRM-20</name>
            <type>missile</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">C3 Computer (Slave)</name>
            <type>equipment</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ MRM-20</name>
            <type>ammunition</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ MRM-20</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ MRM-20</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="7">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="UrbanMech" model="UM-R63" tons="30" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>540</battle_value>
    <cost>1756925.0</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3050</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="60" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>11</ct>
        <ctr>8</ctr>
        <lt>8</lt>
        <ltr>4</ltr>
        <rt>8</rt>
        <rtr>4</rtr>
        <la>10</la>
        <ra>10</ra>
        <ll>12</ll>
        <rl>12</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>UrbanMech UM-R63 30t, 2/3/2, Std FE, Std; 6.0T/91% Armor; 11 SHS; 1 SL, 1 SPL, 1 LB10</info>
        <battleforce pv="5" wt="1" mv="2j" s="2" m="1" l="1" e="0" ov="0" armor="3" internal="3" abilities="SRCH, ES, SEAL, SOA, FLK 1/1/1" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="2">
            <type>Standard Jump Jet</type>
            <location index="10">CT</location>
            <location index="11">CT</location>
        </jumpjets>
        <heatsinks number="11" techbase="2">
            <type>Single Heat Sink</type>

            <location index="5">LL</location>
            <location index="4">RL</location>
            <location index="1">LT</location>
            <location index="0">LT</location>
            <location index="1">RT</location>
            <location index="3">HD</location>
            <location index="5">RL</location>
            <location index="0">RT</location>
            <location index="4">LL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Pulse Laser</name>
            <type>energy</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LB 10-X AC</name>
            <type>ballistic</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Slug)</name>
            <type>ammunition</type>
            <location index="2">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="UrbanMech" model="UM-R68" tons="30" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>561</battle_value>
    <cost>1527425.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3065</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="60" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>11</ct>
        <ctr>8</ctr>
        <lt>8</lt>
        <ltr>4</ltr>
        <rt>8</rt>
        <rtr>4</rtr>
        <la>10</la>
        <ra>10</ra>
        <ll>12</ll>
        <rl>12</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>UrbanMech UM-R68 30t, 2/3/2, Std FE, Std; 6.0T/91% Armor; 11 SHS; 1 SL, 1 SPL, 1 MRM30</info>
        <battleforce pv="6" wt="1" mv="2j" s="3" m="2" l="0" e="0" ov="0" armor="3" internal="3" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="2">
            <type>Standard Jump Jet</type>
            <location index="10">CT</location>
            <location index="11">CT</location>
        </jumpjets>
        <heatsinks number="11" techbase="2">
            <type>Single Heat Sink</type>

            <location index="5">LL</location>
            <location index="4">RL</location>
            <location index="1">LT</location>
            <location index="0">LT</location>
            <location index="1">RT</location>
            <location index="3">HD</location>
            <location index="5">RL</location>
            <location index="0">RT</location>
            <location index="4">LL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Small Pulse Laser</name>
            <type>energy</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) MRM-30</name>
            <type>missile</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ MRM-30</name>
            <type>ammunition</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ MRM-30</name>
            <type>ammunition</type>
            <location index="3">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="UrbanMech" model="UM-R69" tons="30" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>589</battle_value>
    <cost>1702325.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3063</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="60" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>11</ct>
        <ctr>3</ctr>
        <lt>9</lt>
        <ltr>3</ltr>
        <rt>9</rt>
        <rtr>3</rtr>
        <la>9</la>
        <ra>9</ra>
        <ll>12</ll>
        <rl>12</rl>
        <location index="3">LT</location>
        <location index="3">RT</location>
        <location index="4">LT</location>
        <location index="4">RT</location>
        <location index="5">LT</location>
        <location index="5">RT</location>
        <location index="6">LT</location>
        <location index="6">RT</location>
        <location index="7">LT</location>
        <location index="7">RT</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>UrbanMech UM-R69 30t, 2/3/2, Std FE, Std; 5.0T/85% FF Armor; 10 SHS; 1 SPL, 1 ERSL, 1 UAC10</info>
        <battleforce pv="6" wt="1" mv="2j" s="3" m="2" l="2" e="0" ov="0" armor="3" internal="3" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="2">
            <type>Standard Jump Jet</type>
            <location index="10">CT</location>
            <location index="11">CT</location>
        </jumpjets>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="5">LL</location>
            <location index="4">RL</location>
            <location index="1">LT</location>
            <location index="0">LT</location>
            <location index="1">RT</location>
            <location index="4">LL</location>
            <location index="5">RL</location>
            <location index="0">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) ER Small Laser</name>
            <type>energy</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Pulse Laser</name>
            <type>energy</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Ultra AC/10</name>
            <type>ballistic</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Ultra AC/10</name>
            <type>ammunition</type>
            <location index="2">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="UrbanMech" model="UM-R70" tons="30" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>724</battle_value>
    <cost>1767625.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3066</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="60" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>15</ct>
        <ctr>5</ctr>
        <lt>10</lt>
        <ltr>4</ltr>
        <rt>10</rt>
        <rtr>4</rtr>
        <la>10</la>
        <ra>10</ra>
        <ll>14</ll>
        <rl>14</rl>
        <location index="3">LT</location>
        <location index="3">LA</location>
        <location index="4">LT</location>
        <location index="4">RT</location>
        <location index="4">LA</location>
        <location index="5">LT</location>
        <location index="5">RT</location>
        <location index="5">LA</location>
        <location index="6">LT</location>
        <location index="6">RT</location>
        <location index="6">LA</location>
        <location index="8">RA</location>
        <location index="9">RA</location>
        <location index="10">RA</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>UrbanMech UM-R70 30t, 2/3/2, Std FE, Std; 6.0T/100% FF Armor; 11 SHS; 1 RAC5, 1 ERML, 1 ERSL</info>
        <battleforce pv="7" wt="1" mv="2j" s="3" m="3" l="0" e="0" ov="0" armor="4" internal="3" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="FALSE" lh="FALSE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="2">
            <type>Standard Jump Jet</type>
            <location index="10">CT</location>
            <location index="11">CT</location>
        </jumpjets>
        <heatsinks number="11" techbase="2">
            <type>Single Heat Sink</type>

            <location index="5">LL</location>
            <location index="4">RL</location>
            <location index="1">LT</location>
            <location index="0">LT</location>
            <location index="1">RT</location>
            <location index="4">LL</location>
            <location index="5">RL</location>
            <location index="0">RT</location>
            <location index="3">HD</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) ER Small Laser</name>
            <type>energy</type>
            <location index="2">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rotary AC/5</name>
            <type>ballistic</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Rotary AC/5</name>
            <type>ammunition</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Rotary AC/5</name>
            <type>ammunition</type>
            <location index="3">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Victor" model="C" tons="80" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1925</battle_value>
    <cost>8253720.000000001</cost>
    <rules_level>3</rules_level>
    <era>1</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Mixed</techbase>
    <year restricted="FALSE">3050</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="320" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>30</ct>
        <ctr>15</ctr>
        <lt>20</lt>
        <ltr>10</ltr>
        <rt>20</rt>
        <rtr>10</rtr>
        <la>15</la>
        <ra>15</ra>
        <ll>20</ll>
        <rl>20</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Victor C 80t, 4/6/4, Std FE, Std; 11.5T/74% Armor; 15 SHS; 1 SSRM4, 2 ERML, 1 GR</info>
        <battleforce pv="19" wt="4" mv="4j" s="4" m="4" l="2" e="0" ov="0" armor="6" internal="6" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="5">LL</location>
            <location index="5">RL</location>
            <location index="10">CT</location>
            <location index="11">CT</location>
        </jumpjets>
        <heatsinks number="15" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="0">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(CL) Gauss Rifle</name>
            <type>ballistic</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="1">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) Streak SRM-4</name>
            <type>missile</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) ER Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) ER Medium Laser</name>
            <type>energy</type>
            <location index="5">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(CL) @ Streak SRM-4</name>
            <type>ammunition</type>
            <location index="1">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Victor" model="VTR-10D" tons="80" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1894</battle_value>
    <cost>9039120.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3064</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="1">LT</location>
        <location index="2">LT</location>
        <location index="3">HD</location>
        <location index="3">LT</location>
        <location index="4">LT</location>
        <location index="5">LT</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="6">LT</location>
        <location index="6">LA</location>
        <location index="7">LT</location>
        <location index="7">LA</location>
        <location index="8">LT</location>
        <location index="8">LA</location>
    </structure>
    <engine rating="320" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>33</ct>
        <ctr>10</ctr>
        <lt>25</lt>
        <ltr>7</ltr>
        <rt>25</rt>
        <rtr>7</rtr>
        <la>20</la>
        <ra>20</ra>
        <ll>26</ll>
        <rl>26</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Victor VTR-10D 80t, 4/6/4, Std FE, ES; 13.0T/84% Armor; 12 DHS; 1 SSRM4, 2 ERML, 1 UAC20</info>
        <battleforce pv="19" wt="4" mv="4j" s="4" m="4" l="0" e="0" ov="1" armor="7" internal="6" abilities="CASE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="4">RL</location>
            <location index="4">LL</location>
            <location index="10">CT</location>
            <location index="11">CT</location>
        </jumpjets>
        <heatsinks number="12" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="5">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Ultra AC/20</name>
            <type>ballistic</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Streak SRM-4</name>
            <type>missile</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Streak SRM-4</name>
            <type>ammunition</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Ultra AC/20</name>
            <type>ammunition</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Ultra AC/20</name>
            <type>ammunition</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Ultra AC/20</name>
            <type>ammunition</type>
            <location index="1">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Ultra AC/20</name>
            <type>ammunition</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Ultra AC/20</name>
            <type>ammunition</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Ultra AC/20</name>
            <type>ammunition</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="7">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional>&lt;p style=&quot;margin-top: 0&quot;&gt;:br::br:&lt;/p&gt;:br:</additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Victor" model="VTR-10L" tons="80" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>2279</battle_value>
    <cost>1.025352E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3066</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="3">HD</location>
        <location index="4">LT</location>
        <location index="5">LT</location>
        <location index="6">LT</location>
        <location index="6">LA</location>
        <location index="7">LT</location>
        <location index="7">LA</location>
        <location index="8">LT</location>
        <location index="8">LA</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="11">CT</location>
    </structure>
    <engine rating="320" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Stealth Armor</type>
        <hd>9</hd>
        <ct>38</ct>
        <ctr>12</ctr>
        <lt>26</lt>
        <ltr>8</ltr>
        <rt>26</rt>
        <rtr>8</rtr>
        <la>26</la>
        <ra>26</ra>
        <ll>34</ll>
        <rl>34</rl>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
        <location index="11">LA</location>
        <location index="11">RA</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Victor VTR-10L 80t, 4/6/4, Std FE, ES; 15.5T/100% Stlth Armor; 10 DHS; 1 ECM, 1 GR, 3 ERML, 1 SRM6</info>
        <battleforce pv="23" wt="4" mv="4j" s="3" m="3" l="1" e="0" ov="1" armor="8" internal="6" abilities="ECM, STL, CASE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="1">RT</location>
            <location index="0">RT</location>
            <location index="0">LT</location>
            <location index="1">LT</location>
        </jumpjets>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="5">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Gauss Rifle</name>
            <type>ballistic</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="7">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">Guardian ECM Suite</name>
            <type>equipment</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="8">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Victor" model="VTR-10S" tons="80" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>2034</battle_value>
    <cost>1.8335400000000004E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3062</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="3">HD</location>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">LA</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="6">LA</location>
        <location index="7">LA</location>
        <location index="8">LA</location>
        <location index="9">LA</location>
        <location index="10">LT</location>
        <location index="10">LA</location>
        <location index="11">LT</location>
        <location index="11">LA</location>
    </structure>
    <engine rating="320" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>33</ct>
        <ctr>10</ctr>
        <lt>25</lt>
        <ltr>7</ltr>
        <rt>25</rt>
        <rtr>7</rtr>
        <la>20</la>
        <ra>20</ra>
        <ll>26</ll>
        <rl>26</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Victor VTR-10S 80t, 4/6/0, XLFE, ES; 13.0T/84% Armor; 10 DHS; 1 HGR, 1 GR, 2 ERML, 1 AMS, 1 SRM4</info>
        <battleforce pv="20" wt="4" mv="4" s="5" m="6" l="3" e="0" ov="0" armor="7" internal="4" abilities="AMS, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Gauss Rifle</name>
            <type>ballistic</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="11">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="10">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-4</name>
            <type>missile</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Anti-Missile System</name>
            <type>equipment</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Heavy Gauss Rifle</name>
            <type>ballistic</type>
            <splitlocation index="3" number="9">RT</splitlocation>
            <splitlocation index="10" number="2">CT</splitlocation>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Heavy Gauss Rifle</name>
            <type>ammunition</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Heavy Gauss Rifle</name>
            <type>ammunition</type>
            <location index="7">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Heavy Gauss Rifle</name>
            <type>ammunition</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Anti-Missile System</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4</name>
            <type>ammunition</type>
            <location index="6">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Victor" model="VTR-11D" tons="80" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>2021</battle_value>
    <cost>1.567872E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3065</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="7">RT</location>
        <location index="8">RT</location>
        <location index="8">LA</location>
        <location index="9">RT</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">RT</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
        <location index="11">RT</location>
        <location index="11">LA</location>
        <location index="11">RA</location>
    </structure>
    <engine rating="320" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">Light Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>34</ct>
        <ctr>13</ctr>
        <lt>23</lt>
        <ltr>10</ltr>
        <rt>23</rt>
        <rtr>10</rtr>
        <la>24</la>
        <ra>24</ra>
        <ll>31</ll>
        <rl>31</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Victor VTR-11D 80t, 4/6/4, LFE, ES; 14.5T/94% Armor; 15 DHS; 1 SSRM4, 2 ERLL, 1 C3S, 1 RAC5</info>
        <battleforce pv="20" wt="4" mv="4j" s="4" m="4" l="2" e="0" ov="1" armor="8" internal="4" abilities="C3S, CASE, MHQ1, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="11">CT</location>
            <location index="4">LL</location>
            <location index="10">CT</location>
            <location index="4">RL</location>
        </jumpjets>
        <heatsinks number="15" techbase="0">
            <type>Double Heat Sink</type>

            <location index="5">LT</location>
            <location index="2">LT</location>
            <location index="8">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Rotary AC/5</name>
            <type>ballistic</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Large Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Large Laser</name>
            <type>energy</type>
            <location index="6">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Streak SRM-4</name>
            <type>missile</type>
            <location index="11">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Streak SRM-4</name>
            <type>ammunition</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Rotary AC/5</name>
            <type>ammunition</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Rotary AC/5</name>
            <type>ammunition</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Rotary AC/5</name>
            <type>ammunition</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">C3 Computer (Slave)</name>
            <type>equipment</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="6">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Victor" model="VTR-9K" tons="80" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1717</battle_value>
    <cost>8458720.0</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3049</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="3">LT</location>
        <location index="3">RT</location>
        <location index="4">LT</location>
        <location index="4">RT</location>
        <location index="5">LT</location>
        <location index="5">RT</location>
        <location index="6">LT</location>
        <location index="6">RT</location>
        <location index="7">LT</location>
        <location index="7">RT</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
    </structure>
    <engine rating="320" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>34</ct>
        <ctr>15</ctr>
        <lt>23</lt>
        <ltr>10</ltr>
        <rt>23</rt>
        <rtr>10</rtr>
        <la>18</la>
        <ra>18</ra>
        <ll>20</ll>
        <rl>20</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Victor VTR-9K 80t, 4/6/4, Std FE, ES; 12.5T/81% Armor; 15 SHS; 1 GR, 2 MPL, 1 SRM4</info>
        <battleforce pv="17" wt="4" mv="4j" s="4" m="4" l="2" e="0" ov="0" armor="7" internal="6" abilities="CASE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="5">RL</location>
            <location index="5">LL</location>
            <location index="10">CT</location>
            <location index="11">CT</location>
        </jumpjets>
        <heatsinks number="15" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">RL</location>
            <location index="4">LL</location>
            <location index="3">HD</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="5">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Gauss Rifle</name>
            <type>ballistic</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-4</name>
            <type>missile</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4</name>
            <type>ammunition</type>
            <location index="1">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="1">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="2">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Victor" model="VTR-C" tons="80" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1717</battle_value>
    <cost>8905120.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3054</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="3">LT</location>
        <location index="3">RT</location>
        <location index="4">LT</location>
        <location index="4">RT</location>
        <location index="5">LT</location>
        <location index="5">RT</location>
        <location index="6">LT</location>
        <location index="6">RT</location>
        <location index="7">LT</location>
        <location index="7">RT</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
    </structure>
    <engine rating="320" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>34</ct>
        <ctr>15</ctr>
        <lt>23</lt>
        <ltr>10</ltr>
        <rt>23</rt>
        <rtr>10</rtr>
        <la>18</la>
        <ra>18</ra>
        <ll>20</ll>
        <rl>20</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Victor VTR-C 80t, 4/6/4, Std FE, ES; 12.5T/81% Armor; 14 SHS; 1 C3S, 1 GR, 2 MPL, 1 SRM4</info>
        <battleforce pv="17" wt="4" mv="4j" s="4" m="4" l="2" e="0" ov="0" armor="7" internal="6" abilities="C3S, CASE, MHQ1, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="5">RL</location>
            <location index="10">CT</location>
            <location index="11">CT</location>
            <location index="5">LL</location>
        </jumpjets>
        <heatsinks number="14" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">RL</location>
            <location index="4">LL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Gauss Rifle</name>
            <type>ballistic</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="0">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="1">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="5">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-4</name>
            <type>missile</type>
            <location index="0">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-4</name>
            <type>ammunition</type>
            <location index="1">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">C3 Computer (Slave)</name>
            <type>equipment</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="2">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Vindicator" model="VND-3L" tons="45" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1105</battle_value>
    <cost>3510870.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3051</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="180" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>18</ct>
        <ctr>9</ctr>
        <lt>16</lt>
        <ltr>6</ltr>
        <rt>16</rt>
        <rtr>6</rtr>
        <la>14</la>
        <ra>14</ra>
        <ll>18</ll>
        <rl>18</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Vindicator VND-3L 45t, 4/6/4, Std FE, Std; 9.0T/94% Armor; 15 DHS; 1 LRM5, 1 MPL, 1 ERPPC</info>
        <battleforce pv="11" wt="2" mv="4j" s="2" m="2" l="2" e="0" ov="0" armor="5" internal="4" abilities="CASE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="4">LL</location>
            <location index="11">CT</location>
            <location index="10">CT</location>
            <location index="4">RL</location>
        </jumpjets>
        <heatsinks number="15" techbase="0">
            <type>Double Heat Sink</type>

            <location index="6">LT</location>
            <location index="3">RT</location>
            <location index="6">RT</location>
            <location index="0">LT</location>
            <location index="0">RT</location>
            <location index="3">RA</location>
            <location index="3">LT</location>
            <location index="4">LA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) ER PPC</name>
            <type>energy</type>
            <location index="6">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-5</name>
            <type>missile</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-5</name>
            <type>ammunition</type>
            <location index="9">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="10">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Vindicator" model="VND-4L" tons="45" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1411</battle_value>
    <cost>8105920.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3063</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="225" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Stealth Armor</type>
        <hd>9</hd>
        <ct>18</ct>
        <ctr>9</ctr>
        <lt>16</lt>
        <ltr>6</ltr>
        <rt>16</rt>
        <rtr>6</rtr>
        <la>14</la>
        <ra>14</ra>
        <ll>18</ll>
        <rl>18</rl>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="8">LT</location>
        <location index="9">LT</location>
        <location index="9">RA</location>
        <location index="10">RT</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
        <location index="11">RT</location>
        <location index="11">LA</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Vindicator VND-4L 45t, 5/8/5, XLFE, Std; 9.0T/94% Stlth Armor; 13 DHS; 1 ECM, 1 ERML, 1 LRM5, 1 ERPPC, 1 MPL</info>
        <battleforce pv="14" wt="2" mv="5j" s="2" m="2" l="1" e="0" ov="1" armor="5" internal="2" abilities="ECM, STL, CASE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="5">
            <type>Standard Jump Jet</type>
            <location index="4">LT</location>
            <location index="6">RT</location>
            <location index="7">RT</location>
            <location index="10">CT</location>
            <location index="3">LT</location>
        </jumpjets>
        <heatsinks number="13" techbase="0">
            <type>Double Heat Sink</type>

            <location index="3">RT</location>
            <location index="4">LA</location>
            <location index="3">RA</location>
            <location index="7">LA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER PPC</name>
            <type>energy</type>
            <location index="6">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-5</name>
            <type>missile</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-5</name>
            <type>ammunition</type>
            <location index="8">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">Guardian ECM Suite</name>
            <type>equipment</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="9">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Vindicator" model="VND-5L" tons="45" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1484</battle_value>
    <cost>4636882.5</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3060</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="180" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <enhancement techbase="0">
        <type>TSM</type>
        <location index="6">LT</location>
        <location index="6">RT</location>
        <location index="7">LT</location>
        <location index="7">RT</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
    </enhancement>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>20</ct>
        <ctr>7</ctr>
        <lt>16</lt>
        <ltr>6</ltr>
        <rt>16</rt>
        <rtr>6</rtr>
        <la>14</la>
        <ra>14</ra>
        <ll>22</ll>
        <rl>22</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Vindicator VND-5L 45t, 4[5]/6[8]/4 TSM, Std FE, Std; 9.5T/99% Armor; 10 DHS; 1 SL, 2 ERML, 1 SPL, 2 MPL, 1 ERPPC, 1 Swrd</info>
        <battleforce pv="15" wt="2" mv="4j" s="3" m="2" l="1" e="0" ov="2" armor="5" internal="4" abilities="TSM, MEL, ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="11">CT</location>
            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="10">CT</location>
        </jumpjets>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

            <location index="0">RT</location>
            <location index="3">RA</location>
            <location index="0">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) ER PPC</name>
            <type>energy</type>
            <location index="6">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Pulse Laser</name>
            <type>energy</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">Sword</name>
            <type>physical</type>
            <location index="4">LA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Vindicator" model="VND-6L" tons="45" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1739</battle_value>
    <cost>5304090.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>6</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3068</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="180" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <enhancement techbase="0">
        <type>TSM</type>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
        <location index="11">LA</location>
        <location index="11">RA</location>
    </enhancement>
    <armor manufacturer="Unknown" techbase="0">
        <type>Stealth Armor</type>
        <hd>9</hd>
        <ct>20</ct>
        <ctr>7</ctr>
        <lt>16</lt>
        <ltr>6</ltr>
        <rt>16</rt>
        <rtr>6</rtr>
        <la>14</la>
        <ra>14</ra>
        <ll>22</ll>
        <rl>22</rl>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Vindicator VND-6L 45t, 4[5]/6[8]/4 TSM, Std FE, Std; 9.5T/99% Stlth Armor; 10 DHS; 1 PR, 1 ECM, 2 ERML, 2 RL10, 1 Swrd, TC</info>
        <battleforce pv="17" wt="2" mv="4j" s="2" m="2" l="1" e="0" ov="1" armor="5" internal="4" abilities="TSM, ECM, STL, MEL, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="TRUE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="3">LT</location>
            <location index="11">CT</location>
            <location index="3">RT</location>
            <location index="10">CT</location>
        </jumpjets>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

            <location index="0">LT</location>
            <location index="4">RA</location>
            <location index="0">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">Sword</name>
            <type>physical</type>
            <location index="4">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">Guardian ECM Suite</name>
            <type>equipment</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Plasma Rifle</name>
            <type>energy</type>
            <location index="7">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rocket Launcher 10</name>
            <type>missile</type>
            <location index="7">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Plasma Rifle</name>
            <type>ammunition</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Plasma Rifle</name>
            <type>ammunition</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rocket Launcher 10</name>
            <type>missile</type>
            <location index="8">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Targeting Computer</name>
            <type>TargetingComputer</type>
            <location index="6">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Vulcan" model="VT-5M" tons="40" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>811</battle_value>
    <cost>3788699.9999999995</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3050</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="4">LT</location>
        <location index="4">RT</location>
        <location index="5">LT</location>
        <location index="5">RT</location>
        <location index="6">LT</location>
        <location index="6">RT</location>
        <location index="7">LT</location>
        <location index="7">RT</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
    </structure>
    <engine rating="240" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>8</hd>
        <ct>10</ct>
        <ctr>6</ctr>
        <lt>8</lt>
        <ltr>4</ltr>
        <rt>8</rt>
        <rtr>4</rtr>
        <la>6</la>
        <ra>6</ra>
        <ll>10</ll>
        <rl>10</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Vulcan VT-5M 40t, 6/9/6, Std FE, ES; 5.0T/58% Armor; 11 DHS; 1 Flmr, 1 MG, 1 MPL, 1 LPL</info>
        <battleforce pv="8" wt="2" mv="6j" s="3" m="2" l="0" e="0" ov="0" armor="3" internal="3" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="6">
            <type>Standard Jump Jet</type>
            <location index="0">RT</location>
            <location index="1">LT</location>
            <location index="10">CT</location>
            <location index="0">LT</location>
            <location index="11">CT</location>
            <location index="1">RT</location>
        </jumpjets>
        <heatsinks number="11" techbase="0">
            <type>Double Heat Sink</type>

            <location index="3">LA</location>
            <location index="3">RA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="6">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="6">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Machine Gun</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Large Pulse Laser</name>
            <type>energy</type>
            <location index="2">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Vulcan" model="VT-5S" tons="40" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>883</battle_value>
    <cost>7145100.0</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3050</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="240" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <enhancement techbase="0">
        <type>MASC</type>
        <location index="8">LT</location>
    </enhancement>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>8</hd>
        <ct>10</ct>
        <ctr>5</ctr>
        <lt>10</lt>
        <ltr>5</ltr>
        <rt>10</rt>
        <rtr>5</rtr>
        <la>6</la>
        <ra>6</ra>
        <ll>12</ll>
        <rl>12</rl>
        <location index="4">LA</location>
        <location index="4">RA</location>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">LA</location>
        <location index="5">RA</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="6">LA</location>
        <location index="6">RA</location>
        <location index="7">LA</location>
        <location index="7">RA</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Vulcan VT-5S 40t, 6/9[12]/6 MASC, XLFE, Std; 5.0T/65% FF Armor; 10 SHS; 1 ML, 1 Flmr, 1 UAC5, 1 MG</info>
        <battleforce pv="9" wt="2" mv="8/6j" s="2" m="2" l="1" e="0" ov="0" armor="3" internal="2" abilities="CASE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="6">
            <type>Standard Jump Jet</type>
            <location index="5">LT</location>
            <location index="4">LT</location>
            <location index="10">CT</location>
            <location index="3">RT</location>
            <location index="11">CT</location>
            <location index="4">RT</location>
        </jumpjets>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="3">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Machine Gun</name>
            <type>ammunition</type>
            <location index="7">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Ultra AC/5</name>
            <type>ballistic</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Ultra AC/5</name>
            <type>ammunition</type>
            <location index="10">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="11">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Vulcan" model="VT-6C" tons="40" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>959</battle_value>
    <cost>4908550.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>6</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3070</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="4">LT</location>
        <location index="5">LT</location>
        <location index="6">LT</location>
        <location index="6">RT</location>
        <location index="7">LT</location>
        <location index="7">RT</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
    </structure>
    <engine rating="240" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>8</hd>
        <ct>10</ct>
        <ctr>6</ctr>
        <lt>8</lt>
        <ltr>4</ltr>
        <rt>8</rt>
        <rtr>4</rtr>
        <la>6</la>
        <ra>6</ra>
        <ll>10</ll>
        <rl>10</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Vulcan VT-6C 40t, 6/9/6, Std FE, ES; 5.0T/58% Armor; 10 DHS; 1 Flmr, 1 MGA+3LMG, 1 C3I, 1 ERLL, 1 ERML</info>
        <battleforce pv="10" wt="2" mv="6j" s="2" m="2" l="1" e="0" ov="0" armor="3" internal="3" abilities="C3I, MHQ2, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="6">
            <type>Standard Jump Jet</type>
            <location index="1">LT</location>
            <location index="0">LT</location>
            <location index="10">CT</location>
            <location index="0">RT</location>
            <location index="11">CT</location>
            <location index="1">RT</location>
        </jumpjets>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

            <location index="3">RA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) MG Array (3 Light Machine Gun)</name>
            <type>mgarray</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Large Laser</name>
            <type>energy</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="6">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">Improved C3 Computer</name>
            <type>equipment</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Light Machine Gun</name>
            <type>ammunition</type>
            <location index="3">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Vulcan" model="VT-6M" tons="40" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>959</battle_value>
    <cost>6721800.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>6</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3069</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="3">HD</location>
        <location index="4">LA</location>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">LA</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="7">LT</location>
        <location index="7">RA</location>
        <location index="8">LT</location>
        <location index="8">RA</location>
        <location index="9">LT</location>
        <location index="10">LT</location>
        <location index="11">LT</location>
    </structure>
    <engine rating="240" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Light Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>10</ct>
        <ctr>6</ctr>
        <lt>9</lt>
        <ltr>4</ltr>
        <rt>9</rt>
        <rtr>4</rtr>
        <la>10</la>
        <ra>10</ra>
        <ll>11</ll>
        <rl>11</rl>
        <location index="6">LA</location>
        <location index="7">LA</location>
        <location index="8">LA</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Vulcan VT-6M 40t, 6/9/6, XLFE, ES; 5.5T/68% LFF Armor; 10 DHS; 1 Flmr, 1 ERML, 1 MG, 1 LGR</info>
        <battleforce pv="10" wt="2" mv="6j" s="2" m="2" l="1" e="0" ov="0" armor="3" internal="2" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="6">
            <type>Standard Jump Jet</type>
            <location index="4">LT</location>
            <location index="3">RT</location>
            <location index="10">CT</location>
            <location index="4">RT</location>
            <location index="11">CT</location>
            <location index="3">LT</location>
        </jumpjets>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

            <location index="3">RA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Light Gauss Rifle</name>
            <type>ballistic</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Machine Gun</name>
            <type>ballistic</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Flamer</name>
            <type>energy</type>
            <location index="6">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Machine Gun</name>
            <type>ammunition</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Light Gauss Rifle</name>
            <type>ammunition</type>
            <location index="10">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Light Gauss Rifle</name>
            <type>ammunition</type>
            <location index="11">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Whitworth" model="WTH-1H" tons="40" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>993</battle_value>
    <cost>2867133.3333333335</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3066</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="160" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>16</ct>
        <ctr>7</ctr>
        <lt>12</lt>
        <ltr>6</ltr>
        <rt>12</rt>
        <rtr>6</rtr>
        <la>12</la>
        <ra>12</ra>
        <ll>18</ll>
        <rl>18</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Whitworth WTH-1H 40t, 4/6/4, Std FE, Std; 8.0T/93% Armor; 14 SHS; 3 ML, 8 RL10, 1 SRM6</info>
        <battleforce pv="10" wt="2" mv="4j" s="3" m="3" l="1" e="0" ov="0" armor="4" internal="3" abilities="SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="5">LL</location>
            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="5">RL</location>
        </jumpjets>
        <heatsinks number="14" techbase="2">
            <type>Single Heat Sink</type>

            <location index="10">CT</location>
            <location index="1">LT</location>
            <location index="0">RT</location>
            <location index="1">RT</location>
            <location index="0">LT</location>
            <location index="2">RT</location>
            <location index="11">CT</location>
            <location index="2">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rocket Launcher 10</name>
            <type>missile</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rocket Launcher 10</name>
            <type>missile</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rocket Launcher 10</name>
            <type>missile</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rocket Launcher 10</name>
            <type>missile</type>
            <location index="9">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rocket Launcher 10</name>
            <type>missile</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rocket Launcher 10</name>
            <type>missile</type>
            <location index="8">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rocket Launcher 10</name>
            <type>missile</type>
            <location index="10">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Rocket Launcher 10</name>
            <type>missile</type>
            <location index="7">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) SRM-6</name>
            <type>missile</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ SRM-6</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Whitworth" model="WTH-2" tons="40" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>936</battle_value>
    <cost>3116933.3333333335</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3050</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="160" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>16</ct>
        <ctr>7</ctr>
        <lt>12</lt>
        <ltr>6</ltr>
        <rt>12</rt>
        <rtr>6</rtr>
        <la>12</la>
        <ra>12</ra>
        <ll>18</ll>
        <rl>18</rl>
    </armor>
    <baseloadout fcsa4="TRUE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Whitworth WTH-2 40t, 4/6/4, Std FE, Std; 8.0T/93% Armor; 10 SHS; 1 ML, 2 LRM10</info>
        <battleforce pv="9" wt="2" mv="4j" s="2" m="2" l="2" e="0" ov="1" armor="4" internal="3" abilities="SRCH, ES, SEAL, SOA, IF 2" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="0">LT</location>
            <location index="1">RT</location>
            <location index="0">RT</location>
            <location index="1">LT</location>
        </jumpjets>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="4">LL</location>
            <location index="5">LL</location>
            <location index="4">RL</location>
            <location index="5">RL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-10</name>
            <type>missile</type>
            <location index="2">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-10</name>
            <type>missile</type>
            <location index="2">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="5">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Whitworth" model="WTH-2A" tons="40" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1055</battle_value>
    <cost>3637883.3333333335</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>6</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3068</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="4">LA</location>
        <location index="4">RA</location>
        <location index="5">LA</location>
        <location index="5">RA</location>
        <location index="6">LA</location>
        <location index="6">RA</location>
        <location index="7">LA</location>
        <location index="7">RA</location>
        <location index="8">LT</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">RT</location>
    </structure>
    <engine rating="160" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>16</ct>
        <ctr>7</ctr>
        <lt>12</lt>
        <ltr>6</ltr>
        <rt>12</rt>
        <rtr>6</rtr>
        <la>12</la>
        <ra>12</ra>
        <ll>18</ll>
        <rl>18</rl>
    </armor>
    <baseloadout fcsa4="TRUE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Whitworth WTH-2A 40t, 4/6/4, Std FE, ES; 8.0T/93% Armor; 10 DHS; 4 SSRM4, 2 ML, 1 SL, 1 C3S</info>
        <battleforce pv="11" wt="2" mv="4j" s="4" m="4" l="0" e="0" ov="0" armor="4" internal="3" abilities="C3S, CASE, MHQ1, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="4">
            <type>Standard Jump Jet</type>
            <location index="5">LL</location>
            <location index="5">RL</location>
            <location index="4">RL</location>
            <location index="4">LL</location>
        </jumpjets>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

            <location index="0">RT</location>
            <location index="3">LT</location>
            <location index="0">LT</location>
            <location index="3">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Small Laser</name>
            <type>energy</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Streak SRM-4</name>
            <type>missile</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Streak SRM-4</name>
            <type>missile</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Streak SRM-4</name>
            <type>missile</type>
            <location index="7">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Streak SRM-4</name>
            <type>missile</type>
            <location index="7">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Streak SRM-4</name>
            <type>ammunition</type>
            <location index="8">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">C3 Computer (Slave)</name>
            <type>equipment</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="9">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Whitworth" model="WTH-3" tons="40" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>882</battle_value>
    <cost>3787733.333333333</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>6</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3069</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="160" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>16</ct>
        <ctr>7</ctr>
        <lt>12</lt>
        <ltr>6</ltr>
        <rt>12</rt>
        <rtr>6</rtr>
        <la>12</la>
        <ra>12</ra>
        <ll>18</ll>
        <rl>18</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Whitworth WTH-3 40t, 4/6/6, Std FE, Std; 8.0T/93% Armor; 10 SHS; 1 C3S, 4 LRM5</info>
        <battleforce pv="9" wt="2" mv="4/6j" s="1" m="2" l="2" e="0" ov="0" armor="4" internal="3" abilities="C3S, MHQ1, SRCH, ES, SEAL, SOA, LRM 1/1/1, IF 1" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="6">
            <type>Improved Jump Jet</type>
            <location index="0">RT</location>
            <location index="0">LT</location>
            <location index="2">LT</location>
            <location index="2">RT</location>
            <location index="4">LT</location>
            <location index="4">RT</location>
        </jumpjets>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="5">LL</location>
            <location index="4">RL</location>
            <location index="5">RL</location>
            <location index="4">LL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) LRM-5</name>
            <type>missile</type>
            <location index="7">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-5</name>
            <type>missile</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-5</name>
            <type>missile</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-5</name>
            <type>missile</type>
            <location index="7">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-5</name>
            <type>ammunition</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-5</name>
            <type>ammunition</type>
            <location index="8">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">C3 Computer (Slave)</name>
            <type>equipment</type>
            <location index="3">HD</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Whitworth" model="WTH-K" tons="40" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>819</battle_value>
    <cost>3602733.3333333335</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>6</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3070</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="160" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>16</ct>
        <ctr>7</ctr>
        <lt>12</lt>
        <ltr>6</ltr>
        <rt>12</rt>
        <rtr>6</rtr>
        <la>12</la>
        <ra>12</ra>
        <ll>18</ll>
        <rl>18</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Whitworth WTH-K 40t, 4/6/5, Std FE, Std; 8.0T/93% Armor; 10 SHS; 1 C3S, 2 MML7</info>
        <battleforce pv="8" wt="2" mv="4/5j" s="2" m="2" l="1" e="0" ov="0" armor="4" internal="3" abilities="C3S, MHQ1, SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <jumpjets number="5">
            <type>Improved Jump Jet</type>
            <location index="0">RT</location>
            <location index="0">LT</location>
            <location index="2">LT</location>
            <location index="2">RT</location>
            <location index="10">CT</location>
        </jumpjets>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

            <location index="5">LL</location>
            <location index="4">RL</location>
            <location index="5">RL</location>
            <location index="4">LL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">C3 Computer (Slave)</name>
            <type>equipment</type>
            <location index="3">HD</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) MML-7</name>
            <type>missile</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) MML-7</name>
            <type>missile</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ MML-7 (LRM)</name>
            <type>ammunition</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ MML-7 (SRM)</name>
            <type>ammunition</type>
            <location index="8">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>Unknown</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Wolf Trap (Tora)" model="WFT-1" tons="45" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1070</battle_value>
    <cost>7882357.5</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3051</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="4">LA</location>
        <location index="5">LT</location>
        <location index="5">LA</location>
        <location index="6">LT</location>
        <location index="7">LT</location>
        <location index="7">RT</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
    </structure>
    <engine rating="270" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>17</ct>
        <ctr>6</ctr>
        <lt>13</lt>
        <ltr>5</ltr>
        <rt>13</rt>
        <rtr>5</rtr>
        <la>11</la>
        <ra>11</ra>
        <ll>15</ll>
        <rl>15</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Wolf Trap (Tora) WFT-1 45t, 6/9/0, XLFE, ES; 7.5T/78% Armor; 10 SHS; 2 ML, 1 LRM10, 1 LB10</info>
        <battleforce pv="11" wt="2" mv="6" s="2" m="3" l="2" e="0" ov="0" armor="4" internal="2" abilities="CASE, SRCH, ES, SEAL, SOA, IF 1, FLK 1/1/1" />
        <actuators lla="TRUE" lh="TRUE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LB 10-X AC</name>
            <type>ballistic</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-10</name>
            <type>missile</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Slug)</name>
            <type>ammunition</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Cluster)</name>
            <type>ammunition</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="6">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Wolf Trap (Tora)" model="WFT-2" tons="45" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1227</battle_value>
    <cost>7851670.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>6</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3071</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="4">LA</location>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">LA</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="6">LA</location>
        <location index="7">RA</location>
        <location index="8">RA</location>
        <location index="9">LT</location>
        <location index="9">RA</location>
        <location index="10">CT</location>
        <location index="11">CT</location>
        <location index="11">RT</location>
    </structure>
    <engine rating="270" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>16</ct>
        <ctr>5</ctr>
        <lt>15</lt>
        <ltr>5</ltr>
        <rt>15</rt>
        <rtr>5</rtr>
        <la>10</la>
        <ra>10</ra>
        <ll>15</ll>
        <rl>15</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Wolf Trap (Tora) WFT-2 45t, 6/9/0, XLFE, ES; 7.5T/78% Armor; 10 DHS; 1 PR, 1 MGA+3LMG, 1 ERML, 1 MML9</info>
        <battleforce pv="12" wt="2" mv="6" s="3" m="3" l="1" e="0" ov="0" armor="4" internal="2" abilities="CASE, SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="TRUE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) MG Array (3 Light Machine Gun)</name>
            <type>mgarray</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Plasma Rifle</name>
            <type>energy</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) MML-9</name>
            <type>missile</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Plasma Rifle</name>
            <type>ammunition</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ MML-9 (LRM)</name>
            <type>ammunition</type>
            <location index="8">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ MML-9 (SRM)</name>
            <type>ammunition</type>
            <location index="9">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Light Machine Gun</name>
            <type>ammunition</type>
            <location index="7">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Plasma Rifle</name>
            <type>ammunition</type>
            <location index="6">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ Plasma Rifle</name>
            <type>ammunition</type>
            <location index="5">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="10">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Wolf Trap (Tora)" model="WFT-B" tons="45" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1021</battle_value>
    <cost>8875607.5</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>6</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3069</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="4">LA</location>
        <location index="5">LA</location>
        <location index="6">LA</location>
        <location index="7">LT</location>
        <location index="7">RT</location>
        <location index="7">LA</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
    </structure>
    <engine rating="270" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>17</ct>
        <ctr>6</ctr>
        <lt>14</lt>
        <ltr>4</ltr>
        <rt>14</rt>
        <rtr>4</rtr>
        <la>12</la>
        <ra>12</ra>
        <ll>18</ll>
        <rl>18</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Wolf Trap (Tora) WFT-B 45t, 6/9/0, XLFE, ES; 8.0T/84% Armor; 10 SHS; 2 ML, 1 C3I, 1 LRM5, 1 LB10</info>
        <battleforce pv="10" wt="2" mv="6" s="2" m="2" l="1" e="0" ov="0" armor="4" internal="2" abilities="C3I, CASE, MHQ2, SRCH, ES, SEAL, SOA, FLK 1/1/1" />
        <actuators lla="TRUE" lh="TRUE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LB 10-X AC</name>
            <type>ballistic</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Slug)</name>
            <type>ammunition</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Cluster)</name>
            <type>ammunition</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-5</name>
            <type>missile</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-5</name>
            <type>ammunition</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">Improved C3 Computer</name>
            <type>equipment</type>
            <location index="5">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="6">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Wolf Trap (Tora)" model="WFT-C" tons="45" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1001</battle_value>
    <cost>8186857.5</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3060</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="4">LA</location>
        <location index="5">LT</location>
        <location index="5">LA</location>
        <location index="6">LT</location>
        <location index="7">LT</location>
        <location index="7">RT</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
    </structure>
    <engine rating="270" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>17</ct>
        <ctr>6</ctr>
        <lt>13</lt>
        <ltr>5</ltr>
        <rt>13</rt>
        <rtr>5</rtr>
        <la>11</la>
        <ra>11</ra>
        <ll>15</ll>
        <rl>15</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Wolf Trap (Tora) WFT-C 45t, 6/9/0, XLFE, ES; 7.5T/78% Armor; 10 SHS; 1 ML, 1 C3S, 1 LRM10, 1 LB10</info>
        <battleforce pv="10" wt="2" mv="6" s="2" m="2" l="2" e="0" ov="0" armor="4" internal="2" abilities="C3S, CASE, MHQ1, SRCH, ES, SEAL, SOA, IF 1, FLK 1/1/1" />
        <actuators lla="TRUE" lh="TRUE" rla="FALSE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="2">
            <type>Single Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LB 10-X AC</name>
            <type>ballistic</type>
            <location index="2">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-10</name>
            <type>missile</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-10</name>
            <type>ammunition</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Slug)</name>
            <type>ammunition</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 10-X AC (Cluster)</name>
            <type>ammunition</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">C3 Computer (Slave)</name>
            <type>equipment</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="6">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional>&lt;p style=&quot;margin-top: 0&quot;&gt;:br::br:&lt;/p&gt;:br:</additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Wolfhound IIC" model="&quot;Grinner&quot;" tons="35" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1579</battle_value>
    <cost>5694030.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Clan</techbase>
    <year restricted="FALSE">3051</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="1">
        <type>Endo-Steel</type>
        <location index="5">LT</location>
        <location index="6">LT</location>
        <location index="7">LT</location>
        <location index="8">LT</location>
        <location index="9">LT</location>
        <location index="10">LT</location>
        <location index="11">LT</location>
    </structure>
    <engine rating="210" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="1">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="1">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>16</ct>
        <ctr>6</ctr>
        <lt>11</lt>
        <ltr>5</ltr>
        <rt>11</rt>
        <rtr>5</rtr>
        <la>12</la>
        <ra>12</ra>
        <ll>16</ll>
        <rl>16</rl>
        <location index="5">RT</location>
        <location index="6">RT</location>
        <location index="7">RT</location>
        <location index="8">RT</location>
        <location index="9">RT</location>
        <location index="10">RT</location>
        <location index="11">RT</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Wolfhound IIC "Grinner" 35t, 6/9/0, XLFE, ES; 6.5T/100% FF Armor; 14 DHS; 1 ECM, 1 ERLL, 1 ERML, 3 MPL</info>
        <battleforce pv="16" wt="1" mv="6" s="4" m="4" l="1" e="0" ov="0" armor="4" internal="2" abilities="ECM, ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>TRUE</clancase>
        <heatsinks number="14" techbase="1">
            <type>Double Heat Sink</type>

            <location index="2">LT</location>
            <location index="3">RA</location>
            <location index="4">RL</location>
            <location index="2">RT</location>
            <location index="4">LA</location>
            <location index="4">LL</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(CL) ER Large Laser</name>
            <type>energy</type>
            <location index="5">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (CL) ER Medium Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">ECM Suite</name>
            <type>equipment</type>
            <location index="3">HD</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Wolfhound" model="WLF-1 (Allard)" tons="35" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1941</battle_value>
    <cost>5427067.5</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Clan</techbase>
    <year restricted="FALSE">3062</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="210" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="1">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>16</ct>
        <ctr>6</ctr>
        <lt>11</lt>
        <ltr>5</ltr>
        <rt>11</rt>
        <rtr>5</rtr>
        <la>12</la>
        <ra>12</ra>
        <ll>16</ll>
        <rl>16</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Wolfhound WLF-1 (Allard) 35t, 6/9/0, XLFE, Std; 7.5T/100% Armor; 12 DHS; 3 ERML, 1 ERSL, 1 ERPPC, TC</info>
        <battleforce pv="19" wt="1" mv="6" s="4" m="4" l="2" e="0" ov="0" armor="4" internal="2" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>TRUE</clancase>
        <heatsinks number="12" techbase="1">
            <type>Double Heat Sink</type>

            <location index="2">LT</location>
            <location index="4">LL</location>
            <location index="4">RL</location>
            <location index="2">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(CL) ER Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) ER Medium Laser</name>
            <type>energy</type>
            <location index="4">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) ER Medium Laser</name>
            <type>energy</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (CL) ER Small Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) ER PPC</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(CL) Targeting Computer</name>
            <type>TargetingComputer</type>
            <location index="5">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Wolfhound" model="WLF-2" tons="35" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1061</battle_value>
    <cost>3141180.0</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>4</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3052</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="210" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>16</ct>
        <ctr>6</ctr>
        <lt>11</lt>
        <ltr>5</ltr>
        <rt>11</rt>
        <rtr>5</rtr>
        <la>12</la>
        <ra>12</ra>
        <ll>16</ll>
        <rl>16</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Wolfhound WLF-2 35t, 6/9/0, Std FE, Std; 7.5T/100% Armor; 10 DHS; 4 ML, 1 ERLL</info>
        <battleforce pv="11" wt="1" mv="6" s="3" m="3" l="1" e="0" ov="0" armor="4" internal="3" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

            <location index="0">LT</location>
            <location index="0">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) ER Large Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Wolfhound" model="WLF-3S" tons="35" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1176</battle_value>
    <cost>4795267.5</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3064</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="4">LA</location>
        <location index="5">LA</location>
        <location index="6">LA</location>
        <location index="6">RA</location>
        <location index="7">LA</location>
        <location index="7">RA</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="9">RA</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="10">RA</location>
    </structure>
    <engine rating="210" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">Light Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>16</ct>
        <ctr>6</ctr>
        <lt>11</lt>
        <ltr>5</ltr>
        <rt>11</rt>
        <rtr>5</rtr>
        <la>12</la>
        <ra>12</ra>
        <ll>16</ll>
        <rl>16</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Wolfhound WLF-3S 35t, 6/9/0, LFE, ES; 7.5T/100% Armor; 12 DHS; 3 ERML, 1 ERSL, 1 ERPPC</info>
        <battleforce pv="12" wt="1" mv="6" s="3" m="3" l="1" e="0" ov="0" armor="4" internal="2" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="12" techbase="0">
            <type>Double Heat Sink</type>

            <location index="2">LT</location>
            <location index="5">LT</location>
            <location index="5">RT</location>
            <location index="2">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="8">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) ER Small Laser</name>
            <type>energy</type>
            <location index="11">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER PPC</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Wolfhound" model="WLF-4W" tons="35" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1090</battle_value>
    <cost>3442567.5</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>6</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3069</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="5">LT</location>
        <location index="5">RT</location>
        <location index="6">LT</location>
        <location index="6">RT</location>
        <location index="7">LT</location>
        <location index="7">RT</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
    </structure>
    <engine rating="210" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>16</ct>
        <ctr>6</ctr>
        <lt>11</lt>
        <ltr>5</ltr>
        <rt>11</rt>
        <rtr>5</rtr>
        <la>12</la>
        <ra>12</ra>
        <ll>16</ll>
        <rl>16</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Wolfhound WLF-4W 35t, 6/9/0, Std FE, ES; 7.5T/100% Armor; 10 DHS; 1 ERML, 1 ERSL, 3 LPPC</info>
        <battleforce pv="11" wt="1" mv="6" s="2" m="3" l="2" e="0" ov="0" armor="4" internal="3" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

            <location index="0">RT</location>
            <location index="0">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Small Laser</name>
            <type>energy</type>
            <location index="4">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Light PPC</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Light PPC</name>
            <type>energy</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Light PPC</name>
            <type>energy</type>
            <location index="3">LT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Wolfhound" model="WLF-4WA" tons="35" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1050</battle_value>
    <cost>3589380.0000000005</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>6</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3071</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="0">
        <type>Endo-Steel</type>
        <location index="5">LT</location>
        <location index="5">RT</location>
        <location index="6">LT</location>
        <location index="6">RT</location>
        <location index="7">LT</location>
        <location index="7">RT</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
    </structure>
    <engine rating="210" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="2">
        <type>Standard Armor</type>
        <hd>9</hd>
        <ct>16</ct>
        <ctr>6</ctr>
        <lt>11</lt>
        <ltr>5</ltr>
        <rt>11</rt>
        <rtr>5</rtr>
        <la>12</la>
        <ra>12</ra>
        <ll>16</ll>
        <rl>16</rl>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Wolfhound WLF-4WA 35t, 6/9/0, Std FE, ES; 7.5T/100% Armor; 10 DHS; 1 ECM, 3 LPPC</info>
        <battleforce pv="11" wt="1" mv="6" s="2" m="2" l="2" e="0" ov="0" armor="4" internal="3" abilities="ECM, ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="TRUE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="10" techbase="0">
            <type>Double Heat Sink</type>

            <location index="0">RT</location>
            <location index="0">LT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Light PPC</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Light PPC</name>
            <type>energy</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Light PPC</name>
            <type>energy</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">Guardian ECM Suite</name>
            <type>equipment</type>
            <location index="3">RA</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional>&lt;p style=&quot;margin-top: 0&quot;&gt;:br::br:&lt;/p&gt;</additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Zeus" model="ZEU-10WB" tons="80" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>2075</battle_value>
    <cost>1.56672E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>6</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3070</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="320" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">Light Fusion Engine</engine>
    <gyro techbase="0">Extra-Light Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>38</ct>
        <ctr>12</ctr>
        <lt>26</lt>
        <ltr>8</ltr>
        <rt>26</rt>
        <rtr>8</rtr>
        <la>26</la>
        <ra>26</ra>
        <ll>34</ll>
        <rl>34</rl>
        <location index="3">HD</location>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="8">RT</location>
        <location index="9">RT</location>
        <location index="10">RT</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
        <location index="11">LT</location>
        <location index="11">RT</location>
        <location index="11">LA</location>
        <location index="11">RA</location>
    </armor>
    <baseloadout fcsa4="TRUE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Zeus ZEU-10WB 80t, 4/6/0, LFE, Std, XL Gyro; 14.0T/100% FF Armor; 17 DHS; 1 ERLL, 4 ERML, 2 HPPC</info>
        <battleforce pv="21" wt="4" mv="4" s="3" m="4" l="3" e="0" ov="2" armor="8" internal="4" abilities="ENE, SRCH, ES, SEAL, SOA" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="17" techbase="0">
            <type>Double Heat Sink</type>

            <location index="3">LA</location>
            <location index="3">RA</location>
            <location index="2">LT</location>
            <location index="5">LT</location>
            <location index="2">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Heavy PPC</name>
            <type>energy</type>
            <location index="6">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Heavy PPC</name>
            <type>energy</type>
            <location index="6">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Large Laser</name>
            <type>energy</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="10">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="7">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="5">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Zeus" model="ZEU-9S-DC" tons="80" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1612</battle_value>
    <cost>9371400.0</cost>
    <rules_level>2</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3049</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="320" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="TRUE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>26</ct>
        <ctr>8</ctr>
        <lt>25</lt>
        <ltr>6</ltr>
        <rt>25</rt>
        <rtr>6</rtr>
        <la>22</la>
        <ra>22</ra>
        <ll>24</ll>
        <rl>24</rl>
        <location index="6">LA</location>
        <location index="6">RA</location>
        <location index="7">LA</location>
        <location index="7">RA</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
        <location index="11">LA</location>
        <location index="11">RA</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Zeus ZEU-9S-DC 80t, 4/6/0, Std FE, Std; 11.0T/80% FF Armor; 16 DHS; 1 LRM15, 1 ERLL, 1 MPL, 1 ERPPC</info>
        <battleforce pv="16" wt="4" mv="4" s="3" m="3" l="3" e="0" ov="1" armor="7" internal="6" abilities="CASE, SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="16" techbase="0">
            <type>Double Heat Sink</type>

            <location index="3">LT</location>
            <location index="0">LT</location>
            <location index="3">RT</location>
            <location index="0">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER PPC</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Large Laser</name>
            <type>energy</type>
            <location index="6">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="7">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Zeus" model="ZEU-9S" tons="80" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1639</battle_value>
    <cost>8590200.0</cost>
    <rules_level>1</rules_level>
    <era>1</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="TRUE">3048</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="320" manufacturer="Unknown" lsstart="-1" rsstart="-1" techbase="2">Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>26</ct>
        <ctr>8</ctr>
        <lt>25</lt>
        <ltr>6</ltr>
        <rt>25</rt>
        <rtr>6</rtr>
        <la>22</la>
        <ra>22</ra>
        <ll>24</ll>
        <rl>24</rl>
        <location index="3">HD</location>
        <location index="6">LA</location>
        <location index="6">RA</location>
        <location index="7">LA</location>
        <location index="7">RA</location>
        <location index="8">RT</location>
        <location index="8">LA</location>
        <location index="8">RA</location>
        <location index="9">LA</location>
        <location index="9">RA</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
        <location index="11">LA</location>
        <location index="11">RA</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Zeus ZEU-9S 80t, 4/6/0, Std FE, Std; 11.0T/80% FF Armor; 17 DHS; 1 LRM15, 1 ERLL, 2 MPL, 1 ERPPC</info>
        <battleforce pv="16" wt="4" mv="4" s="3" m="4" l="3" e="0" ov="0" armor="7" internal="6" abilities="CASE, SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="17" techbase="0">
            <type>Double Heat Sink</type>

            <location index="3">LT</location>
            <location index="0">LT</location>
            <location index="6">LT</location>
            <location index="0">RT</location>
            <location index="3">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER PPC</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Large Laser</name>
            <type>energy</type>
            <location index="9">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(R) (IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="11">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="7">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Zeus" model="ZEU-9S2" tons="80" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1769</battle_value>
    <cost>1.7981200000000004E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>3</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3051</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="320" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">XL Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>26</ct>
        <ctr>8</ctr>
        <lt>25</lt>
        <ltr>6</ltr>
        <rt>25</rt>
        <rtr>6</rtr>
        <la>22</la>
        <ra>22</ra>
        <ll>24</ll>
        <rl>24</rl>
        <location index="3">HD</location>
        <location index="5">LT</location>
        <location index="6">LT</location>
        <location index="7">LT</location>
        <location index="8">LT</location>
        <location index="8">RT</location>
        <location index="9">LT</location>
        <location index="9">RT</location>
        <location index="9">RA</location>
        <location index="10">CT</location>
        <location index="10">LT</location>
        <location index="10">RT</location>
        <location index="10">RA</location>
        <location index="11">RA</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Zeus ZEU-9S2 80t, 4/6/0, XLFE, Std; 11.0T/80% FF Armor; 12 DHS; 2 LRM15, 1 ERLL, 1 GR</info>
        <battleforce pv="18" wt="4" mv="4" s="3" m="5" l="5" e="0" ov="0" armor="7" internal="4" abilities="CASE, SRCH, ES, SEAL, SOA, LRM 1/2/2, IF 2" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="12" techbase="0">
            <type>Double Heat Sink</type>

        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Large Laser</name>
            <type>energy</type>
            <location index="3">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="6">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Gauss Rifle</name>
            <type>ballistic</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="11">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">@ Gauss Rifle</name>
            <type>ammunition</type>
            <location index="10">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="4">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="3">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15</name>
            <type>ammunition</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="7">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Zeus" model="ZEU-9T" tons="80" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1835</battle_value>
    <cost>1.52382E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3062</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="320" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">Light Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>38</ct>
        <ctr>12</ctr>
        <lt>25</lt>
        <ltr>8</ltr>
        <rt>25</rt>
        <rtr>8</rtr>
        <la>25</la>
        <ra>25</ra>
        <ll>33</ll>
        <rl>33</rl>
        <location index="3">HD</location>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="5">LL</location>
        <location index="5">RL</location>
        <location index="6">LA</location>
        <location index="7">LA</location>
        <location index="8">LA</location>
        <location index="9">LA</location>
        <location index="10">LA</location>
        <location index="10">RA</location>
        <location index="11">LT</location>
        <location index="11">LA</location>
        <location index="11">RA</location>
    </armor>
    <baseloadout fcsa4="TRUE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Zeus ZEU-9T 80t, 4/6/0, LFE, Std; 13.5T/98% FF Armor; 17 DHS; 1 LRM15, 1 ERLL, 1 ERML, 1 ERPPC, 2 MPL</info>
        <battleforce pv="18" wt="4" mv="4" s="4" m="4" l="3" e="0" ov="1" armor="8" internal="4" abilities="CASE, SRCH, ES, SEAL, SOA, IF 1" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="17" techbase="0">
            <type>Double Heat Sink</type>

            <location index="2">RT</location>
            <location index="5">RT</location>
            <location index="2">LT</location>
            <location index="5">LT</location>
            <location index="3">RA</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) ER Large Laser</name>
            <type>energy</type>
            <location index="8">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="10">CT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="10">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER PPC</name>
            <type>energy</type>
            <location index="3">LA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LRM-15</name>
            <type>missile</type>
            <location index="6">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) ER Medium Laser</name>
            <type>energy</type>
            <location index="8">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="10">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LRM-15 (Artemis IV Capable)</name>
            <type>ammunition</type>
            <location index="9">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="11">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional></additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,
    `<?xml version="1.0" encoding ="UTF-8"?>
<mech name="Zeus-X" model="ZEU-9WD" tons="80" omnimech="FALSE" solaris7id="0" solaris7imageid="0" sswimage="../Images/No_Image.png">
    <ssw_savefile_version>3</ssw_savefile_version>
    <battle_value>1716</battle_value>
    <cost>1.49861E7</cost>
    <rules_level>1</rules_level>
    <era>2</era>
    <productionera>5</productionera>
    <mech_type>BattleMech</mech_type>
    <techbase manufacturer="Unknown" location="Unknown">Inner Sphere</techbase>
    <year restricted="FALSE">3067</year>
    <motive_type>Biped</motive_type>
    <structure manufacturer="Unknown" techbase="2">
        <type>Standard Structure</type>
    </structure>
    <engine rating="320" manufacturer="Unknown" lsstart="0" rsstart="0" techbase="0">Light Fusion Engine</engine>
    <gyro techbase="2">Standard Gyro</gyro>
    <cockpit>
        <type ejectionseat="FALSE" commandconsole="FALSE" fhes="FALSE">Standard Cockpit</type>
    </cockpit>
    <armor manufacturer="Unknown" techbase="0">
        <type>Light Ferro-Fibrous</type>
        <hd>9</hd>
        <ct>28</ct>
        <ctr>10</ctr>
        <lt>20</lt>
        <ltr>7</ltr>
        <rt>20</rt>
        <rtr>7</rtr>
        <la>20</la>
        <ra>20</ra>
        <ll>27</ll>
        <rl>27</rl>
        <location index="4">LL</location>
        <location index="4">RL</location>
        <location index="7">RA</location>
        <location index="8">RA</location>
        <location index="10">CT</location>
        <location index="10">RT</location>
        <location index="11">LT</location>
    </armor>
    <baseloadout fcsa4="FALSE" fcsa5="FALSE" fcsapollo="FALSE">
        <source>RS3050Uu-I</source>
        <info>Zeus-X ZEU-9WD 80t, 4/6/0, LFE, Std; 11.5T/79% LFF Armor; 15 DHS; 2 MPL, 1 HPPC, 1 LB20</info>
        <battleforce pv="17" wt="4" mv="4" s="4" m="5" l="2" e="0" ov="0" armor="7" internal="4" abilities="CASE, SRCH, ES, SEAL, SOA, FLK 1/1/0" />
        <actuators lla="TRUE" lh="FALSE" rla="TRUE" rh="FALSE"/>
        <clancase>FALSE</clancase>
        <heatsinks number="15" techbase="0">
            <type>Double Heat Sink</type>

            <location index="2">LT</location>
            <location index="5">LT</location>
            <location index="2">RT</location>
        </heatsinks>
        <equipment>
            <name manufacturer="">(IS) Heavy PPC</name>
            <type>energy</type>
            <location index="3">RA</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="10">LT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) Medium Pulse Laser</name>
            <type>energy</type>
            <location index="5">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">(IS) LB 20-X AC</name>
            <type>ballistic</type>
            <splitlocation index="3" number="9">LA</splitlocation>
            <splitlocation index="8" number="2">LT</splitlocation>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 20-X AC (Slug)</name>
            <type>ammunition</type>
            <location index="6">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 20-X AC (Cluster)</name>
            <type>ammunition</type>
            <location index="8">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="Unknown">(IS) @ LB 20-X AC (Slug)</name>
            <type>ammunition</type>
            <location index="7">RT</location>
        </equipment>
        <equipment>
            <name manufacturer="">CASE</name>
            <type>CASE</type>
            <location index="9">RT</location>
        </equipment>
    </baseloadout>
    <fluff>
        <overview></overview>
        <capabilities></capabilities>
        <battlehistory></battlehistory>
        <deployment></deployment>
        <variants></variants>
        <notables></notables>
        <additional>&lt;p style=&quot;margin-top: 0&quot;&gt;:br::br:&lt;/p&gt;</additional>
        <jumpjet_model>None</jumpjet_model>
        <commsystem>Unknown</commsystem>
        <tandtsystem>Unknown</tandtsystem>
    </fluff>
</mech>
`,

];
