import { IEquipmentItem } from "./data-interfaces";

/*
* The data here is/may be copyrighted and NOT included in the GPLv3 license.
*/
export const mechISEquipmentBallistic: IEquipmentItem[] = [
    {
        isAmmo: true,
        name: "Ammo (AC/2)",
        tag: "ammo-autocannon-standard-a",
        sort: "ammo, Autocannon/a",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 1000,
        introduced: 2300,
        extinct: 0,
        reintroduced: 0,
        battleValue: 5,
        heat: 0,
        weight: 1,
        range: {
            min: 4,
            short: 8,
            medium: 16,
            long: 24
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 45,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [
            "DB",
            "S"
        ],
        techRating: "c",
        book: "TM",
        page: 208,
        alphaStrike: {
            heat: 1,
            rangeShort: 0.132,
            rangeMedium: 0.2,
            rangeLong: 0.2,
            rangeExtreme: 0.2,
            tc: true,
            notes: []
        },
        heatAero: 0
    },
    {
        isAmmo: true,
        name: "Ammo (AC/2 Armor-Piercing)",
        tag: "ammo-autocannon-standard-a-armor-piercing",
        sort: "ammo, Autocannon/a, Armor-Piercing",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 4000,
        introduced: 3059,
        extinct: 0,
        reintroduced: 0,
        battleValue: 5,
        heat: 0,
        weight: 1,
        range: {
            min: 4,
            short: 8,
            medium: 16,
            long: 24
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 22,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [
            "DB",
            "S"
        ],
        techRating: "e",
        book: "TM",
        page: 208,
        alphaStrike: {
            heat: 1,
            rangeShort: 0.132,
            rangeMedium: 0.2,
            rangeLong: 0.2,
            rangeExtreme: 0.2,
            tc: true,
            notes: []
        },
        heatAero: 0
    },
    {
        isAmmo: true,
        name: "Ammo (AC/2 Caseless)",
        tag: "ammo-autocannon-standard-a-caseless",
        sort: "ammo, Autocannon/a, Caseless",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 1500,
        introduced: 3079,
        extinct: 0,
        reintroduced: 0,
        battleValue: 5,
        heat: 0,
        weight: 1,
        range: {
            min: 4,
            short: 8,
            medium: 16,
            long: 24
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 90,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [
            "DB",
            "S"
        ],
        techRating: "d",
        book: "TO",
        page: 352,
        alphaStrike: {
            heat: 1,
            rangeShort: 0.132,
            rangeMedium: 0.2,
            rangeLong: 0.2,
            rangeExtreme: 0.2,
            tc: true,
            notes: []
        },
        heatAero: 0
    },
    {
        isAmmo: true,
        name: "Ammo (AC/2 Flak)",
        tag: "ammo-autocannon-standard-a-flak",
        sort: "ammo, Autocannon/a, Flak",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 1500,
        introduced: 2310,
        extinct: 0,
        reintroduced: 0,
        battleValue: 5,
        heat: 0,
        weight: 1,
        range: {
            min: 4,
            short: 8,
            medium: 16,
            long: 24
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 45,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [
            "DB",
            "S"
        ],
        techRating: "b",
        book: "TO",
        page: 352,
        alphaStrike: {
            heat: 1,
            rangeShort: 0.132,
            rangeMedium: 0.2,
            rangeLong: 0.2,
            rangeExtreme: 0.2,
            tc: true,
            notes: []
        },
        heatAero: 0
    },
    {
        isAmmo: true,
        name: "Ammo (AC/2 Flechette)",
        tag: "ammo-autocannon-standard-a-flechette",
        sort: "ammo, Autocannon/a, Flechette",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 1500,
        introduced: 3055,
        extinct: 0,
        reintroduced: 0,
        battleValue: 5,
        heat: 0,
        weight: 1,
        range: {
            min: 4,
            short: 8,
            medium: 16,
            long: 24
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 45,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [
            "DB",
            "S"
        ],
        techRating: "e",
        book: "TM",
        page: 208,
        alphaStrike: {
            heat: 1,
            rangeShort: 0.132,
            rangeMedium: 0.2,
            rangeLong: 0.2,
            rangeExtreme: 0.2,
            tc: true,
            notes: []
        },
        heatAero: 0
    },
    {
        name: "Ammo (AC/2 Precision)",
        tag: "ammo-autocannon-standard-a-precision",
        sort: "ammo, Autocannon/a, Precision",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 6000,
        introduced: 3062,
        extinct: 0,
        reintroduced: 0,
        battleValue: 5,
        heat: 0,
        weight: 1,
        range: {
            min: 4,
            short: 8,
            medium: 16,
            long: 24
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 22,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [
            "DB",
            "S"
        ],
        techRating: "e",
        book: "TM",
        page: 208,
        alphaStrike: {
            heat: 1,
            rangeShort: 0.132,
            rangeMedium: 0.2,
            rangeLong: 0.2,
            rangeExtreme: 0.2,
            tc: true,
            notes: []
        },
        heatAero: 0,
        isEquipment: false,
        isAmmo: true
    },
    {
        isAmmo: true,
        name: "Ammo (AC/2 Tracer)",
        tag: "ammo-autocannon-standard-a-tracer",
        sort: "ammo, Autocannon/a, Tracer",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 1500,
        introduced: 2300,
        extinct: 0,
        reintroduced: 0,
        battleValue: 5,
        heat: 0,
        weight: 1,
        range: {
            min: 4,
            short: 8,
            medium: 16,
            long: 24
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 45,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [
            "DB",
            "S"
        ],
        techRating: "b",
        book: "TO",
        page: 353,
        alphaStrike: {
            heat: 1,
            rangeShort: 0.132,
            rangeMedium: 0.2,
            rangeLong: 0.2,
            rangeExtreme: 0.2,
            tc: true,
            notes: []
        },
        heatAero: 0
    },
    {
        isAmmo: true,
        name: "Ammo (AC/5)",
        tag: "ammo-autocannon-standard-b",
        sort: "ammo, Autocannon/b",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 4500,
        introduced: 2250,
        extinct: 0,
        reintroduced: 0,
        battleValue: 9,
        heat: 0,
        weight: 1,
        range: {
            min: 3,
            short: 6,
            medium: 12,
            long: 18
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 20,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [
            "DB",
            "S"
        ],
        techRating: "c",
        book: "TM",
        page: 208,
        alphaStrike: {
            heat: 1,
            rangeShort: 0.375,
            rangeMedium: 0.5,
            rangeLong: 0.5,
            rangeExtreme: 0,
            tc: true,
            notes: []
        },
        heatAero: 0
    },
    {
        isAmmo: true,
        name: "Ammo (AC/5 Armor-Piercing)",
        tag: "ammo-autocannon-standard-b-armor-piercing",
        sort: "ammo, Autocannon/b, Armor-Piercing",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 18000,
        introduced: 3059,
        extinct: 0,
        reintroduced: 0,
        battleValue: 9,
        heat: 0,
        weight: 1,
        range: {
            min: 3,
            short: 6,
            medium: 12,
            long: 18
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 10,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [
            "DB",
            "S"
        ],
        techRating: "e",
        book: "TM",
        page: 208,
        alphaStrike: {
            heat: 1,
            rangeShort: 0.375,
            rangeMedium: 0.5,
            rangeLong: 0.5,
            rangeExtreme: 0,
            tc: true,
            notes: []
        },
        heatAero: 0
    },
    {
        isAmmo: true,
        name: "Ammo (AC/5 Caseless)",
        tag: "ammo-autocannon-standard-b-caseless",
        sort: "ammo, Autocannon/b, Caseless",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 6750,
        introduced: 3079,
        extinct: 0,
        reintroduced: 0,
        battleValue: 9,
        heat: 0,
        weight: 1,
        range: {
            min: 3,
            short: 6,
            medium: 12,
            long: 18
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 90,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [
            "DB",
            "S"
        ],
        techRating: "d",
        book: "TO",
        page: 352,
        alphaStrike: {
            heat: 1,
            rangeShort: 0.375,
            rangeMedium: 0.5,
            rangeLong: 0.5,
            rangeExtreme: 0,
            tc: true,
            notes: []
        },
        heatAero: 0
    },
    {
        isAmmo: true,
        name: "Ammo (AC/5 Flak)",
        tag: "ammo-autocannon-standard-b-flak",
        sort: "ammo, Autocannon/b, Flak",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 6750,
        introduced: 2310,
        extinct: 0,
        reintroduced: 0,
        battleValue: 9,
        heat: 0,
        weight: 1,
        range: {
            min: 3,
            short: 6,
            medium: 12,
            long: 18
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 20,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [
            "DB",
            "S"
        ],
        techRating: "b",
        book: "TO",
        page: 352,
        alphaStrike: {
            heat: 1,
            rangeShort: 0.375,
            rangeMedium: 0.5,
            rangeLong: 0.5,
            rangeExtreme: 0,
            tc: true,
            notes: []
        },
        heatAero: 0
    },
    {
        isAmmo: true,
        name: "Ammo (AC/5 Flechette)",
        tag: "ammo-autocannon-standard-b-flechette",
        sort: "ammo, Autocannon/b, Flechette",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 6750,
        introduced: 3055,
        extinct: 0,
        reintroduced: 0,
        battleValue: 9,
        heat: 0,
        weight: 1,
        range: {
            min: 3,
            short: 6,
            medium: 12,
            long: 18
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 20,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [
            "DB",
            "S"
        ],
        techRating: "e",
        book: "TM",
        page: 208,
        alphaStrike: {
            heat: 1,
            rangeShort: 0.375,
            rangeMedium: 0.5,
            rangeLong: 0.5,
            rangeExtreme: 0,
            tc: true,
            notes: []
        },
        heatAero: 0
    },
    {
        isAmmo: true,
        name: "Ammo (AC/5 Precision)",
        tag: "ammo-autocannon-standard-b-precision",
        sort: "ammo, Autocannon/b, Precision",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 27000,
        introduced: 3062,
        extinct: 0,
        reintroduced: 0,
        battleValue: 9,
        heat: 0,
        weight: 1,
        range: {
            min: 3,
            short: 6,
            medium: 12,
            long: 18
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 10,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [
            "DB",
            "S"
        ],
        techRating: "e",
        book: "TM",
        page: 208,
        alphaStrike: {
            heat: 1,
            rangeShort: 0.375,
            rangeMedium: 0.5,
            rangeLong: 0.5,
            rangeExtreme: 0,
            tc: true,
            notes: []
        },
        heatAero: 0
    },
    {
        isAmmo: true,
        name: "Ammo (AC/5 Tracer)",
        tag: "ammo-autocannon-standard-b-tracer",
        sort: "ammo, Autocannon/b, Tracer",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 6750,
        introduced: 2300,
        extinct: 0,
        reintroduced: 0,
        battleValue: 9,
        heat: 0,
        weight: 1,
        range: {
            min: 3,
            short: 6,
            medium: 12,
            long: 18
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 20,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [
            "DB",
            "S"
        ],
        techRating: "b",
        book: "TO",
        page: 353,
        alphaStrike: {
            heat: 1,
            rangeShort: 0.375,
            rangeMedium: 0.5,
            rangeLong: 0.5,
            rangeExtreme: 0,
            tc: true,
            notes: []
        },
        heatAero: 0
    },
    {
        isAmmo: true,
        name: "Ammo (AC/10)",
        tag: "ammo-autocannon-standard-c",
        sort: "ammo, Autocannon/c",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 6000,
        introduced: 2460,
        extinct: 0,
        reintroduced: 0,
        battleValue: 15,
        heat: 0,
        weight: 1,
        range: {
            min: 0,
            short: 5,
            medium: 10,
            long: 15
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 10,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [
            "DB",
            "S"
        ],
        techRating: "c",
        book: "TM",
        page: 208,
        alphaStrike: {
            heat: 3,
            rangeShort: 1,
            rangeMedium: 1,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: true,
            notes: []
        },
        heatAero: 0
    },
    {
        isAmmo: true,
        name: "Ammo (AC/10 Armor-Piercing)",
        tag: "ammo-autocannon-standard-c-armor-piercing",
        sort: "ammo, Autocannon/c, Armor-Piercing",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 24000,
        introduced: 3059,
        extinct: 0,
        reintroduced: 0,
        battleValue: 15,
        heat: 0,
        weight: 1,
        range: {
            min: 0,
            short: 5,
            medium: 10,
            long: 15
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 5,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [
            "DB",
            "S"
        ],
        techRating: "e",
        book: "TM",
        page: 208,
        alphaStrike: {
            heat: 3,
            rangeShort: 1,
            rangeMedium: 1,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: true,
            notes: []
        },
        heatAero: 0
    },
    {
        isAmmo: true,
        name: "Ammo (AC/10 Caseless)",
        tag: "ammo-autocannon-standard-c-caseless",
        sort: "ammo, Autocannon/c, Caseless",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 9000,
        introduced: 3079,
        extinct: 0,
        reintroduced: 0,
        battleValue: 15,
        heat: 0,
        weight: 1,
        range: {
            min: 0,
            short: 5,
            medium: 10,
            long: 15
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 90,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [
            "DB",
            "S"
        ],
        techRating: "d",
        book: "TO",
        page: 352,
        alphaStrike: {
            heat: 3,
            rangeShort: 1,
            rangeMedium: 1,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: true,
            notes: []
        },
        heatAero: 0
    },
    {
        isAmmo: true,
        name: "Ammo (AC/10 Flak)",
        tag: "ammo-autocannon-standard-c-flak",
        sort: "ammo, Autocannon/c, Flak",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 9000,
        introduced: 2460,
        extinct: 0,
        reintroduced: 0,
        battleValue: 15,
        heat: 0,
        weight: 1,
        range: {
            min: 0,
            short: 5,
            medium: 10,
            long: 15
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 10,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [
            "DB",
            "S"
        ],
        techRating: "b",
        book: "TO",
        page: 352,
        alphaStrike: {
            heat: 3,
            rangeShort: 1,
            rangeMedium: 1,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: true,
            notes: []
        },
        heatAero: 0
    },
    {
        isAmmo: true,
        name: "Ammo (AC/10 Flechette)",
        tag: "ammo-autocannon-standard-c-flechette",
        sort: "ammo, Autocannon/c, Flechette",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 9000,
        introduced: 3055,
        extinct: 0,
        reintroduced: 0,
        battleValue: 15,
        heat: 0,
        weight: 1,
        range: {
            min: 0,
            short: 5,
            medium: 10,
            long: 15
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 10,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [
            "DB",
            "S"
        ],
        techRating: "e",
        book: "TM",
        page: 208,
        alphaStrike: {
            heat: 3,
            rangeShort: 1,
            rangeMedium: 1,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: true,
            notes: []
        },
        heatAero: 0
    },
    {
        isAmmo: true,
        name: "Ammo (AC/10 Precision)",
        tag: "ammo-autocannon-standard-c-precision",
        sort: "ammo, Autocannon/c, Precision",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 30000,
        introduced: 3062,
        extinct: 0,
        reintroduced: 0,
        battleValue: 15,
        heat: 0,
        weight: 1,
        range: {
            min: 0,
            short: 5,
            medium: 10,
            long: 15
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 5,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [
            "DB",
            "S"
        ],
        techRating: "e",
        book: "TM",
        page: 208,
        alphaStrike: {
            heat: 3,
            rangeShort: 1,
            rangeMedium: 1,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: true,
            notes: []
        },
        heatAero: 0
    },
    {
        isAmmo: true,
        name: "Ammo (AC/10 Tracer)",
        tag: "ammo-autocannon-standard-c-tracer",
        sort: "ammo, Autocannon/c, Tracer",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 9000,
        introduced: 2460,
        extinct: 0,
        reintroduced: 0,
        battleValue: 15,
        heat: 0,
        weight: 1,
        range: {
            min: 0,
            short: 5,
            medium: 10,
            long: 15
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 10,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [
            "DB",
            "S"
        ],
        techRating: "b",
        book: "TO",
        page: 353,
        alphaStrike: {
            heat: 3,
            rangeShort: 1,
            rangeMedium: 1,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: true,
            notes: []
        },
        heatAero: 0
    },
    {
        isAmmo: true,
        name: "Ammo (AC/20)",
        tag: "ammo-autocannon-standard-d",
        sort: "ammo, Autocannon/d",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 10000,
        introduced: 2500,
        extinct: 0,
        reintroduced: 0,
        battleValue: 22,
        heat: 0,
        weight: 1,
        range: {
            min: 0,
            short: 3,
            medium: 6,
            long: 9
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 5,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [
            "DB",
            "S"
        ],
        techRating: "c",
        book: "TM",
        page: 208,
        alphaStrike: {
            heat: 1,
            rangeShort: 1,
            rangeMedium: 1,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: true,
            notes: []
        },
        heatAero: 0
    },
    {
        isAmmo: true,
        name: "Ammo (AC/20 Armor-Piercing)",
        tag: "ammo-autocannon-standard-d-armor-piercing",
        sort: "ammo, Autocannon/d, Armor-Piercing",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 40000,
        introduced: 3059,
        extinct: 0,
        reintroduced: 0,
        battleValue: 22,
        heat: 0,
        weight: 1,
        range: {
            min: 0,
            short: 3,
            medium: 6,
            long: 9
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 2,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [
            "DB",
            "S"
        ],
        techRating: "e",
        book: "TM",
        page: 208,
        alphaStrike: {
            heat: 7,
            rangeShort: 2,
            rangeMedium: 2,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: true,
            notes: []
        },
        heatAero: 0
    },
    {
        isAmmo: true,
        name: "Ammo (AC/20 Caseless)",
        tag: "ammo-autocannon-standard-d-caseless",
        sort: "ammo, Autocannon/d, Caseless",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 15000,
        introduced: 3079,
        extinct: 0,
        reintroduced: 0,
        battleValue: 22,
        heat: 0,
        weight: 1,
        range: {
            min: 0,
            short: 3,
            medium: 6,
            long: 9
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 90,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [
            "DB",
            "S"
        ],
        techRating: "d",
        book: "TO",
        page: 352,
        alphaStrike: {
            heat: 7,
            rangeShort: 2,
            rangeMedium: 2,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: true,
            notes: []
        },
        heatAero: 0
    },
    {
        isAmmo: true,
        name: "Ammo (AC/20 Flak)",
        tag: "ammo-autocannon-standard-d-flak",
        sort: "ammo, Autocannon/d, Flak",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 15000,
        introduced: 2500,
        extinct: 0,
        reintroduced: 0,
        battleValue: 22,
        heat: 0,
        weight: 1,
        range: {
            min: 0,
            short: 3,
            medium: 6,
            long: 9
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 5,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [
            "DB",
            "S"
        ],
        techRating: "b",
        book: "TO",
        page: 352,
        alphaStrike: {
            heat: 7,
            rangeShort: 2,
            rangeMedium: 2,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: true,
            notes: []
        },
        heatAero: 0
    },
    {
        isAmmo: true,
        name: "Ammo (AC/20 Flechette)",
        tag: "ammo-autocannon-standard-d-flechette",
        sort: "ammo, Autocannon/d, Flechette",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 15000,
        introduced: 3055,
        extinct: 0,
        reintroduced: 0,
        battleValue: 22,
        heat: 0,
        weight: 1,
        range: {
            min: 0,
            short: 3,
            medium: 6,
            long: 9
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 5,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [
            "DB",
            "S"
        ],
        techRating: "e",
        book: "TM",
        page: 208,
        alphaStrike: {
            heat: 7,
            rangeShort: 2,
            rangeMedium: 2,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: true,
            notes: []
        },
        heatAero: 0
    },
    {
        isAmmo: true,
        name: "Ammo (AC/20 Precision)",
        tag: "ammo-autocannon-standard-d-precision",
        sort: "ammo, Autocannon/d, Precision",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 60000,
        introduced: 3062,
        extinct: 0,
        reintroduced: 0,
        battleValue: 22,
        heat: 0,
        weight: 1,
        range: {
            min: 0,
            short: 3,
            medium: 6,
            long: 9
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 2,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [
            "DB",
            "S"
        ],
        techRating: "e",
        book: "TM",
        page: 208,
        alphaStrike: {
            heat: 7,
            rangeShort: 2,
            rangeMedium: 2,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: true,
            notes: []
        },
        heatAero: 0
    },
    {
        isAmmo: true,
        name: "Ammo (AC/20 Tracer)",
        tag: "ammo-autocannon-standard-d-tracer",
        sort: "ammo, Autocannon/d, Tracer",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 15000,
        introduced: 2500,
        extinct: 0,
        reintroduced: 0,
        battleValue: 22,
        heat: 0,
        weight: 1,
        range: {
            min: 0,
            short: 3,
            medium: 6,
            long: 9
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 5,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [
            "DB",
            "S"
        ],
        techRating: "b",
        book: "TO",
        page: 353,
        alphaStrike: {
            heat: 7,
            rangeShort: 2,
            rangeMedium: 2,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: true,
            notes: []
        },
        heatAero: 0
    },
    {
        isAmmo: true,
        name: "Ammo (Gauss)",
        tag: "ammo-standard-gauss-rifle",
        sort: "ammo, gauss rifle, b",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 20000,
        introduced: 2590,
        extinct: 2865,
        reintroduced: 3040,
        battleValue: 40,
        heat: 0,
        weight: 1,
        range: {
            min: 2,
            short: 7,
            medium: 15,
            long: 22
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 8,
        minAmmoTons: 1,
        explosive: false,
        weaponType: [
            "DB"
        ],
        techRating: "e",
        book: "TM",
        page: 219,
        alphaStrike: {
            heat: 0,
            rangeShort: 0,
            rangeMedium: 0,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: false,
            notes: []
        },
        heatAero: 0
    },
    {
        isAmmo: true,
        name: "Ammo (IHGR)",
        tag: "ammo-gauss-rifle-heavy-improved",
        sort: "ammo, gauss rifle, d",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 20000,
        introduced: 3081,
        extinct: 0,
        reintroduced: 0,
        battleValue: 48,
        heat: 0,
        weight: 1,
        range: {
            min: 3,
            short: 6,
            medium: 12,
            long: 19
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 4,
        minAmmoTons: 1,
        explosive: false,
        weaponType: [
            "DB"
        ],
        techRating: "e",
        book: "TO",
        page: 313,
        alphaStrike: {
            heat: 0,
            rangeShort: 0,
            rangeMedium: 0,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: false,
            notes: []
        },
        heatAero: 0
    },
    {
        isAmmo: true,
        name: "Ammo (SB Gauss)",
        tag: "ammo-silver-bullet-gauss-rifle",
        sort: "ammo, gauss rifle, e",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 25000,
        introduced: 3081,
        extinct: 0,
        reintroduced: 0,
        battleValue: 25,
        heat: 0,
        weight: 1,
        range: {
            min: 2,
            short: 7,
            medium: 15,
            long: 22
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 8,
        minAmmoTons: 1,
        explosive: false,
        weaponType: [
            "DB",
            "C",
            "F"
        ],
        techRating: "e",
        book: "TO",
        page: 314,
        alphaStrike: {
            heat: 0,
            rangeShort: 0,
            rangeMedium: 0,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: false,
            notes: []
        },
        heatAero: 0
    },
    {
        isAmmo: true,
        name: "Ammo (MagShot)",
        tag: "ammo-gauss-rifle-magshot",
        sort: "ammo, gauss rifle, f",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 1000,
        introduced: 3072,
        extinct: 0,
        reintroduced: 0,
        battleValue: 2,
        heat: 0,
        weight: 1,
        range: {
            min: 0,
            short: 3,
            medium: 6,
            long: 9
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 50,
        minAmmoTons: 1,
        explosive: false,
        weaponType: [
            "DB"
        ],
        techRating: "e",
        book: "TO",
        page: 314,
        alphaStrike: {
            heat: 0,
            rangeShort: 0,
            rangeMedium: 0,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: false,
            notes: []
        },
        heatAero: 0
    },
    {
        isAmmo: true,
        name: "Ammo (Heavy Gauss)",
        tag: "ammo-gauss-rifle-heavy",
        sort: "ammo, gauss rifle, heavy, c",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 20000,
        introduced: 3061,
        extinct: 0,
        reintroduced: 0,
        battleValue: 43,
        heat: 0,
        weight: 1,
        range: {
            min: 4,
            short: 6,
            medium: 13,
            long: 20
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 4,
        minAmmoTons: 1,
        explosive: false,
        weaponType: [
            "DB"
        ],
        techRating: "e",
        book: "TM",
        page: 218,
        alphaStrike: {
            heat: 0,
            rangeShort: 0,
            rangeMedium: 0,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: false,
            notes: []
        },
        heatAero: 0,
        alternateName: "Ammo (Heavy Gauss Rifle)"
    },
    {
        isAmmo: true,
        name: "Ammo (Light Gauss)",
        tag: "ammo-gauss-rifle-light",
        sort: "ammo, gauss rifle, light, a",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 20000,
        introduced: 3056,
        extinct: 0,
        reintroduced: 0,
        battleValue: 20,
        heat: 0,
        weight: 1,
        range: {
            min: 3,
            short: 8,
            medium: 17,
            long: 25
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 16,
        minAmmoTons: 1,
        explosive: false,
        weaponType: [
            "DB"
        ],
        techRating: "e",
        book: "TM",
        page: 219,
        alphaStrike: {
            heat: 0,
            rangeShort: 0,
            rangeMedium: 0,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: false,
            notes: []
        },
        heatAero: 0,
        alternateName: "Ammo (Light Gauss Rifle)"
    },
    {
        isAmmo: true,
        name: "Ammo (LB 2-X)",
        alternateName: "Ammo (LB 2-X AC (Slug))",
        tag: "ammo-autocannon-lb-x-a",
        sort: "ammo, LB a-X",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 2000,
        introduced: 3058,
        extinct: 0,
        reintroduced: 0,
        battleValue: 5,
        heat: 0,
        weight: 1,
        range: {
            min: 4,
            short: 9,
            medium: 18,
            long: 27
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 45,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [
            "DB",
            "S"
        ],
        techRating: "e",
        book: "TM",
        page: 207,
        alphaStrike: {
            heat: 0,
            rangeShort: 0,
            rangeMedium: 0,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: false,
            notes: []
        },
        heatAero: 0
    },
    {
        isAmmo: true,
        name: "Ammo (LB 2-X) Cluster",
        alternateName: "Ammo (LB 2-X AC (Cluster))",
        tag: "ammo-autocannon-lb-x-a-cluster",
        sort: "ammo, LB a-X, Cluster",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 3300,
        introduced: 3058,
        extinct: 0,
        reintroduced: 0,
        battleValue: 5,
        heat: 0,
        weight: 1,
        range: {
            min: 4,
            short: 9,
            medium: 18,
            long: 27
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 45,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [
            "DB",
            "C",
            "S",
            "F"
        ],
        techRating: "e",
        book: "TM",
        page: 207,
        alphaStrike: {
            heat: 0,
            rangeShort: 0,
            rangeMedium: 0,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: false,
            notes: []
        },
        heatAero: 0
    },
    {
        isAmmo: true,
        name: "Ammo (LB 5-X)",
        alternateName: "Ammo (LB 5-X AC (Slug))",
        tag: "ammo-autocannon-lb-x-b",
        sort: "ammo, LB b-X",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 9000,
        introduced: 3058,
        extinct: 0,
        reintroduced: 0,
        battleValue: 5,
        heat: 0,
        weight: 1,
        range: {
            min: 3,
            short: 7,
            medium: 14,
            long: 21
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 20,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [
            "DB",
            "S"
        ],
        techRating: "e",
        book: "TM",
        page: 207,
        alphaStrike: {
            heat: 0,
            rangeShort: 0,
            rangeMedium: 0,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: false,
            notes: []
        },
        heatAero: 0
    },
    {
        isAmmo: true,
        name: "Ammo (LB 5-X) Cluster",
        alternateName: "Ammo (LB 5-X AC (Cluster))",
        tag: "ammo-autocannon-lb-x-b-cluster",
        sort: "ammo, LB b-X, Cluster",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 15000,
        introduced: 3058,
        extinct: 0,
        reintroduced: 0,
        battleValue: 5,
        heat: 0,
        weight: 1,
        range: {
            min: 3,
            short: 7,
            medium: 14,
            long: 21
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 20,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [
            "DB",
            "C",
            "S",
            "F"
        ],
        techRating: "e",
        book: "TM",
        page: 207,
        alphaStrike: {
            heat: 0,
            rangeShort: 0,
            rangeMedium: 0,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: false,
            notes: []
        },
        heatAero: 0
    },
    {
        isAmmo: true,
        name: "Ammo (LB 10-X)",
        alternateName: "Ammo (LB 10-X AC (Slug))",
        tag: "ammo-autocannon-lb-x-c",
        sort: "ammo, LB c-X",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 12000,
        introduced: 2595,
        extinct: 2840,
        reintroduced: 3035,
        battleValue: 19,
        heat: 0,
        weight: 1,
        range: {
            min: 0,
            short: 6,
            medium: 12,
            long: 18
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 10,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [
            "DB",
            "S"
        ],
        techRating: "e",
        book: "TM",
        page: 207,
        alphaStrike: {
            heat: 0,
            rangeShort: 0,
            rangeMedium: 0,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: false,
            notes: []
        },
        heatAero: 0
    },
    {
        isAmmo: true,
        name: "Ammo (LB 10-X) Cluster",
        alternateName: "Ammo (LB 10-X AC (Cluster))",
        tag: "ammo-autocannon-lb-x-c-cluster",
        sort: "ammo, LB c-X, Cluster",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 20000,
        introduced: 2595,
        extinct: 2840,
        reintroduced: 3035,
        battleValue: 19,
        heat: 0,
        weight: 1,
        range: {
            min: 0,
            short: 6,
            medium: 12,
            long: 18
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 10,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [
            "DB",
            "C",
            "S",
            "F"
        ],
        techRating: "e",
        book: "TM",
        page: 207,
        alphaStrike: {
            heat: 0,
            rangeShort: 0,
            rangeMedium: 0,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: false,
            notes: []
        },
        heatAero: 0
    },
    {
        isAmmo: true,
        name: "Ammo (LB 20-X)",
        alternateName: "Ammo (LB 20-X AC (Slug))",
        tag: "ammo-autocannon-lb-x-d",
        sort: "ammo, LB d-X",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 20000,
        introduced: 3058,
        extinct: 0,
        reintroduced: 0,
        battleValue: 30,
        heat: 0,
        weight: 1,
        range: {
            min: 0,
            short: 4,
            medium: 8,
            long: 12
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 20,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [
            "DB",
            "S"
        ],
        techRating: "e",
        book: "TM",
        page: 207,
        alphaStrike: {
            heat: 0,
            rangeShort: 0,
            rangeMedium: 0,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: false,
            notes: []
        },
        heatAero: 0
    },
    {
        isAmmo: true,
        name: "Ammo (LB 20-X) Cluster",
        alternateName: "Ammo (LB 20-X AC (Cluster))",
        tag: "ammo-autocannon-lb-x-d-cluster",
        sort: "ammo, LB d-X, Cluster",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 34000,
        introduced: 3058,
        extinct: 0,
        reintroduced: 0,
        battleValue: 30,
        heat: 0,
        weight: 1,
        range: {
            min: 0,
            short: 4,
            medium: 8,
            long: 12
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 20,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [
            "DB",
            "C",
            "S",
            "F"
        ],
        techRating: "e",
        book: "TM",
        page: 207,
        alphaStrike: {
            heat: 0,
            rangeShort: 0,
            rangeMedium: 0,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: false,
            notes: []
        },
        heatAero: 0
    },
    {
        name: "Ammo (Light Autocannon/2)",
        tag: "ammo-autocannon-light-2",
        sort: "ammo, Light Autocannon/2",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 1000,
        introduced: 3068,
        extinct: 0,
        reintroduced: 0,
        battleValue: 4,
        heat: 0,
        weight: 1,
        range: {
            min: 0,
            short: 6,
            medium: 12,
            long: 18
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 45,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [
            "DB",
            "S"
        ],
        techRating: "c",
        book: "TM",
        page: 208,
        alphaStrike: {
            heat: 1,
            rangeShort: 0.2,
            rangeMedium: 0.2,
            rangeLong: 0.2,
            rangeExtreme: 0,
            tc: true,
            notes: []
        },
        heatAero: 0,
        isEquipment: false,
        isAmmo: true,
        alternateName: "Ammo (Light AC/2)"
    },
    {
        isAmmo: true,
        name: "Ammo (Light Autocannon/2 Armor-Piercing)",
        tag: "ammo-autocannon-light-2-armor-piercing",
        sort: "ammo, Light Autocannon/2, Armor-Piercing",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 4000,
        introduced: 3068,
        extinct: 0,
        reintroduced: 0,
        battleValue: 4,
        heat: 0,
        weight: 1,
        range: {
            min: 0,
            short: 6,
            medium: 12,
            long: 18
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 22,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [
            "DB",
            "S"
        ],
        techRating: "e",
        book: "TM",
        page: 208,
        alphaStrike: {
            heat: 1,
            rangeShort: 0.2,
            rangeMedium: 0.2,
            rangeLong: 0.2,
            rangeExtreme: 0,
            tc: true,
            notes: []
        },
        heatAero: 0,
        alternateName: "Ammo (Light AC/2 Armor-Piercing)"
    },
    {
        isAmmo: true,
        name: "Ammo (Light Autocannon/2 Caseless)",
        tag: "ammo-autocannon-light-2-caseless",
        sort: "ammo, Light Autocannon/2, Caseless",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 1500,
        introduced: 3079,
        extinct: 0,
        reintroduced: 0,
        battleValue: 4,
        heat: 0,
        weight: 1,
        range: {
            min: 0,
            short: 6,
            medium: 12,
            long: 18
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 90,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [
            "DB",
            "S"
        ],
        techRating: "d",
        book: "TO",
        page: 352,
        alphaStrike: {
            heat: 1,
            rangeShort: 0.2,
            rangeMedium: 0.2,
            rangeLong: 0.2,
            rangeExtreme: 0,
            tc: true,
            notes: []
        },
        heatAero: 0,
        alternateName: "Ammo (Light AC/2 Caseless)"
    },
    {
        isAmmo: true,
        name: "Ammo (Light Autocannon/2 Flak)",
        tag: "ammo-autocannon-light-2-flak",
        sort: "ammo, Light Autocannon/2, Flak",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 1500,
        introduced: 3068,
        extinct: 0,
        reintroduced: 0,
        battleValue: 4,
        heat: 0,
        weight: 1,
        range: {
            min: 0,
            short: 6,
            medium: 12,
            long: 18
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 45,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [
            "DB",
            "S"
        ],
        techRating: "b",
        book: "TO",
        page: 352,
        alphaStrike: {
            heat: 1,
            rangeShort: 0.2,
            rangeMedium: 0.2,
            rangeLong: 0.2,
            rangeExtreme: 0,
            tc: true,
            notes: []
        },
        heatAero: 0,
        alternateName: "Ammo (Light AC/2 Flak)"
    },
    {
        isAmmo: true,
        name: "Ammo (Light Autocannon/2 Flechette)",
        tag: "ammo-autocannon-light-2-flechette",
        sort: "ammo, Light Autocannon/2, Flechette",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 1500,
        introduced: 3068,
        extinct: 0,
        reintroduced: 0,
        battleValue: 4,
        heat: 0,
        weight: 1,
        range: {
            min: 0,
            short: 6,
            medium: 12,
            long: 18
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 45,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [
            "DB",
            "S"
        ],
        techRating: "e",
        book: "TM",
        page: 208,
        alphaStrike: {
            heat: 1,
            rangeShort: 0.2,
            rangeMedium: 0.2,
            rangeLong: 0.2,
            rangeExtreme: 0,
            tc: true,
            notes: []
        },
        heatAero: 0,
        alternateName: "Ammo (Light AC/2 Flechette)"
    },
    {
        isAmmo: true,
        name: "Ammo (Light Autocannon/2 Precision)",
        tag: "ammo-autocannon-light-2-precision",
        sort: "ammo, Light Autocannon/2, Precision",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 6000,
        introduced: 3068,
        extinct: 0,
        reintroduced: 0,
        battleValue: 4,
        heat: 0,
        weight: 1,
        range: {
            min: 0,
            short: 6,
            medium: 12,
            long: 18
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 22,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [
            "DB",
            "S"
        ],
        techRating: "e",
        book: "TM",
        page: 208,
        alphaStrike: {
            heat: 1,
            rangeShort: 0.2,
            rangeMedium: 0.2,
            rangeLong: 0.2,
            rangeExtreme: 0,
            tc: true,
            notes: []
        },
        heatAero: 0,
        alternateName: "Ammo (Light AC/2 Precision)"
    },
    {
        isAmmo: true,
        name: "Ammo (Light Autocannon/2 Tracer)",
        tag: "ammo-autocannon-light-2-tracer",
        sort: "ammo, Light Autocannon/2, Tracer",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 1500,
        introduced: 3068,
        extinct: 0,
        reintroduced: 0,
        battleValue: 4,
        heat: 0,
        weight: 1,
        range: {
            min: 0,
            short: 6,
            medium: 12,
            long: 18
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 45,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [
            "DB",
            "S"
        ],
        techRating: "b",
        book: "TO",
        page: 353,
        alphaStrike: {
            heat: 1,
            rangeShort: 0.2,
            rangeMedium: 0.2,
            rangeLong: 0.2,
            rangeExtreme: 0,
            tc: true,
            notes: []
        },
        heatAero: 0,
        alternateName: "Ammo (Light AC/2 Tracer)"
    },
    {
        isAmmo: true,
        name: "Ammo (Light Autocannon/5)",
        tag: "ammo-autocannon-light-5",
        sort: "ammo, Light Autocannon/5",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 4500,
        introduced: 3068,
        extinct: 0,
        reintroduced: 0,
        battleValue: 8,
        heat: 0,
        weight: 1,
        range: {
            min: 0,
            short: 5,
            medium: 10,
            long: 15
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 20,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [
            "DB",
            "S"
        ],
        techRating: "c",
        book: "TM",
        page: 208,
        alphaStrike: {
            heat: 1,
            rangeShort: 0.5,
            rangeMedium: 0.5,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: true,
            notes: []
        },
        heatAero: 0,
        alternateName: "Ammo (Light AC/5)"
    },
    {
        isAmmo: true,
        name: "Ammo (Light Autocannon/5 Armor-Piercing)",
        tag: "ammo-autocannon-light-5-armor-piercing",
        sort: "ammo, Light Autocannon/5, Armor-Piercing",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 18000,
        introduced: 3068,
        extinct: 0,
        reintroduced: 0,
        battleValue: 8,
        heat: 0,
        weight: 1,
        range: {
            min: 0,
            short: 5,
            medium: 10,
            long: 15
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 10,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [
            "DB",
            "S"
        ],
        techRating: "e",
        book: "TM",
        page: 208,
        alphaStrike: {
            heat: 1,
            rangeShort: 0.5,
            rangeMedium: 0.5,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: true,
            notes: []
        },
        heatAero: 0
    },
    {
        isAmmo: true,
        name: "Ammo (Light Autocannon/5 Caseless)",
        tag: "ammo-autocannon-light-5-caseless",
        sort: "ammo, Light Autocannon/5, Caseless",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 6750,
        introduced: 3079,
        extinct: 0,
        reintroduced: 0,
        battleValue: 8,
        heat: 0,
        weight: 1,
        range: {
            min: 0,
            short: 5,
            medium: 10,
            long: 15
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 90,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [
            "DB",
            "S"
        ],
        techRating: "d",
        book: "TO",
        page: 352,
        alphaStrike: {
            heat: 1,
            rangeShort: 0.5,
            rangeMedium: 0.5,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: true,
            notes: []
        },
        heatAero: 0,
        alternateName: "Ammo (Light AC/5 Caseless)"
    },
    {
        isAmmo: true,
        name: "Ammo (Light Autocannon/5 Flak)",
        tag: "ammo-autocannon-light-5-flak",
        sort: "ammo, Light Autocannon/5, Flak",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 6750,
        introduced: 3068,
        extinct: 0,
        reintroduced: 0,
        battleValue: 8,
        heat: 0,
        weight: 1,
        range: {
            min: 0,
            short: 5,
            medium: 10,
            long: 15
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 20,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [
            "DB",
            "S"
        ],
        techRating: "b",
        book: "TO",
        page: 352,
        alphaStrike: {
            heat: 1,
            rangeShort: 0.5,
            rangeMedium: 0.5,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: true,
            notes: []
        },
        heatAero: 0,
        alternateName: "Ammo (Light AC/5 Flak)"
    },
    {
        isAmmo: true,
        name: "Ammo (Light Autocannon/5 Flechette)",
        tag: "ammo-autocannon-light-5-flechette",
        sort: "ammo, Light Autocannon/5, Flechette",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 6750,
        introduced: 3068,
        extinct: 0,
        reintroduced: 0,
        battleValue: 8,
        heat: 0,
        weight: 1,
        range: {
            min: 0,
            short: 5,
            medium: 10,
            long: 15
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 20,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [
            "DB",
            "S"
        ],
        techRating: "e",
        book: "TM",
        page: 208,
        alphaStrike: {
            heat: 1,
            rangeShort: 0.5,
            rangeMedium: 0.5,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: true,
            notes: []
        },
        heatAero: 0,
        alternateName: "Ammo (Light AC/5 Flechette)"
    },
    {
        isAmmo: true,
        name: "Ammo (Light Autocannon/5 Precision)",
        tag: "ammo-autocannon-light-5-precision",
        sort: "ammo, Light Autocannon/5, Precision",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 27000,
        introduced: 3068,
        extinct: 0,
        reintroduced: 0,
        battleValue: 8,
        heat: 0,
        weight: 1,
        range: {
            min: 0,
            short: 5,
            medium: 10,
            long: 15
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 10,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [
            "DB",
            "S"
        ],
        techRating: "e",
        book: "TM",
        page: 208,
        alphaStrike: {
            heat: 1,
            rangeShort: 0.5,
            rangeMedium: 0.5,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: true,
            notes: []
        },
        heatAero: 0,
        alternateName: "Ammo (Light AC/5 Precision)"
    },
    {
        isAmmo: true,
        name: "Ammo (Light Autocannon/5 Tracer)",
        tag: "ammo-autocannon-light-5-tracer",
        sort: "ammo, Light Autocannon/5, Tracer",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 6750,
        introduced: 3068,
        extinct: 0,
        reintroduced: 0,
        battleValue: 8,
        heat: 0,
        weight: 1,
        range: {
            min: 0,
            short: 5,
            medium: 10,
            long: 15
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 20,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [
            "DB",
            "S"
        ],
        techRating: "b",
        book: "TO",
        page: 353,
        alphaStrike: {
            heat: 1,
            rangeShort: 0.5,
            rangeMedium: 0.5,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: true,
            notes: []
        },
        heatAero: 0,
        alternateName: "Ammo (Light AC/5 Tracer)"
    },
    {
        isAmmo: true,
        name: "Ammo (MG)",
        alternateName: "Ammo (Machine Gun)",
        tag: "ammo-machine-gun",
        sort: "ammo, machine gun",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 1000,
        introduced: 1950,
        extinct: 0,
        reintroduced: 0,
        battleValue: 1,
        heat: 0,
        weight: 1,
        range: {
            min: 0,
            short: 1,
            medium: 2,
            long: 3
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 200,
        minAmmoTons: 0.5,
        explosive: true,
        weaponType: [
            "DB",
            "AI"
        ],
        techRating: "b",
        book: "TM",
        page: 227,
        alphaStrike: {
            heat: 0,
            rangeShort: 0,
            rangeMedium: 0,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: false,
            notes: []
        },
        heatAero: 0
    },
    {
        isAmmo: true,
        name: "Ammo (HMG)",
        tag: "ammo-machine-gun-heavy",
        sort: "ammo, machine gun, heavy",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 1000,
        introduced: 3060,
        extinct: 0,
        reintroduced: 0,
        battleValue: 1,
        heat: 0,
        weight: 1,
        range: {
            min: 0,
            short: 1,
            medium: 2,
            long: 3
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 100,
        minAmmoTons: 0.5,
        explosive: true,
        weaponType: [
            "DB",
            "AI"
        ],
        techRating: "b",
        book: "TM",
        page: 228,
        alphaStrike: {
            heat: 0,
            rangeShort: 0,
            rangeMedium: 0,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: false,
            notes: []
        },
        heatAero: 0,
        alternateName: "Ammo (Heavy Machine Gun)"
    },
    {
        isAmmo: true,
        name: "Ammo (LMG)",
        tag: "ammo-machine-gun-light",
        sort: "ammo, machine gun, light",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 500,
        introduced: 3060,
        extinct: 0,
        reintroduced: 0,
        battleValue: 1,
        heat: 0,
        weight: 1,
        range: {
            min: 0,
            short: 1,
            medium: 2,
            long: 3
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 200,
        minAmmoTons: 0.5,
        explosive: true,
        weaponType: [
            "DB",
            "AI"
        ],
        techRating: "b",
        book: "TM",
        page: 228,
        alphaStrike: {
            heat: 0,
            rangeShort: 0,
            rangeMedium: 0,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: false,
            notes: []
        },
        heatAero: 0,
        alternateName: "Ammo (Light Machine Gun)"
    },
    {
        isAmmo: true,
        name: "Ammo (Rotary AC/2)",
        tag: "ammo-rotary-ac-2",
        sort: "ammo, rotary, ac2",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 3000,
        introduced: 3062,
        extinct: 0,
        reintroduced: 0,
        battleValue: 15,
        heat: 0,
        weight: 1,
        range: {
            min: 3,
            short: 7,
            medium: 14,
            long: 21
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 45,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [
            "DB",
            "S"
        ],
        techRating: "e",
        book: "TM",
        page: 207,
        alphaStrike: {
            heat: 0,
            rangeShort: 0,
            rangeMedium: 0,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: false,
            notes: []
        },
        heatAero: 0
    },
    {
        isAmmo: true,
        name: "Ammo (Rotary AC/5)",
        tag: "ammo-rotary-ac-5",
        sort: "ammo, rotary, ac5",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 12000,
        introduced: 3062,
        extinct: 0,
        reintroduced: 0,
        battleValue: 31,
        heat: 0,
        weight: 1,
        range: {
            min: 3,
            short: 7,
            medium: 14,
            long: 21
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 20,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [
            "DB",
            "S"
        ],
        techRating: "e",
        book: "TM",
        page: 207,
        alphaStrike: {
            heat: 0,
            rangeShort: 0,
            rangeMedium: 0,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: false,
            notes: []
        },
        heatAero: 0
    },
    {
        isAmmo: true,
        name: "Ammo (Ultra AC/2)",
        tag: "ammo-autocannon-ultra-a",
        sort: "ammo, Ultra AC/a",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 1000,
        introduced: 3057,
        extinct: 0,
        reintroduced: 0,
        battleValue: 7,
        heat: 0,
        weight: 1,
        range: {
            min: 3,
            short: 8,
            medium: 17,
            long: 25
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 45,
        minAmmoTons: 0,
        explosive: true,
        weaponType: [
            "R",
            "C",
            "R2"
        ],
        techRating: "e",
        book: "TM",
        page: 208,
        alphaStrike: {
            heat: 0,
            rangeShort: 0,
            rangeMedium: 0,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: false,
            notes: []
        },
        heatAero: 0
    },
    {
        isAmmo: true,
        name: "Ammo (Ultra AC/5)",
        tag: "ammo-autocannon-ultra-b",
        sort: "ammo, Ultra AC/b",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 9000,
        introduced: 3035,
        extinct: 0,
        reintroduced: 0,
        battleValue: 14,
        heat: 0,
        weight: 1,
        range: {
            min: 2,
            short: 6,
            medium: 13,
            long: 20
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 20,
        minAmmoTons: 0,
        explosive: true,
        weaponType: [
            "R",
            "C",
            "R2"
        ],
        techRating: "e",
        book: "TM",
        page: 208,
        alphaStrike: {
            heat: 0,
            rangeShort: 0,
            rangeMedium: 0,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: false,
            notes: []
        },
        heatAero: 0
    },
    {
        name: "Ammo (Ultra AC/10)",
        tag: "ammo-autocannon-ultra-c",
        sort: "ammo, Ultra AC/c",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 12000,
        introduced: 3057,
        extinct: 0,
        reintroduced: 0,
        battleValue: 26,
        heat: 0,
        weight: 1,
        range: {
            min: 0,
            short: 6,
            medium: 12,
            long: 18
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 10,
        minAmmoTons: 1,
        explosive: true,
        weaponType: [
            "R",
            "C",
            "R2"
        ],
        techRating: "e",
        book: "TM",
        page: 208,
        alphaStrike: {
            heat: 0,
            rangeShort: 0,
            rangeMedium: 0,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: false,
            notes: []
        },
        heatAero: 0,
        isEquipment: false,
        isAmmo: true
    },
    {
        isAmmo: true,
        name: "Ammo (Ultra AC/20)",
        tag: "ammo-autocannon-ultra-d",
        sort: "ammo, Ultra AC/d",
        category: "Ammunition",
        damage: 0,
        notes: "",
        damageAero: 0,
        accuracyModifier: 0,
        cbills: 20000,
        introduced: 3060,
        extinct: 0,
        reintroduced: 0,
        battleValue: 35,
        heat: 0,
        weight: 1,
        range: {
            min: 0,
            short: 3,
            medium: 7,
            long: 10
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 5,
        minAmmoTons: 0,
        explosive: true,
        weaponType: [
            "R",
            "C",
            "R2"
        ],
        techRating: "e",
        book: "TM",
        page: 208,
        alphaStrike: {
            heat: 0,
            rangeShort: 0,
            rangeMedium: 0,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: false,
            notes: []
        },
        heatAero: 0
    },
    {
        name: "Autocannon/2",
        alternateName: "ac/2",
        tag: "autocannon-standard-a",
        sort: "Autocannon/a",
        category: "Ballistic Weapons",
        damage: 2,
        damageAero: 2,
        accuracyModifier: 0,
        cbills: 75000,
        introduced: 2300,
        extinct: 0,
        reintroduced: 0,
        battleValue: 37,
        heat: 1,
        weight: 6,
        range: {
            min: 4,
            short: 8,
            medium: 16,
            long: 24
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 45,
        minAmmoTons: 1,
        explosive: false,
        weaponType: [
            "DB",
            "S"
        ],
        techRating: "c",
        book: "TM",
        page: 208,
        alphaStrike: {
            heat: 1,
            rangeShort: 0.132,
            rangeMedium: 0.2,
            rangeLong: 0.2,
            rangeExtreme: 0.2,
            tc: true,
            notes: [
                "ac"
            ]
        },
        heatAero: 1,
        rangeAero: "l"
    },
    {
        name: "Autocannon/5",
        alternateName: "ac/5",
        tag: "autocannon-standard-b",
        sort: "Autocannon/b",
        category: "Ballistic Weapons",
        damage: 5,
        damageAero: 5,
        accuracyModifier: 0,
        cbills: 125000,
        introduced: 2250,
        extinct: 0,
        reintroduced: 0,
        battleValue: 70,
        heat: 1,
        weight: 8,
        range: {
            min: 3,
            short: 6,
            medium: 12,
            long: 18
        },
        space: {
            battlemech: 4,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 4,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 0,
        minAmmoTons: 0,
        explosive: false,
        weaponType: [
            "DB",
            "S"
        ],
        techRating: "c",
        book: "TM",
        page: 208,
        alphaStrike: {
            heat: 1,
            rangeShort: 0.375,
            rangeMedium: 0.5,
            rangeLong: 0.5,
            rangeExtreme: 0,
            tc: true,
            notes: [
                "ac"
            ]
        },
        heatAero: 1,
        rangeAero: "m"
    },
    {
        name: "Autocannon/10",
        alternateName: "ac/10",
        tag: "autocannon-standard-c",
        sort: "Autocannon/c",
        category: "Ballistic Weapons",
        damage: 10,
        damageAero: 10,
        accuracyModifier: 0,
        cbills: 200000,
        introduced: 2460,
        extinct: 0,
        reintroduced: 0,
        battleValue: 123,
        heat: 3,
        weight: 12,
        range: {
            min: 0,
            short: 5,
            medium: 10,
            long: 15
        },
        space: {
            battlemech: 7,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 7,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 0,
        minAmmoTons: 0,
        explosive: false,
        weaponType: [
            "DB",
            "S"
        ],
        techRating: "c",
        book: "TM",
        page: 208,
        alphaStrike: {
            heat: 3,
            rangeShort: 1,
            rangeMedium: 1,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: true,
            notes: [
                "ac"
            ]
        },
        heatAero: 3,
        rangeAero: "m"
    },
    {
        name: "Autocannon/20",
        alternateName: "ac/20",
        tag: "autocannon-standard-d",
        sort: "Autocannon/d",
        category: "Ballistic Weapons",
        damage: 20,
        damageAero: 20,
        accuracyModifier: 0,
        cbills: 300000,
        introduced: 2500,
        extinct: 0,
        reintroduced: 0,
        battleValue: 178,
        heat: 7,
        weight: 14,
        range: {
            min: 0,
            short: 3,
            medium: 6,
            long: 9
        },
        space: {
            battlemech: 10,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 10,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 0,
        minAmmoTons: 0,
        explosive: false,
        weaponType: [
            "DB",
            "S"
        ],
        techRating: "c",
        book: "TM",
        page: 208,
        alphaStrike: {
            heat: 7,
            rangeShort: 2,
            rangeMedium: 2,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: true,
            notes: [
                "ac"
            ]
        },
        heatAero: 7,
        rangeAero: "s"
    },
    {
        name: "Light Gauss Rifle",
        tag: "gauss-rifle-light",
        sort: "gauss rifle, a",
        category: "Ballistic Weapons",
        damage: 8,
        damageAero: 8,
        accuracyModifier: 0,
        cbills: 275000,
        introduced: 3056,
        extinct: 0,
        reintroduced: 0,
        battleValue: 159,
        heat: 1,
        weight: 12,
        range: {
            min: 3,
            short: 8,
            medium: 17,
            long: 25
        },
        space: {
            battlemech: 5,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 5,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 0,
        minAmmoTons: 1,
        gauss: true,
        weaponType: [
            "DB"
        ],
        techRating: "e",
        book: "TM",
        page: 219,
        alphaStrike: {
            heat: 1,
            rangeShort: 0.6,
            rangeMedium: 0.8,
            rangeLong: 0.8,
            rangeExtreme: 0,
            tc: true,
            notes: []
        },
        heatAero: 1,
        rangeAero: "e",
        explosive: true
    },
    {
        name: "Gauss Rifle",
        tag: "standard-gauss-rifle",
        sort: "gauss rifle, b",
        category: "Ballistic Weapons",
        damage: 15,
        damageAero: 15,
        accuracyModifier: 0,
        cbills: 300000,
        introduced: 2590,
        extinct: 2865,
        reintroduced: 3040,
        battleValue: 320,
        heat: 1,
        weight: 15,
        range: {
            min: 2,
            short: 7,
            medium: 15,
            long: 22
        },
        space: {
            battlemech: 7,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 7,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 0,
        minAmmoTons: 1,
        gauss: true,
        weaponType: [
            "DB"
        ],
        techRating: "e",
        book: "TM",
        page: 219,
        alphaStrike: {
            heat: 1,
            rangeShort: 1.245,
            rangeMedium: 1.5,
            rangeLong: 1.5,
            rangeExtreme: 0,
            tc: true,
            notes: []
        },
        heatAero: 1,
        rangeAero: "l",
        explosive: true
    },
    {
        name: "Heavy Gauss Rifle",
        tag: "gauss-rifle-heavy",
        sort: "gauss rifle, c",
        category: "Ballistic Weapons",
        damage: {
            short: 25,
            medium: 20,
            long: 10,
            aeroShort: 25,
            aeroMedium: 20,
            aeroLong: 10
        },
        accuracyModifier: 0,
        cbills: 500000,
        introduced: 3061,
        extinct: 0,
        reintroduced: 0,
        battleValue: 346,
        heat: 2,
        weight: 18,
        range: {
            min: 4,
            short: 6,
            medium: 13,
            long: 20
        },
        space: {
            battlemech: 11,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 11,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 0,
        minAmmoTons: 1,
        gauss: true,
        weaponType: [
            "DB"
        ],
        techRating: "e",
        book: "TM",
        page: 218,
        alphaStrike: {
            heat: 2,
            rangeShort: 1.65,
            rangeMedium: 2,
            rangeLong: 1,
            rangeExtreme: 0,
            tc: true,
            notes: []
        },
        heatAero: 2,
        rangeAero: "l",
        explosive: true
    },
    {
        name: "Improved Heavy Gauss Rifle",
        tag: "gauss-rifle-heavy-improved",
        sort: "gauss rifle, d",
        category: "Ballistic Weapons",
        damage: 22,
        damageAero: 22,
        accuracyModifier: 0,
        cbills: 500000,
        introduced: 3081,
        extinct: 0,
        reintroduced: 0,
        battleValue: 346,
        heat: 2,
        weight: 20,
        range: {
            min: 3,
            short: 6,
            medium: 12,
            long: 19
        },
        space: {
            battlemech: 11,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 11,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 0,
        minAmmoTons: 1,
        gauss: true,
        weaponType: [
            "DB"
        ],
        techRating: "e",
        book: "TO",
        page: 313,
        alphaStrike: {
            heat: 2,
            rangeShort: 1.65,
            rangeMedium: 2.2,
            rangeLong: 2.2,
            rangeExtreme: 0,
            tc: true,
            notes: []
        },
        heatAero: 2,
        explosive: true
    },
    {
        name: "Silver Bullet Gauss Rifle",
        tag: "silver-bullet-gauss-rifle",
        sort: "gauss rifle, e",
        category: "Ballistic Weapons",
        damagePerCluster: 1,
        damageClusters: 15,
        damageAero: 9,
        accuracyModifier: -1,
        cbills: 350000,
        introduced: 3080,
        extinct: 0,
        reintroduced: 0,
        battleValue: 198,
        heat: 1,
        weight: 15,
        range: {
            min: 2,
            short: 7,
            medium: 15,
            long: 22
        },
        space: {
            battlemech: 7,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 7,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 0,
        minAmmoTons: 1,
        gauss: true,
        weaponType: [
            "DB",
            "C",
            "F"
        ],
        techRating: "e",
        book: "TO",
        page: 314,
        alphaStrike: {
            heat: 1,
            rangeShort: 0.784,
            rangeMedium: 0.945,
            rangeLong: 0.945,
            rangeExtreme: 0,
            tc: true,
            notes: []
        },
        heatAero: 1,
        explosive: true
    },
    {
        name: "MagShot Gauss Rifle",
        tag: "gauss-rifle-magshot",
        sort: "gauss rifle, f",
        category: "Ballistic Weapons",
        damage: 2,
        damageAero: 2,
        accuracyModifier: 0,
        cbills: 8500,
        introduced: 3072,
        extinct: 0,
        reintroduced: 0,
        battleValue: 15,
        heat: 1,
        weight: 0.5,
        range: {
            min: 0,
            short: 3,
            medium: 6,
            long: 9
        },
        space: {
            battlemech: 2,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 2,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 0,
        minAmmoTons: 1,
        gauss: true,
        weaponType: [
            "DB"
        ],
        techRating: "e",
        book: "TO",
        page: 314,
        alphaStrike: {
            heat: 1,
            rangeShort: 0.2,
            rangeMedium: 0.2,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: true,
            notes: []
        },
        heatAero: 1,
        explosive: true
    },
    {
        name: "LB 2-X AC",
        tag: "autocannon-lb-x-a",
        sort: "LB a-X AC",
        category: "Ballistic Weapons",
        damagePerCluster: 1,
        damageClusters: 2,
        damageAero: 2,
        accuracyModifier: 0,
        cbills: 150000,
        introduced: 3058,
        extinct: 0,
        reintroduced: 0,
        battleValue: 42,
        heat: 1,
        weight: 6,
        range: {
            min: 4,
            short: 9,
            medium: 18,
            long: 27
        },
        space: {
            battlemech: 4,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 4,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 45,
        minAmmoTons: 1,
        explosive: false,
        weaponType: [
            "DB",
            "C",
            "S",
            "F"
        ],
        techRating: "e",
        book: "TM",
        page: 207,
        alphaStrike: {
            heat: 1,
            rangeShort: 0.069,
            rangeMedium: 0.105,
            rangeLong: 0.105,
            rangeExtreme: 0.105,
            tc: true,
            notes: [
                "flak"
            ]
        },
        heatAero: 1,
        rangeAero: "e"
    },
    {
        name: "LB 5-X AC",
        tag: "autocannon-lb-x-b",
        sort: "LB b-X AC",
        category: "Ballistic Weapons",
        damagePerCluster: 1,
        damageClusters: 5,
        damageAero: 3,
        accuracyModifier: 0,
        cbills: 250000,
        introduced: 3058,
        extinct: 0,
        reintroduced: 0,
        battleValue: 83,
        heat: 1,
        weight: 8,
        range: {
            min: 3,
            short: 7,
            medium: 14,
            long: 21
        },
        space: {
            battlemech: 5,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 5,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 20,
        minAmmoTons: 1,
        explosive: false,
        weaponType: [
            "DB",
            "C",
            "S",
            "F"
        ],
        techRating: "e",
        book: "TM",
        page: 207,
        alphaStrike: {
            heat: 1,
            rangeShort: 0.236,
            rangeMedium: 0.315,
            rangeLong: 0.315,
            rangeExtreme: 0,
            tc: true,
            notes: [
                "flak"
            ]
        },
        heatAero: 1,
        rangeAero: "l"
    },
    {
        name: "LB 10-X AC",
        tag: "autocannon-lb-x-c",
        sort: "LB c-X AC",
        category: "Ballistic Weapons",
        damagePerCluster: 1,
        damageClusters: 10,
        damageAero: 6,
        accuracyModifier: 0,
        cbills: 400000,
        introduced: 2595,
        extinct: 2840,
        reintroduced: 3035,
        battleValue: 148,
        heat: 2,
        weight: 11,
        range: {
            min: 0,
            short: 6,
            medium: 12,
            long: 18
        },
        space: {
            battlemech: 6,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 6,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 10,
        minAmmoTons: 1,
        explosive: false,
        weaponType: [
            "DB",
            "C",
            "S",
            "F"
        ],
        techRating: "e",
        book: "TM",
        page: 207,
        alphaStrike: {
            heat: 2,
            rangeShort: 0.63,
            rangeMedium: 0.63,
            rangeLong: 0.63,
            rangeExtreme: 0,
            tc: true,
            notes: [
                "flak"
            ]
        },
        heatAero: 2,
        rangeAero: "m"
    },
    {
        name: "LB 20-X AC",
        tag: "autocannon-lb-x-d",
        sort: "LB d-X AC",
        category: "Ballistic Weapons",
        damagePerCluster: 1,
        damageClusters: 20,
        damageAero: 12,
        accuracyModifier: 0,
        cbills: 600000,
        introduced: 3058,
        extinct: 0,
        reintroduced: 0,
        battleValue: 237,
        heat: 6,
        weight: 14,
        range: {
            min: 0,
            short: 4,
            medium: 8,
            long: 12
        },
        space: {
            battlemech: 11,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 11,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 5,
        minAmmoTons: 1,
        explosive: false,
        weaponType: [
            "DB",
            "C",
            "S",
            "F"
        ],
        techRating: "e",
        book: "TM",
        page: 207,
        alphaStrike: {
            heat: 6,
            rangeShort: 1.26,
            rangeMedium: 1.26,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: true,
            notes: [
                "flak"
            ]
        },
        heatAero: 6,
        rangeAero: "m"
    },
    {
        name: "Light Autocannon/2",
        tag: "autocannon-light-2",
        sort: "Light Autocannon/2",
        category: "Ballistic Weapons",
        damage: 2,
        damageAero: 2,
        accuracyModifier: 0,
        cbills: 100000,
        introduced: 3068,
        extinct: 0,
        reintroduced: 0,
        battleValue: 30,
        heat: 0,
        weight: 4,
        range: {
            min: 0,
            short: 6,
            medium: 12,
            long: 18
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 45,
        minAmmoTons: 1,
        explosive: false,
        weaponType: [
            "DB",
            "S"
        ],
        techRating: "e",
        book: "TM",
        page: 208,
        alphaStrike: {
            heat: 1,
            rangeShort: 0.2,
            rangeMedium: 0.2,
            rangeLong: 0.2,
            rangeExtreme: 0,
            tc: true,
            notes: [
                "ac"
            ]
        },
        heatAero: 0,
        rangeAero: "m",
        alternateName: "Light AC/2"
    },
    {
        name: "Light Autocannon/5",
        tag: "autocannon-light-5",
        sort: "Light Autocannon/5",
        category: "Ballistic Weapons",
        damage: 5,
        damageAero: 5,
        accuracyModifier: 0,
        cbills: 150000,
        introduced: 3068,
        extinct: 0,
        reintroduced: 0,
        battleValue: 62,
        heat: 0,
        weight: 5,
        range: {
            min: 0,
            short: 5,
            medium: 10,
            long: 15
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 20,
        minAmmoTons: 1,
        explosive: false,
        weaponType: [
            "DB",
            "S"
        ],
        techRating: "e",
        book: "TM",
        page: 208,
        alphaStrike: {
            heat: 1,
            rangeShort: 0.5,
            rangeMedium: 0.5,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: true,
            notes: []
        },
        heatAero: 0,
        rangeAero: "m",
        alternateName: "Light AC/5"
    },
    {
        name: "Machine Gun",
        tag: "machine-gun",
        sort: "machine gun",
        category: "Ballistic Weapons",
        damage: 2,
        damageAero: 2,
        accuracyModifier: 0,
        cbills: 5000,
        introduced: 1950,
        extinct: 0,
        reintroduced: 0,
        battleValue: 5,
        heat: 0,
        weight: 0.5,
        range: {
            min: 0,
            short: 1,
            medium: 2,
            long: 3
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 0,
        minAmmoTons: 0.5,
        explosive: false,
        weaponType: [
            "DB",
            "AI"
        ],
        techRating: "b",
        book: "TM",
        page: 227,
        alphaStrike: {
            heat: 0,
            rangeShort: 0.2,
            rangeMedium: 0,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: false,
            notes: [
                "Point Defense"
            ]
        },
        heatAero: 0,
        rangeAero: "s"
    },
    {
        name: "Heavy Machine Gun",
        tag: "machine-gun-heavy",
        sort: "machine gun, heavy",
        category: "Ballistic Weapons",
        damage: 3,
        damageAero: 3,
        accuracyModifier: 0,
        cbills: 7500,
        introduced: 3060,
        extinct: 0,
        reintroduced: 0,
        battleValue: 6,
        heat: 0,
        weight: 1,
        range: {
            min: 0,
            short: 1,
            medium: 2,
            long: 0
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 0,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 0,
        minAmmoTons: 0.5,
        explosive: false,
        weaponType: [
            "DB",
            "AI"
        ],
        techRating: "b",
        book: "TM",
        page: 228,
        alphaStrike: {
            heat: 0,
            rangeShort: 0.3,
            rangeMedium: 0,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: false,
            notes: [
                "Point Defense"
            ]
        },
        heatAero: 0,
        rangeAero: "s"
    },
    {
        name: "Light Machine Gun",
        tag: "machine-gun-light",
        sort: "machine gun, light",
        category: "Ballistic Weapons",
        damage: 2,
        damageAero: 2,
        accuracyModifier: 0,
        cbills: 5000,
        introduced: 3060,
        extinct: 0,
        reintroduced: 0,
        battleValue: 5,
        heat: 0,
        weight: 0.5,
        range: {
            min: 0,
            short: 2,
            medium: 4,
            long: 6
        },
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 1,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 0,
        minAmmoTons: 0.5,
        explosive: false,
        weaponType: [
            "DB",
            "AI"
        ],
        techRating: "b",
        book: "TM",
        page: 228,
        alphaStrike: {
            heat: 0,
            rangeShort: 0.1,
            rangeMedium: 0.1,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: false,
            notes: [
                "Point Defense"
            ]
        },
        heatAero: 0,
        rangeAero: "s"
    },
    {
        name: "Hatchet",
        tag: "melee-hatchet",
        sort: "melee, hatchet",
        category: "Melee",
        weight: 0,
        variableSize: true,
        isMelee: true,
        cbills: 0,
        weightDivisor: 15,
        damageDivisor: 5,
        criticalsDivisor: 15,
        requiresHandActuator: true,
        introduced: 3022,
        extinct: 0,
        reintroduced: 0,
        heat: 0,
        unique: true,
        costPerItemTon: 5000,
        battleValuePerItemDamage: 1.5,
        range: {
            min: 0,
            short: 0,
            medium: 0,
            long: 0
        },
        book: "TM",
        page: 220,
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: -1,
            supportVehicle: -1,
            aerospaceFighter: -1,
            smallCraft: -1,
            dropShip: -1
        },
        alphaStrike: {
            heat: 0,
            rangeShort: 0,
            rangeMedium: 0,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: false,
            notes: [
                "MEL"
            ]
        },
        heatAero: 0
    },
    {
        name: "Sword",
        tag: "melee-sword",
        sort: "melee, sword",
        category: "Melee",
        weight: 0,
        variableSize: true,
        isMelee: true,
        cbills: 0,
        weightDivisor: 20,
        damageDivisor: 10,
        damageBonus: 1,
        criticalsDivisor: 15,
        requiresHandActuator: true,
        introduced: 3058,
        extinct: 0,
        reintroduced: 0,
        heat: 0,
        unique: true,
        costPerItemTon: 10000,
        battleValuePerItemDamage: 1.725,
        range: {
            min: 0,
            short: 0,
            medium: 0,
            long: 0
        },
        book: "TM",
        page: 237,
        space: {
            battlemech: 1,
            protomech: -1,
            combatVehicle: -1,
            supportVehicle: -1,
            aerospaceFighter: -1,
            smallCraft: -1,
            dropShip: -1
        },
        alphaStrike: {
            heat: 0,
            rangeShort: 0,
            rangeMedium: 0,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: false,
            notes: [
                "MEL"
            ]
        },
        heatAero: 0
    },
    {
        uuid: "",
        notes: "",
        count: 0,
        name: "Rotary AC/2",
        alternateName: "",
        tag: "rotary-ac-2",
        sort: "rotary, autocannon, 2",
        category: "",
        damage: 2,
        damageAero: 8,
        heatAero: 6,
        damagePerCluster: 0,
        damageClusters: 0,
        accuracyModifier: 0,
        accuracyModifiier: 0,
        cbills: 175000,
        cbillsOneShot: 0,
        introduced: 3062,
        extinct: 0,
        reintroduced: 0,
        battleValue: 118,
        battleValueOneShot: 0,
        heat: 1,
        weight: 8,
        range: {
            min: 0,
            short: 6,
            medium: 12,
            long: 18,
            extreme: 0
        },
        space: {
            battlemech: 3,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 3,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 0,
        minAmmoTons: 0,
        explosive: false,
        gauss: false,
        weaponType: [],
        techRating: "",
        unique: false,
        book: "TM",
        page: 207,
        alphaStrike: {
            heat: 6,
            rangeShort: 0.8,
            rangeMedium: 0.8,
            rangeLong: 0.8,
            rangeExtreme: 0,
            tc: true,
            notes: []
        },
        battleValuePerItemDamage: 0,
        requiresHandActuator: false,
        weightDivisor: 0,
        damageDivisor: 0,
        criticalsDivisor: 0,
        variableSize: false,
        isMelee: false,
        costPerItemTon: 0,
        location: "",
        rear: false,
        criticals: 0,
        available: false,
        damagePerShot: true,
        heatPerShot: true,
        rangeAero: "m",
        isRotary: true
    },
    {
        uuid: "",
        notes: "",
        count: 0,
        name: "Rotary AC/5",
        alternateName: "",
        tag: "rotary-ac-5",
        sort: "rotary, autocannon, 5",
        category: "",
        damage: 5,
        damageAero: 20,
        heatAero: 6,
        damagePerCluster: 0,
        damageClusters: 0,
        accuracyModifier: 0,
        accuracyModifiier: 0,
        cbills: 275000,
        cbillsOneShot: 0,
        introduced: 3062,
        extinct: 0,
        reintroduced: 0,
        battleValue: 247,
        battleValueOneShot: 0,
        heat: 1,
        weight: 10,
        range: {
            min: 0,
            short: 5,
            medium: 10,
            long: 15,
            extreme: 0
        },
        space: {
            battlemech: 6,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 6,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 0,
        minAmmoTons: 0,
        explosive: false,
        gauss: false,
        weaponType: [],
        techRating: "",
        unique: false,
        book: "TM",
        page: 207,
        alphaStrike: {
            heat: 6,
            rangeShort: 2,
            rangeMedium: 2,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: true,
            notes: []
        },
        battleValuePerItemDamage: 0,
        requiresHandActuator: false,
        weightDivisor: 0,
        damageDivisor: 0,
        criticalsDivisor: 0,
        variableSize: false,
        isMelee: false,
        costPerItemTon: 0,
        location: "",
        rear: false,
        criticals: 0,
        available: false,
        damagePerShot: true,
        heatPerShot: true,
        rangeAero: "m",
        isRotary: true
    },
    {
        name: "Ultra AC/2",
        tag: "autocannon-ultra-a",
        sort: "Ultra AC/a",
        category: "Ballistic Weapons",
        damagePerCluster: 2,
        damageClusters: 2,
        damageAero: 3,
        accuracyModifier: 0,
        cbills: 150000,
        introduced: 3057,
        extinct: 0,
        reintroduced: 0,
        battleValue: 56,
        heat: 1,
        weight: 7,
        range: {
            min: 3,
            short: 8,
            medium: 17,
            long: 25
        },
        space: {
            battlemech: 3,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 3,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 45,
        minAmmoTons: 1,
        explosive: false,
        weaponType: [
            "R",
            "C",
            "R2"
        ],
        techRating: "e",
        book: "TM",
        page: 208,
        alphaStrike: {
            heat: 2,
            rangeShort: 0.225,
            rangeMedium: 0.3,
            rangeLong: 0.3,
            rangeExtreme: 0.3,
            tc: true,
            notes: []
        },
        heatAero: 1,
        rangeAero: "e",
        isUltra: true
    },
    {
        name: "Ultra AC/5",
        tag: "autocannon-ultra-b",
        sort: "Ultra AC/b",
        category: "Ballistic Weapons",
        damagePerCluster: 5,
        damageClusters: 2,
        damageAero: 7,
        accuracyModifier: 0,
        cbills: 200000,
        introduced: 3035,
        extinct: 0,
        reintroduced: 0,
        battleValue: 112,
        heat: 1,
        weight: 9,
        range: {
            min: 2,
            short: 6,
            medium: 13,
            long: 20
        },
        space: {
            battlemech: 5,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 5,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 20,
        minAmmoTons: 1,
        explosive: false,
        weaponType: [
            "R",
            "C",
            "R2"
        ],
        techRating: "e",
        book: "TM",
        page: 208,
        alphaStrike: {
            heat: 2,
            rangeShort: 0.623,
            rangeMedium: 0.75,
            rangeLong: 0.75,
            rangeExtreme: 0,
            tc: true,
            notes: []
        },
        heatAero: 1,
        rangeAero: "l",
        isUltra: true
    },
    {
        name: "Ultra AC/10",
        tag: "autocannon-ultra-c",
        sort: "Ultra AC/c",
        category: "Ballistic Weapons",
        damagePerCluster: 10,
        damageClusters: 2,
        damageAero: 15,
        accuracyModifier: 0,
        cbills: 320000,
        introduced: 3057,
        extinct: 0,
        reintroduced: 0,
        battleValue: 210,
        heat: 4,
        weight: 13,
        range: {
            min: 0,
            short: 6,
            medium: 12,
            long: 18
        },
        space: {
            battlemech: 7,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 7,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 10,
        minAmmoTons: 1,
        explosive: false,
        weaponType: [
            "R",
            "C",
            "R2"
        ],
        techRating: "e",
        book: "TM",
        page: 208,
        alphaStrike: {
            heat: 8,
            rangeShort: 1.5,
            rangeMedium: 1.5,
            rangeLong: 1.5,
            rangeExtreme: 0,
            tc: true,
            notes: []
        },
        heatAero: 4,
        rangeAero: "m",
        isUltra: true
    },
    {
        name: "Ultra AC/20",
        tag: "autocannon-ultra-d",
        sort: "Ultra AC/d",
        category: "Ballistic Weapons",
        damagePerCluster: 20,
        damageClusters: 2,
        damageAero: 30,
        accuracyModifier: 0,
        cbills: 480000,
        introduced: 3060,
        extinct: 0,
        reintroduced: 0,
        battleValue: 281,
        heat: 8,
        weight: 15,
        range: {
            min: 0,
            short: 3,
            medium: 7,
            long: 10
        },
        space: {
            battlemech: 10,
            protomech: -1,
            combatVehicle: 1,
            supportVehicle: 10,
            aerospaceFighter: 1,
            smallCraft: 1,
            dropShip: 1
        },
        ammoPerTon: 5,
        minAmmoTons: 1,
        explosive: false,
        weaponType: [
            "R",
            "C",
            "R2"
        ],
        techRating: "e",
        book: "TM",
        page: 208,
        alphaStrike: {
            heat: 16,
            rangeShort: 3,
            rangeMedium: 3,
            rangeLong: 0,
            rangeExtreme: 0,
            tc: true,
            notes: []
        },
        heatAero: 8,
        rangeAero: "m",
        isUltra: true
    }
]